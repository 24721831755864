import { useContext, useEffect, useState } from "react";
import "../../Inventory Management System/Styles/tax.css";


import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Select from "react-select";

import { useNavigate, useLocation  } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { getWithAuthCall, putWithAuthCall } from "../../../api/ApiServices";
import { notifySuccess } from "../../../toaster/notify";


const UpdateVariant = () => {
  const {selectedStoreID,sidebar} =useContext(AppContext)

    const location = useLocation();
    const { row } = location.state || {}; 
    console.log("update Varuiant Id >>>>>",row)
  const navigetion = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [unitList, setUnitList] = useState([])
  const [addVariant, setAddVariant] = useState({
    name: "",
    groupname: "",
    unit_of_measurement: "",
    size: "",
    quantity: ""


  })


const [errors, setErrors] = useState({
  name: "",
  groupname: "",
  unit_of_measurement: "",
  size: "",
  quantity: "",
});

  

  console.log("addVariant>>>>", addVariant)

  const DeparmnetList = [
    { value: "Size", label: "Size" },
    { value: "Portion", label: "Portion" },
    { value: "Quantity", label: "Quantity" },
    { value: "Portion Size", label: "Portion Size" },
    { value: "Customisation", label: "Customisation" },
    { value: "Preparation", label: "Preparation" },
  ]

  // Sample Data
  const data = [
    "Human Resources",
    "Finance",
    "Marketing",
    "Sales",
    "Engineering",
    "Customer Support",

  ];

  const filteredData = data.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Handle Select
  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const actions1 = [
    "ml",
    "liters",
    "grams",
    "kg",
    "Grocery Store 01",
    "Lorem Ipsum 01",
    "Lorem Ipsum 02",
  ];

  const handleselectData=(select,)=>{
    console.log("unit_of_measurement>>>>",select)
    setAddVariant({
      ...addVariant,
      unit_of_measurement:select.value
    })

  }

  const handleselectDepartment=(select,)=>{
    console.log("groupname>>>>",select)
    setAddVariant({
      ...addVariant,
      groupname:select.value
    })

  }

  const validateForm = () => {
    let isValid = true;
    let validationErrors = {};
  
    // Check if the variant name is empty
    if (!addVariant.name) {
      validationErrors.name = "Variant Name is required.";
      isValid = false;
    }
  
    // Check if the group name is selected
    if (!addVariant.groupname) {
      validationErrors.groupname = "Department is required.";
      isValid = false;
    }
  
    // Check if the unit of measurement is selected
    if (!addVariant.unit_of_measurement) {
      validationErrors.unit_of_measurement = "Unit of Measure is required.";
      isValid = false;
    }
  
    // Check if the size is provided
    if (!addVariant.size) {
      validationErrors.size = "Size is required.";
      isValid = false;
    }
  
    // Check if the quantity is provided and is a number
    if (!addVariant.quantity || isNaN(addVariant.quantity)) {
      validationErrors.quantity = "Minimum Quantity is required ";
      isValid = false;
    }
  
    setErrors(validationErrors);
    return isValid;
  };

  const onSubmitUpdateVariationData=()=>{

    if (!validateForm()) {
      return; // Don't submit if validation fails
    }
  

    const body={
      "name": addVariant?.name,
      "groupname": addVariant?.groupname,
      "status": row.variation_status,
      "shop_id":selectedStoreID,
      "minimum_quantity": addVariant?.quantity,
      "size":addVariant?.size,
      "unit_of_measurment": addVariant?.unit_of_measurement
    }


    putWithAuthCall(ApiConfig.ADD_VARIATION+`/${row.id}`,JSON.stringify(body))
    .then((res)=>{
      console.log("submit varation response>>>",res)
      if(res.success === true){
        notifySuccess(res.message)
        navigetion("/BaseMenu")
        setAddVariant("");
        setErrors({});
      }
    })
    .catch((err)=>{
      console.log("submit varation error>>>",err)
    })


  }

  const getUnitMasurmentList = () => {
    getWithAuthCall(ApiConfig?.UNIT_MEASUREMNET)
      .then((res) => {
        console.log("getUnitMasurmentList>>>>", res.data)
        const unitresponse = res.data.map((item) => ({
          value: item.id,
          label: item.unit_of_measurement
        }))
        setUnitList(unitresponse)
      })
      .catch((err) => {
        console.log("getUnitMasurmentList>>>>", err)
      })
  }


  useEffect(() => {
    getUnitMasurmentList()

    if(row){
        setAddVariant({
            ...addVariant,
            name:row.variation_name,
            groupname:row?.variation_group_name,
            quantity:row.minimum_quantity,
            size:row.size,
            unit_of_measurement:row.unit_of_measurement_id
            
        })
    }

    

  }, [row])


  return (
    <>
    <div
    className={sidebar ? "taskMain " : "cx-active taskMain"}
    id="cx-main"
  >
    <div className="role-content">
    <div className="sub-header-role ">
        <h6 className="mt-2">Stock List</h6>
      </div>
      <div className="p-2">
       


        <div className="">
          <div className="flex flex-column">
            {/* <form> */}
            <div className="w-full bg-white p-4  border border-gray-300">
              <div className="row g-3">
                {/* Department */}
                <div className="col-md-4" >
                  <label htmlFor="department" className="form-label tax-type-label">
                    Department<span style={{ color: "red" }}>*</span>
                  </label>

                  <div className="position-relative z-3 w-full">

                    <Select
                      options={DeparmnetList}
                      onChange={handleselectDepartment}
                      value={DeparmnetList?.filter((val)=>val?.value==addVariant?.groupname)}
                      placeholder="Select Department"
                    />
                         {errors.groupname && (
              <span style={{ color: "red" }}>{errors.groupname}</span>
            )}
                  </div>



                </div>

                {/* Variant Name */}
                <div className="col-md-4">
                  <label
                    htmlFor="variantName"
                    className="form-label tax-type-label"
                  >
                    Variant Name<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    type="text"
                    id="variantName"
                    className="form-control tax-type-label"
                    name="variantName"
                    placeholder="700ml Bottle"
                    onChange={(e) => setAddVariant({
                      ...addVariant,
                      name: e.target.value
                    })}
                    value={addVariant.name}
                  />
                   {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                </div>

                {/* Unit of Measure */}
                <div className="col-md-4">
                  <label
                    htmlFor="unitOfMeasure"
                    className="form-label tax-type-label tax-type-label"
                  >
                    Unit of Measure<span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    options={unitList}
                    onChange={handleselectData}
                    placeholder="Select Unit of Measure"
                    value={unitList?.filter((val)=>val?.value==addVariant?.unit_of_measurement)}
                  />
   {errors.unit_of_measurement && (
              <span style={{ color: "red" }}>{errors.unit_of_measurement}</span>
            )}
                </div>
              </div>
              <div className="row g-3">
                {/* Department */}
                <div className="col-md-4">
                  <label
                    htmlFor="department"
                    className="form-label tax-type-label"
                  >
                    Size<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="variantName"
                    className="form-control tax-type-label"
                    name="variantName"
                    placeholder="1"
                    onChange={(e) => setAddVariant({
                      ...addVariant,
                      size: e.target.value
                    })}
                    value={addVariant.size}
                  />
                     {errors.size && <span style={{ color: "red" }}>{errors.size}</span>}
                </div>

         
                <div className="col-md-4">
                  <label
                    htmlFor="variantName"
                    className="form-label tax-type-label"
                  >
                    Minimum Quantity<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="variantName"
                    className="form-control tax-type-label"
                    name="variantName"
                    placeholder="20"
                    onChange={(e) => setAddVariant({
                      ...addVariant,
                      quantity: e.target.value
                    })}
                    value={addVariant.quantity}
                  />
                       {errors.quantity && (
              <span style={{ color: "red" }}>{errors.quantity}</span>
            )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-end gap-3 pt-2">
                
                <button className="btn " style={{backgroundColor:"#53B7E8", color:"#fff"}} type="button"
                onClick={onSubmitUpdateVariationData}
                >
                  <label className="add-text">Update variantions</label>
                </button>
                <button className="btn " style={{border:"1px solid #53B7E8", color:"#53B7E8"}} type="button"
                onClick={()=>navigetion("/BaseMenu")}
                >
                  <label className=" add-"> Cancel</label>
                </button>

              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default UpdateVariant;
