import ActiveMan from "../assets/images/userActive.svg";
import CRMDashboard_active from "../assets/images/CRMDashboard_active.svg";
import CRMDashboard_inactive from "../assets/images/sidebar/CRMDahboard.svg";
import CRMOfficeCaller_active from "../assets/images/CRMOfficecaller_active..svg";
import CRMphone_iactive from "../assets/images/sidebar/CRMPhonebook.svg";
import ClientMan from "../assets/images/sidebar/clientMan.svg";
import ClientManActive from "../assets/images/sidebar/clientManActive.svg";
import Compliance from "../assets/images/sidebar/icons/ComplianceIcon.svg";
import ComplianceActive from "../assets/images/sidebar/icons/ComplianceActiveIcon.svg";
import EmployeeManagementActiveIcon from "../assets/images/sidebar/icons/EmployeeManagementActiveIcon.svg";
import EmployeeManagementIcon from "../assets/images/sidebar/icons/EmployeeManagementIcon.svg";
import GeneralSettingActiveIcon from "../assets/images/sidebar/icons/GeneralSettingActiveIcon.svg";
import GeneralSettingIcon from "../assets/images/sidebar/icons/GeneralSettingIcon.svg";
import Logo from "../assets/images/wifi-attendance.svg";
import Organ_Dark from "../assets/images/sidebar/icons/OrganDark.svg";
import Organ_active from "../assets/images/sidebar/icons/Organ.svg";
import ProjectMan_Dark from "../assets/images/sidebar/icons/ProjectManDark.svg";
import ProjectMan_active from "../assets/images/sidebar/icons/ProjectMan.svg";
import React, { useContext, useEffect, useState } from "react";
import TeamsManagementActiveIcon from "../assets/images/sidebar/icons/TeamsManagementActiveIcon.svg";
import TeamsManagementIcon from "../assets/images/sidebar/icons/TeamsManagementIcon.svg";
import attendance_icon from "../assets/images/sidebar/icons/attendance.svg";
import attendance_icon_active from "../assets/images/sidebar/icons/attendance_active.svg";
import close from "../assets/images/close.svg";

import comm_icon_Dark from "../assets/images/sidebar/icons/communcationDark.svg";
import comm_icon_active from "../assets/images/sidebar/icons/communcation.svg";
import dashboard_icon from "../assets/images/sidebar/icons/dashboard.svg";
import dashboard_icon_active from "../assets/images/sidebar/icons/dashboard_active.svg";
import down_arrow from "../assets/images/down_arrow.svg";
import ic_blueUP_arrow from "../assets/images/sidebar/ic_blueUP_arrow.svg";

import projCost from "../assets/images/sidebar/icons/ProjectCostingIcon.svg";
import projCostActive from "../assets/images/sidebar/icons/ProjectCostingActiveIcon.svg";
import taskmanag_icon from "../assets/images/sidebar/icons/taskmanag.svg";
import taskmanag_icon_active from "../assets/images/sidebar/icons/taskmanag_active.svg";

import timesheet_icon from "../assets/images/sidebar/icons/timesheet.svg";
import timesheet_icon_active from "../assets/images/sidebar/icons/timesheet_active.svg";
import wallet from "../assets/images/wallet.svg";
import walletActive from "../assets/images/walletActive.svg";
import { Accordion, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "../pages/TostMsg";
import CryptoJS from "crypto-js";

import unAns from "../assets/images/icons/question-list.svg";
import queList from "../assets/images/icons/Layer 3.svg";
import addQue from "../assets/images/icons/add-circle.svg";
import setting from "../assets/images/icons/setting.svg";
import accesscode from "../assets/images/icons/message-programming.svg";
import traineeQue from "../assets/images/icons/Frame 699.svg";
import convFlow from "../assets/images/icons/Frame 703.svg";
import coversations from "../assets/images/icons/user (1).svg";

import unAns1 from "../assets/images/icons/un-anslist.svg";
import queList1 from "../assets/images/icons/questionlist.svg";
import addQue1 from "../assets/images/icons/addQue.svg";
import setting1 from "../assets/images/icons/setting (2).svg";
import accesscode1 from "../assets/images/icons/access.svg";
import traineeQue1 from "../assets/images/icons/trainingQue.svg";
import convFlow1 from "../assets/images/icons/conversationflow.svg";
import coversations1 from "../assets/images/icons/conversations.svg";

import task from "../assets/images/icons/taskk.svg";
import task1 from "../assets/images/icons/taskk-1.svg";
import tasklist from "../assets/images/icons/tasklist.svg";
import tasklist1 from "../assets/images/icons/tasklist-1.svg";
import myattend from "../assets/images/icons/attendance.svg";
import myattend1 from "../assets/images/icons/attendance-1.svg";
import subordatt from "../assets/images/icons/subordinateattend.svg";
import subordatt1 from "../assets/images/icons/subordinateattend-1.svg";
import applyleave from "../assets/images/icons/applyleave.svg";
import applyleave1 from "../assets/images/icons/applyleave-1.svg";
import leavesett from "../assets/images/icons/leavesetting.svg";
import leavesett1 from "../assets/images/icons/leavesetting-1.svg";
import apprvtask from "../assets/images/icons/approvetask.svg";
import apprvtask1 from "../assets/images/icons/approvetask-1.svg";
import facedet from "../assets/images/icons/facedet.svg";
import facedet1 from "../assets/images/icons/facedet-1.svg";
import usertracking from "../assets/images/icons/usertacking.svg";
import usertracking1 from "../assets/images/icons/usertracking-1.svg";
import branchattend from "../assets/images/icons/branchattend.svg";
import branchattend1 from "../assets/images/icons/branchattend-1.svg";
import prj from "../assets/images/icons/prj.svg";
import prj1 from "../assets/images/icons/prj-1.svg";

import team from "../assets/images/icons/team.svg";
import team1 from "../assets/images/icons/team-1.svg";
import agent from "../assets/images/icons/agent.svg";
import agent1 from "../assets/images/icons/agent-1.svg";
import calls from "../assets/images/icons/calls.svg";
import calls1 from "../assets/images/icons/calls-1.svg";
import whtsappsett from "../assets/images/icons/whtsappsett.svg";
import whtsappsett1 from "../assets/images/icons/whtsppsett-1.svg";
import notcall from "../assets/images/icons/notcall.svg";
import notcall1 from "../assets/images/icons/notcall-1.svg";
import rawdata from "../assets/images/icons/rawdata.svg";
import rawdata1 from "../assets/images/icons/rawdata-1.svg";
import freqcall from "../assets/images/icons/freqcaller.svg";
import freqcall1 from "../assets/images/icons/freqcaller-1.svg";
import zerocallasst from "../assets/images/icons/zerocallasst.svg";
import zerocallasst1 from "../assets/images/icons/zerocallasst-1.svg";
import candi from "../assets/images/icons/candi.svg";
import candi1 from "../assets/images/icons/candi-1.svg";

import prjcostRep from "../assets/images/icons/prjCostRep.svg";
import prjcostRep1 from "../assets/images/icons/prjCostRep-1.svg";
import manageTeam from "../assets/images/icons/manageTeam.svg";
import manageTeam1 from "../assets/images/icons/manageTeam-1.svg";
import addTeam from "../assets/images/icons/addTeam.svg";
import addTeam1 from "../assets/images/icons/addTeam-1.svg";
import calenderSett from "../assets/images/icons/calenderSett.svg";
import calenderSett1 from "../assets/images/icons/CalenderSett-1.svg";
import holidays from "../assets/images/icons/holidays.svg";
import holidays1 from "../assets/images/icons/holidays-1.svg";
import announcement from "../assets/images/icons/announcement.svg";
import announcement1 from "../assets/images/icons/announcement-1.svg";
import prjtimesheet from "../assets/images/icons/prjTimesheetDet-1.svg";
import prjtimesheet1 from "../assets/images/icons/prjTimesheetDet.svg";
import prjss from "../assets/images/icons/prjs.svg";
import prjss1 from "../assets/images/icons/prjs-1.svg";
import prjAlloc from "../assets/images/icons/prjAllocation.svg";
import prjAlloc1 from "../assets/images/icons/prjAllocation-1.svg";
import prjres from "../assets/images/icons/prjResources.svg";
import prjres1 from "../assets/images/icons/prjResources-1.svg";
import prjschedule from "../assets/images/icons/prjschedule.svg";
import prjschedule1 from "../assets/images/icons/prjschedule-1.svg";
import prjdoc1 from "../assets/images/icons/prjdoc-1.svg";
import prjdoc from "../assets/images/icons/prjdoc.svg";
import prjtask from "../assets/images/icons/prjtask.svg";
import prjtask1 from "../assets/images/icons/prjtask-1.svg";
import completetask from "../assets/images/icons/completedtask.svg";
import completetask1 from "../assets/images/icons/completedtask-1.svg";
import holdtask from "../assets/images/icons/holdtask.svg";
import holdtask1 from "../assets/images/icons/holdtask-1.svg";
import aborttask from "../assets/images/icons/aborttask.svg";
import aborttask1 from "../assets/images/icons/aborttask-1.svg";
import taskalloc from "../assets/images/icons/taskAllocation.svg";
import taskalloc1 from "../assets/images/icons/taskAllocation-1.svg";
import clientmgt from "../assets/images/icons/clientMgt.svg";
import clientmgt1 from "../assets/images/icons/clientMgt-1.svg";
import role from "../assets/images/icons/role.svg";
import role1 from "../assets/images/icons/role-1.svg";
import contactgrpmgt from "../assets/images/icons/contactGrpMgt.svg";
import contactgrpmgt1 from "../assets/images/icons/contactGrpMgt-1.svg";
import domapp from "../assets/images/icons/domains&app.svg";
import domapp1 from "../assets/images/icons/domains&app-1.svg";
import admin from "../assets/images/icons/admin.svg";
import admin1 from "../assets/images/icons/admin-1.svg";
import querymgt from "../assets/images/icons/queryMgt.svg";
import querymgt1 from "../assets/images/icons/queryMgt-1.svg";
import leads from "../assets/images/icons/leads.svg";
import leads1 from "../assets/images/icons/leads-1.svg";
import whtsapppromo from "../assets/images/icons/whatsPromo.svg";
import whtsapppromo1 from "../assets/images/icons/whatsPromo-1.svg";
import supplier from "../assets/images/icons/supplier.svg";
import supplier1 from "../assets/images/icons/supplier-1.svg";
import consultant from "../assets/images/icons/consultant.svg";
import consultant1 from "../assets/images/icons/consultant-1.svg";
import servicereq from "../assets/images/icons/serviceReq.svg";
import servicereq1 from "../assets/images/icons/serviceReq-1.svg";
import salesinter from "../assets/images/icons/salesInternational.svg";
import salesinter1 from "../assets/images/icons/salesInternational-1.svg";
import whtsappsett11 from "../assets/images/icons/whtsppsett-1.svg";
import whtsappsettt from "../assets/images/icons/whtsappsett.svg";
import timesheetentry from "../assets/images/icons/timesheetentry.svg";
import timesheetentry1 from "../assets/images/icons/timesheetentry-1.svg";
import emptimedet from "../assets/images/icons/empTimedet.svg";
import emptimedet1 from "../assets/images/icons/empTimedet-1.svg";
import timesheetapp from "../assets/images/icons/timesheetapp.svg";
import timesheetapp1 from "../assets/images/icons/timesheetapp-1.svg";
import emplist from "../assets/images/icons/empList.svg";
import emplist1 from "../assets/images/icons/empList-1.svg";
import addemp from "../assets/images/icons/addEmp.svg";
import addemp1 from "../assets/images/icons/addEmp-1.svg";
import timesheethis from "../assets/images/icons/timesheethis.svg";
import timesheethis1 from "../assets/images/icons/timesheethis-1.svg";
import paymentgat from "../assets/images/icons/paymentgat.svg";
import paymentgat1 from "../assets/images/icons/paymentgat-1.svg";
import ivrsett from "../assets/images/icons/ivrsetting.svg";
import ivrsett1 from "../assets/images/icons/ivrsetting-1.svg";
import empcode from "../assets/images/icons/empcode.svg";
import empcode1 from "../assets/images/icons/empcode-1.svg";
import teamnotassigned from "../assets/images/icons/teamnotassign.svg";
import teamnotassigned1 from "../assets/images/icons/teamnotassign-1.svg";
import tasknotadd from "../assets/images/icons/tasknotadd.svg";
import tasknotadd1 from "../assets/images/icons/tasknotadd-1.svg";
import usernotlogin from "../assets/images/icons/usernotlogin.svg";
import usernotlogin1 from "../assets/images/icons/usernotlogin-1.svg";
import salarystr from "../assets/images/icons/salarystr.svg";
import salarystr1 from "../assets/images/icons/salarystr-1.svg";
import gensalslip from "../assets/images/icons/gensalslip.svg";
import gensalslip1 from "../assets/images/icons/gensalslip-1.svg";
import empDoc from "../assets/images/icons/empDoc.svg";
import empDoc1 from "../assets/images/icons/empDoc-1.svg";
import empattrep from "../assets/images/icons/empattrep.svg";
import empattrep1 from "../assets/images/icons/empattrep-1.svg";
import atthis from "../assets/images/icons/attenhis.svg";
import atthis1 from "../assets/images/icons/attenhis-1.svg";
import users from "../assets/images/icons/users.svg";
import users1 from "../assets/images/icons/users-1.svg";
import agentcallsumm from "../assets/images/icons/agentcallsumm.svg";
import agentcallsumm1 from "../assets/images/icons/agentcallsumm-1.svg";
import invalidleads from "../assets/images/icons/invalidlead.svg";
import invalidleads1 from "../assets/images/icons/invalidlead-1.svg";
import emailrep from "../assets/images/icons/emailrep.svg";
import emailrep1 from "../assets/images/icons/emailrep-1.svg";
import campaign from "../assets/images/icons/campaign.svg";
import campaign1 from "../assets/images/icons/campaign-1.svg";
import pushnoti from "../assets/images/icons/pushnotifi.svg";
import pushnoti1 from "../assets/images/icons/pushnotifi-1.svg";
import googleana from "../assets/images/icons/googleana.svg";
import googleana1 from "../assets/images/icons/googleana-1.svg";
import website from "../assets/images/icons/website.svg";
import website1 from "../assets/images/icons/website-1.svg";
import prjnotassign from "../assets/images/icons/prjnotassign.svg";
import prjnotassign1 from "../assets/images/icons/prjnotassign-1.svg";
import tasknotapp from "../assets/images/icons/tasknotapp.svg";
import tasknotapp1 from "../assets/images/icons/tasknotapp-1.svg";
import timesheerperover from "../assets/images/icons/timesheetperover.svg";
import timesheerperover1 from "../assets/images/icons/timesheetperover-1.svg";
import benchlist from "../assets/images/icons/benchlist.svg";
import benchlist1 from "../assets/images/icons/benchlist-1.svg";
import complsett from "../assets/images/icons/compliancesett.svg";
import complsett1 from "../assets/images/icons/compliancesett-1.svg";
import download from "../assets/images/icons/download.svg";
import download1 from "../assets/images/icons/download-1.svg";
import callrep from "../assets/images/icons/callrep.svg";
import callrep1 from "../assets/images/icons/callrep-1.svg";
import chat from "../assets/images/icons/chat.svg";
import chat1 from "../assets/images/icons/chat-1.svg";
import domain from "../assets/images/icons/domain.svg";
import domain1 from "../assets/images/icons/domain-1.svg";
import categories from "../assets/images/icons/categories.svg";
import categories1 from "../assets/images/icons/categories-1.svg";
import apps from "../assets/images/icons/apps.svg";
import apps1 from "../assets/images/icons/app-1.svg";
import nonfuncfea from "../assets/images/icons/nonfuncfea.svg";
import nonfuncfea1 from "../assets/images/icons/nonfuncfea-1.svg";
import bulkupload from "../assets/images/icons/bulkupload.svg";
import bulkupload1 from "../assets/images/icons/bulkupload-1.svg";
import teamtask from "../assets/images/icons/teamtask.svg";
import teamtask1 from "../assets/images/icons/teamtask-1.svg";
import finyear from "../assets/images/icons/finyear.svg";
import finyear1 from "../assets/images/icons/finyear-1.svg";
import leaveappr from "../assets/images/icons/leaveappr.svg";
import leaveappr1 from "../assets/images/icons/leaveappr-1.svg";
import crmdash from "../assets/images/icons/crmdash.svg";
import crmdash1 from "../assets/images/icons/crmdash-1.svg";
import callassign from "../assets/images/icons/callassign.svg";
import callassign1 from "../assets/images/icons/callassign-1.svg";
import viewdata from "../assets/images/icons/viewdata.svg";
import viewdata1 from "../assets/images/icons/viewdata-1.svg";
import contactcusgrp from "../assets/images/icons/contactcusgrp.svg";
import contactcusgrp1 from "../assets/images/icons/contactcusgrp-1.svg";
import defgrp from "../assets/images/icons/defaultgrp.svg";
import defgrp1 from "../assets/images/icons/defaultgrp-1.svg";
import prjissues from "../assets/images/icons/prjissue.svg";
import prjissues1 from "../assets/images/icons/prjissue-1.svg";
import stdtask from "../assets/images/icons/stdtask.svg";
import stdtask1 from "../assets/images/icons/stdtask-1.svg";
import shiftwiseemp from "../assets/images/icons/shiftwiseemp.svg";
import shiftwiseemp1 from "../assets/images/icons/shiftwiseemp-1.svg";
import emppresentlist from "../assets/images/icons/emppresentlist.svg";
import emppresentlist1 from "../assets/images/icons/emppresentlist-1.svg";
import empabsentlist from "../assets/images/icons/empabsentlist.svg";
import empabsentlist1 from "../assets/images/icons/empabsentlist-1.svg";
import latepuch from "../assets/images/icons/latepunch.svg";
import latepuch1 from "../assets/images/icons/latepunch-1.svg";
import userlastlogin from "../assets/images/icons/userlastlogin.svg";
import userlastlogin1 from "../assets/images/icons/userlastlogin-1.svg";
import datastat from "../assets/images/icons/datastat.svg";
import datastat1 from "../assets/images/icons/datastat-1.svg";
import smspromo from "../assets/images/icons/smspromo.svg";
import smspromo1 from "../assets/images/icons/smspromo-1.svg";
import whtsppenq from "../assets/images/icons/whtsppenq.svg";
import whtsppenq1 from "../assets/images/icons/whtsppenq-1.svg";
import reguser from "../assets/images/icons/reguser.svg";
import reguser1 from "../assets/images/icons/reguser-1.svg";
import contactmgt from "../assets/images/icons/contactmgt.svg";
import contactmgt1 from "../assets/images/icons/contactmgt-1.svg";
import callenq from "../assets/images/icons/callenq.svg";
import callenq1 from "../assets/images/icons/callenq-1.svg";
import emptimesheetdet from "../assets/images/icons/emptimesheerdet.svg";
import emptimesheetdet1 from "../assets/images/icons/emptimesheerdet-1.svg";
import shiftmas from "../assets/images/icons/shiftmaster.svg";
import shiftmas1 from "../assets/images/icons/shiftmaster-1.svg";
import orginfo from "../assets/images/icons/orginfo.svg";
import orginfo1 from "../assets/images/icons/orginfo-1.svg";
import twoplusprj from "../assets/images/icons/twoplusprj.svg";
import twoplusprj1 from "../assets/images/icons/twoplusprj-1.svg";
import prjdeassign from "../assets/images/icons/prjdeassign.svg";
import prjdeassign1 from "../assets/images/icons/prjdeassign-1.svg";
import workflow from "../assets/images/icons/workflow.svg";
import workflow1 from "../assets/images/icons/workflow-1.svg";
import leadmgt from "../assets/images/icons/leadmgt.svg";
import leadmgt1 from "../assets/images/icons/leadmgt-1.svg";
import zerocall from "../assets/images/icons/zerocall.svg";
import zerocall1 from "../assets/images/icons/zerocall-1.svg";
import sendgrid from "../assets/images/icons/sendgrid.svg";
import musterrole from "../assets/images/icons/muster-roll.svg";
import musterrole1 from "../assets/images/icons/muster-1.svg";
import reassign from "../assets/images/icons/reassign.svg";
import reassign1 from "../assets/images/icons/reassign-1.svg";
import settings from "../assets/images/icons/settings.svg";
import settings1 from "../assets/images/icons/settings-1.svg";
import Service from "./../pages/ServiceManagement/Service";

const Sidebar = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  // const [activeSidebar, setActiveSidebar] = useState("");
  const {
    setUserData,
    userData,
    setSocket,
    socket,
    openSessionLogoutModal,
    setOpenSessionLogoutModal,
    setActiveChat,
    setCurrentSChannel,
    chatCount,
    unreadProjectCount,
    pendingWorkflowTaskCount
  } = useContext(AppContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  let permissions = JSON.parse(localStorage.getItem("Permissions"))
    ? JSON.parse(localStorage.getItem("Permissions"))
    : {};
  console.log("Old permissions", permissions);

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    handleOpenMenu();
    return () => { };
  }, [isMobile]);
  const [Purchasemenue, setPurchasemenue] = useState();

  const handleOpenMenu = () => {
    if (currentRoute === "/" || currentRoute === "/") {
      setDashboard(true);
    } else if (currentRoute === "/" || currentRoute === "/") {
      setPurchasemenue(true);
    }
  };

  const [Dashboard, setDashboard] = useState(false);
  const handleDashboard = () => {
    setDashboard(!Dashboard);
    setCRMOfficeCaller(false);
    setCRMphoneook(false);
    setCompliance(false);
    setOrg(false);
    setTeamsmgt(false);
    setCommunication(false);
    setUserManagement(false);
    setEmpmgt(false);
    setPrjcosting(false);
    setProjectMan(false);
  };
  const [clickMenu, setClickMenu] = useState("");
  const [TaskManagement, setTaskManagement] = useState(false);
  const handleTaskManagement = () => {
    setTaskManagement(!TaskManagement);
  };

  const [Attendance, setAttendance] = useState(false);
  const handleAttendance = () => {
    setAttendance(!Attendance);
    setCRMOfficeCaller(false);
    setCRMphoneook(false);
    setCompliance(false);
    setOrg(false);
    setTeamsmgt(false);
    setCommunication(false);
    setUserManagement(false);
    setEmpmgt(false);
    setPrjcosting(false);
    setProjectMan(false);
    setChatadd(false);
    setGenset(false);
  };

  const [Leave, setLeave] = useState(false);
  const handleLeave = () => setLeave(!Leave);

  const [genset, setGenset] = useState(false);
  const handleGenSet = () => {
    setGenset(!genset);
  };
  const [climgt, setClimgt] = useState(false);
  const handleclientmgt = () => {
    setClimgt(!climgt);
    setUserManagement(false);
    setCRMphoneook(false);
    setCompliance(false);
    setOrg(false);
    setTeamsmgt(false);
    setCommunication(false);
    setAttendance(false);
    setCRMOfficeCaller(false);
    setProjectMan(false);
    setChatadd(false);
    setGenset(false);
    setEmpmgt(false);
    setTaskManagement(false);
    setTimesheet(false);
    setPrjcosting(false);
  };
  const [approveLeave, setApproveLeave] = useState(false);
  const handleApproveLeave = () => setApproveLeave(!approveLeave);

  const [Timesheet, setTimesheet] = useState(false);
  const handleTimesheet = () => {
    setTimesheet(!Timesheet);
  };

  const [userManagement, setUserManagement] = useState(false);
  const handleUsermgt = () => {
    setUserManagement(!userManagement);
    setCRMphoneook(false);
    setCompliance(false);
    setOrg(false);
    setTeamsmgt(false);
    setCommunication(false);
    setAttendance(false);
    setCRMOfficeCaller(false);
    setProjectMan(false);
    setChatadd(false);
    setGenset(false);
    setEmpmgt(false);
    setTaskManagement(false);
    setClimgt(false);
    setTimesheet(false);
    setPrjcosting(false);
  };

  const [Notifications, setNotifications] = useState(false);
  const handleNotifications = () => setNotifications(!Notifications);

  const [walletCheck, setWalletCheck] = useState(false);
  const handleWallet = () => setWalletCheck(!Notifications);

  const [chatadd, setChatadd] = useState(false);
  const handleChatadd = () => {
    setChatadd(!chatadd);
  };

  const [compliance, setCompliance] = useState(false);
  const handleCompliance = () => {
    setCompliance(!compliance);
    setTeamsmgt(false);
  };
  const [teamsmgt, setTeamsmgt] = useState(false);
  const handleTeammgt = () => {
    setTeamsmgt(!teamsmgt);
    setCompliance(false);
    setOrg(false);
  };
  const [org, setOrg] = useState(false);
  const handleOrg = () => {
    setOrg(!org);
    setCompliance(false);
    setTeamsmgt(false);
  };
  const [prjcosting, setPrjcosting] = useState(false);
  const handlePrjcosting = () => {
    setPrjcosting(!prjcosting);
  };
  const [empmgt, setEmpmgt] = useState(false);
  const handleEmpmgt = () => {
    setEmpmgt(!empmgt);
  };

  const [Communication, setCommunication] = useState(false);
  const handleCommunication = () => {
    setCommunication(!Communication);
    setCompliance(false);
    setOrg(false);
    setTeamsmgt(false);
    setChatadd(false);
    setGenset(false);
    setEmpmgt(false);
    setPrjcosting(false);
    setCRMphoneook(false);
    setCRMOfficeCaller(false);
    setUserManagement(false);
    setClimgt(false);
  };

  const [ProjectMan, setProjectMan] = useState(false);
  const handleProjectMan = () => {
    setProjectMan(!ProjectMan);
  };

  ///////////////////////////////////

  const [CRMPhoneBook, setCRMphoneook] = useState(false);
  const handleRMPhoneBook = () => {
    setCRMphoneook(!CRMPhoneBook);
    setCompliance(false);
    setOrg(false);
    setTeamsmgt(false);
    setCommunication(false);
    setAttendance(false);
    setCRMOfficeCaller(false);
    setUserManagement(false);
    setProjectMan(false);
    setChatadd(false);
    setGenset(false);
    setEmpmgt(false);
    setTaskManagement(false);
    setClimgt(false);
    setTimesheet(false);
    setPrjcosting(false);
  };
  const [CRMOfficeCaller, setCRMOfficeCaller] = useState(false);
  const handleCRMOfficeCaller = () => {
    setCRMOfficeCaller(!CRMOfficeCaller);
    setAttendance(false);
    setCommunication(false);
    setCRMphoneook(false);
    setUserManagement(false);
    setClimgt(false);
    setTaskManagement(false);
    setTimesheet(false);
    setProjectMan(false);
    setOrg(false);
    setCompliance(false);
    setTeamsmgt(false);
    setPrjcosting(false);
    setEmpmgt(false);
    setGenset(false);
    setChatadd(false);
  };
  const [CRMDashboard, setCRMDashboard] = useState(false);
  const handleCRMDashboard = () => {
    // setCRMDashboard(!CRMDashboard);
    setAttendance(false);
    setCommunication(false);
    setCRMphoneook(false);
    setCRMOfficeCaller(false);
    setUserManagement(false);
    setClimgt(false);
    setTaskManagement(false);
    setTimesheet(false);
    setProjectMan(false);
    setOrg(false);
    setCompliance(false);
    setTeamsmgt(false);
    setPrjcosting(false);
    setEmpmgt(false);
    setGenset(false);
    setChatadd(false);
  };

  const [LeadOpen, setLeadOpen] = useState(false);
  const [QueryOpen, setQueryOpen] = useState(false);
  const [SettingsOpen, setSettingsOpen] = useState(false);
  const [CandidateOpen, setCandidateOpen] = useState(false);
  const [ContactCustomGroup, setContactCustomGroup] = useState(false);
  //const [unreadProjectCount, setUnreadProjectCount] = useState(0);

  //const [chatCount, setChatCount] = useState(0);

  const [DomainOpen, setDomainOpen] = useState(false);
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isPayRollOpen, setIsPayRollOpen] = useState(false);

  const {
    loadworkFlowTaskForm,
    setLoadWorkFlowTaskForm,
    sidebar,
    setSidebar,
    Dark,
    Newpermissions,
    unreadCount,
    setProjects,
    setCallPage,
    setCallLoadMore,
    setTeams,
    recentChats,
    projects,
    teams,
    unreadTeamCount,
  } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };
  console.log("====================================");
  console.log("Newpermissions amerrr", Newpermissions);
  console.log("====================================");
  const [isActive, setIsActive] = useState(false);
  const handleClick = (Event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const [logoutModalShow, setLogoutModalShow] = useState(false);

  const userLogout = () => {
    PostCallWithErrorResponse(ApiConfig.LOGOUT).then((res) => {
      if (res.json.Success === true) {
        ToastMsg("success", res.json.Message);
      } else {
        ToastMsg("error", res.json.Message);
      }
    });
  };
  const logout = (e) => {
    userLogout();
    localStorage.clear();
    socket && socket.disconnect();
    setSocket(null);
    setUserData({});
    setLoggedIn(false);
    setLogoutModalShow(false);
    setOpenSessionLogoutModal(false);
  };
  const getProjects = async () => {
    if (socket) {
      socket.emit("get-projects-android", {});
      socket &&
        socket.on("get-projects-android", (data) => {
          setProjects(
            data.map((item) => {
              return { ...item, colapse: false };
            })
          );
        });

      return () => {
        if (socket) {
          socket.off("get-projects-android");
        }
      };
    }
  };

  const getTeams = async () => {
    if (socket) {
      socket.emit("get-teams-android", {});
      socket &&
        socket.on("get-teams-android", (data) => {
          console.log(data);
          setTeams(
            data.map((item) => {
              return { ...item, colapse: false };
            })
          );
        });
      return () => {
        if (socket) {
          socket.off("get-teams-android");
        }
      };
    }
  };

  const getCallHistory = async () => {
    if (socket) {
      setCallPage(0);
      setCallLoadMore(true);
      socket.emit("call-history", { page: 0 });
    }
  };
  const getPrivateChat = async () => {
    if (socket) {
      socket.emit("get-private-chat-list", {});
    }
  };

  useEffect(() => {
    if (recentChats?.length > 0) {
      let count = recentChats?.filter((i) => {
        return i?.unread_count > 0;
      });

      //   setChatCount(count.length);

      //   let countPrjt = 0;

      //   console.log(projects);

      //   projects &&
      //     projects?.forEach((item) => {
      //       let indx = item?.subchannel.findIndex((i) => {
      //         return i.unread_count > 0;
      //       });
      //       if (indx != -1) {
      //         countPrjt++;
      //       }
      //     });

      //   setUnreadProjectCount(countPrjt);

      //   let countTeams = 0;

      //   teams &&
      //     teams?.forEach((item) => {
      //       let indx = item?.subchannel?.findIndex((i) => {
      //         return i.unread_count > 0;
      //       });
      //       if (indx != -1) {
      //         countTeams++;
      //       }
      //     });

      //   setUnreadTeamCount(countTeams);
    }
  }, [recentChats, projects, teams]);

  const workFlowTask = () => {
    simpleGetCallWithErrorResponse(ApiConfig.WORKFLOW_TASK_FORM).then((res) => {
      if (!res?.json?.Data?.WorkflowId) {
        ToastMsg("error", "You don't have Task");
      } else {
        navigate(
          `/dynamicComplteTask/${res?.json?.Data?.TaskAllocationId}/${res?.json?.Data?.TaskId}`
        );
      }
    });
  };

  return (
    <main className="cx-sidebar">
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={logoutModalShow}
        className="create-workflow-main modal-md"
        onHide={() => setLogoutModalShow(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              User Logout
            </label>
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={() => setLogoutModalShow(false)}
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className="text-center">
          Are you sure you want to Logout ?
        </Modal.Body>
        <div
          style={{ padding: "15px", display: "flex", justifyContent: "center" }}
        >
          <button
            className="modal-cancel-btn sm-btn-delete"
            onClick={() => setLogoutModalShow(false)}
          >
            Cancel
          </button>
          <button className="modal-create-btn sm-btn-delete" onClick={logout}>
            Yes
          </button>
        </div>
      </Modal>

      {/* Session Logout Modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openSessionLogoutModal}
        className="create-workflow-main modal-md"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Session expired !
            </label>
          </Modal.Title>
          {/* <img
            src={close}
            alt=''
            onClick={() => setLogoutModalShow(false)}
            className='cr-class'
          /> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className="text-center">
          Please log in again to continue using the app.
        </Modal.Body>
        <div
          style={{ padding: "15px", display: "flex", justifyContent: "end" }}
        >
          <button className="modal-create-btn sm-btn-delete" onClick={logout}>
            Log in
          </button>
        </div>
      </Modal>
      {/* Session Logout Modal */}

      <div
        className={
          sidebar ? "cx-sidebar-wrapper " : "cx-sidebar-wrapper sidebarActive"
        }
      >
        <aside>
          {sidebar ? (
            <div className="top-logo-big">
              <div className="left d-flex justify-content-center">
                <Link to="/Dashboard">
                  <img
                    src={userData.CompanyLogo ? userData?.CompanyLogo : Logo}
                    alt="Logo"
                    className="img-fluid"
                    onError={(e) => (e.target.src = Logo)}
                  />
                </Link>
              </div>

              {/* <div className="right">
                <Link to="#">
                  <img src={down_arrow} alt="" />
                </Link>
              </div> */}
            </div>
          ) : (
            <div className="small-logo">
              <Link to="/dashboard">
                <img src={userData.CompanyLogo} alt="" />
              </Link>
            </div>
          )}

          <div className="left-arrow">
            <div
              className={sidebar ? "text-end " : "doubleArrowActive"}
              onClick={handleSidebar}
            >
              {currentRoute === "/" ||
                currentRoute === "/" ||
                currentRoute === "/" ||
                currentRoute === "/" ||
                currentRoute === "/" ||
                currentRoute === "/" ? (
                <>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#3DA298"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#3DA298"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#53B7E8"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#53B7E8"
                    />
                  </svg>
                </>
              )}
            </div>
          </div>
          <div className="right-arrow"></div>
          <div className="menus-main">
            <Accordion defaultActiveKey="0">
              {permissions["Employee Wallet"] != true ? (
                <>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="menus-items">
                        {/* ================> START HOME <============== */}
                        <Link
                          to="/Dashboard"
                          className="icon-menu-name"
                          onClick={() => {
                            setWalletCheck(false);
                            handleDashboard();
                            setAttendance(false);
                            setTaskManagement(false);
                            setApproveLeave(false);
                            setLeave(false);
                            setTimesheet(false);
                            setNotifications(false);
                            setCommunication(false);
                          }}
                        >
                          <div className="icon-left">
                            {currentRoute === "/Dashboard" ? (
                              <img src={dashboard_icon_active} alt="" />
                            ) : (
                              <img src={dashboard_icon} alt="" />
                            )}
                          </div>
                          <div
                            className={
                              currentRoute === "/Dashboard"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Dashboard
                          </div>
                        </Link>
                      </div>
                    </Accordion.Header>
                  </Accordion.Item>
                  {/* ================> END HOME <============== */}

                  {/* =================> START Attendance <============= */}
                  {permissions &&
                    (permissions["View My Attendance"] ||
                      permissions["View Subordinates Attendance"] ||
                      permissions["View Employee Attendance Report"] ||
                      permissions["View Attendance History"] ||
                      permissions["View Apply Leave"] ||
                      permissions["View Approve Leave"] ||
                      permissions["View Branch Attendance Report"] ||
                      permissions["View User Face Detection"] ||
                      permissions["View User Tracking"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            // className="icon-menu-name-arrow"
                            className={
                              isActive
                                ? "icon-menu-name-arrow activeColorBlue"
                                : "icon-menu-name-arrow"
                            }
                            onClick={() => {
                              handleAttendance();
                              setLeave(false);
                              setApproveLeave(false);
                              setWalletCheck(false);
                              setDashboard(false);
                              setNotifications(false);
                              // setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/attendance">
                                  {currentRoute === "/attendance" ||
                                    currentRoute === "/EmployeeAttendence" ||
                                    currentRoute ===
                                    "/EmployeeAttendenceReport" ||
                                    currentRoute === "/DevicePunchHistory" ||
                                    currentRoute === "/LeaveApprove" ||
                                    currentRoute === "/Leave" ||
                                    currentRoute ===
                                    "/AllEmployeeAttendanceReport" ||
                                    currentRoute === "/FaceDetection" ||
                                    currentRoute === "/LeaveSetting" ||
                                    currentRoute === "/UserTracking" ||
                                    currentRoute === "/employeeLeave" ? (
                                    <img src={attendance_icon_active} alt="" />
                                  ) : (
                                    <img src={attendance_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                onClick={() => {
                                  handleClick();
                                }}
                                className={
                                  currentRoute === "/attendance" ||
                                    currentRoute ===
                                    "/View User Face Detection" ||
                                    currentRoute === "/EmployeeAttendence" ||
                                    currentRoute ===
                                    "/EmployeeAttendenceReport" ||
                                    currentRoute === "/DevicePunchHistory" ||
                                    currentRoute === "/LeaveApprove" ||
                                    currentRoute === "/Leave" ||
                                    currentRoute === "/FaceDetection" ||
                                    currentRoute ===
                                    "/AllEmployeeAttendanceReport" ||
                                    currentRoute === "/UserTracking" ||
                                    currentRoute === "/LeaveSetting" ||
                                    currentRoute === "/employeeLeave"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name "
                                }
                              >
                                Attendance
                              </div>
                            </div>

                            <div className="arrow">
                              {Attendance ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/attendance"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View My Attendance"] && (
                                  <div
                                    className={
                                      currentRoute === "/attendance"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/attendance"
                                          ? myattend1
                                          : myattend
                                      }
                                      className="me-2"
                                    />
                                    My Attendance
                                  </div>
                                )}
                            </Link>

                            <Link
                              to="/EmployeeAttendence"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Subordinates Attendance"] && (
                                  <div
                                    className={
                                      currentRoute === "/EmployeeAttendence"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/EmployeeAttendence"
                                          ? subordatt1
                                          : subordatt
                                      }
                                      className="me-2"
                                    />
                                    Subordinates <br /> Attendance
                                  </div>
                                )}
                            </Link>

                            <Link
                              to="/EmployeeAttendenceReport"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions[
                                "View Employee Attendance Report"
                                ] && (
                                  <div
                                    className={
                                      currentRoute ===
                                        "/EmployeeAttendenceReport"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute ===
                                          "/EmployeeAttendenceReport"
                                          ? empattrep1
                                          : empattrep
                                      }
                                      className="me-2"
                                    />
                                    Employee Attendance Report
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/DevicePunchHistory"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Attendance History"] && (
                                  <div
                                    className={
                                      currentRoute === "/DevicePunchHistory"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/DevicePunchHistory"
                                          ? atthis1
                                          : atthis
                                      }
                                      className="me-2"
                                    />
                                    Attendance History
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/Leave"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Apply Leave"] && (
                                  <div
                                    className={
                                      currentRoute === "/Leave"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/Leave"
                                          ? applyleave1
                                          : applyleave
                                      }
                                      className="me-2"
                                    />
                                    Apply Leave
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/employeeLeave"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Apply Employee Leave"] && (
                                  <div
                                    className={
                                      currentRoute === "/employeeLeave"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/employeeLeave"
                                          ? applyleave1
                                          : applyleave
                                      }
                                      className="me-2"
                                    />
                                    Apply Employee Leave
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/LeaveSetting"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Leave Setting"] && (
                                  <div
                                    className={
                                      currentRoute === "/LeaveSetting"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/LeaveSetting"
                                          ? leavesett1
                                          : leavesett
                                      }
                                      className="me-2"
                                    />
                                    Leave Setting
                                  </div>
                                )}
                              {/* )} */}
                            </Link>
                            <Link
                              to="/LeaveApprove"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Approve Leave"] && (
                                  <div
                                    className={
                                      currentRoute === "/LeaveApprove"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/LeaveApprove"
                                          ? leaveappr1
                                          : leaveappr
                                      }
                                      className="me-2"
                                    />
                                    Approve <br /> Leave
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/FaceDetection"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View User Face Detection"] && (
                                  <div
                                    className={
                                      currentRoute === "/FaceDetection"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/FaceDetection"
                                          ? facedet1
                                          : facedet
                                      }
                                      className="me-2"
                                    />
                                    Face Detection
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/UserTracking"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View User Tracking"] && (
                                  <div
                                    className={
                                      currentRoute === "/UserTracking"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/UserTracking"
                                          ? usertracking1
                                          : usertracking
                                      }
                                      className="me-2"
                                    />
                                    User Tracking
                                  </div>
                                )}
                            </Link>
                            {/* <Link
                              to="/AllEmployeeAttendanceReport"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["All Employees Attendance"] && (
                                <div
                                  className={
                                    currentRoute === "/AllEmployeeAttendanceReport"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Employee Attendance Report
                                </div>
                              )}
                            </Link> */}
                            <Link
                              to={"/AllEmployeeAttendanceReport"}
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                              className="sub-menue-items"
                            >
                              {permissions &&
                                permissions[
                                "View Branch Attendance Report"
                                ] && (
                                  <div
                                    className={
                                      currentRoute ===
                                        "/AllEmployeeAttendanceReport"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute ===
                                          "/AllEmployeeAttendanceReport"
                                          ? branchattend1
                                          : branchattend
                                      }
                                      className="me-2"
                                    />
                                    Branch Attendance Report
                                  </div>
                                )}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}

                  {/* =================> END Attendance <============= */}

                  {/* =================> START Communication <============= */}

                  {(permissions && permissions["View Chat"]) ||
                    (permissions && permissions["View Communication Projects"]) ||
                    (permissions && permissions["View Teams"]) ||
                    (permissions && permissions["View Calls"]) ||
                    (permissions && permissions["View Ongoing Calls"]) ||
                    (permissions && permissions["View Meetings"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              // handleCommunication();
                              setAttendance(false);
                              setTimesheet(false);
                              setWalletCheck(false);
                              setTaskManagement(false);
                              setNotifications(false);
                              setProjectMan(false);
                              handleCommunication();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/chat">
                                  {currentRoute === "/chat" ||
                                    currentRoute === "/projects" ||
                                    currentRoute === "/Call" ||
                                    currentRoute === "/teams" ||
                                    currentRoute === "/ongoing-calls" ? (
                                    <>
                                      {unreadCount > 0 && (
                                        <span class="badge">{unreadCount}</span>
                                      )}
                                      <img src={comm_icon_Dark} alt="" />
                                    </>
                                  ) : (
                                    <>
                                      {unreadCount > 0 && (
                                        <span class="badge">{unreadCount}</span>
                                      )}
                                      <img src={comm_icon_active} alt="" />
                                    </>
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/chat" ||
                                    currentRoute === "/projects" ||
                                    currentRoute === "/Call" ||
                                    currentRoute === "/teams" ||
                                    currentRoute === "/ongoing-calls"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Communication
                              </div>
                            </div>

                            <div className="arrow">
                              {Communication ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            {permissions["View Chat"] && (
                              <Link
                                to="/chat"
                                className="sub-menue-items d-flex "
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getPrivateChat();
                                }}
                              >
                                {/* {unreadCount > 0 && <span class="badge">{unreadCount}</span>} */}
                                <div
                                  className={
                                    currentRoute === "/chat"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                  onClick={() => {
                                    setActiveChat({});
                                    setCurrentSChannel(null);
                                  }}
                                >
                                  <img
                                    src={
                                      currentRoute === "/chat" ? chat1 : chat
                                    }
                                    className="me-2"
                                  />
                                  Chat
                                  {chatCount > 0 && (
                                    <span
                                      style={{
                                        position: "relative",
                                        marginLeft: "-2px",
                                      }}
                                      className=" badge"
                                    >
                                      {chatCount}
                                    </span>
                                  )}
                                </div>
                              </Link>
                            )}
                            {permissions["View Communication Projects"] && (
                              <Link
                                to="/projects"
                                className="sub-menue-items d-flex"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getProjects();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/projects"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                  onClick={() => {
                                    setActiveChat({});
                                    setCurrentSChannel(null);
                                  }}
                                >
                                  <img
                                    src={
                                      currentRoute === "/projects" ? prj1 : prj
                                    }
                                    className="me-2"
                                  />
                                  Projects
                                </div>
                                {unreadProjectCount > 0 && (
                                  <span
                                    style={{
                                      position: "relative",
                                      marginLeft: "-2px",
                                    }}
                                    className=" badge"
                                  >
                                    {unreadProjectCount}
                                  </span>
                                )}
                              </Link>
                            )}
                            {permissions["View Teams"] && (
                              <Link
                                to="/teams"
                                className="sub-menue-items d-flex"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getTeams();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/teams"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                  onClick={() => {
                                    setActiveChat({});
                                    setCurrentSChannel(null);
                                  }}
                                >
                                  <img
                                    src={
                                      currentRoute === "/teams" ? team1 : team
                                    }
                                    className="me-2"
                                  />
                                  Teams
                                </div>
                                {unreadTeamCount > 0 && (
                                  <span
                                    style={{
                                      position: "relative",
                                      marginLeft: "-2px",
                                    }}
                                    className=" badge"
                                  >
                                    {unreadTeamCount}
                                  </span>
                                )}
                              </Link>
                            )}
                            {permissions["View Calls"] && (
                              <Link
                                to="/Call"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Call"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/Call" ? calls1 : calls
                                    }
                                    className="me-2"
                                  />
                                  Calls
                                </div>
                              </Link>
                            )}
                            {permissions["View Ongoing Calls"] && (
                              <Link
                                to="/ongoing-calls"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/ongoing-calls"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Ongoing Calls
                                </div>
                              </Link>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}

                  {/* ================> END Communication <============== */}

                  {/* =================>  CRM Dashboard <============= */}
                  {/* {permissions && permissions["notshowfrosometime"] && ( */}
                  <>
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="16">
                        <Accordion.Header>
                          {permissions && permissions["View CRM Dashboard"] && (
                            <div className="menus-items">
                              {/* ================> START HOME <============== */}

                              <Link
                                to="/CRMDashboard"
                                className="icon-menu-name"
                                onClick={handleCRMDashboard}
                              >
                                <div className="icon-left">
                                  {currentRoute === "/CRMDashboard" ? (
                                    <>
                                      {/* {unreadCount > 0 && (
                                      <span class="badge">{unreadCount}</span>
                                    )} */}
                                      <img src={CRMDashboard_active} alt="" />
                                    </>
                                  ) : (
                                    <>
                                      <img src={CRMDashboard_inactive} alt="" />
                                    </>
                                  )}
                                </div>

                                <div
                                  className={
                                    currentRoute === "/CRMDashboard"
                                      ? "menu-name activeColorBlue "
                                      : "menu-name"
                                  }
                                >
                                  CRM Dashboard
                                </div>
                              </Link>
                            </div>
                          )}
                        </Accordion.Header>
                      </Accordion.Item>
                    </div>

                    {/* ================> END CRM Phonebook <============== */}

                    {/* =================> START CRM Phonebook <============= */}
                    {permissions &&
                      (permissions["View Agent"] ||
                        permissions["View Call"] ||
                        permissions["View Not Call Classification"] ||
                        permissions["View Users"] ||
                        permissions["View Agent Call Summary"] ||
                        permissions["View Candidate Raw Data"] ||
                        permissions["View Frequent Caller"] ||
                        permissions["View Zero Call List"] ||
                        permissions["View Service Request"] ||
                        permissions["View Candidate Raw Data"] ||
                        permissions["View Consultant"] ||
                        permissions["View Consultant"] ||
                        permissions["View Supplier"]) && (
                        <div className="with-subMenu">
                          <Accordion.Item eventKey="3">
                            <Accordion.Header className="menus-items">
                              <Link
                                to="#"
                                className="icon-menu-name-arrow"
                                onClick={handleRMPhoneBook}
                              >
                                <div className="menu-left">
                                  <div className="icon-left">
                                    <Link to="/chat">
                                      {currentRoute === "/AgentList" ||
                                        currentRoute === "/CRMCalls" ||
                                        currentRoute === "/Users" ||
                                        currentRoute === "/WhatsappSetting" ||
                                        currentRoute ===
                                        "/NotCallClassification" ||
                                        currentRoute === "/ZeroCallList" ||
                                        currentRoute === "/FrequentCaller" ||
                                        currentRoute === "/ServiceRequest" ||
                                        currentRoute === "/AgentCallSummary" ||
                                        currentRoute === "/AssignCallUsers" ||
                                        currentRoute ===
                                        "/SalesInternationalcall" ||
                                        currentRoute === "/UploadCandidateOld" ||
                                        currentRoute === "/Supplier" ||
                                        currentRoute === "/Consultant" ||
                                        currentRoute === "/WhatsappPromotion" ||
                                        currentRoute === "/CandidatesData" ||
                                        currentRoute === "/DownloadAgentReport" ||
                                        currentRoute === "/BulkCallView" ||
                                        currentRoute === "/CallReport" ||
                                        currentRoute ===
                                        "/bulkuploadofficecaller" ? (
                                        <>
                                          {/* {unreadCount > 0 && (
                                      <span class="badge">{unreadCount}</span>
                                    )} */}
                                          <img
                                            src={CRMOfficeCaller_active}
                                            alt=""
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {unreadCount > 0 && (
                                            <span class="badge">
                                              {unreadCount}
                                            </span>
                                          )}
                                          <img src={CRMphone_iactive} alt="" />
                                        </>
                                      )}
                                    </Link>
                                  </div>
                                  <div
                                    className={
                                      currentRoute === "/AgentList" ||
                                        currentRoute === "/CRMCalls" ||
                                        currentRoute === "/Users" ||
                                        currentRoute === "/WhatsappSetting" ||
                                        currentRoute ===
                                        "/NotCallClassification" ||
                                        currentRoute === "/FrequentCaller" ||
                                        currentRoute === "/ServiceRequest" ||
                                        currentRoute === "/ZeroCallList" ||
                                        currentRoute === "/AgentCallSummary" ||
                                        currentRoute === "/AssignCallUsers" ||
                                        currentRoute ===
                                        "/SalesInternationalcall" ||
                                        currentRoute === "/UploadCandidateOld" ||
                                        currentRoute === "/Supplier" ||
                                        currentRoute === "/WhatsappPromotion" ||
                                        currentRoute === "/Consultant" ||
                                        currentRoute === "/CandidatesData" ||
                                        currentRoute === "/DownloadAgentReport" ||
                                        currentRoute === "/BulkCallView" ||
                                        currentRoute === "/CallReport" ||
                                        currentRoute === "/bulkuploadofficecaller"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    CRM Phonebook
                                  </div>
                                </div>

                                <div className="arrow">
                                  {CRMPhoneBook ? (
                                    <img src={down_arrow} alt="" />
                                  ) : (
                                    <img src={ic_blueUP_arrow} alt="" />
                                  )}
                                </div>
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className={sidebar ? "sub-menus" : "none"}>
                                {permissions && permissions["View Agent"] && (
                                  <Link
                                    to="/AgentList"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getPrivateChat();
                                    }}
                                  >
                                    {/* {unreadCount > 0 && <span class="badge">{unreadCount}</span>} */}
                                    <div
                                      className={
                                        currentRoute === "/AgentList"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                      onClick={() => {
                                        // setActiveChat({});
                                        // setCurrentSChannel(null);
                                      }}
                                    >
                                      <img
                                        src={
                                          currentRoute === "/AgentList"
                                            ? agent1
                                            : agent
                                        }
                                        className="me-2"
                                      />
                                      Agent
                                    </div>
                                  </Link>
                                )}
                                {permissions && permissions["View Call"] && (
                                  <Link
                                    to="/CRMCalls"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <div
                                      className={
                                        currentRoute === "/CRMCalls"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                      onClick={() => {
                                        // setActiveChat({});
                                        // setCurrentSChannel(null);
                                      }}
                                    >
                                      <img
                                        src={
                                          currentRoute === "/CRMCalls"
                                            ? calls1
                                            : calls
                                        }
                                        className="me-2"
                                      />
                                      Calls
                                    </div>
                                  </Link>
                                )}
                                {permissions && permissions["View Users"] && (
                                  <Link
                                    to="/Users"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getTeams();
                                    }}
                                  >
                                    <div
                                      className={
                                        currentRoute === "/Users"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                      onClick={() => {
                                        // setActiveChat({});
                                        // setCurrentSChannel(null);
                                      }}
                                    >
                                      <img
                                        src={
                                          currentRoute === "/Users"
                                            ? users1
                                            : users
                                        }
                                        className="me-2"
                                      />
                                      Users
                                    </div>
                                  </Link>
                                )}
                                {permissions &&
                                  permissions[
                                  "View Not Call Classification"
                                  ] && (
                                    <Link
                                      to="/NotCallClassification"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                            "/NotCallClassification"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/NotCallClassification"
                                              ? notcall1
                                              : notcall
                                          }
                                          className="me-2"
                                        />
                                        Not Call Classification
                                      </div>
                                    </Link>
                                  )}
                                {permissions &&
                                  permissions["View Agent Call Summary"] && (
                                    <Link
                                      to="/AgentCallSummary"
                                      className="sub-menue-items"
                                      onClick={() => {
                                        isMobile
                                          ? setSidebar(false)
                                          : setSidebar(true);
                                        getPrivateChat();
                                      }}
                                    >
                                      {/* {unreadCount > 0 && <span class="badge">{unreadCount}</span>} */}
                                      <div
                                        className={
                                          currentRoute === "/AgentCallSummary"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/AgentCallSummary"
                                              ? agentcallsumm1
                                              : agentcallsumm
                                          }
                                          className="me-2"
                                        />
                                        Agent Call Summary
                                      </div>
                                    </Link>
                                  )}
                                {/* <Link
                            to="/AssignCallUsers"
                            className="sub-menue-items"
                            // onClick={() => {
                            //   isMobile ? setSidebar(false) : setSidebar(true);
                            //   getCallHistory()
                            // }}
                          >
                            <div
                              className={
                                currentRoute === "/AssignCallUsers"
                                  ? "menu-name activeColorBlue"
                                  : "menu-name"
                              }
                            >
                              Candidate (Process Data)
                            </div>
                          </Link> */}
                                {permissions &&
                                  (permissions["View Candidates Data"] ||
                                    permissions["View Re-sign Candidate"] ||
                                    permissions["View Call Report"]) && (
                                    <Link className="sub-menue-items">
                                      <div
                                        className={
                                          currentRoute === "/CandidatesData" ||
                                            currentRoute ===
                                            "/DownloadAgentReport" ||
                                            currentRoute === "/BulkCallView" ||
                                            currentRoute ===
                                            "/UpdateUserDetails" ||
                                            currentRoute ===
                                            "/UpdateUserDetails" ||
                                            currentRoute === "/AssignCallUsers" ||
                                            currentRoute === "/CallReport"
                                            ? // currentRoute === "/EmailReport" ||
                                            // currentRoute === "/LeadListCount"
                                            "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          setCandidateOpen(!CandidateOpen);
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/CandidatesData" ||
                                              currentRoute ===
                                              "/DownloadAgentReport" ||
                                              currentRoute === "/BulkCallView" ||
                                              currentRoute ===
                                              "/UpdateUserDetails" ||
                                              currentRoute ===
                                              "/UpdateUserDetails" ||
                                              currentRoute ===
                                              "/AssignCallUsers" ||
                                              currentRoute === "/CallReport"
                                              ? candi1
                                              : candi
                                          }
                                          className="me-2"
                                        />
                                        Candidate (Process Data)
                                        <div className="arrow">
                                          {SettingsOpen ? (
                                            <img src={down_arrow} alt="" />
                                          ) : (
                                            <img src={ic_blueUP_arrow} alt="" />
                                          )}
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                {CandidateOpen ? (
                                  <div
                                    style={{
                                      marginLeft: "20px",
                                      borderLeft: "1px solid  #53b7e8",
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      // to="/LeadManagement"
                                      className="sub-menue-items"
                                      onClick={() => {
                                        isMobile
                                          ? setSidebar(false)
                                          : setSidebar(true);
                                        getProjects();
                                      }}
                                    >
                                      <Link
                                        style={{}}
                                        to="/AssignCallUsers"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                      >
                                        <div
                                          className={
                                            currentRoute === "/AssignCallUsers"
                                              ? "menu-name activeColorBlue d-flex justify-content-start"
                                              : "menu-name d-flex justify-content-start"
                                          }
                                          onClick={() => {
                                            // setActiveChat({});
                                            // setCurrentSChannel(null);
                                          }}
                                        >
                                          <img
                                            src={
                                              currentRoute ===
                                                "/AssignCallUsers"
                                                ? callassign1
                                                : callassign
                                            }
                                            className="me-2"
                                          />
                                          Call Assigning
                                        </div>
                                      </Link>
                                      {permissions &&
                                        permissions["View Candidates Data"] && (
                                          <Link
                                            style={{}}
                                            to="/CandidatesData"
                                          // className="sub-menue-items"
                                          // onClick={() => {
                                          //   isMobile
                                          //     ? setSidebar(false)
                                          //     : setSidebar(true);
                                          //   getProjects();
                                          // }}
                                          >
                                            <div
                                              className={
                                                currentRoute ===
                                                  "/CandidatesData"
                                                  ? "menu-name activeColorBlue d-flex justify-content-start"
                                                  : "menu-name d-flex justify-content-start"
                                              }
                                              onClick={() => {
                                                // setActiveChat({});
                                                // setCurrentSChannel(null);
                                              }}
                                            >
                                              <img
                                                src={
                                                  currentRoute ===
                                                    "/CandidatesData"
                                                    ? viewdata1
                                                    : viewdata
                                                }
                                                className="me-2"
                                              />
                                              View Data
                                            </div>
                                          </Link>
                                        )}
                                      <Link
                                        style={{}}
                                        to="/DownloadAgentReport"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                      >
                                        <div
                                          className={
                                            currentRoute ===
                                              "/DownloadAgentReport"
                                              ? "menu-name activeColorBlue d-flex justify-content-start"
                                              : "menu-name d-flex justify-content-start"
                                          }
                                          onClick={() => {
                                            // setActiveChat({});
                                            // setCurrentSChannel(null);
                                          }}
                                        >
                                          <img
                                            src={
                                              currentRoute ===
                                                "/DownloadAgentReport"
                                                ? download1
                                                : download
                                            }
                                            className="me-2"
                                          />
                                          Download Data
                                        </div>
                                      </Link>
                                      {permissions &&
                                        permissions[
                                        "View Re-sign Candidate"
                                        ] && (
                                          <Link
                                            style={{}}
                                            to="/BulkCallView"
                                          // className="sub-menue-items"
                                          // onClick={() => {
                                          //   isMobile
                                          //     ? setSidebar(false)
                                          //     : setSidebar(true);
                                          //   getProjects();
                                          // }}
                                          >
                                            <div
                                              className={
                                                currentRoute === "/BulkCallView"
                                                  ? "menu-name activeColorBlue d-flex justify-content-start"
                                                  : "menu-name d-flex justify-content-start"
                                              }
                                              onClick={() => {
                                                // setActiveChat({});
                                                // setCurrentSChannel(null);
                                              }}
                                            >
                                              <img
                                                src={
                                                  currentRoute ===
                                                    "/BulkCallView"
                                                    ? reassign1
                                                    : reassign
                                                }
                                                className="me-2"
                                              />
                                              Re-Sign Candidate
                                            </div>
                                          </Link>
                                        )}
                                      {permissions &&
                                        permissions["View Call Report"] && (
                                          <Link
                                            style={{}}
                                            to="/CallReport"
                                          // className="sub-menue-items"
                                          // onClick={() => {
                                          //   isMobile
                                          //     ? setSidebar(false)
                                          //     : setSidebar(true);
                                          //   getProjects();
                                          // }}
                                          >
                                            <div
                                              className={
                                                currentRoute === "/CallReport"
                                                  ? "menu-name activeColorBlue d-flex justify-content-start"
                                                  : "menu-name d-flex justify-content-start"
                                              }
                                              onClick={() => {
                                                // setActiveChat({});
                                                // setCurrentSChannel(null);
                                              }}
                                            >
                                              <img
                                                src={
                                                  currentRoute === "/CallReport"
                                                    ? callrep1
                                                    : callrep
                                                }
                                                className="me-2"
                                              />
                                              Call Report
                                            </div>
                                          </Link>
                                        )}
                                    </Link>
                                  </div>
                                ) : null}

                                <Link
                                  to="/SalesInternationalcall"
                                  className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                                >
                                  <div
                                    className={
                                      currentRoute === "/SalesInternationalcall"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute ===
                                          "/SalesInternationalcall"
                                          ? salesinter1
                                          : salesinter
                                      }
                                      className="me-2"
                                    />
                                    Sales International call
                                  </div>
                                </Link>
                                {permissions &&
                                  permissions["View Candidate Raw Data"] && (
                                    <Link
                                      to="/UploadCandidateOld"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/UploadCandidateOld"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/UploadCandidateOld"
                                              ? rawdata1
                                              : rawdata
                                          }
                                          className="me-2"
                                        />
                                        Candidate Raw Data
                                      </div>
                                    </Link>
                                  )}
                                {permissions &&
                                  permissions["View Frequent Caller"] && (
                                    <Link
                                      to="/FrequentCaller"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/FrequentCaller"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute === "/FrequentCaller"
                                              ? freqcall1
                                              : freqcall
                                          }
                                          className="me-2"
                                        />
                                        Frequent Caller
                                      </div>
                                    </Link>
                                  )}
                                {permissions &&
                                  permissions["View Zero Call List"] && (
                                    <Link
                                      to="/ZeroCallList"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/ZeroCallList"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute === "/ZeroCallList"
                                              ? zerocall1
                                              : zerocall
                                          }
                                          className="me-2"
                                        />
                                        Zero Call List
                                      </div>
                                    </Link>
                                  )}
                                {permissions &&
                                  permissions["View Service Request"] && (
                                    <Link
                                      to="/ServiceRequest"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/ServiceRequest"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute === "/ServiceRequest"
                                              ? servicereq1
                                              : servicereq
                                          }
                                          className="me-2"
                                        />
                                        Service Request
                                      </div>
                                    </Link>
                                  )}
                                {permissions &&
                                  permissions["View Consultant"] && (
                                    <Link
                                      to="/Consultant"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/Consultant"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute === "/Consultant"
                                              ? consultant1
                                              : consultant
                                          }
                                          className="me-2"
                                        />
                                        Consultant
                                      </div>
                                    </Link>
                                  )}
                                {permissions &&
                                  permissions["View Supplier"] && (
                                    <Link
                                      to="/Supplier"
                                      className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile ? setSidebar(false) : setSidebar(true);
                                    //   getCallHistory()
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/Supplier"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute === "/Supplier"
                                              ? supplier1
                                              : supplier
                                          }
                                          className="me-2"
                                        />
                                        Supplier
                                      </div>
                                    </Link>
                                  )}
                                <Link
                                  to="/WhatsappPromotion"
                                  className="sub-menue-items"
                                >
                                  <div
                                    className={
                                      currentRoute === "/WhatsappPromotion"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/WhatsappPromotion"
                                          ? whtsapppromo1
                                          : whtsapppromo
                                      }
                                      className="me-2"
                                    />
                                    WhatsappPromotion
                                  </div>
                                </Link>
                                {permissions &&
                                  permissions["View Bulk Contact Upload"] && (
                                    <Link
                                      to="/bulkuploadofficecaller"
                                      className="sub-menue-items"
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                            "/bulkuploadofficecaller"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/bulkuploadofficecaller"
                                              ? bulkupload1
                                              : bulkupload
                                          }
                                          className="me-2"
                                        />
                                        Bulk contact upload
                                      </div>
                                    </Link>
                                  )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      )}
                    {/* ================> END CRM Phonebook <============== */}

                    {/* =================> START CRM Phonebook <============= */}

                    {permissions && permissions["View CRM Office Caller"] && (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="15">
                          <Accordion.Header className="menus-items">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleCRMOfficeCaller();
                                // setAttendance(false);
                                // setTimesheet(false);
                                // setWalletCheck(false);
                                // setTaskManagement(false);
                                // setNotifications(false);
                                // setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/chat">
                                    {currentRoute === "/LeadManagement" ||
                                      currentRoute === "/Call" ||
                                      currentRoute === "/WhatsappSetting" ||
                                      currentRoute === "/Campaign" ||
                                      currentRoute === "/Admin" ||
                                      currentRoute === "/QueryCases" ||
                                      currentRoute === "/InvalidLeads" ||
                                      currentRoute === "/EmailReport" ||
                                      currentRoute === "/CrmPushNotification" ||
                                      currentRoute === "/GoogleAnalytics" ||
                                      currentRoute === "/CrmWebsite" ||
                                      currentRoute === "/CrmSendgrid" ||
                                      currentRoute === "/Domains" ||
                                      currentRoute ===
                                      "/CategoriesOfficeCaller" ||
                                      currentRoute === "/CrmApp" ||
                                      currentRoute === "/NonFunctionalFeatures" ||
                                      currentRoute === "/BulkUpload" ||
                                      currentRoute === "/ContactCustomGroup" ||
                                      currentRoute === "/DefaultGroup" ? (
                                      <>
                                        {/* {unreadCount > 0 && (
                                      <span class="badge">{unreadCount}</span>
                                    )} */}
                                        <img
                                          src={CRMOfficeCaller_active}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {unreadCount > 0 && (
                                          <span class="badge">
                                            {unreadCount}
                                          </span>
                                        )}
                                        <img src={CRMphone_iactive} alt="" />
                                      </>
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/LeadManagement" ||
                                      currentRoute === "/InvalidLeads" ||
                                      currentRoute === "/EmailReport" ||
                                      currentRoute === "/Campaign" ||
                                      currentRoute === "/Admin" ||
                                      currentRoute === "/QueryCases" ||
                                      currentRoute === "/CrmPushNotification" ||
                                      currentRoute === "/GoogleAnalytics" ||
                                      currentRoute === "/CrmWebsite" ||
                                      currentRoute === "/CrmSendgrid" ||
                                      currentRoute ===
                                      "/CategoriesOfficeCaller" ||
                                      currentRoute === "/CrmApp" ||
                                      currentRoute === "/BulkUpload" ||
                                      currentRoute === "/Domains" ||
                                      currentRoute === "/NonFunctionalFeatures" ||
                                      currentRoute === "/ContactCustomGroup" ||
                                      currentRoute === "/DefaultGroup" ||
                                      currentRoute === "/LeadListCount"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  CRM Office Caller
                                </div>
                              </div>
                              <div className="arrow">
                                {CRMOfficeCaller ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/LeadManagement" ||
                                      currentRoute === "/InvalidLeads" ||
                                      currentRoute === "/EmailReport" ||
                                      currentRoute === "/LeadListCount"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                  onClick={() => {
                                    setLeadOpen(!LeadOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  <img
                                    src={
                                      currentRoute === "/LeadManagement" ||
                                        currentRoute === "/InvalidLeads" ||
                                        currentRoute === "/EmailReport" ||
                                        currentRoute === "/LeadListCount"
                                        ? leadmgt1
                                        : leadmgt
                                    }
                                    className="me-2"
                                  />
                                  Lead Management
                                  <div className="arrow">
                                    {LeadOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>
                              {LeadOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/LeadManagement"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/LeadManagement"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/LeadManagement"
                                              ? leads1
                                              : leads
                                          }
                                          className="me-2"
                                        />
                                        Leads
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/InvalidLeads"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/InvalidLeads"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/InvalidLeads"
                                              ? invalidleads1
                                              : invalidleads
                                          }
                                          className="me-2"
                                        />
                                        Invalid Leads
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/EmailReport"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/EmailReport"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/EmailReport"
                                              ? emailrep1
                                              : emailrep
                                          }
                                          className="me-2"
                                        />
                                        Email Report
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}

                              <Link
                                to="/Campaign"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Campaign"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/Campaign"
                                        ? campaign1
                                        : campaign
                                    }
                                    className="me-2"
                                  />
                                  Campaign
                                </div>
                              </Link>

                              <Link
                                to="/QueryCases"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/QueryCases"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/QueryCases"
                                        ? querymgt1
                                        : querymgt
                                    }
                                    className="me-2"
                                  />
                                  Query Management
                                </div>
                              </Link>

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/CrmPushNotification" ||
                                      currentRoute === "/GoogleAnalytics" ||
                                      currentRoute === "/CrmWebsite" ||
                                      currentRoute === "/CrmSendgrid"
                                      ? // currentRoute === "/EmailReport" ||
                                      // currentRoute === "/LeadListCount"
                                      "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setSettingsOpen(!SettingsOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Settings
                                  <div className="arrow">
                                    {SettingsOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>
                              {SettingsOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/CrmPushNotification"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                            "/CrmPushNotification"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/CrmPushNotification"
                                              ? pushnoti1
                                              : pushnoti
                                          }
                                          className="me-2"
                                        />
                                        Push Notification
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/GoogleAnalytics"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/GoogleAnalytics"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/GoogleAnalytics"
                                              ? googleana1
                                              : googleana
                                          }
                                          className="me-2"
                                        />
                                        Google Analytics
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CrmWebsite"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CrmWebsite"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/CrmWebsite"
                                              ? website1
                                              : website
                                          }
                                          className="me-2"
                                        />
                                        Website
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CrmSendgrid"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CrmSendgrid"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          style={{ width: "20px" }}
                                          src={sendgrid}
                                          className="me-2"
                                        />
                                        Sendgrid
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}
                              <Link
                                to="/Admin"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Admin"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/Admin" ? admin1 : admin
                                    }
                                    className="me-2"
                                  />
                                  Admin
                                </div>
                              </Link>

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute ===
                                      "/CategoriesOfficeCaller" ||
                                      currentRoute === "/CrmApp" ||
                                      currentRoute === "/BulkUpload" ||
                                      currentRoute === "/Domains" ||
                                      currentRoute === "/NonFunctionalFeatures"
                                      ? // currentRoute === "/LeadListCount"
                                      "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                  onClick={() => {
                                    setDomainOpen(!DomainOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  <img
                                    src={
                                      currentRoute ===
                                        "/CategoriesOfficeCaller" ||
                                        currentRoute === "/CrmApp" ||
                                        currentRoute === "/BulkUpload" ||
                                        currentRoute === "/Domains" ||
                                        currentRoute === "/NonFunctionalFeatures"
                                        ? domapp1
                                        : domapp
                                    }
                                    className="me-2"
                                  />
                                  Domains And Apps
                                  <div className="arrow">
                                    {DomainOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>

                              {DomainOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/Domains"
                                    //     className={
                                    //   currentRoute === "/LeadManagement" ||
                                    //   currentRoute === "/InvalidLeads" ||
                                    //   currentRoute === "/EmailReport"
                                    //     ? "menu-name activeColorBlue"
                                    //     : "menu-name"
                                    // }
                                    >
                                      <div
                                        className={
                                          currentRoute === "/Domains"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/Domains"
                                              ? domain1
                                              : domain
                                          }
                                          className="me-2"
                                        />
                                        Domains
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CategoriesOfficeCaller"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                            "/CategoriesOfficeCaller"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/CategoriesOfficeCaller"
                                              ? categories1
                                              : categories
                                          }
                                          className="me-2"
                                        />
                                        Categories
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CrmApp"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CrmApp"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/CrmApp"
                                              ? apps1
                                              : apps
                                          }
                                          className="me-2"
                                        />
                                        Apps
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/NonFunctionalFeatures"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                            "/NonFunctionalFeatures"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/NonFunctionalFeatures"
                                              ? nonfuncfea1
                                              : nonfuncfea
                                          }
                                          className="me-2"
                                        />
                                        Non-Functional Features
                                      </div>
                                    </Link>

                                    <Link
                                      style={{}}
                                      to="/BulkUpload"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/BulkUpload"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/BulkUpload"
                                              ? bulkupload1
                                              : bulkupload
                                          }
                                          className="me-2"
                                        />
                                        Bulk contact upload
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/ContactCustomGroup" ||
                                      currentRoute === "/DefaultGroup"
                                      ? // currentRoute === "/LeadListCount"
                                      "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                  onClick={() => {
                                    setContactCustomGroup(!ContactCustomGroup);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  <img
                                    src={
                                      currentRoute === "/ContactCustomGroup" ||
                                        currentRoute === "/DefaultGroup"
                                        ? contactgrpmgt1
                                        : contactgrpmgt
                                    }
                                    className="me-2"
                                  />
                                  Contact Group Management
                                  <div className="arrow">
                                    {ContactCustomGroup ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>

                              {ContactCustomGroup ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/ContactCustomGroup"
                                    //     className={
                                    //   currentRoute === "/LeadManagement" ||
                                    //   currentRoute === "/InvalidLeads" ||
                                    //   currentRoute === "/EmailReport"
                                    //     ? "menu-name activeColorBlue"
                                    //     : "menu-name"
                                    // }
                                    >
                                      <div
                                        className={
                                          currentRoute === "/ContactCustomGroup"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/ContactCustomGroup"
                                              ? contactcusgrp1
                                              : contactcusgrp
                                          }
                                          className="me-2"
                                        />
                                        Contact Custom Group
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/DefaultGroup"
                                    // className="sub-menue-items"
                                    // onClick={() => {
                                    //   isMobile
                                    //     ? setSidebar(false)
                                    //     : setSidebar(true);
                                    //   getProjects();
                                    // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/DefaultGroup"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        <img
                                          src={
                                            currentRoute === "/DefaultGroup"
                                              ? defgrp1
                                              : defgrp
                                          }
                                          className="me-2"
                                        />
                                        Default Groups
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    )}
                  </>
                  {/*  // )} */}
                  {/* ================> END CRM Phonebook <============== */}

                  {/* =================> START User Management <============= */}

                  {permissions && permissions["View Roles"] && (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="4">
                        <Accordion.Header className="menus-items">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              setWalletCheck(false);
                              setApproveLeave(false);
                              setLeave(false);
                              handleUsermgt();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/Role">
                                  {currentRoute === "/Role" ||
                                    currentRoute === "/AssignRole" ||
                                    currentRoute === "/Permission" ? (
                                    <img src={ActiveMan} alt="" />
                                  ) : (
                                    <img src={taskmanag_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/Role" ||
                                    currentRoute === "/AssignRole" ||
                                    currentRoute === "/Permission"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                User <br /> Management
                              </div>
                            </div>
                            <div className="arrow">
                              {userManagement ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/Role"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              <div
                                className={
                                  currentRoute === "/Role" ||
                                    currentRoute === "/AssignRole" ||
                                    currentRoute === "/Permission"
                                    ? "menu-name activeColorBlue d-flex justify-content-start"
                                    : "menu-name d-flex justify-content-start"
                                }
                              >
                                <img
                                  src={
                                    currentRoute === "/Role" ||
                                      currentRoute === "/AssignRole" ||
                                      currentRoute === "/Permission"
                                      ? role1
                                      : role
                                  }
                                  className="me-2"
                                />
                                Role
                              </div>
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  )}

                  {/* =================> START Client Management <============= */}
                  {permissions && permissions["View Client List"] ? (
                    <>
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="5">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleNotifications();
                                setWalletCheck(false);
                                setTimesheet(false);
                                handleclientmgt();
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/ClientManagement">
                                    {currentRoute === "/ClientManagement" ||
                                      currentRoute === "/clientlist" ||
                                      currentRoute === "/servicemapping" ||
                                      currentRoute === "/subscriptionlist" ||
                                      currentRoute === "/feedback" ||
                                      currentRoute === "/clientDetails" ? (
                                      <img
                                        src={ClientManActive}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={ClientMan}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/ClientManagement" ||
                                      currentRoute === "/clientlist" ||
                                      currentRoute === "/servicemapping" ||
                                      currentRoute === "/subscriptionlist" ||
                                      currentRoute === "/feedback" ||
                                      currentRoute === "/clientDetails"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Client <br />
                                  Management
                                </div>
                              </div>

                              <div className="arrow">
                                {climgt ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/ClientManagement"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              ></Link>
                              <Link
                                to="/ClientManagement"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Client List"] && (
                                    <div
                                      className={
                                        currentRoute === "/ClientManagement"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ClientManagement"
                                            ? clientmgt1
                                            : clientmgt
                                        }
                                        className="me-2"
                                      />
                                      Client Management
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/clientlist"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Client List"] && (
                                    <div
                                      className={
                                        currentRoute === "/clientlist"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/clientlist"
                                            ? clientmgt1
                                            : clientmgt
                                        }
                                        className="me-2"
                                      />
                                      Client
                                    </div>
                                  )}
                              </Link>
                              {/* <Link
                                to="/clientDetails"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Client Management"] && (
                                    <div
                                      className={
                                        currentRoute === "/clientDetails"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                     
                                      Client Details
                                    </div>
                                  )}
                              </Link> */}

                              {/* <Link
                                to="/feedback"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Client Management"] && (
                                    <div
                                      className={
                                        currentRoute === "/feedback"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      
                                      Feedback
                                    </div>
                                  )}
                              </Link> */}
                              <Link
                                to="/holidaysandevents"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["Holiday And Events"] && (
                                    <div
                                      className={
                                        currentRoute === "/holidaysandevents"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Holidays And Events
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TearmsAmdCondiation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["Terms & Conditions"] && (
                                    <div
                                      className={
                                        currentRoute === "/TearmsAmdCondiation"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Terms & Conditions
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/PrivacyPolicy"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["Privacy Policy"] && (
                                    <div
                                      className={
                                        currentRoute === "/PrivacyPolicy"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Privacy Policy
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/AboutOrganziation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Organization"] && (
                                    <div
                                      className={
                                        currentRoute === "/AboutOrganziation" ||
                                          currentRoute === "/AboutOrganziation"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      About Organization
                                    </div>
                                  )}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </>
                  ) : null}

                  {/* ================> END User Management <============== */}

                  {/* =================> START Task Management <============= */}
                  {permissions &&
                    (permissions["View Task"] ||
                      permissions["View Task List"] ||
                      permissions["View Task Allocation"] ||
                      permissions["View Abort Task"] ||
                      permissions["View Hold Task"] ||
                      permissions["View Complete Task"] ||
                      permissions["View Team Task"] ||
                      permissions["View roject Task"] ||
                      permissions["View Task Approve"] ||
                      permissions["View Standard Task"]) ? (
                    <>
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="6">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleTaskManagement();
                                setWalletCheck(false);
                                setApproveLeave(false);
                                setLeave(false);
                                setDashboard(false);
                                setTimesheet(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/tasklist">
                                    {currentRoute === "/tasklist" ||
                                      currentRoute === "/teamtask" ||
                                      currentRoute === "/projecttask" ||
                                      currentRoute === "/TaskApprove" ||
                                      currentRoute === "/AbortTask" ||
                                      currentRoute === "/CompleteTask" ||
                                      currentRoute === "/StandardTask" ||
                                      currentRoute === "/taskDetails" ||
                                      currentRoute === "/HoldTask" ||
                                      currentRoute === "/Task" ||
                                      currentRoute === "/TaskAllocation" ? (
                                      <img src={taskmanag_icon_active} alt="" />
                                    ) : (
                                      <img src={taskmanag_icon} alt="" />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/tasklist" ||
                                      currentRoute === "/teamtask" ||
                                      currentRoute === "/projecttask" ||
                                      currentRoute === "/TaskApprove" ||
                                      currentRoute === "/AbortTask" ||
                                      currentRoute === "/CompleteTask" ||
                                      currentRoute === "/StandardTask" ||
                                      currentRoute === "/taskDetails" ||
                                      currentRoute === "/HoldTask" ||
                                      currentRoute === "/Task" ||
                                      currentRoute === "/TaskAllocation"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Task <br /> Management
                                </div>
                              </div>

                              <div className="arrow">
                                {TaskManagement ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/task"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions && permissions["View Un Assigned Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/task"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/task" ? task1 : task
                                      }
                                      className="me-2"
                                    />
                                    Un-Assigned
                                    <br /> Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/tasklist"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task List"] ? (
                                  <div
                                    className={
                                      currentRoute === "/tasklist"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/tasklist"
                                          ? tasklist1
                                          : tasklist
                                      }
                                      className="me-2"
                                    />
                                    Task List
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/TaskAllocation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Allocation"] ? (
                                  <div
                                    className={
                                      currentRoute === "/TaskAllocation"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/TaskAllocation"
                                          ? taskalloc1
                                          : taskalloc
                                      }
                                      className="me-2"
                                    />
                                    Task Allocation
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/AbortTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Abort Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/AbortTask"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/AbortTask"
                                          ? aborttask1
                                          : aborttask
                                      }
                                      className="me-2"
                                    />
                                    Abort Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/HoldTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Hold Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/HoldTask"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/HoldTask"
                                          ? holdtask1
                                          : holdtask
                                      }
                                      className="me-2"
                                    />
                                    Hold Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/CompleteTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Complete Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/CompleteTask"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/CompleteTask"
                                          ? completetask1
                                          : completetask
                                      }
                                      className="me-2"
                                    />
                                    Inprogress Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/teamtask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Team Task"] && (
                                    <div
                                      className={
                                        currentRoute === "/teamtask"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/teamtask"
                                            ? teamtask1
                                            : teamtask
                                        }
                                        className="me-2"
                                      />
                                      Team Task
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/projecttask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Task"] && (
                                    <div
                                      className={
                                        currentRoute === "/projecttask"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/projecttask"
                                            ? prjtask1
                                            : prjtask
                                        }
                                        className="me-2"
                                      />
                                      Project Task
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TaskApprove"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Approve"] && (
                                    <div
                                      className={
                                        currentRoute === "/TaskApprove"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/TaskApprove"
                                            ? apprvtask1
                                            : apprvtask
                                        }
                                        className="me-2"
                                      />
                                      Task Approval
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/StandardTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Standard Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/StandardTask"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/StandardTask"
                                          ? stdtask1
                                          : stdtask
                                      }
                                      className="me-2"
                                    />
                                    Standard Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/taskDetails"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Standard Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/taskDetails"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/taskDetails"
                                          ? emppresentlist1
                                          : emppresentlist
                                      }
                                      className="me-2"
                                    />
                                    Employee Task Details
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                className="sub-menue-items"
                                onClick={() => {
                                  workFlowTask();
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions &&
                                  permissions["View Standard Task"] ? ( */}
                                <div
                                  className={
                                    currentRoute ===
                                      "/dynamicComplteTask/:id/:taskAllocationId"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute ===
                                        "/dynamicComplteTask/:id/:taskAllocationId"
                                        ? emppresentlist1
                                        : emppresentlist
                                    }
                                    className="me-2"
                                  />
                                  {
                                    pendingWorkflowTaskCount > 0 &&
                                    <span class="badge "
                                      style={{
                                        position: "relative",
                                        marginLeft: "-8px",
                                        right: '6px'
                                      }}
                                    >{pendingWorkflowTaskCount}</span>
                                  }

                                  WorkFlow Task Form
                                </div>
                                {/* ) : null} */}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </>
                  ) : null}

                  {/* ================> END Task Management <============== */}

                  {/* =================> START Timesheet <============= */}
                  {permissions &&
                    (permissions["View Timesheet Entry"] ||
                      permissions["View Timesheet History"] ||
                      permissions["View Timesheet Approve"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="7">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleTimesheet();
                              setWalletCheck(false);
                              setApproveLeave(false);
                              setLeave(false);
                              setDashboard(false);
                              setNotifications(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/timesheet">
                                  {currentRoute === "/timesheet" ||
                                    currentRoute === "/timesheethistory" ||
                                    currentRoute === "/TimesheetApproval" ||
                                    clickMenu === "TManagments" ? (
                                    <img src={timesheet_icon_active} alt="" />
                                  ) : (
                                    <img src={timesheet_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  clickMenu === "TManagments" ||
                                    currentRoute === "/timesheet" ||
                                    currentRoute === "/timesheethistory" ||
                                    currentRoute === "/TimesheetApproval"
                                    ? // currentRoute === "/EmployeeTimesheetDetails"
                                    "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Timesheet Management
                              </div>
                            </div>

                            <div className="arrow">
                              {Timesheet ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/timesheet"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("");
                              }}
                            >
                              {permissions &&
                                permissions["View Timesheet Entry"] && (
                                  <div
                                    className={
                                      currentRoute === "/timesheet"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/timesheet"
                                          ? timesheetentry
                                          : timesheetentry1
                                      }
                                      className="me-2"
                                    />
                                    Timesheet Entry
                                  </div>
                                )}
                            </Link>

                            <Link
                              to="/timesheethistory"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("");
                              }}
                            >
                              {permissions &&
                                permissions["View Timesheet History"] && (
                                  <div
                                    className={
                                      currentRoute === "/timesheethistory"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/timesheethistory"
                                          ? timesheethis1
                                          : timesheethis
                                      }
                                      className="me-2"
                                    />
                                    Timesheet History
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/TimesheetApproval"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("");
                              }}
                            >
                              {permissions &&
                                permissions["View Timesheet Approve"] && (
                                  <div
                                    className={
                                      currentRoute === "/TimesheetApproval"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/TimesheetApproval"
                                          ? timesheetapp1
                                          : timesheetapp
                                      }
                                      className="me-2"
                                    />
                                    Timesheet Approval
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/EmployeeTimesheetDetails"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("TManagments");
                              }}
                            >
                              {permissions &&
                                permissions[
                                "View Employee Timesheet Details"
                                ] && (
                                  <div
                                    className={
                                      clickMenu === "TManagments"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute ===
                                          "/EmployeeTimesheetDetails"
                                          ? emptimedet1
                                          : emptimedet
                                      }
                                      className="me-2"
                                    />
                                    Employee Timesheet Details
                                  </div>
                                )}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}

                  {/* ================> End Timesheet <============== */}

                  {/* =================> START ProjectMangent <============= */}
                  {
                    (permissions && permissions["View Project Documents"]) ||
                      permissions["View Project Resources"] ||
                      permissions["View Project Issues"] ||
                      permissions["View Project Allocation"] ||
                      permissions["View Project Timesheet Details"] ||
                      permissions["View Employee Timesheet Detail"] ||
                      permissions["View Project"] ||
                      permissions["View Project Schedule"] ? (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="8">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                setTimesheet(false);
                                setWalletCheck(false);
                                setApproveLeave(false);
                                setLeave(false);
                                setDashboard(false);
                                setAttendance(false);
                                setTaskManagement(false);
                                setNotifications(false);
                                setCommunication(false);
                                handleProjectMan();
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/projectdocumentation">
                                    {currentRoute === "/projectdocumentation" ||
                                      currentRoute === "/projectdetails" ||
                                      currentRoute === "/ProjectSchedule" ||
                                      currentRoute === "/projectresources" ||
                                      currentRoute === "/Project" ||
                                      currentRoute === "/ProjectAssignment" ||
                                      clickMenu === "PManagments" ||
                                      currentRoute ===
                                      "/ProjectTimesheetDetails" ? (
                                      <img
                                        src={ProjectMan_Dark}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={ProjectMan_active}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    clickMenu === "PManagments" ||
                                      currentRoute === "/projectdocumentation" ||
                                      currentRoute === "/projectdetails" ||
                                      currentRoute === "/ProjectSchedule" ||
                                      currentRoute === "/projectresources" ||
                                      currentRoute === "/Project" ||
                                      currentRoute === "/ProjectAssignment" ||
                                      // currentRoute ===
                                      //   "/EmployeeTimesheetDetails" ||
                                      currentRoute === "/ProjectTimesheetDetails"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Project Management
                                </div>
                              </div>

                              <div className="arrow">
                                {ProjectMan ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/projectdocumentation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Documents"] && (
                                    <div
                                      className={
                                        currentRoute ===
                                          "/projectdocumentation" ||
                                          currentRoute === "/projectdetails"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute ===
                                            "/projectdocumentation" ||
                                            currentRoute === "/projectdetails"
                                            ? prjdoc1
                                            : prjdoc
                                        }
                                        className="me-2"
                                      />
                                      Project Documents
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectSchedule"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Schedule"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectSchedule"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ProjectSchedule"
                                            ? prjschedule1
                                            : prjschedule
                                        }
                                        className="me-2"
                                      />
                                      Project Schedule
                                    </div>
                                  )}
                              </Link>
                              {permissions &&
                                permissions["View Project Resources"] && (
                                  <Link
                                    to="/projectresources"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      setClickMenu("");
                                    }}
                                  >
                                    {/* {permissions && permissions["Project Resources"] && ( */}
                                    <div
                                      className={
                                        currentRoute === "/projectresources"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/projectresources"
                                            ? prjres1
                                            : prjres
                                        }
                                        className="me-2"
                                      />
                                      Project Resources
                                    </div>
                                    {/* )} */}
                                  </Link>
                                )}
                              {permissions &&
                                permissions["View Project Issues"] && (
                                  <Link
                                    to="#"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      setClickMenu("");
                                    }}
                                  >
                                    <div
                                      className={
                                        currentRoute === "/"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/"
                                            ? prjissues1
                                            : prjissues
                                        }
                                        className="me-2"
                                      />
                                      Project Issues
                                    </div>
                                  </Link>
                                )}
                              <Link
                                to="/Project"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions && permissions["View Project"] && (
                                  <div
                                    className={
                                      currentRoute === "/Project"
                                        ? "menu-name activeColorBlue  d-flex justify-content-start"
                                        : "menu-name  d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/Project" ? prj1 : prj
                                      }
                                      className="me-2"
                                    />
                                    Projects
                                  </div>
                                )}
                              </Link>
                              <Link
                                to="/ProjectAssignment"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Allocation"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectAssignment"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ProjectAssignment"
                                            ? prjAlloc1
                                            : prjAlloc
                                        }
                                        className="me-2"
                                      />
                                      Project Allocation
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/EmployeeTimesheetDetails"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("PManagments");
                                }}
                              >
                                {permissions &&
                                  permissions[
                                  "View Employee Timesheet Detail"
                                  ] && (
                                    <div
                                      className={
                                        clickMenu === "PManagments"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute ===
                                            "/EmployeeTimesheetDetails"
                                            ? emptimesheetdet1
                                            : emptimesheetdet
                                        }
                                        className="me-2"
                                      />
                                      Employee Timesheet Details
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectTimesheetDetails"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions[
                                  "View Project Timesheet Details"
                                  ] && (
                                    <div
                                      className={
                                        currentRoute ===
                                          "/ProjectTimesheetDetails"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name  d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute ===
                                            "/ProjectTimesheetDetails"
                                            ? prjtimesheet
                                            : prjtimesheet1
                                        }
                                        className="me-2"
                                      />
                                      Project Timesheet Details
                                    </div>
                                  )}
                              </Link>
                              {/* <Link
                  to="/MyDiary"
                  className="sub-menue-items"
                  onClick={() => {
                    isMobile ? setSidebar(false) : setSidebar(true);
                  }}
                > 
                {
                 permissions &&  permissions["My Diary"] && (
                    <div
                    className={
                      currentRoute === "/MyDiary"
                        ? "menu-name activeColorBlue"
                        : "menu-name"
                    }
                  >
                    My Diary
                  </div>
                  )
                }
                </Link> */}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    ) : null
                    // ) : null
                  }

                  {/* ================> End Timesheet <============== */}

                  {/* =================> START Organizatoin <============= */}

                  {permissions &&
                    (permissions["Team Chart"] ||
                      permissions["View Financial Year"] ||
                      permissions["View Shift Masters"] ||
                      permissions["View Calendar Settings"] ||
                      permissions["View Organization Details"] ||
                      permissions["SMS Gateway"] ||
                      permissions["WhatsApp Settings"] ||
                      permissions["About Organization"] ||
                      permissions["Privacy Policy"] ||
                      permissions["Holiday And Events"] ||
                      permissions["Terms & Conditions"] ||
                      permissions["Payment Gateway"] ||
                      permissions["View Holiday"] ||
                      (permissions && permissions["IVR Settings"]) ||
                      permissions["View Employee Code"] ||
                      permissions["View All Announcement"] ||
                      permissions['View Service Group'] ||

                      permissions['View Service'] ||
                      permissions['View Rate Table']
                    ) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="9">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setAttendance(false);
                              setTimesheet(false);
                              setWalletCheck(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                              handleOrg();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/teamchart">
                                  {currentRoute === "/teamchart" ||
                                    currentRoute === "/AllAnnouncement" ||
                                    currentRoute === "/MyAnnouncement" ||
                                    currentRoute === "/holidaysandevents" ||
                                    currentRoute === "/TearmsAmdCondiation" ||
                                    currentRoute === "/PrivacyPolicy" ||
                                    currentRoute === "/AboutOrganziation" ||
                                    currentRoute === "/Holidays" ||
                                    currentRoute === "/Academic" ||
                                    currentRoute === "/CalendarSettings" ||
                                    currentRoute === "/ShiftMaster" ||
                                    currentRoute === "/OrgainsationInfo" ||
                                    currentRoute === "/OrgDetails" ||
                                    currentRoute === "/ManageDept" ||
                                    currentRoute === "/ManageDesig" ||
                                    currentRoute === "/ManageLoc" ||
                                    currentRoute === "/SMSgateway" ||
                                    currentRoute === "/PaymentGateway" ||
                                    currentRoute === "/ivrsetting" ||
                                    currentRoute === "/whatsappsetting" ? (
                                    <img
                                      src={Organ_Dark}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={Organ_active}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/teamchart" ||
                                    currentRoute === "/AllAnnouncement" ||
                                    currentRoute === "/MyAnnouncement" ||
                                    currentRoute === "/TearmsAmdCondiation" ||
                                    currentRoute === "/PrivacyPolicy" ||
                                    currentRoute === "/AboutOrganziation" ||
                                    currentRoute === "/holidaysandevents" ||
                                    currentRoute === "/Holidays" ||
                                    currentRoute === "/Academic" ||
                                    currentRoute === "/CalendarSettings" ||
                                    currentRoute === "/OrgainsationInfo" ||
                                    currentRoute === "/ShiftMaster" ||
                                    currentRoute === "/EmployeeCode" ||
                                    currentRoute === "/SMSgateway" ||
                                    currentRoute === "/PaymentGateway" ||
                                    currentRoute === "/whatsappsetting" ||
                                    currentRoute === "/ivrsetting" ||
                                    currentRoute === "/serviceManagement" ||
                                    currentRoute === "/service" ||
                                    currentRoute === "/ratetable"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Organization
                              </div>
                            </div>

                            <div className="arrow">
                              {org ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/teamchart"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Team Chart"] && (
                                <div
                                  className={
                                    currentRoute === "/teamchart"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Team Chart
                                </div>
                              )}
                            </Link>
                            <Link
                              to="/AllAnnouncement"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View All Announcement"] && (
                                  <div
                                    className={
                                      currentRoute === "/AllAnnouncement" ||
                                        currentRoute === "/MyAnnouncement"
                                        ? "menu-name activeColorBlue  d-flex justify-content-start"
                                        : "menu-name  d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/AllAnnouncement" ||
                                          currentRoute === "/MyAnnouncement"
                                          ? announcement1
                                          : announcement
                                      }
                                      className="me-2"
                                    />
                                    Announcements
                                  </div>
                                )}
                            </Link>
                            {permissions && permissions["View Holiday"] && (
                              <Link
                                to="/Holidays"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Team Chart"] && ( */}
                                <div
                                  className={
                                    currentRoute === "/Holidays"
                                      ? "menu-name activeColorBlue  d-flex justify-content-start"
                                      : "menu-name  d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/Holidays"
                                        ? holidays1
                                        : holidays
                                    }
                                    className="me-2"
                                  />
                                  Holidays
                                </div>
                                {/* )} */}
                              </Link>
                            )}
                            <Link
                              to="/Academic"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Financial Year"] && (
                                  <div
                                    className={
                                      currentRoute === "/Academic"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/Academic"
                                          ? finyear1
                                          : finyear
                                      }
                                      className="me-2"
                                    />
                                    Financial Year
                                  </div>
                                )}
                            </Link>
                            {/* <Link
                              to="/CalendarSettings"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Calendar Settings"] && (
                                  <div
                                    className={
                                      currentRoute === "/CalendarSettings"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name  d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/CalendarSettings"
                                          ? calenderSett1
                                          : calenderSett
                                      }
                                      className="me-2"
                                    />
                                    Calendar Settings
                                  </div>
                                )}
                            </Link> */}
                            <Link
                              to="/holidaysandevents"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["Holiday And Events"] && (
                                  <div
                                    className={
                                      currentRoute === "/holidaysandevents"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Holidays And Events
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/TearmsAmdCondiation"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["Terms & Conditions"] && (
                                  <div
                                    className={
                                      currentRoute === "/TearmsAmdCondiation"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Terms & Conditions
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/PrivacyPolicy"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Privacy Policy"] && (
                                <div
                                  className={
                                    currentRoute === "/PrivacyPolicy"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Privacy Policy
                                </div>
                              )}
                            </Link>
                            <Link
                              to="/AboutOrganziation"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["About Organization"] && (
                                  <div
                                    className={
                                      currentRoute === "/AboutOrganziation" ||
                                        currentRoute === "/AboutOrganziation"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    About Organization
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/ShiftMaster"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Shift Masters"] && (
                                  <div
                                    className={
                                      currentRoute === "/ShiftMaster" ||
                                        currentRoute === "/ShiftMaster"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/ShiftMaster"
                                          ? shiftmas1
                                          : shiftmas
                                      }
                                      className="me-2"
                                    />
                                    Shift Master
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/OrgainsationInfo"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Organization Details"] && (
                                  <div
                                    className={
                                      currentRoute === "/OrgainsationInfo" ||
                                        currentRoute === "/OrgainsationInfo"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/OrgainsationInfo" ||
                                          currentRoute === "/OrgDetails" ||
                                          currentRoute === "/ManageDesig" ||
                                          currentRoute === "/ManageLoc" ||
                                          currentRoute === "/ManageDept"
                                          ? orginfo1
                                          : orginfo
                                      }
                                      className="me-2"
                                    />
                                    Organization Info
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/PaymentGateway"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["Payment Gateway"] && (
                                  <div
                                    className={
                                      currentRoute === "/PaymentGateway" ||
                                        currentRoute === "/PaymentGateway"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/PaymentGateway"
                                          ? paymentgat1
                                          : paymentgat
                                      }
                                      className="me-2"
                                    />
                                    Payment Gateway
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/SMSgateway"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["SMS Gateway"] && (
                                <div
                                  className={
                                    currentRoute === "/SMSgateway" ||
                                      currentRoute === "/SMSgateway"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/SMSgateway"
                                        ? smspromo1
                                        : smspromo
                                    }
                                    className="me-2"
                                  />
                                  SMS Gateway
                                </div>
                              )}
                            </Link>

                            <Link
                              to="/Ivrgetewway"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {/* {permissions && permissions["IVR Service"] && ( */}
                              <div
                                className={
                                  currentRoute === "/Ivrgetewway" ||
                                    currentRoute === "/Ivrgetewway"
                                    ? "menu-name activeColorBlue d-flex justify-content-start"
                                    : "menu-name d-flex justify-content-start"
                                }
                              >
                                <img
                                  src={
                                    currentRoute === "/Ivrgetewway"
                                      ? smspromo1
                                      : smspromo
                                  }
                                  className="me-2"
                                />
                                IVR Service
                              </div>
                              {/* )} */}
                            </Link>

                            {permissions &&
                              permissions["WhatsApp Settings"] && (
                                <Link
                                  to="/whatsappsetting"
                                  className="sub-menue-items"
                                  onClick={() => {
                                    isMobile
                                      ? setSidebar(false)
                                      : setSidebar(true);
                                  }}
                                >
                                  <div
                                    className={
                                      currentRoute === "/whatsappsetting"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/whatsappsetting"
                                          ? whtsappsett11
                                          : whtsappsettt
                                      }
                                      className="me-2"
                                    />
                                    WhatsApp Setting
                                  </div>
                                </Link>
                              )}
                            <Link
                              to="/ivrsetting"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["IVR Settings"] && (
                                <div
                                  className={
                                    currentRoute === "/ivrsetting" ||
                                      currentRoute === "/ivrsetting"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/ivrsetting"
                                        ? ivrsett1
                                        : ivrsett
                                    }
                                    className="me-2"
                                  />
                                  IVR Setting
                                </div>
                              )}
                            </Link>

                            <Link
                              to="/EmployeeCode"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Employee Code"] && (
                                  <div
                                    className={
                                      currentRoute === "/EmployeeCode"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/EmployeeCode"
                                          ? empcode1
                                          : empcode
                                      }
                                      className="me-2"
                                    />
                                    Employee Code Setting
                                  </div>
                                )}
                            </Link>


                            <Link className="sub-menue-items">
                              {
                                permissions && (
                                  permissions['View Service Group'] ||
                                  permissions['View Service'] ||
                                  permissions['View Rate Table']
                                ) && (
                                  <div
                                    className={
                                      currentRoute === "/serviceManagement" ||
                                        currentRoute === "/service" ||
                                        currentRoute === "/ratetable"
                                        ? // currentRoute === "/LeadListCount"
                                        "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                    onClick={() => {
                                      setIsServiceOpen(!isServiceOpen);
                                      // setActiveChat({});
                                      // setCurrentSChannel(null);
                                    }}
                                  >
                                    <img
                                      src={
                                        currentRoute === "/serviceManagement" ||
                                          currentRoute === "/service" ||
                                          currentRoute === "/ratetable"
                                          ? domapp1
                                          : domapp
                                      }
                                      className="me-2"
                                    />
                                    Service Management
                                    <div className="arrow">
                                      {isServiceOpen ? (
                                        <img src={down_arrow} alt="" />
                                      ) : (
                                        <img src={ic_blueUP_arrow} alt="" />
                                      )}
                                    </div>
                                  </div>)

                              }
                            </Link>

                            {isServiceOpen && (
                              <div
                                style={{
                                  marginLeft: "20px",
                                  borderLeft: "1px solid  #53b7e8",
                                }}
                              >
                                {
                                  permissions && permissions['View Service Group'] &&

                                  <Link
                                    to="/serviceManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                    }}
                                  >
                                    {/* {permissions && permissions["Announcements"] && ( */}
                                    <div
                                      className={
                                        currentRoute === "/serviceManagement"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                                      Service Group
                                    </div>
                                    {/* )} */}
                                  </Link>
                                }
                                {
                                  permissions && permissions['View Service'] &&

                                  <Link
                                    to="/service"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                    }}
                                  >

                                    <div
                                      className={
                                        currentRoute === "/service"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      Service
                                    </div>

                                  </Link>
                                }
                                {
                                  permissions && permissions['View Rate Table'] &&

                                  <Link
                                    to="/ratetable"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                    }}
                                  >
                                    {/* {permissions && permissions["Announcements"] && ( */}
                                    <div
                                      className={
                                        currentRoute === "/ratetable"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                                      Rate Table
                                    </div>
                                    {/* )} */}
                                  </Link>
                                }
                              </div>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}
                  {/* =================> End Organixatoin <============= */}

                  {/* Financial Management */}

                  <div className="with-subMenu">
                    <Accordion.Item eventKey="26">
                      <Accordion.Header className="menus-items ">
                        <Link
                          to="#"
                          className="icon-menu-name-arrow"
                          onClick={() => {
                            handleNotifications();
                            setAttendance(false);
                            setWalletCheck(false);
                            setTimesheet(false);
                            setTaskManagement(false);
                            setCommunication(false);
                            setProjectMan(false);
                            handleCompliance();
                          }}
                        >
                          <div className="menu-left">
                            <div className="icon-left">
                              <Link to="#">
                                {currentRoute === "/SalaryMasterRoll" ||
                                  currentRoute === "/SalaryStructure" ||
                                  currentRoute === "/GenerateSalarySlip" ? (
                                  <img
                                    src={ComplianceActive}
                                    alt=""
                                    className="mt-1"
                                  />
                                ) : (
                                  <img
                                    src={Compliance}
                                    alt=""
                                    className="mt-1"
                                  />
                                )}
                              </Link>
                            </div>
                            <div
                              className={
                                currentRoute === "/SalaryMasterRoll" ||
                                  currentRoute === "/SalaryStructure" ||
                                  currentRoute === "/GenerateSalarySlip"
                                  ? "menu-name activeColorBlue"
                                  : "menu-name"
                              }
                            >
                              Finance Management
                            </div>
                          </div>

                          <div className="arrow">
                            {compliance ? (
                              <img src={down_arrow} alt="" />
                            ) : (
                              <img src={ic_blueUP_arrow} alt="" />
                            )}
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={sidebar ? "sub-menus" : "none"}>
                          <Link
                            to={"#"}
                            //to="/TeamNotAssigned"
                            className="sub-menue-items"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            {/* {permissions && permissions["Announcements"] && ( */}

                            <div
                              className={
                                currentRoute === "/SalaryMasterRoll" ||
                                  currentRoute === "/SalaryStructure" ||
                                  currentRoute === "/GenerateSalarySlip"
                                  ? "menu-name activeColorBlue d-flex justify-content-start"
                                  : "menu-name d-flex justify-content-start"
                              }
                              onClick={() => setIsPayRollOpen(!isPayRollOpen)}
                            >
                              <img
                                src={
                                  currentRoute === "/SalaryMasterRoll" ||
                                    currentRoute === "/SalaryStructure" ||
                                    currentRoute === "/GenerateSalarySlip"
                                    ? teamnotassigned1
                                    : teamnotassigned
                                }
                                className="me-2"
                              />
                              PayRoll
                            </div>
                            {isPayRollOpen && (
                              <div
                                style={{
                                  marginLeft: "5px",
                                  borderLeft: "1px solid  #53b7e8",
                                }}
                              >
                                <Link
                                  to="/SalaryStructure"
                                  className="sub-menue-items"
                                  onClick={() => {
                                    isMobile
                                      ? setSidebar(false)
                                      : setSidebar(true);
                                  }}
                                >
                                  {permissions &&
                                    permissions["View Salary Structure"] && (
                                      <div
                                        className={
                                          currentRoute === "/SalaryStructure"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute === "/SalaryStructure"
                                              ? salarystr1
                                              : salarystr
                                          }
                                          className="me-2"
                                        />
                                        Salary Structure
                                      </div>
                                    )}
                                </Link>
                                <Link
                                  to="/GenerateSalarySlip"
                                  className="sub-menue-items"
                                  onClick={() => {
                                    isMobile
                                      ? setSidebar(false)
                                      : setSidebar(true);
                                  }}
                                >
                                  {permissions &&
                                    permissions[
                                    "View Generate Salary Slip"
                                    ] && (
                                      <div
                                        className={
                                          currentRoute === "/GenerateSalarySlip"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          src={
                                            currentRoute ===
                                              "/GenerateSalarySlip"
                                              ? gensalslip1
                                              : gensalslip
                                          }
                                          className="me-2"
                                        />
                                        Generate Salary Slip
                                      </div>
                                    )}
                                </Link>
                                {permissions &&
                                  permissions["View Salary Muster Roll"] && (
                                    <Link
                                      to="/SalaryMasterRoll"
                                      className="sub-menue-items"
                                      onClick={() => {
                                        isMobile
                                          ? setSidebar(false)
                                          : setSidebar(true);
                                      }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/SalaryMasterRoll"
                                            ? "menu-name activeColorBlue d-flex justify-content-start"
                                            : "menu-name d-flex justify-content-start"
                                        }
                                      >
                                        <img
                                          style={{ width: "20px" }}
                                          src={
                                            currentRoute === "/SalaryMasterRoll"
                                              ? musterrole1
                                              : musterrole
                                          }
                                          className="me-2"
                                        />
                                        Salary Muster Roll
                                      </div>
                                    </Link>
                                  )}
                              </div>
                            )}
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>

                  {/* =================> START Complaince <============= */}

                  {permissions &&
                    (permissions["View Team Not Assigned"] ||
                      permissions["View Project Assigned"] ||
                      permissions["View Project De-Assigned"] ||
                      permissions["View Timesheet Not Entered"] ||
                      permissions["View Project Not Assigned"] ||
                      permissions["View Two Plus Project Assigned"] ||
                      permissions["View Task Not Added"] ||
                      permissions["View Timesheet Not Approved"] ||
                      permissions["View Timesheet Period Over"] ||
                      permissions["View Bench List"] ||
                      permissions["View Compliance Settings"] ||
                      permissions["View User Not Login"] ||
                      permissions["View User Last Login"] ||
                      permissions["View User Activity"]) && (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="10">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleNotifications();
                                setAttendance(false);
                                setWalletCheck(false);
                                setTimesheet(false);
                                setTaskManagement(false);
                                setCommunication(false);
                                setProjectMan(false);
                                handleCompliance();
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/teamchart">
                                    {currentRoute === "/TeamNotAssigned" ||
                                      currentRoute === "/ProjectNotAssigned" ||
                                      currentRoute === "/ProjectAssigned" ||
                                      currentRoute === "/ProjectDeAssign" ||
                                      currentRoute ===
                                      "/TwoPlusProjectsAssigned" ||
                                      currentRoute ===
                                      "/TaskNotAddedAgainstProject" ||
                                      currentRoute === "/TaskNotApproved" ||
                                      currentRoute === "/TimesheetNotEntered" ||
                                      currentRoute === "/TimesheetNotApproved" ||
                                      currentRoute === "/TimePeriodOver" ||
                                      currentRoute === "/BenchList" ||
                                      currentRoute === "/UsernotLogin" ||
                                      currentRoute === "/ComplianceSetting" ||
                                      currentRoute === "/ComplianceSetting" ||
                                      currentRoute === "/UserLastLogin" ||
                                      currentRoute === "/UserActivity" ||
                                      currentRoute === "/UserNotLogin" ? (
                                      <img
                                        src={ComplianceActive}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={Compliance}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/TeamNotAssigned" ||
                                      currentRoute === "/ProjectNotAssigned" ||
                                      currentRoute === "/ProjectAssigned" ||
                                      currentRoute === "/ProjectDeAssign" ||
                                      currentRoute ===
                                      "/TwoPlusProjectsAssigned" ||
                                      currentRoute ===
                                      "/TaskNotAddedAgainstProject" ||
                                      currentRoute === "/TaskNotApproved" ||
                                      currentRoute === "/TimesheetNotEntered" ||
                                      currentRoute === "/TimesheetNotApproved" ||
                                      currentRoute === "/TimePeriodOver" ||
                                      currentRoute === "/BenchList" ||
                                      currentRoute === "/UsernotLogin" ||
                                      currentRoute === "/ComplianceSetting" ||
                                      currentRoute === "/UserNotLogin" ||
                                      currentRoute === "/UserLastLogin" ||
                                      currentRoute === "/UserActivity"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Compliance
                                </div>
                              </div>

                              <div className="arrow">
                                {compliance ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/TeamNotAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View Team Not Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/TeamNotAssigned"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/TeamNotAssigned"
                                            ? teamnotassigned1
                                            : teamnotassigned
                                        }
                                        className="me-2"
                                      />
                                      Team Not Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectNotAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Not Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectNotAssigned"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ProjectNotAssigned"
                                            ? prjnotassign1
                                            : prjnotassign
                                        }
                                        className="me-2"
                                      />
                                      Project Not Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectAssigned"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ProjectAssigned"
                                            ? prjss1
                                            : prjss
                                        }
                                        className="me-2"
                                      />
                                      Project Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectDeAssign"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project De-Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectDeAssign"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ProjectDeAssign"
                                            ? prjdeassign1
                                            : prjdeassign
                                        }
                                        className="me-2"
                                      />
                                      Project De-Assigned
                                    </div>
                                  )}
                              </Link>

                              <Link
                                to="/TwoPlusProjectsAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions[
                                  "View Two Plus Project Assigned"
                                  ] && (
                                    <div
                                      className={
                                        currentRoute ===
                                          "/TwoPlusProjectsAssigned"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute ===
                                            "/TwoPlusProjectsAssigned"
                                            ? twoplusprj1
                                            : twoplusprj
                                        }
                                        className="me-2"
                                      />
                                      2+ Projects Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TaskNotAddedAgainstProject"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Not Added"] && (
                                    <div
                                      className={
                                        currentRoute ===
                                          "/TaskNotAddedAgainstProject"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute ===
                                            "/TaskNotAddedAgainstProject"
                                            ? tasknotadd1
                                            : tasknotadd
                                        }
                                        className="me-2"
                                      />
                                      Task Not Added
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TaskNotApproved"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Not Approved"] && (
                                    <div
                                      className={
                                        currentRoute === "/TaskNotApproved"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/TaskNotApproved"
                                            ? tasknotapp1
                                            : tasknotapp
                                        }
                                        className="me-2"
                                      />
                                      Task Not Approved
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TimesheetNotEntered"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Timesheet Not Entered"] && (
                                    <div
                                      className={
                                        currentRoute === "/TimesheetNotEntered"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Timesheet Not Entered
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TimesheetNotApproved"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions[
                                  "View Timesheet Not Approved"
                                  ] && (
                                    <div
                                      className={
                                        currentRoute === "/TimesheetNotApproved"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Timesheet Not Approved
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TimePeriodOver"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Timesheet Period Over"] && (
                                    <div
                                      className={
                                        currentRoute === "/TimePeriodOver"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/TimePeriodOver"
                                            ? timesheerperover1
                                            : timesheerperover
                                        }
                                        className="me-2"
                                      />
                                      Timesheet Period Over
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/BenchList"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Bench List"] && (
                                    <div
                                      className={
                                        currentRoute === "/BenchList"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/BenchList"
                                            ? benchlist1
                                            : benchlist
                                        }
                                        className="me-2"
                                      />
                                      Bench list
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ComplianceSetting"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Compliance Settings"] && (
                                    <div
                                      className={
                                        currentRoute === "/ComplianceSetting"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ComplianceSetting"
                                            ? complsett1
                                            : complsett
                                        }
                                        className="me-2"
                                      />
                                      Compliance Setting
                                    </div>
                                  )}
                              </Link>

                              <Link
                                to="/UserNotLogin"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View User Not Login"] && (
                                    <div
                                      className={
                                        currentRoute === "/UserNotLogin"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/UserNotLogin"
                                            ? usernotlogin1
                                            : usernotlogin
                                        }
                                        className="me-2"
                                      />
                                      User Not Login
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/UserLastLogin"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View User Last Login"] && (
                                    <div
                                      className={
                                        currentRoute === "/UserLastLogin"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/UserLastLogin"
                                            ? userlastlogin1
                                            : userlastlogin
                                        }
                                        className="me-2"
                                      />
                                      User Last Login
                                    </div>
                                  )}
                              </Link>

                              <Link
                                to="/UserActivity"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View User Activity"] && (
                                    <div
                                      className={
                                        currentRoute === "/UserActivity"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/UserActivity"
                                            ? reguser1
                                            : reguser
                                        }
                                        className="me-2"
                                      />
                                      User Activity
                                    </div>
                                  )}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    )}

                  {/* ) : null
              } */}

                  {/* ================> END Compliance <============== */}

                  {/* =================> START Teams Preference <============= */}

                  {permissions && permissions["View Create Team"] && (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="11">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setWalletCheck(false);
                              setAttendance(false);
                              setTimesheet(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                              handleTeammgt();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/CreateTeam">
                                  {currentRoute === "/CreateTeam" ||
                                    currentRoute === "/ManageTeam" ? (
                                    <img
                                      src={TeamsManagementActiveIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={TeamsManagementIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/CreateTeam" ||
                                    currentRoute === "/ManageTeam"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Teams Management
                              </div>
                            </div>

                            <div className="arrow">
                              {teamsmgt ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/CreateTeam"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Create Team"] && (
                                  <div
                                    className={
                                      currentRoute === "/CreateTeam"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/CreateTeam"
                                          ? addTeam1
                                          : addTeam
                                      }
                                      className="me-2"
                                    />
                                    Create Team
                                  </div>
                                )}
                            </Link>
                            {permissions && permissions["View Manage Team"] && (
                              <Link
                                to="/ManageTeam"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                <div
                                  className={
                                    currentRoute === "/ManageTeam"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/ManageTeam"
                                        ? manageTeam1
                                        : manageTeam
                                    }
                                    className="me-2"
                                  />
                                  Manage Teams
                                </div>
                                {/* )} */}
                              </Link>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  )}

                  {/* =================> START Project Costing <============= */}
                  {permissions &&
                    (permissions["View Project Cost Report"] ||
                      permissions["View Employee Documents"]) && (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="12">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleNotifications();
                                setAttendance(false);
                                setWalletCheck(false);
                                setTimesheet(false);
                                setTaskManagement(false);
                                setCommunication(false);
                                setProjectMan(false);
                                handlePrjcosting();
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="#">
                                    {currentRoute === "/ProjectCost" ||
                                      currentRoute === "/ProjectCostEnd" ? (
                                      <img
                                        src={projCostActive}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={projCost}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/ProjectCost" ||
                                      currentRoute === "/ProjectCostEnd"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Project Costing
                                </div>
                              </div>

                              <div className="arrow">
                                {prjcosting ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/ProjectCost"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions && permissions["Project Cost"] && (
                                  <div
                                    className={
                                      currentRoute === "/ProjectCost"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Project Cost
                                  </div>
                                )}
                              </Link>
                              <Link
                                to="/ProjectCostReport"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Cost Report"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectCostReport"
                                          ? "menu-name activeColorBlue d-flex justify-content-start"
                                          : "menu-name d-flex justify-content-start"
                                      }
                                    >
                                      <img
                                        src={
                                          currentRoute === "/ProjectCostReport"
                                            ? prjcostRep1
                                            : prjcostRep
                                        }
                                        className="me-2"
                                      />
                                      Project Cost Report
                                    </div>
                                  )}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    )}

                  {/* =================> START Employee <============= */}
                  {permissions && permissions["Create Employee"] && (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="13">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setAttendance(false);
                              setWalletCheck(false);
                              setTimesheet(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                              handleEmpmgt();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="#">
                                  {currentRoute === "/List" ||
                                    currentRoute === "/AddEmployee" ||
                                    currentRoute ===
                                    "/EditEmployeeProfile/:id" ? (
                                    <img
                                      src={EmployeeManagementActiveIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={EmployeeManagementIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/List" ||
                                    currentRoute === "/AddEmployee" ||
                                    currentRoute === "/EditEmployeeProfile/:id"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Employee Management
                              </div>
                            </div>

                            <div className="arrow">
                              {empmgt ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/List"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Employee List"] && (
                                <div
                                  className={
                                    currentRoute === "/List"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/List"
                                        ? emplist1
                                        : emplist
                                    }
                                    className="me-2"
                                  />
                                  Employee List
                                </div>
                              )}
                            </Link>
                            <Link
                              to="/AddEmployee"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Add Employee"] && (
                                <div
                                  className={
                                    currentRoute === "/AddEmployee"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/AddEmployee"
                                        ? addemp1
                                        : addemp
                                    }
                                    className="me-2"
                                  />
                                  Add Employee
                                </div>
                              )}
                            </Link>
                            {permissions &&
                              permissions["View Employee Documents"] && (
                                <Link
                                  to="/employeedoucemnt"
                                  className="sub-menue-items"
                                  onClick={() => {
                                    isMobile
                                      ? setSidebar(false)
                                      : setSidebar(true);
                                  }}
                                >
                                  <div
                                    className={
                                      currentRoute === "/employeedoucemnt"
                                        ? "menu-name activeColorBlue d-flex justify-content-start"
                                        : "menu-name d-flex justify-content-start"
                                    }
                                  >
                                    <img
                                      src={
                                        currentRoute === "/employeedoucemnt"
                                          ? empDoc1
                                          : empDoc
                                      }
                                      className="me-2"
                                    />
                                    Employee Document
                                  </div>
                                </Link>
                              )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  )}

                  {/* Inverter Mangement  */}
                  {permissions && permissions["View Inventory Management"] && (
                  <div className="with-subMenu">
                    <Accordion.Item eventKey="23">
                      <Accordion.Header className="menus-items ">
                        <Link
                          to="#"
                          className="icon-menu-name-arrow"
                          onClick={() => {
                            handleNotifications();
                            setAttendance(false);
                            setWalletCheck(false);
                            setTimesheet(false);
                            setTaskManagement(false);
                            setCommunication(false);
                            setProjectMan(false);
                            handleEmpmgt();
                          }}
                        >
                          <div className="menu-left">
                            <div className="icon-left">
                              <Link to="#">
                                {currentRoute === "/List" ||
                                  currentRoute === "/AddEmployee" ||
                                  currentRoute === "/EditEmployeeProfile/:id" ? (
                                  <img
                                    src={EmployeeManagementActiveIcon}
                                    alt=""
                                    className="mt-1"
                                  />
                                ) : (
                                  <img
                                    src={EmployeeManagementIcon}
                                    alt=""
                                    className="mt-1"
                                  />
                                )}
                              </Link>
                            </div>
                            <div
                              className={
                                currentRoute === "/List" ||
                                  currentRoute === "/AddEmployee" ||
                                  currentRoute === "/EditEmployeeProfile/:id"
                                  ? "menu-name activeColorBlue"
                                  : "menu-name"
                              }
                            >
                              Inventory Management
                            </div>
                          </div>

                          <div className="arrow">
                            {empmgt ? (
                              <img src={down_arrow} alt="" />
                            ) : (
                              <img src={ic_blueUP_arrow} alt="" />
                            )}
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={sidebar ? "sub-menus" : "none"}>
                          <Link
                            to="/BaseMenu"
                            className="sub-menue-items"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            {permissions && permissions["View Menu List"] && (
                              <div
                                className={
                                  currentRoute === "/BaseMenu"
                                    ? "menu-name activeColorBlue d-flex justify-content-start"
                                    : "menu-name d-flex justify-content-start"
                                }
                              >
                                <img
                                  src={
                                    currentRoute === "/BaseMenu"
                                      ? emplist1
                                      : emplist
                                  }
                                  className="me-2"
                                />
                                Menu List
                              </div>
                            )}
                          </Link>
                          <Link
                            to="/MenuOnOff"
                            className="sub-menue-items"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            {permissions && permissions["View Menu Item On/Off"] && (
                              <div
                                className={
                                  currentRoute === "/MenuOnOff"
                                    ? "menu-name activeColorBlue d-flex justify-content-start"
                                    : "menu-name d-flex justify-content-start"
                                }
                              >
                                <img
                                  src={
                                    currentRoute === "/MenuOnOff"
                                      ? addemp1
                                      : addemp
                                  }
                                  className="me-2"
                                />
                                Menu Item On/Off
                              </div>
                            )}
                          </Link>
                          {permissions &&
                            permissions["View Order Listing"] && (
                              <Link
                                to="/Onlineorderactivity"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Onlineorderactivity"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/Onlineorderactivity"
                                        ? empDoc1
                                        : empDoc
                                    }
                                    className="me-2"
                                  />
                                  Order Listing
                                </div>
                              </Link>
                            )}

                          {permissions &&
                            permissions["View Store Management"] && (
                              <Link
                                to="/ManageStore"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/ManageStore"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/ManageStore"
                                        ? empDoc1
                                        : empDoc
                                    }
                                    className="me-2"
                                  />
                                  Store Management
                                </div>
                              </Link>
                            )}

                          {permissions &&
                            permissions["View Stock"] && (
                              <Link
                                to="/Stock"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Stock"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/Stock"
                                        ? empDoc1
                                        : empDoc
                                    }
                                    className="me-2"
                                  />
                                  Stock
                                </div>
                              </Link>
                            )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
               )} 

                  {/* =================> START General setting <============= */}
                  <div className="with-subMenu">
                    {permissions && permissions["View Settings"] && (
                      <Accordion.Item eventKey="14">
                        <Accordion.Header className="menus-items">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setWalletCheck(false);
                              setAttendance(false);
                              setTimesheet(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                              handleGenSet();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="#">
                                  {currentRoute === "/GeneralSetting" ? (
                                    <img
                                      src={GeneralSettingActiveIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={GeneralSettingIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/GeneralSetting"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                General Setting
                              </div>
                            </div>

                            <div className="arrow">
                              {genset ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/GeneralSetting"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {/* {permissions && permissions["Announcements"] && ( */}
                              <div
                                className={
                                  currentRoute === "/GeneralSetting"
                                    ? "menu-name activeColorBlue d-flex justify-content-start"
                                    : "menu-name d-flex justify-content-start"
                                }
                              >
                                <img
                                  src={
                                    currentRoute === "/GeneralSetting"
                                      ? settings1
                                      : settings
                                  }
                                  className="me-2"
                                />
                                Settings
                              </div>
                              {/* )} */}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </div>
                </>
              ) : null}

              {/* Chat Admin */}

              {/* =================> START General setting <============= */}
              <div className="with-subMenu">
                <Accordion.Item eventKey="16">
                  {permissions &&
                    (permissions["Un-Answered List"] ||
                      permissions["Question List"] ||
                      permissions["Add Question"] ||
                      permissions["ChatBot Setting"] ||
                      permissions["Import Training Questions"] ||
                      permissions["Conversation Flow"] ||
                      permissions["ChatBot Conversation"]) && (
                      <Accordion.Header className="menus-items">
                        <Link
                          to="#"
                          className="icon-menu-name-arrow"
                          onClick={() => {
                            handleNotifications();
                            setWalletCheck(false);
                            setAttendance(false);
                            setTimesheet(false);
                            setTaskManagement(false);
                            setCommunication(false);
                            setProjectMan(false);
                            handleChatadd();
                          }}
                        >
                          <div className="menu-left">
                            <div className="icon-left">
                              <Link to="#">
                                {currentRoute === "/accesscode" ||
                                  currentRoute === "/unAnsweredListing" ||
                                  currentRoute === "/questionListing" ||
                                  currentRoute === "/addQuestion" ||
                                  currentRoute === "/setting" ||
                                  currentRoute === "/traineeQues" ||
                                  currentRoute === "/converstionflow" ||
                                  currentRoute === "/adminChat" ? (
                                  <img
                                    src={GeneralSettingActiveIcon}
                                    alt=""
                                    className="mt-1"
                                  />
                                ) : (
                                  <img
                                    src={GeneralSettingIcon}
                                    alt=""
                                    className="mt-1"
                                  />
                                )}
                              </Link>
                            </div>
                            <div
                              className={
                                currentRoute === "/accesscode" ||
                                  currentRoute === "/unAnsweredListing" ||
                                  currentRoute === "/questionListing" ||
                                  currentRoute === "/addQuestion" ||
                                  currentRoute === "/setting" ||
                                  currentRoute === "/traineeQues" ||
                                  currentRoute === "/converstionflow" ||
                                  currentRoute === "/adminChat"
                                  ? "menu-name activeColorBlue"
                                  : "menu-name"
                              }
                            >
                              ChatBot Admin
                            </div>
                          </div>

                          <div className="arrow">
                            {chatadd ? (
                              <img src={down_arrow} alt="" />
                            ) : (
                              <img src={ic_blueUP_arrow} alt="" />
                            )}
                          </div>
                        </Link>
                      </Accordion.Header>
                    )}
                  <Accordion.Body>
                    <div className={sidebar ? "sub-menus" : "none"}>
                      {/* <Link
                              to="/accesscode"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              <div
                                className={
                                  currentRoute === "/accesscode"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Access Code
                              </div>
                            </Link> */}

                      <Link
                        to="/adminChat"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["ChatBot Conversation"] && (
                          <div
                            className={
                              currentRoute === "/adminChat"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/adminChat"
                                  ? coversations
                                  : coversations1
                              }
                              className="me-2"
                            />
                            Conversations
                          </div>
                        )}
                      </Link>

                      <Link
                        to="/unAnsweredListing"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["Un-Answered List"] && (
                          <div
                            className={
                              currentRoute === "/unAnsweredListing"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/unAnsweredListing"
                                  ? unAns
                                  : unAns1
                              }
                              className="me-2"
                            />
                            Un-Answered List
                          </div>
                        )}
                      </Link>

                      <Link
                        to="/questionListing"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["Question List"] && (
                          <div
                            className={
                              currentRoute === "/questionListing"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/questionListing"
                                  ? queList
                                  : queList1
                              }
                              className="me-2"
                            />
                            Question List
                          </div>
                        )}
                      </Link>
                      {/* <Link
                        to="/userfeddback"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["Question List"] && (
                          <div
                            className={
                              currentRoute === "/userfeddback"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/userfeddback"
                                  ? queList
                                  : queList1
                              }
                              className="me-2"
                            />
                            User Feedback
                          </div>
                        )}
                      </Link> */}

                      <Link
                        to="/addQuestion"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["Add Question"] && (
                          <div
                            className={
                              currentRoute === "/addQuestion"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/addQuestion"
                                  ? addQue
                                  : addQue1
                              }
                              className="me-2"
                            />
                            Add Question
                          </div>
                        )}
                      </Link>

                      <Link to="/setting" className="sub-menue-items">
                        {permissions && permissions["ChatBot Setting"] && (
                          <div
                            onClick={() => {
                              setSettingsOpen(!SettingsOpen);
                            }}
                            className={
                              currentRoute === "/setting" ||
                                currentRoute === "/accesscode"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/setting" ||
                                  currentRoute === "/accesscode"
                                  ? setting
                                  : setting1
                              }
                              className="me-2"
                            />
                            Setting
                          </div>
                        )}
                        {SettingsOpen && (
                          <div
                            style={{
                              marginLeft: "10px",
                              borderLeft: "1px solid  #53b7e8",
                            }}
                          >
                            <Link
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                getProjects();
                              }}
                            // className="sub-menue-items"
                            // onClick={() => {
                            //   isMobile ? setSidebar(false) : setSidebar(true);
                            // }}
                            >
                              <Link to="/accesscode">
                                <div
                                  className={
                                    currentRoute === "/accesscode"
                                      ? "menu-name activeColorBlue d-flex justify-content-start"
                                      : "menu-name d-flex justify-content-start"
                                  }
                                >
                                  <img
                                    src={
                                      currentRoute === "/accesscode"
                                        ? accesscode
                                        : accesscode1
                                    }
                                    className="me-2"
                                  />
                                  Access
                                </div>
                              </Link>
                              {/* {permissions && permissions["Announcements"] && ( */}
                            </Link>
                          </div>
                        )}
                      </Link>

                      <Link
                        to="/traineeQues"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions &&
                          permissions["Import Training Questions"] && (
                            <div
                              className={
                                currentRoute === "/traineeQues"
                                  ? "menu-name activeColorBlue d-flex justify-content-start"
                                  : "menu-name d-flex justify-content-start"
                              }
                            >
                              <img
                                src={
                                  currentRoute === "/traineeQues"
                                    ? traineeQue
                                    : traineeQue1
                                }
                                className="me-2"
                              />
                              Import Training Questions
                            </div>
                          )}
                      </Link>

                      <Link
                        to="/converstionflow"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["Conversation Flow"] && (
                          <div
                            className={
                              currentRoute === "/converstionflow"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/converstionflow"
                                  ? convFlow
                                  : convFlow1
                              }
                              className="me-2"
                            />
                            Conversation Flow
                          </div>
                        )}
                      </Link>

                      <Link
                        to="/dataStatistics"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {permissions && permissions["Conversation Flow"] && (
                          <div
                            className={
                              currentRoute === "/dataStatistics"
                                ? "menu-name activeColorBlue d-flex justify-content-start"
                                : "menu-name d-flex justify-content-start"
                            }
                          >
                            <img
                              src={
                                currentRoute === "/dataStatistics"
                                  ? datastat1
                                  : datastat
                              }
                              className="me-2"
                            />
                            Data Statistics
                          </div>
                        )}
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>

              {permissions["Products"] ||
                permissions["Categories"] ||
                permissions["Employee Wallet"] ||
                permissions["Store"] ||
                permissions["Wallet Recharges"] ||
                permissions["Wallet Sales"] ? (
                <div className="with-subMenu">
                  <Accordion.Item eventKey="15">
                    <Accordion.Header className="menus-items ">
                      <Link
                        to="#"
                        className="icon-menu-name-arrow"
                        onClick={() => {
                          handleWallet();
                          setNotifications(false);
                          setAttendance(false);
                          setTimesheet(false);
                          setTaskManagement(false);
                          setCommunication(false);
                          setProjectMan(false);
                        }}
                      >
                        <div className="menu-left">
                          <div className="icon-left">
                            <Link to="#">
                              {currentRoute === "/EmpoloyeeWallet" ||
                                currentRoute === "/RechargeWallet" ||
                                currentRoute === "/WalletSales" ||
                                currentRoute === "/TransactionReceipt" ||
                                currentRoute === "/WalletRecharge" ||
                                currentRoute === "/Store" ||
                                currentRoute === "/Categories" ||
                                currentRoute === "/Product" ||
                                currentRoute === "/CreateCategories" ||
                                currentRoute === "/CreateProducts" ||
                                currentRoute === "/ProductStock" ||
                                currentRoute === "/AddProductStock" ||
                                currentRoute === "/IssueCard" ? (
                                <img
                                  src={walletActive}
                                  alt=""
                                  className="mt-1"
                                />
                              ) : (
                                <img src={wallet} alt="" className="mt-1" />
                              )}
                            </Link>
                          </div>
                          <div
                            className={
                              currentRoute === "/EmpoloyeeWallet" ||
                                currentRoute === "/RechargeWallet" ||
                                currentRoute === "/WalletSales" ||
                                currentRoute === "/TransactionReceipt" ||
                                currentRoute === "/WalletRecharge" ||
                                currentRoute === "/Store" ||
                                currentRoute === "/Categories" ||
                                currentRoute === "/Product" ||
                                currentRoute === "/CreateCategories" ||
                                currentRoute === "/CreateProducts" ||
                                currentRoute === "/ProductStock" ||
                                currentRoute === "/AddProductStock" ||
                                currentRoute === "/IssueCard"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Wallet
                          </div>
                        </div>

                        <div className="arrow">
                          {walletCheck ? (
                            <img src={down_arrow} alt="" />
                          ) : (
                            <img src={ic_blueUP_arrow} alt="" />
                          )}
                        </div>
                      </Link>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={sidebar ? "sub-menus" : "none"}>
                        <Link
                          to="/Store"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/Store"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Store
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/Categories"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/Categories" ||
                                currentRoute === "/CreateCategories"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Categories
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/Product"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/Product" ||
                                currentRoute === "/CreateProducts"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Products
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/ProductStock"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/ProductStock" ||
                                currentRoute === "/AddProductStock"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Products Stock
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/IssueCard"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/IssueCard"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Issue Card
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/EmpoloyeeWallet"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/EmpoloyeeWallet" ||
                                currentRoute === "/RechargeWallet"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Employee Wallet
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/WalletSales"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/WalletSales" ||
                                currentRoute === "/TransactionReceipt"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Wallet Sales
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/WalletRecharge"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/WalletRecharge" ||
                                currentRoute === "/TransactionReceipt"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Wallet Recharges
                          </div>
                          {/* )} */}
                        </Link>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              ) : null}

              {/* starting of Service Management */}

              <div className="with-subMenu">
                <Accordion.Item eventKey="20">
                  <Accordion.Header className="menus-items">
                    <Link to="#" className="icon-menu-name-arrow">
                      <div className="menu-left">
                        <div className="icon-left">
                          <Link to="#">
                            {currentRoute === "/SubscriptionMgt" ? (
                              <img
                                src={GeneralSettingActiveIcon}
                                alt=""
                                className="mt-1"
                              />
                            ) : (
                              <img
                                src={GeneralSettingIcon}
                                alt=""
                                className="mt-1"
                              />
                            )}
                          </Link>
                        </div>
                        <div
                          className={
                            currentRoute === "/SubscriptionMgt"
                              ? "menu-name activeColorBlue"
                              : "menu-name"
                          }
                        >
                          Subcription Management
                        </div>
                      </div>
                      <div className="arrow">
                        {genset ? (
                          <img src={down_arrow} alt="" />
                        ) : (
                          <img src={ic_blueUP_arrow} alt="" />
                        )}
                      </div>
                    </Link>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className={sidebar ? "sub-menus" : "none"}>
                      <Link
                        to="/SubscriptionMgt"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/SubscriptionMgt"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          <img
                            src={
                              currentRoute === "/SubscriptionMgt"
                                ? settings1
                                : settings
                            }
                            className="me-2"
                          />
                          Subscription List
                        </div>
                        {/* )} */}
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>

      
            
              <div className="with-subMenu">
                {/* {permissions && permissions["View Settings"] && ( */}
                <Accordion.Item eventKey="29">
                  <Accordion.Header className="menus-items">
                    <Link
                      to="#"
                      className="icon-menu-name-arrow"
                    // onClick={() => {
                    //   handleNotifications();
                    //   setWalletCheck(false);
                    //   setAttendance(false);
                    //   setTimesheet(false);
                    //   setTaskManagement(false);
                    //   setCommunication(false);
                    //   setProjectMan(false);
                    //   handleGenSet();
                    // }}
                    >
                      <div className="menu-left">
                        <div className="icon-left">
                          <Link to="/workflowchart">
                            {currentRoute === "/workFlowList" ||
                              currentRoute === "/subscriptionlist" ||
                              currentRoute == '/workflowchart' ? (
                              < img src={workflow} alt="" />
                            ) : (
                              <img src={workflow} alt="" />
                            )}
                          </Link>
                        </div>
                        <div
                          className={
                            currentRoute === "/workFlowList" ||
                              currentRoute === "/subscriptionlist" ||
                              currentRoute == "/workflowchart"
                              ? "menu-name activeColorBlue"
                              : "menu-name"
                          }
                        >
                          Workflow
                        </div>
                      </div>

                      <div className="arrow">
                        {genset ? (
                          <img src={down_arrow} alt="" />
                        ) : (
                          <img src={ic_blueUP_arrow} alt="" />
                        )}
                      </div>
                    </Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className={sidebar ? "sub-menus" : "none"}>
                      <Link
                        to="/workflowDashboard"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/workflowDashboard"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Workflow Deshboard
                        </div>
                        {/* )} */}
                      </Link>
                      <Link
                        to="/workFlowList"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/workFlowList"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Defined Workflow
                        </div>
                        {/* )} */}
                      </Link>

                      <Link
                        to="/workflowEventMapping"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/workflowEventMapping"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Workflow Event Mapping
                        </div>
                        {/* )} */}
                      </Link>

                      <Link
                        to="/subscriptionlist"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        <div
                          className={
                            currentRoute === "/subscriptionlist"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img
                                        src={
                                          currentRoute === "/ClientManagement"
                                            ? clientmgt1
                                            : clientmgt
                                        }
                                        className="me-2"
                                      /> */}
                          Workflow Trigger
                        </div>

                      </Link>
                      <Link
                        to="/completedworkflows"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/completedworkflows"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Completed Workflows
                        </div>
                        {/* )} */}
                      </Link>
                      <Link
                        to="/completedWorkflowTask"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/completedWorkflowTask"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}

                          Completed Workflow Task
                        </div>
                        {/* )} */}
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* )} */}
              </div>
  {/* {permissions && permissions["View Roles"] && ( */}
  {/* <div className="with-subMenu">
                      <Accordion.Item eventKey="4">
                        <Accordion.Header className="menus-items">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              setWalletCheck(false);
                              setApproveLeave(false);
                              setLeave(false);
                              handleUsermgt();
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/testCases">
                                  {currentRoute === "/Role" ||
                                    currentRoute === "/AssignRole" ||
                                    currentRoute === "/Permission" ? (
                                    <img src={ActiveMan} alt="" />
                                  ) : (
                                    <img src={taskmanag_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/testCases" 
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                               Test Cases
                              </div>
                            </div>
                            <div className="arrow">
                              {userManagement ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/testCases"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              <div
                                className={
                                  currentRoute === "/testCases" 
                                  
                                    ? "menu-name activeColorBlue d-flex justify-content-start"
                                    : "menu-name d-flex justify-content-start"
                                }
                              >
                                <img
                                  src={
                                    currentRoute === "/testCases" 
                            
                                      ? role1
                                      : role
                                  }
                                  className="me-2"
                                />
                                Test Cases
                              </div>
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div> */}
                  {/* )} */}
              <div className="with-subMenu">
                {/* {permissions && permissions["View Settings"] && ( */}
                <Accordion.Item eventKey="18">
                  <Accordion.Header className="menus-items">
                    <Link
                      to="#"
                      className="icon-menu-name-arrow"
                    // onClick={() => {
                    //   handleNotifications();
                    //   setWalletCheck(false);
                    //   setAttendance(false);
                    //   setTimesheet(false);
                    //   setTaskManagement(false);
                    //   setCommunication(false);
                    //   setProjectMan(false);
                    //   handleGenSet();
                    // }}
                    >
                      <div className="menu-left">
                        <div className="icon-left">
                          <Link to="#">
                            {currentRoute === "/paymentreciept" ||
                              currentRoute === "/moneyreciept" ||
                              currentRoute === "/invoicegenerate" ||
                              currentRoute === "/generateinvoice" ||
                              currentRoute === "/generateinvoice" ? (
                              <img src="" alt="ss" className="mt-1" />
                            ) : (
                              <img src="" alt="AR" className="mt-1" />
                            )}
                          </Link>
                        </div>
                        <div
                          className={
                            currentRoute === "/paymentreciept" ||
                              currentRoute === "/moneyreciept" ||
                              currentRoute === "/invoicegenerate" ||
                              currentRoute === "/generateinvoice" ||
                              currentRoute === "/generateinvoice"
                              ? "menu-name activeColorBlue"
                              : "menu-name"
                          }
                        >
                          Account Receivable
                        </div>
                      </div>

                      <div className="arrow">
                        {genset ? (
                          <img src={down_arrow} alt="" />
                        ) : (
                          <img src={ic_blueUP_arrow} alt="" />
                        )}
                      </div>
                    </Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className={sidebar ? "sub-menus" : "none"}>
                      <Link
                        to="/paymentreciept"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/paymentreciept"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Advance Receipt
                        </div>
                        {/* )} */}
                      </Link>
                      <Link
                        to="/moneyreciept"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/moneyreciept"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Money Receipt
                        </div>
                        {/* )} */}
                      </Link>

                      <Link
                        to="/invoicegenerate"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/invoicegenerate"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Generate Invoice
                        </div>
                        {/* )} */}
                      </Link>
                      <Link
                        to="/generateinvoice"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/generateinvoice"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Create Invoice
                        </div>
                        {/* )} */}
                      </Link>

                      <Link
                        to="/ledger"
                        className="sub-menue-items"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        {/* {permissions && permissions["Announcements"] && ( */}
                        <div
                          className={
                            currentRoute === "/ledger"
                              ? "menu-name activeColorBlue d-flex justify-content-start"
                              : "menu-name d-flex justify-content-start"
                          }
                        >
                          {/* <img src={currentRoute === "/GeneralSetting" ? settings1 : settings} className="me-2" /> */}
                          Ledger
                        </div>
                        {/* )} */}
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* )} */}
              </div>

              {/* ================> START Logout <============== */}
              {/* {
                permissions && permissions['View Workflow'] &&

                <div className="menus-items">
                  <div className="icon-menu-name">
                    <div className="icon-left">
                      <img src={workflow} alt="" />
            
                    </div>
                    <div
                      className="menu-name"
                      onClick={() => {
                 

                        const data = localStorage.getItem("encrypted");
                        const secretKey = "your-secret-key";
                        console.log("data", data);
                        let encryptedData = CryptoJS.AES.decrypt(
                          data,
                          secretKey
                        ).toString(CryptoJS.enc.Utf8);

                        console.log("encryptedData", encryptedData);
                        const targetWindow = window.open(
                          `http://localhost:8090/account/login?data=${encodeURIComponent(
                            data
                          )}`
                        );
                        targetWindow.postMessage(
                          data,
                          `http://localhost:8090/account/login?data=${encodeURIComponent(
                            data
                          )}`
                        );
                      }}
                    >
                      WorkFlow
                    </div>
                  </div>
                </div>
              } */}
              <div
                className="main-logout"
                onClick={() => setLogoutModalShow(true)}
              >
                <div className="menus-items" id="logout">
                  <div className="icon-menu-name">
                    <div className="icon-left">
                      {Dark === "lightMode" ? (
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5 4.5082C13.5002 3.95592 13.0527 3.50803 12.5004 3.50781C11.9481 3.5076 11.5002 3.95514 11.5 4.50742L11.4968 12.5109C11.4966 13.0632 11.9442 13.511 12.4965 13.5113C13.0487 13.5115 13.4966 13.0639 13.4968 12.5117L13.5 4.5082Z"
                            fill="#111111"
                            fill-opacity="0.5"
                          />
                          <path
                            d="M4.5 13.4909C4.5 11.2818 5.39541 9.28186 6.84308 7.83415L8.2573 9.24837C7.17155 10.3341 6.5 11.8341 6.5 13.4909C6.5 16.8047 9.18629 19.4909 12.5 19.4909C15.8137 19.4909 18.5 16.8047 18.5 13.4909C18.5 11.8341 17.8284 10.3341 16.7426 9.2483L18.1568 7.83408C19.6046 9.2818 20.5 11.2818 20.5 13.4909C20.5 17.9092 16.9183 21.4909 12.5 21.4909C8.08172 21.4909 4.5 17.9092 4.5 13.4909Z"
                            fill="#111111"
                            fill-opacity="0.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5 4.5082C13.5002 3.95592 13.0527 3.50803 12.5004 3.50781C11.9481 3.5076 11.5002 3.95514 11.5 4.50742L11.4968 12.5109C11.4966 13.0632 11.9442 13.511 12.4965 13.5113C13.0487 13.5115 13.4966 13.0639 13.4968 12.5117L13.5 4.5082Z"
                            fill="#cccccc"
                            fill-opacity="1"
                          />
                          <path
                            d="M4.5 13.4909C4.5 11.2818 5.39541 9.28186 6.84308 7.83415L8.2573 9.24837C7.17155 10.3341 6.5 11.8341 6.5 13.4909C6.5 16.8047 9.18629 19.4909 12.5 19.4909C15.8137 19.4909 18.5 16.8047 18.5 13.4909C18.5 11.8341 17.8284 10.3341 16.7426 9.2483L18.1568 7.83408C19.6046 9.2818 20.5 11.2818 20.5 13.4909C20.5 17.9092 16.9183 21.4909 12.5 21.4909C8.08172 21.4909 4.5 17.9092 4.5 13.4909Z"
                            fill="#cccccc"
                            fill-opacity="1"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="menu-name">Logout</div>
                  </div>
                </div>
              </div>
              {/* ================> END Logout <============== */}
            </Accordion>
          </div>
        </aside>
      </div >
    </main >
  );
};

export default Sidebar;
