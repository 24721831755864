import { useState, useContext, useEffect } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";

export function WorkflowChart() {


    let { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
    const [chart, setChart] = useState([]);
    const [orgName, setOrgName] = useState('');
    useEffect(() => {
        simpleGetCallWithErrorResponse(ApiConfig.GET_WORKFLOW_CHART)
            .then((res) => {
                console.log(res?.json);
                setChart(res?.json?.data || []);
                setOrgName(res?.json?.data || "");
                console.log(chart);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const [selectNode, setSelectNode] = useState(0);
    const handleClickNode = (nodeID) => {
        setSelectNode(nodeID);
    }

    const getChartData = () => {



    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content-main">
                    <div className="sub-header-role mb-4">
                        <div>
                            <h6 className="mt-2">Workflow Chart</h6>
                        </div>

                    </div>
                    <Tree
                        lineWidth={"2px"}
                        lineColor={"green"}
                        lineBorderRadius={"10px"}
                        label={
                            <div
                                className="mx-auto rounded-3 p-4"
                                style={{
                                    backgroundColor: "white",
                                    width: "20%",
                                }}
                            >
                                {/* <img src={RB} alt="" /> */}
                                {/* <p className="box-main-heading">Sameer Kutty</p> */}
                                <p className="box-sub-heading">Defined Workflows</p>
                                {/* <p className="box-sub-heading">{item?.Name}</p> */}
                            </div>
                        }
                    >
                        {chart.map((item, index) => {
                            return (
                                <TreeNode key={item?.workflowName}
                                    label={
                                        <div key={item?.workflowName}
                                            className="p-2 rounded-3 text-nowrap"
                                            style={{
                                                backgroundColor: "white",
                                                width: "auto",
                                            }}
                                            onClick={() => handleClickNode(item?.workflowName)}
                                        >
                                            <p className="box-main-heading mb-2">{item?.workflowName}</p>
                                        </div>
                                    }

                                >
                                    {
                                        item?.workflowName === selectNode && (
                                            <div className="bg-white p-4 rounded-3">{item?.workflowDetails?.length != 0 ? item?.workflowDetails?.map((team, index) => (
                                                <div className="m-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: "relative" }}>
                                                    <div className="rounded-circle p-2" style={{ backgroundColor: '#53b7e8', width: "40px", height: '40px', color: 'white', fontWeight: 'bolder', position: "absolute", left: "-3%", top: "-20%" }}>
                                                        {index + 1}
                                                    </div>
                                                    <div className=" p-4 rounded-3 p-4" style={{ backgroundColor: '#3da298', width: '400px', color: 'white', fontWeight: 'bold' }}>
                                                        {`${team?.name} - ${team?.stepAssign}`}
                                                    </div>
                                                </div>
                                                // <TreeNode
                                                //     label={<div className={`p-2 rounded-3 text-nowrap`} style={{
                                                //         backgroundColor: "white",
                                                //         width: "auto",
                                                //     }}>{`${team?.name} - ${team?.stepAssign}`}</div>}
                                                // >
                                                //     {
                                                //         team?.Employees?.map((employee, index) => (

                                                //             <TreeNode
                                                //                 label={<div className="p-2 rounded-3 text-nowrap " style={{
                                                //                     backgroundColor: "white",
                                                //                     width: "auto",
                                                //                 }}>{employee.Name}</div>}
                                                //             ></TreeNode>

                                                //         ))
                                                //     }
                                                // </TreeNode>
                                            )) : <></>}</div>
                                        )
                                    }

                                </TreeNode>
                            );
                        })}
                    </Tree>
                </div>
            </div>
        </>
    )

}

