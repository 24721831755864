import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Breadcrumps = ({ activeTab }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const isBaseMenuRoute = pathnames.includes("BaseMenu"); // Check if current route is under BaseMenu
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        padding: "10px",
        flexDirection: "row",
        display: isSmallScreen ? null : "flex",
      }}
    >
      {isSmallScreen ? (
        <div style={{}}>
          <button
            onClick={() => navigate(-1)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "6px 12px",
              backgroundColor: "white",
              border: "1px solid #D1D5DB",
              borderRadius: "8px",
              color: "#374151",
              cursor: "pointer",
              width: "100%",
              maxWidth: "120px",
            }}
          >
            <span style={{ fontSize: "12px" }}>Back</span>
            <img
              src="/images/Vector.svg"
              alt="Back Icon"
              width={16}
              height={16}
            />
          </button>
        </div>
      ) : null}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          fontSize: "14px",
          marginTop: isSmallScreen ? "5px" : 0,
        }}
      >
        {/* Home Icon */}
        <img
          src="/images/Home.svg"
          alt="Home"
          width={24}
          height={24}
          style={{ flexShrink: 0 }}
        />

        {/* Base Breadcrumb: Always show "Home / Menu" */}
        <span style={{ color: "#98a6ad", margin: "0 10px" }}>/</span>
        <Link
          to="/BaseMenu"
          style={{
            textDecoration: "none",
            fontWeight: "500",
            color: "#111827",
          }}
        >
          Menu
        </Link>

        {/* Conditional Breadcrumb Logic */}
        {isBaseMenuRoute ? (
          // For Tabs (under BaseMenu): Show active tab as the last breadcrumb
          <>
            <span style={{ color: "#98a6ad", margin: "0 10px" }}>/</span>
            <span style={{ color: "#3B82F6", fontWeight: "500" }}>
              {activeTab}
            </span>
          </>
        ) : (
          // For Sidebar Pages: Show full URL path
          pathnames.map((name, index) => {
            const isLast = index === pathnames.length - 1;
            const breadcrumbPath = `/${pathnames
              .slice(0, index + 1)
              .join("/")}`;

            return (
              <span
                key={breadcrumbPath}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ color: "#98a6ad", margin: "0 10px" }}>/</span>
                {isLast ? (
                  <span style={{ color: "#3B82F6", fontWeight: "500" }}>
                    {name}
                  </span>
                ) : (
                  <Link
                    to={breadcrumbPath}
                    style={{ color: "#ADC927", textDecoration: "none" }}
                  >
                    {name}
                  </Link>
                )}
              </span>
            );
          })
        )}
      </div>
      {!isSmallScreen ? (
        <div style={{}}>
          <button
            onClick={() => navigate(-1)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "6px 12px",
              backgroundColor: "white",
              border: "1px solid #D1D5DB",
              borderRadius: "8px",
              color: "#374151",
              cursor: "pointer",
              width: "100%",
              maxWidth: "120px",
            }}
          >
            <span style={{ fontSize: "14px" }}>Back</span>
            <img
              src="/images/Vector.svg"
              alt="Back Icon"
              width={16}
              height={16}
            />
          </button>
        </div>
      ) : null}
      {/* Back Button */}
    </div>
  );
};

export default Breadcrumps;
