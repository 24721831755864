// import React from "react";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Tooltip,
//   Title,
// } from "chart.js";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Title);

// const BarGraph = () => {
//   const data = {
//     labels: ["3 Jan", "4 Jan", "5 Jan", "6 Jan", "7 Jan"],
//     datasets: [
//       {
//         label: "Actual Orders",
//         data: [10000, 33500, 25000, 30000, 27000],
//         backgroundColor: "rgba(54, 162, 235, 1)",
//         borderRadius: 5,
//         barPercentage: 0.5,
//       },
//       {
//         label: "Projected Orders",
//         data: [40000, 40000, 35000, 38000, 36000],
//         backgroundColor: "rgba(54, 162, 235, 0.3)",
//         borderRadius: 5,
//         barPercentage: 0.5,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: function (context) {
//             return `₹${context.raw.toLocaleString()}`;
//           },
//         },
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: function (value) {
//             return `₹${value.toLocaleString()}`;
//           },
//         },
//       },
//     },
//   };

//   return (
//     <div style={{ width: "60%", margin: "0 auto" }}>
//       <h3 className="text-center font-bold text-lg">Last 5 Days Order</h3>
//       <Bar data={data} options={options} />
//     </div>
//   );
// };

// export default BarGraph;
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Title);

const BarGraph = ({dataList}) => {
  const maxOrders = Math.max(...dataList.map(i => i.total_orders)); // Find the max tota
  
  const data = {
    labels: dataList.map(i=> i.order_date),
    datasets: [
      {
        label: "Actual Orders",
        data: dataList.map(i=> i.total_orders),
        backgroundColor: "rgba(54, 162, 235, 1)", // Solid color for actual data
        borderRadius: 5,
        barPercentage: 0.5,

      },
     
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 1,
        max: maxOrders + 5, // Adjust maximum value (set to a specific number or use dynamic logic)
        ticks: {
          callback: function (value) {
            return `${value.toLocaleString()}`;
          },
        },
      },
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
    },
  };

  return (
    <div style={{ width: "60%", margin: "0 auto",height:"20%" }}>
      <h3 className="text-center font-bold text-lg">Last 5 Days Order</h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarGraph;

