"use client";
import React, { useContext, useEffect, useState } from "react";
import "../../Inventory Management System/Styles/spreadsheet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";



import { useNavigate } from "react-router-dom";

// import SelectDrop from "../../components/DropDown/SelectDrop";

import Select from "react-select";
import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { postMultipartWithAuthCall, postMultipartWithAuthCallINT, simpleGetCall } from "../../../api/ApiServices";
import { notifyError, notifySuccess } from "../../../toaster/notify";

export default function Categorymanagement() {
    const { parentCategory,selectedStoreID,sidebar,setActiveTab } = useContext(AppContext)

    
  const naviget = useNavigate()
  const [schedules, setSchedules] = useState([]);
  const [newSchedule, setNewSchedule] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [fileName, setFileName] = useState("");
  const [everyDayChecked, setEveryDayChecked] = useState(false);
  const [checkDays, setCheckDays] = useState(false)
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [categoryList,setCategoryList] = useState([])
  const [categoryId,setCategoryId] = useState([])
  const [addCategory, setAddCategory] = useState({
    category_name: "",
    category_timing: [
      {
        schedule_name: "",
        schedule_day: "",
        schedule_time_slots: [
          {
            start_time: "",
            end_time: ""
          }
        ]
      }
    ],
    category_image: ""

  })

  const [errors, setErrors] = useState({
    category_name: '',
    schedule_name: '',
    schedule_day: '',
    start_time: '',
    end_time: '',
    category_image: ""


  });




const handleCategoryID=(select)=>{

  setCategoryId(select)

}


  const handleAddSchedule = () => {
    setShowScheduleForm(true);
  };

  const handleSaveSchedule = () => {
    if (newSchedule.trim() !== "") {
      setSchedules([...schedules, newSchedule]);
      setNewSchedule(""); // Clear input after saving
    }
  };

  const getCategoryList=()=>{
    simpleGetCall(ApiConfig?.GET_CATEGORY_LIST+`shop_id=${selectedStoreID}`)
    .then((res)=>{
      console.log("Category List>>>>>",res.data)
      const categotyResponse = res?.data.map((store) => ({
        value: store.id,
        label: store.category_name,
      }));
      setCategoryList(categotyResponse)
    
    })
    .catch((err)=>{
      console.log("Category err>>>>>",err)

    })
  }

  const handleTimeChange = (e, timeSlotIndex, timeType) => {
    const updatedTime = e.target.value;

    setAddCategory({
      ...addCategory,
      category_timing: addCategory.category_timing.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            schedule_time_slots: item.schedule_time_slots.map((timeSlot, slotIndex) => {
              if (slotIndex === timeSlotIndex) {
                if (timeType === 'start_time') {
                  return { ...timeSlot, start_time: updatedTime };
                } else if (timeType === 'end_time') {
                  return { ...timeSlot, end_time: updatedTime };
                }
              }
              return timeSlot;
            }),
          };
        }
        return item;
      }),
    });
  };

  const handleClearAddData=()=>{
    setAddCategory({
      ...addCategory,
      category_name: "",
      category_timing: [
        {
          schedule_name: "",
          schedule_day: "",
          schedule_time_slots: [
            {
              start_time: "",
              end_time: ""
            }
          ]
        }
      ],
      category_image: ""
  
    })
  }

  const onAddCategoryData = () => {
    let validation = false;
    const newErrors = { ...errors };

    if (!addCategory.category_name) {
      validation = true;
      newErrors.category_name = 'Category name is required';
    } else {
      newErrors.category_name = '';
    }

    if (!addCategory.category_image) {
      validation = true;
      newErrors.category_image = 'Image file  required';
    } else {
      newErrors.category_image = '';
    }

    if(showScheduleForm === true){

   
    if (!addCategory.category_timing[0]?.schedule_name) {
      validation = true;
      newErrors.schedule_name = 'Schedule name is required';
    } else {
      newErrors.schedule_name = '';
    }

    if (!addCategory.category_timing[0]?.schedule_day?.length) {
      validation = true;
      newErrors.schedule_day = 'At least one day must be selected';
    } else {
      newErrors.schedule_day = '';
    }

    if (!addCategory.category_timing[0]?.schedule_time_slots[0]?.start_time) {
      validation = true;
      newErrors.start_time = 'Start time is required';
    } else {
      newErrors.start_time = '';
    }

    if (!addCategory.category_timing[0]?.schedule_time_slots[0]?.end_time) {
      validation = true;
      newErrors.end_time = 'End time is required';
    } else {
      newErrors.end_time = '';
    }
  }

    setErrors(newErrors);
    if (!validation) {

      let formData = new FormData()
      formData.append('category_name', addCategory.category_name)
      formData.append('category_rank', 0)
      formData.append('category_timing', JSON.stringify(addCategory.category_timing))
      formData.append('status', 1)
      formData.append('shop_id', selectedStoreID)
      formData.append('category_image', addCategory.category_image)

      const addCategoryById = categoryId.map((item) => item.value);

      if (parentCategory === true) {
        addCategoryById.forEach((category,index) => {
          formData.append(`category_ids[${index}]`, category); 
        });
      }
 

   

      const url = parentCategory === true ?ApiConfig.ADD_PARENT_CATEGORY :ApiConfig.ADD_CATEGORY

      postMultipartWithAuthCallINT(url, formData)
        .then((res) => {
          console.log(res)
          if (res.success === true) {
            naviget("/BaseMenu")
            setActiveTab("Categories")

            handleClearAddData()
                      notifySuccess(res.message);
            

          } else {
                      notifyError(res.message);
            
          }
        })
        .catch((err) => {
          console.log(err)
        })

    }


  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setAddCategory({
        ...addCategory,
        category_image: selectedFile
      });
    }
  };


  const handleCancel = () => {
    naviget("/BaseMenu")
    handleClearAddData()
   
  
  };

  useEffect(()=>{
    getCategoryList()
  },[])

  return (
    <div
    className={sidebar ? "taskMain " : "cx-active taskMain"}
    id="cx-main"
  >
    <div className="role-content">
    <div className="sub-header-role ">
        <h6 className="mt-2">Stock List</h6>
      </div>
    <div className="p-3 h-screen bg-white mt-4" >
     
      <div className="w-full bg-white p-4 border border-gray-300">
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label cat-lab">Name</label>
            <input
              className="form-control me-2 flex-grow-1"
              type="text"
              placeholder="Enter name"
              onChange={(e) => setAddCategory(
                {
                  ...addCategory,
                  category_name: e.target.value
                }
              )}
            />
            {errors.category_name && (
              <div className="text-danger mt-1">{errors.category_name}</div>
            )}
          </div>
       
            {/* <input
              className="form-control me-2 flex-grow-1"
              type="text"
              placeholder="Enter name"
              onChange={(e) => setAddCategory(
                {
                  ...addCategory,
                  category_name: e.target.value
                }
              )}
            /> */}
            {/* {errors.category_name && (
              <div className="text-danger mt-1">{errors.category_name}</div>
            )} */}
            {
              parentCategory === true?
              <div className="col-md-4">
            <label className="form-label cat-lab">Category</label>
              <Select
              options={categoryList}
              onChange={handleCategoryID}
              placeholder="Select Category"
              isMulti
              />
                 </div>

              :""
            }
       
       
          <div className="col-md-4">
            <label className="form-label cat-lab">Image</label>
            <div className="input-group">
              <input
                type="file"
                className="form-control"
                id="customFile"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div id="fileName" className="mt-1 ms-2 flex-1">
                {addCategory?.category_image?.name || "No file selected"}
              </div>
              <label
              style={{backgroundColor:"#53B7E8", color:"#fff"}}
                className="input-group-text btn"
                htmlFor="customFile"
              >
                Choose File
              </label>
            </div>
            <label className="tax-lab text-head text-muted small">
              Upload only png, jpeg, or jpg file
            </label>
            {errors.category_image && (
              <div className="text-danger mt-1">{errors.category_image}</div>
            )}
          </div>
        </div>
      </div>

      <div className="border border-gray-300 p-3 mt-3">
        {/* Header with Add Schedule Button */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <label className="edit-cat">Category Schedule</label>
          <button type="button" className="btn" style={{backgroundColor:"#53B7E8", color:"#fff"}} onClick={handleAddSchedule}>
            Add Schedule
          </button>
        </div>

        {/* Show Schedule Form when Add Schedule is clicked */}
        {showScheduleForm && (
          <div className="row pb-3">
            <div className="col">
              <label className="form-label cat-lab">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="form-control mb-2"
                type="text"
                placeholder="Enter name"
                // value={newSchedule}
                onChange={(e) => setAddCategory({
                  ...addCategory,
                  category_timing: addCategory.category_timing.map((item, index) =>
                    index === 0 ? { ...item, schedule_name: e.target.value } : item
                  )
                })}
              />
              {errors.schedule_name && (
                <div className="text-danger mt-1">{errors.schedule_name}</div>
              )}

              {/* Conditionally show individual checkboxes */}
              {!everyDayChecked && (
                <div className="d-flex flex-wrap gap-2">
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                    <div className="form-check" key={day}>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const updatedScheduleDay = isChecked
                            ? [...addCategory.category_timing[0].schedule_day, day] // Add day
                            : addCategory.category_timing[0].schedule_day.filter(d => d !== day || !everyDayChecked); // Remove day if unchecked

                          setAddCategory({
                            ...addCategory,
                            category_timing: addCategory.category_timing.map((item, index) =>
                              index === 0 ? { ...item, schedule_day: updatedScheduleDay } : item
                            )
                          });
                        }}
                        id={day.toLowerCase()} />
                      <label className="form-check-label" htmlFor={day.toLowerCase()}>
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              )}
              {errors.schedule_day && (
                <div className="text-danger mt-1"><label className="sm">{errors.schedule_day}</label></div>
              )}
            </div>

            <div className="col">
              <label className="form-check-label " style={{ marginLeft: "20px" }}>
                Day<span style={{ color: "red" }}>*</span>
              </label>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="everyDay"
                  onChange={(e) => {
                    setEveryDayChecked(!everyDayChecked)
                    const isChecked = e.target.checked;

                    // If "Every Day" is selected, set all days; otherwise, clear it
                    const updatedScheduleDay = isChecked ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].join(",") : "";

                    setAddCategory({
                      ...addCategory,
                      category_timing: addCategory.category_timing.map((item, index) =>
                        index === 0 ? { ...item, schedule_day: updatedScheduleDay } : item
                      )
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="everyDay">
                  Every Day
                </label>
              </div>
            </div>

            <div className="row g-3 mb-4">
            <label className="form-label cat-lab">
                Times<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-md-3">
                <label className="form-label">
                  Start Time
                </label>
                <input
                  type="time"
                  className="form-control"
                  placeholder="Enter name..."
                  value={addCategory.category_timing[0].schedule_time_slots[0].start_time}
                  onChange={(e) => handleTimeChange(e, 0, 'start_time')}
                />
                {errors.start_time && <div className="text-danger mt-1">{errors.start_time}</div>}

              </div>
              <div className="col-md-3">
                <label className="form-label">
                  End Time
                </label>
                <input
                  type="time"
                  className="form-control"
                  placeholder="Enter name..."
                  value={addCategory.category_timing[0].schedule_time_slots[0].end_time}
                  onChange={(e) => handleTimeChange(e, 0, 'end_time')}
                />
                {errors.end_time && <div className="text-danger mt-1">{errors.end_time}</div>}

              </div>
            </div>

          </div>
        )}

        {/* List of Saved Schedules */}
        <div className="mt-3">
          <ul>
            {schedules.map((schedule, index) => (
              <li key={index}>{schedule}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="d-flex flex-container justify-content-end gap-3 mt-5">
        <button type="button" style={{border:"1px solid #53B7E8", color:"#53B7E8"}} className="btn bt-1" onClick={handleCancel}>
          Cancel
        </button>
        <button type="button" style={{backgroundColor:"#53B7E8", color:"#fff",width:"80px"}} className="btn bt-1" onClick={onAddCategoryData}>
          Add 
        </button>
      </div>
    </div>
    </div>
    </div>
  );
}
