import { useContext, useEffect, useState } from "react";
import "../../Inventory Management System/Styles/tax.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Select from "react-select";



import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { PostCallWithErrorResponse, putRequest, simpleGetCall, simpleGetCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { makeSelectOptions, simpleHandleChange } from "../../../common";
import { tableAreaOptions } from "../../../constant/tableAreaOption";
import { ToastMsg } from "../../TostMsg";

const AddArea = () => {
    const { sidebar, setSidebar, selectedStoreID } = useContext(AppContext);
    const [params] = useSearchParams()
    const navigate = useNavigate();

    const initialArea = {
        "master_area_id": 0,
        "name": "",
        "shop_id": 0,
        "container_charges": "",
        "service_charges": "",
        "table_ids": [],
        "status": false
    }

    const [area, setArae] = useState(initialArea)

    const [tables, setTables] = useState([])
    const [areaOptions, setAreaOption] = useState([])

    const [selectedArea, setSelectedArea] = useState({})
    const [isValided, setIsValided] = useState(false)

    const [showTablesEdit, setShowTableEdit] = useState(0)




    useEffect(() => {
        getMasterArea()

        let id = params.get('id')

        if (id) {
            getData(id)
        } else {
            getTableLockup()
        }

    }, [selectedStoreID])

    const getData = (id) => {

        simpleGetCall(ApiConfig.ADD_AREA + '/' + id).then((res) => {

            if (res?.success) {



                setArae(res?.area)

                setTables(res?.area?.tables)

                setShowTableEdit(showTablesEdit + 1)


            }

        })

    }

    useEffect(() => {

        if (params.get('id')) {
            setSelectedArea(areaOptions?.filter((id) => area?.master_area_id == id?.value)[0]?.item)
            setArae(prev => ({
                ...prev,
                table_ids: area?.tables?.map((i) => i?.id)
            }))
        }

    }, [showTablesEdit])


    const getMasterArea = () => {

        simpleGetCallWithErrorResponse(ApiConfig.GET_MASTER_AREA).then((res) => {



            if (res?.json?.success) {
                setAreaOption(makeSelectOptions(res?.json?.master_areas, 'name', 'id'))
            }

        })

    }

    const getTableLockup = () => {

        simpleGetCallWithErrorResponse(ApiConfig.GET_TABLE_LOCKUP + selectedStoreID).then((res) => {
            if (res?.json?.success) {
                setTables(res?.json?.tables)
            }

        })

    }

    const handleSubmit = () => {

        let isValid = true;

        if (!area?.master_area_id ||
            !area?.name ||
            (selectedArea?.has_container_charges && area?.container_charges == null) ||
            (selectedArea?.has_service_charges && area?.service_charges == null)
        ) {
            setIsValided(true)
            isValid = false
        } else {
            if (!selectedArea?.has_container_charges) {
                area.container_charges = null
            }
            if (!selectedArea?.has_service_charges) {
                area.service_charges = null
            }
            if (!selectedArea?.has_tables) {
                area.table_ids = []
            }

            area.shop_id = selectedStoreID

            if (params.get('id')) {
                console.log(area);
                putRequest(ApiConfig.ADD_AREA + '/' + params.get('id'), JSON.stringify(area)).then((res) => {
                    if (res?.success) {
                        ToastMsg('success', res?.message)
                        navigate('/baseMenu')
                    } else {
                        ToastMsg('error', res?.message)
                    }

                })
            } else {


                PostCallWithErrorResponse(ApiConfig.ADD_AREA, JSON.stringify(area)).then((res) => {

                    if (res?.json?.success) {
                        ToastMsg('success', res?.json?.message)
                        navigate('/baseMenu')
                    } else {
                        ToastMsg('error', res?.json?.message)
                    }

                })
            }
        }

    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">Add Area</h6>
                    </div>

                    <div className="bg-white mt-3 rounded-3 p-2 row mx-1">
                        <div className="col-6 d-flex flex-column">
                            <label className="tax-lab sp-text mb-1">Area Type</label>
                            <Select style={{ height: "38px" }}
                                options={areaOptions}
                                value={areaOptions?.filter((i) => i?.value == area?.master_area_id)}
                                onChange={(e) => {

                                    setSelectedArea(e?.item)
                                    simpleHandleChange("master_area_id", e.value, setArae)

                                }}

                            >

                            </Select>
                            <span className="text-danger">{isValided && !area?.master_area_id && 'Please Select Area Type'}</span>

                        </div>

                        <div className="col-6 d-flex flex-column">
                            <label className="tax-lab sp-text mb-1">Addon Item Name</label>
                            <input type="text" className="form-control" placeholder="Enter Department Name"
                                value={area?.name}
                                onChange={(e) => {
                                    simpleHandleChange("name", e.target.value, setArae)

                                }}
                            />
                            <span className="text-danger">{isValided && !area?.name && 'Please Enter name'}</span>

                        </div>
                        {
                            selectedArea?.has_container_charges &&

                            <div className="col-6 d-flex flex-column">
                                <label className="tax-lab sp-text mb-1">Container Charge</label>
                                <Select style={{ height: "38px" }}
                                    options={tableAreaOptions}
                                    value={tableAreaOptions?.filter((i) => i?.value == area?.container_charges)}
                                    onChange={(e) => {
                                        simpleHandleChange("container_charges", e.value, setArae)

                                    }}
                                >
                                </Select>
                                <span className="text-danger">{isValided && !area?.container_charges && 'Please Select Container Charge'}</span>

                            </div>
                        }
                        {
                            selectedArea?.has_service_charges &&

                            <div className="col-6 d-flex flex-column">
                                <label className="tax-lab sp-text mb-1">Service Charge</label>
                                <Select style={{ height: "38px" }}
                                    options={tableAreaOptions}
                                    value={tableAreaOptions?.filter((i) => i?.value == area?.service_charges)}
                                    onChange={(e) => {
                                        simpleHandleChange("service_charges", e.value, setArae)

                                    }}
                                >
                                </Select>
                                <span className="text-danger">{isValided && !area?.service_charges && 'Please Select Service Charge'}</span>

                            </div>
                        }

                        {
                            selectedArea?.has_tables && <>

                                <div className="col-12 border-bottom mx-2">
                                    <input type="checkbox" className="me-1" style={{ scale: "1.2" }}
                                        checked={area?.table_ids?.length == tables?.length}
                                        onChange={(e) => {

                                            let ids = []
                                            if (e.target.checked) {

                                                tables?.forEach((id) => ids.push(id?.id))

                                            } else {
                                                ids = []
                                            }

                                            simpleHandleChange("table_ids", ids, setArae)
                                        }}
                                    />
                                    <label>Check All</label>
                                </div>

                                <div className="border-bottom row pl-4 pr-4">
                                    {
                                        tables?.map((table, index) => {
                                            return <div className="col-4" key={index}>
                                                <input type="checkbox" className="me-1" style={{ scale: "1.2" }}
                                                    checked={area?.table_ids?.includes(table?.id)}
                                                    onChange={(e) => {

                                                        let ids = area?.table_ids

                                                        if (e.target.checked) {
                                                            ids?.push(table?.id)
                                                        } else {
                                                            let selectIndex = area?.table_ids?.indexOf(table?.id)
                                                            ids?.splice(selectIndex, 1)
                                                        }

                                                        simpleHandleChange("table_ids", ids, setArae)

                                                    }}
                                                />
                                                <label>{table?.table_no}</label>
                                            </div>
                                        })
                                    }

                                </div>

                            </>
                        }

                        <div className="col-12 border-bottom mx-2">
                            <input type="checkbox" className="me-1" style={{ scale: "1.2" }}
                                checked={area?.status}
                                onChange={(e) => {
                                    simpleHandleChange("status", e.target.checked, setArae)
                                }}
                            />
                            <label>Active</label>
                        </div>

                        <div className="d-flex justify-content-end my-3">
                            <button className="btn btn-primary" style={{ height: "38px" }}>Cancel</button>
                            <button className="btn" style={{ height: "38px", backgroundColor: "#858D9D", color: "white" }}
                                onClick={handleSubmit}
                            >Save Changes</button>
                        </div>


                    </div>

                </div>

            </div>
        </>
    )
}

export default AddArea;