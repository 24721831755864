import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { putWithAuthCall, simpleGetCall, simplePostCallIVT } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import Editor from "../../ChatAdmin/AddQuestions/Editor";
import { isArray } from "lodash";
import { handlwValidationRequired } from "../../../common";

const AddDiscount = () => {
  const { selectedStoreID, sidebar } = useContext(AppContext);



  const [formData, setFormData] = useState({
    discount_name: "",
    discount_type: "",
    discount: "",
    discount_description: "",
    discount_applicable_on: "",
    discount_days: "",
    discount_order_type: "",
    discount_on_total: "",
    discount_status: "0",
    discount_has_coupon: "0", //
    discount_max_limit: "",

  });

  const [valided, setValided] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const [answer, setAnswer] = useState("");

  const navigetion = useNavigate();

  const [AddTaxes, setAddTeaxes] = useState({
    discount_name: "",
    discount_type: "",
    discount: "",
    discount_description: "",
    discount_applicable_on: "",
    discount_days: "",
    discount_order_type: "",
    discount_on_total: "",
    discount_status: "0",
    discount_has_coupon: "0", //
    discount_max_limit: "",
    discount_start_date: "",
    discount_end_date: "",
    discount_start_time: "",
    discount_end_time: "",
    discount_min_amount: "",
    discount_max_amount: "",
    discount_category_item_id: "", //iD
    bogo_applicable_on_item_ids: "",
    shop_id: "",
    bogo_buy_qty: "",
    bogo_applicable_on: "",
    bogo_applicable_on_item: "",
    bogo_applicable_on_purchase_item_ids: "",
    bogo_applicable_on_purchase_item: "",
    bogo_applicable_on_purchase: "",
    bogo_get_qty: "",
    bogo_item_amount_limit: "",
    bogo_purchase_discount: "",
    bogo_type: "",
    mapped_res_ids: ""

  });
  console.log("AddTaxes", AddTaxes)
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};

    // Validate tax_name
    if (!AddTaxes.tax_name) {
      tempErrors.tax_name = "Tax Name is required";
    }

    // Validate tax
    if (!AddTaxes.tax) {
      tempErrors.tax = "Tax amount is required";
    } else if (isNaN(AddTaxes.tax) || AddTaxes.tax <= 0) {
      tempErrors.tax = "Tax amount must be a valid positive number";
    }

    // Validate tax_tax_type
    if (!AddTaxes.tax_tax_type) {
      tempErrors.tax_tax_type = "Tax Type  is required";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const onAddOrUpdateDiscount = () => {
    // if (!validate()) {
    //   return; // Stop submission if validation fails
    // }

    let notCheckValidation = ['shop_id', 'bogo_purchase_discount', 'bogo_applicable_on_item', 'bogo_applicable_on_purchase_item', 'mapped_res_ids']

    if (!AddTaxes?.bogo_applicable_on || AddTaxes?.bogo_applicable_on == 'All') {

      notCheckValidation.push('bogo_applicable_on_item_ids')
    }

    if (AddTaxes?.discount_type == "3") {

      notCheckValidation.push('discount_on_total', 'discount_applicable_on', 'discount_max_limit', 'discount_min_amount', "discount_max_amount", 'bogo_item_amount_limit')

    }


    if (AddTaxes?.discount_type != '3') {


      notCheckValidation.push('discount_applicable_on')

    }

    if (AddTaxes?.discount_applicable_on != "Items") {

      notCheckValidation.push('discount_category_item_id')

    }

    if (AddTaxes?.bogo_applicable_on_purchase != "Items") {
      notCheckValidation.push('bogo_applicable_on_purchase_item_ids')
    }



    if (!handlwValidationRequired(AddTaxes, notCheckValidation, setValided)) {
      return
    }

    const body = {
      discount_name: AddTaxes.discount_name,
      discount_type: AddTaxes.discount_type,
      discount: AddTaxes.discount,
      discount_description: AddTaxes.discount_description,
      discount_applicable_on: AddTaxes.discount_applicable_on,
      discount_days: AddTaxes.discount_days,
      discount_order_type: AddTaxes.discount_order_type,
      discount_on_total: AddTaxes.discount_on_total || '0',
      discount_status: AddTaxes.discount_status || "0",
      discount_has_coupon: AddTaxes.discount_has_coupon || "0",
      discount_max_limit: AddTaxes.discount_max_limit || "0",
      discount_start_date: AddTaxes.discount_start_date,
      discount_end_date: AddTaxes.discount_end_date,
      discount_start_time: AddTaxes.discount_start_time,
      discount_end_time: AddTaxes.discount_end_time,
      discount_min_amount: AddTaxes?.discount_min_amount || "0",
      discount_max_amount: AddTaxes.discount_max_amount || "0",
      bogo_applicable_on: AddTaxes.bogo_applicable_on,
      bogo_applicable_on_item: AddTaxes.bogo_applicable_on_item,
      bogo_applicable_on_purchase: AddTaxes.bogo_applicable_on_purchase,

      bogo_applicable_on_purchase_item_ids: Array.isArray(AddTaxes.bogo_applicable_on_purchase_item_ids)
        ? AddTaxes.bogo_applicable_on_purchase_item_ids.join(",") // Convert array to string
        : AddTaxes.bogo_applicable_on_purchase_item_ids,

      bogo_get_qty: AddTaxes.bogo_get_qty,
      bogo_applicable_on_purchase_item: AddTaxes.bogo_applicable_on_purchase_item,
      bogo_purchase_discount: AddTaxes.bogo_purchase_discount,
      bogo_item_amount_limit: AddTaxes.bogo_item_amount_limit,
      bogo_type: AddTaxes.bogo_type,
      mapped_res_ids: AddTaxes.mapped_res_ids,
      discount_category_item_id: Array.isArray(AddTaxes.discount_category_item_id)
        ? AddTaxes.discount_category_item_id.join(",") // Convert array to string
        : AddTaxes.discount_category_item_id,
      bogo_applicable_on_item_ids: Array.isArray(AddTaxes.bogo_applicable_on_item_ids)
        ? AddTaxes.bogo_applicable_on_item_ids.join(",") // Convert array to string
        : AddTaxes.bogo_applicable_on_item_ids,
      shop_id: selectedStoreID,
      bogo_buy_qty: AddTaxes.bogo_buy_qty,
    };

    // Determine API URL based on whether updating or adding
    const apiUrl = id
      ? ApiConfig.UPDATE_DISCOUNT_CATREGOREY + `${id}`
      : ApiConfig.ADD_DISCOUNT_CATREGOREY;

    const apiCall = id

      ? putWithAuthCall(apiUrl, JSON.stringify(body)) // Use PUT for update
      : simplePostCallIVT(apiUrl, JSON.stringify(body)); // Use POST for add

    apiCall
      .then((res) => {
        console.log("Discount API response:", res);
        if (res.success === true) {
          notifySuccess(res.message);
          //  setActiveTab("Discount")
          navigetion("/BaseMenu");
          setAddTeaxes({
            discount_name: "",
            discount_type: "",
            discount: "",
            discount_description: "",
            discount_applicable_on: "",
            discount_days: "",
            discount_order_type: "",
            discount_on_total: "",
            discount_status: "0",
            discount_has_coupon: "0",
            discount_max_limit: "",
            discount_start_date: "",
            discount_end_date: "",
            discount_start_time: "",
            discount_end_time: "",
            discount_min_amount: "",
            discount_max_amount: "",
            discount_category_item_id: "",
            bogo_applicable_on_item_ids: "",
            shop_id: "",
            bogo_buy_qty: "",
            bogo_applicable_on: "",
            bogo_applicable_on_item: "",
            bogo_applicable_on_purchase_item_ids: "",
            bogo_applicable_on_purchase_item: "",
            bogo_applicable_on_purchase: "",
            bogo_get_qty: "",
            bogo_item_amount_limit: "",
            bogo_purchase_discount: "",
            bogo_type: "",
            mapped_res_ids: "",
          });
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log("Discount API error:", err);
      });
  };


  const { id } = useParams()

  useEffect(() => {
    if (selectedStoreID) {
      fetchData();
      fetchDataViewId()

    }
  }, [selectedStoreID]);
  const [DataList, setDataList] = useState([]);

  const fetchData = async () => {


    try {
      const res = await simpleGetCall(
        `${ApiConfig.LIST_MENU_ITEM}&shop_id=${selectedStoreID || ""
        }`
      );

      if (res?.data) {
        setDataList(res.data || []);

      } else {
        setDataList([]);

      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const fetchDataViewId = async () => {
    try {
      const res = await simpleGetCall(ApiConfig.GET_DISCOUNT_VIEW + id);

      if (res?.data) {
        const responseData = res.data;

        setAddTeaxes({
          discount_name: responseData.discount_name || "",
          discount_type: responseData.discount_type || "",
          discount: responseData.discount || "",
          discount_description: responseData.discount_description || "",
          discount_applicable_on: responseData.discount_applicable_on || "",
          discount_days: responseData.discount_days || "",
          discount_order_type: responseData.discount_order_type || "",
          discount_on_total: responseData.discount_on_total || "",
          discount_status: responseData.discount_status?.toString() || "0", // Ensure it's a string
          discount_has_coupon: responseData.discount_has_coupon?.toString() || "0", // Ensure it's a string
          discount_max_limit: responseData.discount_max_limit || "",
          discount_start_date: responseData.discount_start_date || "",
          discount_end_date: responseData.discount_end_date || "",
          discount_start_time: responseData.discount_start_time || "",
          discount_end_time: responseData.discount_end_time || "",
          discount_min_amount: responseData.discount_min_amount || "",
          discount_max_amount: responseData.discount_max_amount || "",
          discount_category_item_id: responseData.discount_category_item_id
            ? responseData.discount_category_item_id.split(",").map(Number) // Convert to array of numbers
            : "",
          bogo_applicable_on_item_ids: responseData.bogo_applicable_on_item_ids
            ? responseData.bogo_applicable_on_item_ids.split(",").map(Number)
            : "",
          shop_id: responseData.restaurant_id || "",
          bogo_buy_qty: responseData.bogo_buy_qty || "",
          bogo_applicable_on: responseData.bogo_applicable_on || "",
          bogo_applicable_on_item: responseData.bogo_applicable_on_item || "",
          bogo_applicable_on_purchase: responseData.bogo_applicable_on_purchase || "",
          bogo_applicable_on_purchase_item: responseData.bogo_applicable_on_purchase_item || "",
          bogo_applicable_on_purchase_item_ids: responseData.bogo_applicable_on_purchase_item_ids
            ? responseData.bogo_applicable_on_purchase_item_ids.split(",").map(Number)
            : "",
          bogo_get_qty: responseData.bogo_get_qty || "",
          bogo_item_amount_limit: responseData.bogo_item_amount_limit || "",
          bogo_purchase_discount: responseData.bogo_purchase_discount || "",
          bogo_type: responseData.bogo_type || "",
          mapped_res_ids: responseData.mapped_res_ids || "",
        });
      } else {

      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };



  const [selectedCategory, setSelectedCategory] = useState(null);


  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Stock List</h6>
        </div>
        <div className="py-2">
          {/* <div className="container"> */}
          <div className="bg-white border border-1 p-3 border-tax">
            <div className="flex flex-column  gap-4">
              {/* Add remaining form sections following similar patterns */}
              <div className="p-2">
                {/* <div className="container"> */}
                <div className="bg-white border border-1 p-3 border-tax">
                  <div className="flex flex-column  gap-4 ">
                    <div className="mb-4 mt-4">
                      <label htmlFor="title" className="tax-type-label">
                        Discount name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control tax-inp tax-type-label"
                        type="text"
                        id="tax_name"
                        name="tax_name"
                        placeholder="Enter Title Name"
                        value={AddTaxes.discount_name}
                        onChange={(e) =>
                          setAddTeaxes({
                            ...AddTaxes,
                            discount_name: e.target.value,
                          })
                        }
                      />
                      <span className="text-danger">{valided && !AddTaxes?.discount_name && "Please Enter discount Name"}</span>
                    </div>

                    <div className="row items-center">
                      {/* Title */}

                      {/* Category */}
                      <div className="row">
                        {/* Left Column */}
                        <div className="col-md-6">
                          {/* Title */}
                          <div className="mt-4">
                            <label className="tax-type-label">Discount Type</label>
                          </div>

                          {/* Display Name */}
                          <div className="col-md-12">
                            <label className="radio-option tax-type-label">
                              <input
                                type="radio"
                                name="discount_type"
                                value="1"
                                checked={AddTaxes?.discount_type === "1"}
                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    discount_type: "1",
                                    bogo_applicable_on_purchase_item_ids: "",
                                    discount_category_item_id: "",
                                    bogo_applicable_on_item_ids: ""
                                  })
                                }
                              />
                              Percentage
                            </label>
                          </div>

                          {/* Category */}
                          <div className="col-md-12">
                            {AddTaxes?.tax_tax_type !== "2" && (
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="discount_type"
                                  value="2"
                                  checked={AddTaxes?.discount_type === "2"}
                                  onChange={(e) =>
                                    setAddTeaxes({
                                      ...AddTaxes,
                                      discount_type: "2",
                                      bogo_applicable_on_purchase_item_ids: "",
                                      discount_category_item_id: "",
                                      bogo_applicable_on_item_ids: ""
                                    })
                                  }
                                />
                                Fixed
                              </label>
                            )}
                          </div>
                          <div className="col-md-12">
                            <label className="radio-option tax-type-label">
                              <input
                                type="radio"
                                name="discount_type"
                                value="3"
                                checked={AddTaxes?.discount_type === "3"}
                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    discount_type: "3",
                                    discount_category_item_id: "",
                                    discount_applicable_on: ""
                                  })
                                }
                              />
                              BOGO
                            </label>
                          </div>

                          <span className="text-danger">{valided && !AddTaxes?.discount_type && "Please Select Discout Type"}</span>
                        </div>

                        {/* Right Column */}
                        {AddTaxes?.discount_type != "3" && (
                          <div className="col-md-6">
                            {/* Title */}
                            <div className="mt-4">
                              <label className="tax-type-label">Add On</label>
                            </div>

                            {/* Display Name */}
                            <div className="col-md-12">
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type-7"
                                  value="1"
                                  checked={AddTaxes?.discount_on_total == "1"}
                                  onChange={(e) =>
                                    setAddTeaxes({ ...AddTaxes, discount_on_total: "1" })
                                  }
                                />
                                Add on Core
                              </label>
                            </div>

                            {/* Category */}
                            <div className="col-md-12">
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type-9"
                                  value="2"
                                  checked={AddTaxes?.discount_on_total == "2"}
                                  onChange={(e) =>
                                    setAddTeaxes({ ...AddTaxes, discount_on_total: "2" })
                                  }
                                />
                                Add on Total
                              </label>
                            </div>

                            <span className="text-danger">{valided && !AddTaxes?.discount_on_total && "Please Select Discout Add On"}</span>
                          </div>
                        )}
                        <div className="mt-4">
                          <label className="tax-type-label">Discount</label>
                        </div>
                        <input
                          className="form-control tax-inp tax-type-label"
                          type="number"
                          id="title"
                          name="tax"
                          placeholder="Enter discount"
                          value={AddTaxes.discount}
                          onChange={(e) =>
                            setAddTeaxes({
                              ...AddTaxes,
                              discount: Number(e.target.value) || '',
                            })
                          }
                        />
                        <span className="text-danger">{valided && !AddTaxes?.discount && "Please Select Discount"}</span>
                      </div>

                      {/* Price */}

                      {/* Description */}
                    </div>


                    <div className="row">
                      <div className="">
                        <label
                          htmlFor="description"
                          className="tax-type-label flex"
                        >
                          Description:
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          placeholder="Enter the description here..."
                          rows={4} // Use numbers for numeric attributes in TSX
                          className="tax-type-label border border-gray p-3"
                          value={AddTaxes.discount_description}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            setAddTeaxes({
                              ...AddTaxes,
                              discount_description: e.target.value,
                            })
                          }
                        ></textarea>
                        <span className="text-danger">{valided && !AddTaxes?.discount_description && "Please Select Discount Description"}</span>
                      </div>


                      <div className="row">
                        {/* Left Column */}
                        <div className="col-md-6">
                          {/* Title */}
                          <div className="mt-4">
                            <label className="tax-type-label">Order Type</label>
                          </div>

                          {/* Display Name */}
                          <div className="col-md-12">
                            <label className="radio-option tax-type-label">
                              <input
                                type="radio"
                                name="tax_type-1"
                                value="1"
                                checked={AddTaxes?.discount_order_type === "1"}
                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    discount_order_type: "1",
                                  })
                                }
                              />
                              Delivery
                            </label>
                          </div>

                          {/* Category */}
                          <div className="col-md-12">
                            {AddTaxes?.tax_tax_type !== "2" && (
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type-2"
                                  value="2"
                                  checked={
                                    AddTaxes?.discount_order_type === "2"
                                  }
                                  onChange={(e) =>
                                    setAddTeaxes({
                                      ...AddTaxes,
                                      discount_order_type: "2",
                                    })
                                  }
                                />
                                Pick Up
                              </label>
                            )}
                          </div>
                          <div className="col-md-12">
                            {AddTaxes?.tax_tax_type !== "2" && (
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type-3"
                                  value="3"
                                  checked={
                                    AddTaxes?.discount_order_type === "3"
                                  }
                                  onChange={(e) =>
                                    setAddTeaxes({
                                      ...AddTaxes,
                                      discount_order_type: "3",
                                    })
                                  }
                                />
                                Dine In
                              </label>
                            )}
                          </div>
                          <span className="text-danger">{valided && !AddTaxes?.discount_order_type && "Please Select Discount Order Type"}</span>
                        </div>

                        {/* Right Column */}
                        {AddTaxes?.discount_type != "3" ? (
                          <div className="col-md-3">
                            {/* Title */}
                            <div className="mt-4">
                              <label className="tax-type-label">
                                Applicable On
                              </label>
                            </div>

                            {/* Radio Buttons */}
                            <div className="col-md-12">
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type"
                                  value="All"
                                  checked={AddTaxes?.discount_applicable_on === "All"}
                                  onChange={(e) =>
                                    setAddTeaxes({
                                      ...AddTaxes, discount_applicable_on: "All",
                                      discount_category_item_id: "",

                                    })
                                  }
                                />
                                All
                              </label>
                            </div>

                            <div className="col-md-12">
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type"
                                  value="Category"
                                  checked={AddTaxes?.discount_applicable_on === "Category"}
                                  onChange={(e) =>
                                    setAddTeaxes({
                                      ...AddTaxes,
                                      discount_applicable_on: "Category",
                                    })
                                  }
                                />
                                Category
                              </label>
                            </div>

                            <div className="col-md-12">
                              <label className="radio-option tax-type-label">
                                <input
                                  type="radio"
                                  name="tax_type"
                                  value="3"
                                  checked={AddTaxes?.discount_applicable_on === "Items"}
                                  onChange={(e) =>
                                    setAddTeaxes({
                                      ...AddTaxes,
                                      discount_applicable_on: "Items",

                                    })
                                  }
                                />
                                Items
                              </label>
                            </div>

                            <span className="text-danger">{valided && !AddTaxes?.discount_applicable_on && "Please Select Applicable On"}</span>

                            {/* Category Checkbox List */}
                          </div>
                        ) : (
                          <>
                            <div className="col-md-3">
                              {/* Title */}
                              <div className="mt-4">
                                <label className="tax-type-label">
                                  Applicable On(Buy)
                                </label>
                              </div>

                              {/* Radio Buttons */}
                              <div className="col-md-12">
                                <label className="radio-option tax-type-label">
                                  <input
                                    type="radio"
                                    name="bogo_applicable_on-1"
                                    value="All"
                                    checked={AddTaxes?.bogo_applicable_on_purchase === "All"}
                                    onChange={(e) =>
                                      setAddTeaxes({
                                        ...AddTaxes,
                                        bogo_applicable_on_purchase: "All",
                                      })
                                    }
                                  />
                                  All
                                </label>
                              </div>

                              <div className="col-md-12">
                                <label className="radio-option tax-type-label">
                                  <input
                                    type="radio"
                                    name="Category-2"
                                    value="Category"
                                    checked={AddTaxes?.bogo_applicable_on_purchase === "Category"}
                                    onChange={(e) =>
                                      setAddTeaxes({
                                        ...AddTaxes,
                                        bogo_applicable_on_purchase: "Category",
                                      })
                                    }
                                  />
                                  Category
                                </label>
                              </div>

                              <div className="col-md-12">
                                <label className="radio-option tax-type-label">
                                  <input
                                    type="radio"
                                    name="Items-3"
                                    value="Items"
                                    checked={AddTaxes?.bogo_applicable_on_purchase === "Items"}
                                    onChange={(e) =>
                                      setAddTeaxes({
                                        ...AddTaxes,
                                        bogo_applicable_on_purchase: "Items",
                                      })
                                    }
                                  />
                                  Items
                                </label>
                              </div>
                              <span className="text-danger">{valided && !AddTaxes?.bogo_applicable_on_purchase && "Please Select Applicable Buy (On)"}</span>
                              {/* Category Checkbox List */}
                            </div>

                            <div className="col-md-3">
                              {/* Title */}
                              <div className="mt-4">
                                <label className="tax-type-label">
                                  Applicable On(Get)
                                </label>
                              </div>

                              {/* Radio Buttons */}
                              <div className="col-md-12">
                                <label className="radio-option tax-type-label">
                                  <input
                                    type="radio"
                                    name="bogo_applicable_on-4"
                                    value="All"
                                    checked={AddTaxes?.bogo_applicable_on === "All"}
                                    onChange={(e) =>
                                      setAddTeaxes({
                                        ...AddTaxes,
                                        bogo_applicable_on: "All",
                                      })
                                    }
                                  />
                                  All
                                </label>
                              </div>

                              <div className="col-md-12">
                                <label className="radio-option tax-type-label">
                                  <input
                                    type="radio"
                                    name="tax_type-5"
                                    value="Category"
                                    checked={AddTaxes?.bogo_applicable_on === "Category"}
                                    onChange={(e) =>
                                      setAddTeaxes({
                                        ...AddTaxes,
                                        bogo_applicable_on: "Category",
                                      })
                                    }
                                  />
                                  Category
                                </label>
                              </div>

                              <div className="col-md-12">
                                <label className="radio-option tax-type-label">
                                  <input
                                    type="radio"
                                    name="Items-8"
                                    value="Items"
                                    checked={AddTaxes?.bogo_applicable_on === "Items"}
                                    onChange={(e) =>
                                      setAddTeaxes({
                                        ...AddTaxes,
                                        bogo_applicable_on: "Items",
                                      })
                                    }
                                  />
                                  Items
                                </label>
                              </div>
                              <span className="text-danger">{valided && !AddTaxes?.bogo_applicable_on && "Please Select Applicable (Get)"}</span>
                              {/* Category Checkbox List */}
                            </div>
                          </>
                        )}
                        {/* Applicable On  */}
                        <div className="col-md-12">
                          {AddTaxes?.discount_applicable_on === "Category" ? (
                            <div className="mt-3">
                              <label className="tax-type-label">Select Categoriessss:</label>
                              <div className="d-flex flex-wrap gap-2">
                                {(() => {
                                  const allCategoryIds = DataList.map(cat => cat.category_id);
                                  const allCategoriesSelected = allCategoryIds.every(id => AddTaxes?.discount_category_item_id?.includes(id));

                                  return (
                                    <>
                                      {/* "Select all" for Categories */}
                                      <div className="form-check" key="all_categories" style={{ marginLeft: "-12px" }}>
                                        <input
                                          type="checkbox"
                                          checked={allCategoriesSelected}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setAddTeaxes({
                                              ...AddTaxes,
                                              discount_category_item_id: isChecked ? allCategoryIds : []
                                            });
                                          }}
                                        />
                                        <label className="form-check-label">Select all</label>
                                      </div>

                                      {/* Individual Categories */}
                                      {DataList.map(category => (
                                        <div className="form-check" key={category.category_id} style={{ marginLeft: "-12px" }}>
                                          <input
                                            type="checkbox"
                                            checked={AddTaxes?.discount_category_item_id?.includes(category.category_id)}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              const updatedCategories = isChecked
                                                ? [...(AddTaxes?.discount_category_item_id || []), category.category_id]
                                                : AddTaxes?.discount_category_item_id?.filter(id => id !== category.category_id);

                                              setAddTeaxes({ ...AddTaxes, discount_category_item_id: updatedCategories });
                                            }}
                                          />
                                          <label className="form-check-label">{category.category_name}</label>
                                        </div>
                                      ))}
                                    </>
                                  );
                                })()}
                              </div>

                              <span className="text-danger">{'Plase Select Atleast One'}</span>
                            </div>
                          ) : AddTaxes?.discount_applicable_on === "Items" ? (
                            <div className="mt-3">
                              <label className="tax-type-label">Select Category:</label>
                              <div className="d-flex flex-wrap gap-2">
                                {/* Multi-Select Categories */}
                                {(() => {
                                  const allCategoryIds = DataList.map(cat => cat.category_id);
                                  const allCategoriesSelected = allCategoryIds.every(id => selectedCategory?.includes(id));

                                  return (
                                    <>
                                      {/* "Select all" for Categories */}
                                      <div className="form-check" key="all_categories" style={{ marginLeft: "-12px" }}>
                                        <input
                                          type="checkbox"
                                          checked={allCategoriesSelected}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedCategory(isChecked ? allCategoryIds : []);
                                          }}
                                        />
                                        <label className="form-check-label">Select all</label>
                                      </div>

                                      {/* Individual Categories */}
                                      {DataList.map(category => (
                                        <div className="form-check" key={category.category_id} style={{ marginLeft: "-12px" }}>
                                          <input
                                            type="checkbox"
                                            checked={selectedCategory?.includes(category.category_id)}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              const updatedCategories = isChecked
                                                ? [...(selectedCategory || []), category.category_id]
                                                : selectedCategory?.filter(id => id !== category.category_id);

                                              setSelectedCategory(updatedCategories);
                                            }}
                                          />
                                          <label className="form-check-label">{category.category_name}</label>
                                        </div>
                                      ))}
                                    </>
                                  );
                                })()}
                              </div>

                              {/* Show items based on selected categories */}
                              {selectedCategory?.length > 0 && (
                                <div className="mt-3">
                                  <label className="tax-type-label">Select Items:</label>
                                  <div className="d-flex flex-wrap gap-2">
                                    {(() => {
                                      const filteredItems = selectedCategory.includes("Select all")
                                        ? DataList.flatMap(category => category.item_data || []) // Show all items if "Select all"
                                        : DataList.filter(cat => selectedCategory.includes(cat.category_id))
                                          .flatMap(cat => cat.item_data || []); // Show only selected category's items

                                      const allItemIds = filteredItems.map(item => item.id);
                                      const allItemsSelected = allItemIds.every(id => AddTaxes?.discount_category_item_id?.includes(id));

                                      return (
                                        <>
                                          {/* "Select all" for Items */}
                                          <div className="form-check" key="select_all_items" style={{ marginLeft: "-12px" }}>
                                            <input
                                              type="checkbox"
                                              checked={allItemsSelected}
                                              onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                setAddTeaxes({
                                                  ...AddTaxes,
                                                  discount_category_item_id: isChecked ? allItemIds : []
                                                });
                                              }}
                                            />
                                            <label className="form-check-label">Select all</label>
                                          </div>

                                          {/* Individual Items */}
                                          {filteredItems.map(item => (
                                            <div key={item.id} className="form-check" style={{ marginLeft: "-12px" }}>
                                              <input
                                                type="checkbox"
                                                checked={AddTaxes?.discount_category_item_id?.includes(item.id)}
                                                onChange={(e) => {
                                                  const isChecked = e.target.checked;
                                                  const updatedItems = isChecked
                                                    ? [...(AddTaxes?.discount_category_item_id || []), item.id]
                                                    : AddTaxes?.discount_category_item_id?.filter(i => i !== item.id);

                                                  setAddTeaxes({ ...AddTaxes, discount_category_item_id: updatedItems });
                                                }}
                                              />
                                              <label className="form-check-label">{item.item_name}</label>
                                            </div>
                                          ))}
                                        </>
                                      );
                                    })()}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>



                        {/* Applicable On(Buy)  */}
                        <div className="col-md-12">
                          {AddTaxes?.bogo_applicable_on_purchase === "Category" ? (
                            <div className="mt-3">
                              <label className="tax-type-label">Select Categories:</label>
                              <div className="d-flex flex-wrap gap-2">
                                {(() => {
                                  const allCategoryIds = DataList.map(cat => cat.category_id);
                                  const allCategoriesSelected = allCategoryIds.every(id => AddTaxes?.bogo_applicable_on_purchase_item_ids?.includes(id));

                                  return (
                                    <>
                                      {/* "Select all" for Categories */}
                                      <div className="form-check" key="all_categories" style={{ marginLeft: "-12px" }}>
                                        <input
                                          type="checkbox"
                                          checked={allCategoriesSelected}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setAddTeaxes({
                                              ...AddTaxes,
                                              bogo_applicable_on_purchase_item_ids: isChecked ? allCategoryIds : []
                                            });
                                          }}
                                        />
                                        <label className="form-check-label">Select all</label>
                                      </div>

                                      {/* Individual Categories */}
                                      {DataList.map(category => (
                                        <div className="form-check" key={category.category_id} style={{ marginLeft: "-12px" }}>
                                          <input
                                            type="checkbox"
                                            checked={AddTaxes?.bogo_applicable_on_purchase_item_ids?.includes(category.category_id)}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              const updatedCategories = isChecked
                                                ? [...(AddTaxes?.bogo_applicable_on_purchase_item_ids || []), category.category_id]
                                                : AddTaxes?.bogo_applicable_on_purchase_item_ids?.filter(id => id !== category.category_id);

                                              setAddTeaxes({ ...AddTaxes, bogo_applicable_on_purchase_item_ids: updatedCategories });
                                            }}
                                          />
                                          <label className="form-check-label">{category.category_name}</label>
                                        </div>
                                      ))}
                                    </>
                                  );
                                })()}
                              </div>
                              <span className="text-danger">{valided && !AddTaxes?.bogo_applicable_on_purchase_item_ids?.length && 'Please Select Atleast One'}</span>
                            </div>
                          ) : AddTaxes?.bogo_applicable_on_purchase === "Items" ? (
                            <div className="mt-3">
                              <label className="tax-type-label">Select Category:</label>
                              <div className="d-flex flex-wrap gap-2">
                                {/* Multi-Select Categories */}
                                {(() => {
                                  const allCategoryIds = DataList.map(cat => cat.category_id);
                                  const allCategoriesSelected = allCategoryIds.every(id => selectedCategory?.includes(id));

                                  return (
                                    <>
                                      {/* "Select all" for Categories */}
                                      <div className="form-check" key="all_categories" style={{ marginLeft: "-12px" }}>
                                        <input
                                          type="checkbox"
                                          checked={allCategoriesSelected}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedCategory(isChecked ? allCategoryIds : []);
                                          }}
                                        />
                                        <label className="form-check-label">Select all</label>
                                      </div>

                                      {/* Individual Categories */}
                                      {DataList.map(category => (
                                        <div className="form-check" key={category.category_id} style={{ marginLeft: "-12px" }}>
                                          <input
                                            type="checkbox"
                                            checked={selectedCategory?.includes(category.category_id)}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              const updatedCategories = isChecked
                                                ? [...(selectedCategory || []), category.category_id]
                                                : selectedCategory?.filter(id => id !== category.category_id);

                                              setSelectedCategory(updatedCategories);
                                            }}
                                          />
                                          <label className="form-check-label">{category.category_name}</label>
                                        </div>
                                      ))}
                                    </>
                                  );
                                })()}
                              </div>
                              <span className="text-danger">{valided && !selectedCategory?.length && 'Please Select Atleast One'}</span>

                              {/* Show items based on selected categories */}
                              {selectedCategory?.length > 0 && (
                                <div className="mt-3">
                                  <label className="tax-type-label">Select Items:</label>
                                  <div className="d-flex flex-wrap gap-2">
                                    {(() => {
                                      const filteredItems = selectedCategory.includes("Select all")
                                        ? DataList.flatMap(category => category.item_data || []) // Show all items if "Select all"
                                        : DataList.filter(cat => selectedCategory.includes(cat.category_id))
                                          .flatMap(cat => cat.item_data || []); // Show only selected category's items

                                      const allItemIds = filteredItems.map(item => item.id);
                                      const allItemsSelected = allItemIds.every(id => AddTaxes?.bogo_applicable_on_purchase_item_ids?.includes(id));

                                      return (
                                        <>
                                          {/* "Select all" for Items */}
                                          <div className="form-check" key="select_all_items" style={{ marginLeft: "-12px" }}>
                                            <input
                                              type="checkbox"
                                              checked={allItemsSelected}
                                              onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                setAddTeaxes({
                                                  ...AddTaxes,
                                                  bogo_applicable_on_purchase_item_ids: isChecked ? allItemIds : []
                                                });
                                              }}
                                            />
                                            <label className="form-check-label">Select all</label>
                                          </div>

                                          {/* Individual Items */}
                                          {filteredItems.map(item => (
                                            <div key={item.id} className="form-check" style={{ marginLeft: "-12px" }}>
                                              <input
                                                type="checkbox"
                                                checked={AddTaxes?.bogo_applicable_on_purchase_item_ids?.includes(item.id)}
                                                onChange={(e) => {
                                                  const isChecked = e.target.checked;
                                                  const updatedItems = isChecked
                                                    ? [...(AddTaxes?.bogo_applicable_on_purchase_item_ids || []), item.id]
                                                    : AddTaxes?.bogo_applicable_on_purchase_item_ids?.filter(i => i !== item.id);

                                                  setAddTeaxes({ ...AddTaxes, bogo_applicable_on_purchase_item_ids: updatedItems });
                                                }}
                                              />
                                              <label className="form-check-label">{item.item_name}</label>
                                            </div>
                                          ))}
                                        </>
                                      );
                                    })()}
                                  </div>
                                  <span className="text-danger">{valided && !AddTaxes?.bogo_applicable_on_purchase_item_ids.length && 'Please Select Atleast One'}</span>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>

                        {/*  Applicable On(Get)*/}


                        <div className="col-md-12">
                          {AddTaxes?.bogo_applicable_on === "Category" ? (
                            <div className="mt-3">
                              <label className="tax-type-label">Select Categories:</label>
                              <div className="d-flex flex-wrap gap-2">
                                {(() => {
                                  const allCategoryIds = DataList.map(cat => cat.category_id);
                                  const allCategoriesSelected = allCategoryIds.every(id => AddTaxes?.bogo_applicable_on_item_ids?.includes(id));

                                  return (
                                    <>
                                      {/* "Select all" for Categories */}
                                      <div className="form-check" key="all_categories" style={{ marginLeft: "-12px" }}>
                                        <input
                                          type="checkbox"
                                          checked={allCategoriesSelected}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setAddTeaxes({
                                              ...AddTaxes,
                                              bogo_applicable_on_item_ids: isChecked ? allCategoryIds : []
                                            });
                                          }}
                                        />
                                        <label className="form-check-label">Select all</label>
                                      </div>

                                      {/* Individual Categories */}
                                      {DataList.map(category => (
                                        <div className="form-check" key={category.category_id} style={{ marginLeft: "-12px" }}>
                                          <input
                                            type="checkbox"
                                            checked={AddTaxes?.bogo_applicable_on_item_ids?.includes(category.category_id)}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              const updatedCategories = isChecked
                                                ? [...(AddTaxes?.bogo_applicable_on_item_ids || []), category.category_id]
                                                : AddTaxes?.bogo_applicable_on_item_ids?.filter(id => id !== category.category_id);

                                              setAddTeaxes({ ...AddTaxes, bogo_applicable_on_item_ids: updatedCategories });
                                            }}
                                          />
                                          <label className="form-check-label">{category.category_name}</label>
                                        </div>
                                      ))}
                                    </>
                                  );
                                })()}
                              </div>
                              <span className="text-danger">{valided && !AddTaxes?.bogo_applicable_on_item_ids.length && 'Please Select Atleast One'}</span>
                            </div>
                          ) : AddTaxes?.bogo_applicable_on === "Items" ? (
                            <div className="mt-3">
                              <label className="tax-type-label">Select Category:</label>
                              <div className="d-flex flex-wrap gap-2">
                                {/* Multi-Select Categories */}
                                {(() => {
                                  const allCategoryIds = DataList.map(cat => cat.category_id);
                                  const allCategoriesSelected = allCategoryIds.every(id => selectedCategory?.includes(id));

                                  return (
                                    <>
                                      {/* "Select all" for Categories */}
                                      <div className="form-check" key="all_categories" style={{ marginLeft: "-12px" }}>
                                        <input
                                          type="checkbox"
                                          checked={allCategoriesSelected}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedCategory(isChecked ? allCategoryIds : []);
                                          }}
                                        />
                                        <label className="form-check-label">Select all</label>
                                      </div>

                                      {/* Individual Categories */}
                                      {DataList.map(category => (
                                        <div className="form-check" key={category.category_id} style={{ marginLeft: "-12px" }}>
                                          <input
                                            type="checkbox"
                                            checked={selectedCategory?.includes(category.category_id)}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              const updatedCategories = isChecked
                                                ? [...(selectedCategory || []), category.category_id]
                                                : selectedCategory?.filter(id => id !== category.category_id);

                                              setSelectedCategory(updatedCategories);
                                            }}
                                          />
                                          <label className="form-check-label">{category.category_name}</label>
                                        </div>
                                      ))}
                                    </>
                                  );
                                })()}
                              </div>
                              <span className="text-danger">{valided && !selectedCategory?.length && 'Please Select Atleast One'}</span>


                              {/* Show items based on selected categories */}
                              {selectedCategory?.length > 0 && (
                                <div className="mt-3">
                                  <label className="tax-type-label">Select Items:</label>
                                  <div className="d-flex flex-wrap gap-2">
                                    {(() => {
                                      const filteredItems = selectedCategory.includes("Select all")
                                        ? DataList.flatMap(category => category.item_data || []) // Show all items if "Select all"
                                        : DataList.filter(cat => selectedCategory.includes(cat.category_id))
                                          .flatMap(cat => cat.item_data || []); // Show only selected category's items

                                      const allItemIds = filteredItems.map(item => item.id);
                                      const allItemsSelected = allItemIds.every(id => AddTaxes?.bogo_applicable_on_item_ids?.includes(id));

                                      return (
                                        <>
                                          {/* "Select all" for Items */}
                                          <div className="form-check" key="select_all_items" style={{ marginLeft: "-12px" }}>
                                            <input
                                              type="checkbox"
                                              checked={allItemsSelected}
                                              onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                setAddTeaxes({
                                                  ...AddTaxes,
                                                  bogo_applicable_on_item_ids: isChecked ? allItemIds : []
                                                });
                                              }}
                                            />
                                            <label className="form-check-label">Select all</label>
                                          </div>

                                          {/* Individual Items */}
                                          {filteredItems.map(item => (
                                            <div key={item.id} className="form-check" style={{ marginLeft: "-12px" }}>
                                              <input
                                                type="checkbox"
                                                checked={AddTaxes?.bogo_applicable_on_item_ids?.includes(item.id)}
                                                onChange={(e) => {
                                                  const isChecked = e.target.checked;
                                                  const updatedItems = isChecked
                                                    ? [...(AddTaxes?.bogo_applicable_on_item_ids || []), item.id]
                                                    : AddTaxes?.bogo_applicable_on_item_ids?.filter(i => i !== item.id);

                                                  setAddTeaxes({ ...AddTaxes, bogo_applicable_on_item_ids: updatedItems });
                                                }}
                                              />
                                              <label className="form-check-label">{item.item_name}</label>
                                            </div>
                                          ))}
                                        </>
                                      );
                                    })()}
                                  </div>
                                  <span className="text-danger">{valided && !AddTaxes?.bogo_applicable_on_item_ids?.length && 'Please Select Atleast One'}</span>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div>

                        </div>


                      </div>

                      {AddTaxes?.discount_type === "3" && (
                        <div className="mt-3">
                          <p className="text-muted">BOGO offer details</p>
                          <div className="row">
                            <div className="col-3 d-flex flex-column">
                              <label>Buy</label>
                              <input type="number" className="form-control"
                                value={AddTaxes.bogo_buy_qty}

                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    bogo_buy_qty: e.target.value,
                                  })
                                }
                              />
                              <p className="text-muted">For Ex:- BUY 2 GET 2</p>
                              <span className="text-danger">{valided && !AddTaxes?.bogo_buy_qty && 'Please Enter Buy'}</span>
                            </div>
                            <div className="col-3 d-flex flex-column">
                              <label>Get</label>
                              <input type="number" className="form-control"

                                value={AddTaxes.bogo_get_qty}

                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    bogo_get_qty: e.target.value,
                                  })
                                }
                              />
                              <span className="text-danger">{valided && !AddTaxes?.bogo_get_qty && 'Please Enter Get'}</span>
                            </div>
                            <div className="col-6 d-flex flex-column">
                              <label>{`Item Amount Limit(>=)`}</label>
                              <input type="number" className="form-control"

                                value={AddTaxes.bogo_item_amount_limit}

                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    bogo_item_amount_limit: e.target.value,
                                  })
                                }
                              />
                              <p className="text-muted">
                                Note:- Leave blank for no limit
                              </p>

                            </div>

                            <div className="col-6 mt-3">
                              <div className="d-flex flex-column ">
                                <p>Bogo Type</p>
                                <div className="d-flex">
                                  <label className="d-flex align-items-center my-2">
                                    <input
                                      type="radio"
                                      name="bogotype"
                                      className="me-2"
                                      value="1"
                                      checked={AddTaxes?.bogo_type === "1"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_type: "1",
                                        })
                                      }
                                    />
                                    Percentage
                                  </label>
                                  <label className="d-flex align-items-center ms-4">
                                    <input
                                      type="radio"
                                      name="bogotype"
                                      className="me-2"
                                      value="2"
                                      checked={AddTaxes?.bogo_type === "2"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_type: "2",
                                        })
                                      }
                                    />
                                    Fixed
                                  </label>
                                </div>
                                <p className="text-muted">
                                  Note:- Please select Bogo Discount type.
                                </p>
                                <span className="text-danger">{valided && !AddTaxes?.bogo_type && 'Please Select'}</span>
                              </div>
                            </div>
                            <div className="col-6 mt-3">
                              <label>Bogo Amount</label>
                              <input type="number" className="form-control"
                                value={AddTaxes.bogo_purchase_discount}

                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    bogo_purchase_discount: e.target.value,
                                  })
                                }
                              />
                              <p className="text-muted">
                                Note:-Should be between 1%​ to​100% for
                                Percentage ​discounts.
                              </p>
                            </div>

                            <div className="col-6 my-3">
                              <div className="d-flex flex-column">
                                <p>Bogo Get Item Type</p>
                                <div className="d-flex">
                                  <label className="d-flex align-items-center my-2">
                                    <input
                                      type="radio"
                                      name="bogotypeget"
                                      className="me-2"
                                      value="0"
                                      checked={AddTaxes?.bogo_applicable_on_item === "0"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_applicable_on_item: "0",
                                        })
                                      }
                                    />
                                    Lower Price
                                  </label>
                                  <label className="d-flex align-items-center ms-4">
                                    <input
                                      type="radio"
                                      name="bogotypeget"
                                      className="me-2"
                                      value="1"
                                      checked={AddTaxes?.bogo_applicable_on_item === "1"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_applicable_on_item: "1",
                                        })
                                      }
                                    />
                                    Higher Price
                                  </label>
                                  <label className="d-flex align-items-center ms-4">
                                    <input
                                      type="radio"
                                      name="bogotypeget"
                                      className="me-2"
                                      value="2"
                                      checked={AddTaxes?.bogo_applicable_on_item === "2"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_applicable_on_item: "2",
                                        })
                                      }
                                    />
                                    Same items only
                                  </label>
                                </div>
                                <p className="text-muted">
                                  Note:- Please select Bogo get item type.
                                </p>
                              </div>
                            </div>

                            <div className="col-6 my-3">
                              <input type="checkbox" className="me-2" />
                              <label>
                                Do you want to show quantity for Bogo discount
                                on POS?
                              </label>
                              <p className="text-muted">
                                Note:- if not checked then bogo discount will
                                applicable on all the relevant items.
                              </p>
                            </div>

                            <div className="col-6">
                              <div className="d-flex flex-column">
                                <p>Bogo Buy Item Type</p>
                                <div className="d-flex">
                                  <label className="d-flex align-items-center my-2">
                                    <input
                                      type="radio"
                                      name="bogotypebuy"
                                      className="me-2"
                                      value="0"
                                      checked={AddTaxes?.bogo_applicable_on_purchase_item === "0"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_applicable_on_purchase_item: "0",
                                        })
                                      }
                                    />
                                    Lower Price
                                  </label>
                                  <label className="d-flex align-items-center ms-4">
                                    <input
                                      type="radio"
                                      name="bogotypebuy"
                                      className="me-2"
                                      value="1"
                                      checked={AddTaxes?.bogo_applicable_on_purchase_item === "1"}
                                      onChange={(e) =>
                                        setAddTeaxes({
                                          ...AddTaxes,
                                          bogo_applicable_on_purchase_item: "1",
                                        })
                                      }
                                    />
                                    Higher Price
                                  </label>
                                </div>
                                <p className="text-muted">
                                  Note:- Please select Bogo buy item type. This
                                  will work only if "Bogo Buy Amount" is proper
                                  defined.
                                </p>
                              </div>
                            </div>

                            {/* <div className="col-6">
                              <label>Bogo Buy Amount</label>
                              <input type="number" className="form-control" />
                              <p className="text-muted">
                                Note:-Should be between 1%​ to​100% for
                                Percentage ​discounts.
                              </p>
                            </div> */}
                          </div>
                        </div>
                      )}
                      {AddTaxes?.discount_type !== "3" && (
                        <div className="row g-3">
                          {/* Department */}
                          <div className="col-md-4">
                            <label
                              htmlFor="department"
                              className="form-label tax-type-label"
                            >
                              Applicable Amount
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <div className="position-relative z-3 w-full">
                              <input
                                type="number"
                                id="variantName"
                                className="form-control tax-type-label"
                                name="discount_min_amount"
                                value={AddTaxes.discount_min_amount}
                                placeholder="Discount min Amount"
                                onChange={(e) =>
                                  setAddTeaxes({
                                    ...AddTaxes,
                                    discount_min_amount: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <span className="text-danger">{valided && !AddTaxes?.discount_min_amount && 'Please Enter Min Discount'}</span>
                          </div>

                          {/* Variant Name */}

                          <div className="col-md-4">
                            <label
                              htmlFor="variantName"
                              className="form-label tax-type-label"
                            >
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <input
                              type="number"
                              id="variantName"
                              className="form-control tax-type-label"
                              placeholder="Discount Max Amount"
                              value={AddTaxes.discount_max_amount}

                              onChange={(e) =>
                                setAddTeaxes({
                                  ...AddTaxes,
                                  discount_max_amount: e.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{valided && !AddTaxes?.discount_max_amount && 'Please Enter Max Discount'}</span>
                          </div>
                          {/* Unit of Measure */}
                          <div className="col-md-4">
                            <label
                              htmlFor="unitOfMeasure"
                              className="form-label tax-type-label tax-type-label"
                            >
                              Allow Max Discount*
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <input
                              type="number"
                              id="variantName"
                              className="form-control tax-type-label"
                              placeholder=""
                              value={AddTaxes.discount_max_limit}
                              onChange={(e) =>
                                setAddTeaxes({
                                  ...AddTaxes,
                                  discount_max_limit: e.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{valided && !AddTaxes?.discount_max_limit && 'Please Enter Max Discount'}</span>
                          </div>
                        </div>
                      )}

                    </div>

                    <div className="row">
                      {/* First Column */}
                      <div className="col-md-6">
                        <label className="text-sm font-medium text-Dark">
                          Date Duration{" "}
                          <span className="text-danger mt-2">*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label">
                              To <span className="text-danger">*</span>
                            </label>
                            <input
                              value={
                                AddTaxes?.discount_start_date

                              }
                              type="Date"
                              className="form-control"
                              placeholder="Enter name..."
                              onChange={(e) =>
                                setAddTeaxes({
                                  ...AddTaxes,
                                  discount_start_date: e.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{valided && !AddTaxes?.discount_start_date && 'Please Enter Discount Start Date'}</span>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">
                              From <span className="text-danger">*</span>
                            </label>
                            <input
                              value={
                                AddTaxes?.discount_end_date

                              }
                              type="Date"
                              className="form-control"
                              placeholder="Enter name..."
                              onChange={(e) =>
                                setAddTeaxes({
                                  ...AddTaxes,
                                  discount_end_date: e.target.value,
                                })
                              }
                            />

                            <span className="text-danger">{valided && !AddTaxes?.discount_end_date && 'Please Enter Discount Start Date'}</span>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}
                      <div className="col-md-6">
                        <label className="text-sm font-medium text-Dark">
                          Time Duration{" "}
                          <span className="text-danger mt-2">*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label">
                              To <span className="text-danger">*</span>
                            </label>
                            <input
                              value={
                                AddTaxes?.discount_start_time

                              }
                              type="time"
                              className="form-control"
                              placeholder="Enter name..."
                              onChange={(e) =>
                                setAddTeaxes({
                                  ...AddTaxes,
                                  discount_start_time: e.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{valided && !AddTaxes?.discount_start_time && 'Please Enter Discount Start Time'}</span>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">
                              From <span className="text-danger">*</span>
                            </label>
                            <input
                              value={
                                AddTaxes?.discount_end_time

                              }
                              type="time"
                              className="form-control"
                              placeholder="Enter name..."
                              onChange={(e) =>
                                setAddTeaxes({
                                  ...AddTaxes,
                                  discount_end_time: e.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{valided && !AddTaxes?.discount_end_time && 'Please Enter Discount End Time'}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Days Selection Section */}
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="flex items-center gap-4">
                          <label className="text-sm font-medium text-Dark">
                            Days <span className="text-red-600">*</span>
                          </label>
                          <div className="flex items-center gap-6 border-l border-gray-200 pl-4">
                            <div className="flex items-center gap-4">
                              {/* All Days Checkbox */}
                              <label className="flex items-center gap-2 text-Dark">
                                <input
                                  type="checkbox"
                                  id="everyDay"
                                  checked={AddTaxes?.discount_days === "Sun,Mon,Tue,Wed,Thu,Fri,Sat"} // Checked if all days are selected
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setAddTeaxes((prevState) => ({
                                      ...prevState,
                                      discount_days: isChecked
                                        ? "Sun,Mon,Tue,Wed,Thu,Fri,Sat" // Select all days
                                        : "", // Clear all
                                    }));
                                  }}
                                />
                                All Days
                              </label>

                              {/* Individual Days Checkboxes */}
                              <div className="d-flex flex-wrap gap-2">
                                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                                  <div className="form-check" key={day}>
                                    <input
                                      type="checkbox"
                                      checked={AddTaxes?.discount_days?.split(",").includes(day)}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        let currentDaysArray = AddTaxes?.discount_days
                                          ? AddTaxes.discount_days.split(",")
                                          : [];

                                        let updatedDaysArray = isChecked
                                          ? [...currentDaysArray, day] // Add day if checked
                                          : currentDaysArray.filter((d) => d !== day); // Remove if unchecked

                                        setAddTeaxes((prevState) => ({
                                          ...prevState,
                                          discount_days: updatedDaysArray.join(","), // Convert array to string
                                        }));
                                      }}
                                      id={day.toLowerCase()}
                                    />
                                    <label className="form-check-label" htmlFor={day.toLowerCase()}>
                                      {day}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="text-danger">{valided && !AddTaxes?.discount_days && 'Please Select'}</span>
                      </div>
                    </div>



                    <div className="col-md-2 d-flex align-items-center">
                      <label
                        htmlFor="status"
                        className="tax-type-label d-flex align-items-center"
                      >
                        <input
                          type="checkbox"
                          id="status"
                          name="status"
                          className="me-2"
                          value="1"
                          checked={AddTaxes?.discount_status === "1"}
                          onChange={(e) =>
                            setAddTeaxes({
                              ...AddTaxes,
                              discount_status: e.target.checked ? "1" : "0", // Toggle between "1" and "0"
                            })
                          }
                        />
                        Add on Status
                      </label>
                    </div>


                    {/* </div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-end gap-3 pt-2 pb-2">
                    <button
                      className="w-30 w-sm-auto btn"
                      style={{ border: "1px solid #53B7E8", color: "#53B7E8" }}
                      type="button"
                    >
                      <span className="add-txt">Cancel</span>
                    </button>
                    <button
                      className="w-30 w-sm-auto btn"
                      style={{ backgroundColor: "#53B7E8", color: "#fff" }}
                      type="button"
                      onClick={onAddOrUpdateDiscount}
                    >
                      <span className="add-text">{id ? "Update Changes" : "Add Changes"}</span>
                    </button>
                  </div>
                </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDiscount;
