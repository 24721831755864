import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../../api/ApiConfig";
import {
  deleteWithAuthCall,
  multipartPostCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCall,
} from "../../../api/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import MapComponent from "./storeMap";
import { AppContext } from "../../../context/AppContext";


const AddNewStore = () => {
  const { id } = useParams();
  const {sidebar  } = useContext(AppContext);

  const navigate = useNavigate();

  const [fileName, setFileName] = useState("");
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Availability Mode");

  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const [createdOpen, setCreatedOpen] = useState(false);
  useEffect(() => {
    if (createdOpen) {
      document.querySelector(".leaflet-container").style.pointerEvents = "none";
    } else {
      document.querySelector(".leaflet-container").style.pointerEvents = "auto";
    }
  }, [createdOpen]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [AddStore, setAddStore] = useState({
    shop_name: "",
    shop_address: "",
    shop_longitude: "",
    shop_latitude: "",
    shop_contact: "",
    shop_landmark: "",
    shop_city: "",
    shop_state: "",
    shop_country: "",
    shop_menu_sharing_code: "",
    shop_status: "",
    shop_working_days: "",
    shop_type: "",
    shop_opening_time: "",
    shop_closing_time: "",
    shop_gst_number: "",
    shop_gst_type: "",
    shop_images: "",
  });


  useEffect(() => {
    if (id) {
      fetchDataForID();
    }
  }, [id]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setAddStore({ ...AddStore, shop_images: selectedFile });
    }
  };


  const handleWorkingDaysChange = (day) => {
    let days = AddStore.shop_working_days
      ? AddStore.shop_working_days.split(",")
      : [];

    if (days.includes(day)) {
      days = days.filter((d) => d !== day);
    } else {
      days.push(day);
    }

    // Ensure no leading comma
    const updatedDays = days.filter(Boolean).join(",");
    setAddStore({ ...AddStore, shop_working_days: updatedDays });
  };

  // Add Store
  const handleAddStore = () => {
    const formdata = new FormData();

    formdata.append("shop_name", AddStore.shop_name);

    formdata.append("shop_address", AddStore.shop_address);

    formdata.append("shop_longitude", AddStore.shop_longitude);

    formdata.append("shop_latitude", AddStore.shop_latitude);
    formdata.append("shop_contact", AddStore.shop_contact);
    formdata.append("shop_landmark", AddStore.shop_landmark);
    formdata.append("shop_city", AddStore.shop_city);
    formdata.append("shop_state", AddStore.shop_state);
    formdata.append("shop_country", AddStore.shop_country);
    formdata.append("shop_menu_sharing_code", AddStore.shop_menu_sharing_code);
    formdata.append("shop_status", AddStore.shop_status);
    formdata.append("shop_working_days", AddStore.shop_working_days);
    formdata.append("shop_type", AddStore.shop_type);
    formdata.append("shop_opening_time", AddStore.shop_opening_time);
    formdata.append("shop_closing_time", AddStore.shop_closing_time);
    formdata.append("shop_gst_number", AddStore.shop_gst_number);

    formdata.append("shop_gst_type", AddStore.shop_gst_type);

    formdata.append("shop_images", AddStore.shop_images);

    console.log(formdata);

    multipartPostCallWithErrorResponse(ApiConfig.ADD_STORE, formdata).then(
      (res) => {
        console.log(res);

        if (res?.json?.success) {
          notifySuccess(res?.json?.message);
          navigate(`/ManageStore`);
        } else {
          notifyError(res?.json?.message);
        }
      }
    );
  };
  // Update Store

  const handleUpdateStore = (id) => {
    const formdata = new FormData();

    formdata.append("shop_name", AddStore.shop_name);

    formdata.append("shop_address", AddStore.shop_address);

    formdata.append("shop_longitude", AddStore.shop_longitude);

    formdata.append("shop_latitude", AddStore.shop_latitude);
    formdata.append("shop_contact", AddStore.shop_contact);
    formdata.append("shop_landmark", AddStore.shop_landmark);
    formdata.append("shop_city", AddStore.shop_city);
    formdata.append("shop_state", AddStore.shop_state);
    formdata.append("shop_country", AddStore.shop_country);
    formdata.append("shop_menu_sharing_code", AddStore.shop_menu_sharing_code);
    formdata.append("shop_status", AddStore.shop_status);
    formdata.append("shop_working_days", AddStore.shop_working_days);
    formdata.append("shop_type", AddStore.shop_type);
    formdata.append("shop_opening_time", AddStore.shop_opening_time);
    formdata.append("shop_closing_time", AddStore.shop_closing_time);
    formdata.append("shop_gst_number", AddStore.shop_gst_number);

    formdata.append("shop_gst_type", AddStore.shop_gst_type);

    formdata.append("shop_images", AddStore.shop_images);

    console.log(formdata);

    putMultipartWithAuthCallWithErrorResponse(
      ApiConfig.UPDATE_STORE + id,
      formdata
    ).then((res) => {
      console.log(res);

      if (res?.json?.success) {
        notifySuccess(res?.json?.message);
        navigate(`/ManageStore`);
      } else {
        notifyError(res?.json?.message);
      }
    });
  };

  const handleDeletetStore = (id) => {
    deleteWithAuthCall(ApiConfig.DELETE_STORE + id).then((res) => {
      if (res.success) {
        notifySuccess(res?.message);
        navigate(`/ManageStore`);

      } else {
        notifyError(res?.message);
      }

      //
    });
  };

  const fetchDataForID = async () => {
    try {
      const res = await simpleGetCall(ApiConfig.STORE_Detalis + id);
      console.log("res====", res);
      if (res?.success) {
        let data = res.shop;
        setAddStore({
          shop_name: data.restaurant_name,
          shop_address: data.restaurant_address,
          shop_longitude: data.restaurant_longitude,
          shop_latitude: data.restaurant_latitude,
          shop_contact: data.restaurant_contact,
          shop_landmark: data.restaurant_landmark,
          shop_city: data.restaurant_city,
          shop_state: data.restaurant_state,
          shop_country: data.restaurant_country,
          shop_menu_sharing_code: data.restaurant_menu_sharing_code,
          shop_status: data.store_status,
          shop_working_days: data.working_days,
          shop_type: data.shop_type,
          shop_opening_time: data.opening_time,
          shop_closing_time: data.closing_time,
          shop_gst_number: data.gst_number,
          shop_gst_type: data.shop_gst_type,
          shop_images: data.shop_images?.[0].image_url
        });
      } else {
        setAddStore([]);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  // Set the initial dropdown value based on AddStore.shop_status
  useEffect(() => {
    if (AddStore.shop_status !== undefined) {
      setSelectedOption(AddStore.shop_status === 1 ? "Open" : "Closed");
    }
  }, [AddStore.shop_status]);

  // Handle selection
  const handleSelect = (eventKey) => {
    const optionText = eventKey === "1" ? "Open" : "Closed";
    setSelectedOption(optionText);

    // Update the state
    setAddStore((prevState) => ({
      ...prevState,
      shop_status: eventKey === "1" ? 1 : 0, // Ensure it's stored as a number
    }));
  };

  // useEffect(() => {
  //   return () => {
  //     if (AddStore.shop_images) {
  //       URL.revokeObjectURL(AddStore.shop_images); // Clean up URL
  //     }
  //   };
  // }, [AddStore.shop_images]);
  return (
    <div>
      {/* <Header /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
        <div className="sub-header-role ">
            <h6 className="mt-2">Stock List</h6>
          </div>
      <div className="p-6 bg-white mt-4 ">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="p-3 bg-white rounded-lg">
            <div className="grid grid-cols-3 gap-4 mb-4">
              <div className="w-1/2">
                <label className="block mb-2 text-sm font-medium  text-Dark">
                  Operational Status
                </label>
                <div
                style={{border:"1px solid #53B7E8",width: "175px"}}
                  className="available_btn ms-2 p-1 text-gray-700  rounded font-size-14 "
                >
                  <Dropdown onSelect={handleSelect} style={{width:"95%"}}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="d-flex justify-content-center align-items-center available_btn w-full text-start btn-default text-black border-none "
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        backgroundColor: "red",
                      }}
                    >
                      {selectedOption}
                    </Dropdown.Toggle>

                       
                        <Dropdown.Menu className="mt-2" style={{ border:"1px solid #53B7E8" }}>
                      <Dropdown.Item  eventKey="1">Open</Dropdown.Item>
                      <Dropdown.Item eventKey="0">Closed</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/* First Row */}
            <div className="row g-3 align-items-center">
              {/* Store Name */}
              <div className="col-md-4">
                <label className="form-label fw-medium">
                  Store Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  placeholder="Enter name..."
                  value={AddStore.shop_name}
                  onChange={(e) =>
                    setAddStore({ ...AddStore, shop_name: e.target.value })
                  }
                />
              </div>

              {/* GST Number */}
              <div className="col-md-4">
                <label className="form-label fw-medium">
                  GST Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  placeholder="Enter name..."
                  value={AddStore.shop_gst_number}
                  onChange={(e) =>
                    setAddStore({
                      ...AddStore,
                      shop_gst_number: e.target.value,
                    })
                  }
                />
              </div>

              {/* Store Code */}
              <div className="col-md-4">
                <label className="form-label fw-medium">
                  Store Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  placeholder="Enter name..."
                  value={AddStore.shop_menu_sharing_code}
                  onChange={(e) =>
                    setAddStore({
                      ...AddStore,
                      shop_menu_sharing_code: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {/* Second Row */}
            <div className=" mt-3">
              <div className="mb-3 row">
                {/* Label */}
                <label className="col-md-2 col-form-label fw-medium">
                  Store Type <span className="text-danger">*</span>
                </label>

                {/* Checkboxes */}
                <div className="col-md-10">
                  <div className="border-start ps-3">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2">
                    {[
  "Supermarket SMKT",
  "Wholesale Grocery Store WGS",
  "Frozen Foods Store FFS",
  "Restaurant REST",
  "Quick Service Restaurant QSR",
  "Cafes CAFE",
].map((type) => {
  const parts = type.split(" ");
  const abbreviation = parts.pop(); // Extracts the last word (abbreviation)
  const displayName = parts.join(" "); // Joins the remaining words as the display name

  return (
    <div className="col" key={abbreviation}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="shop_type"
          checked={AddStore.shop_type === abbreviation}
          onChange={() =>
            setAddStore({ ...AddStore, shop_type: abbreviation })
          }
        />
        <label className="form-check-label">{displayName}</label>
      </div>
    </div>
  );
})}


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 align-items-center">
              <label className="col-md-2 col-form-label fw-medium">
                GST Type <span className="text-danger">*</span>
              </label>
              <div className="col-md-10 border-start ps-4 d-flex align-items-center gap-3 ms-15">
                {["Services", "Goods"].map((type) => (
                  <div className="form-check" key={type}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="shop_gst_type"
                      checked={AddStore.shop_gst_type === type}
                      onChange={() =>
                        setAddStore({ ...AddStore, shop_gst_type: type })
                      }
                    />
                    <label className="form-check-label">{type}</label>
                  </div>
                ))}
              </div>
            </div>

           
          </div>
          {/* Scheduling */}
          <div className="p-3 bg-white rounded-lg my-3">
            <div>
              <p
                className="text-sm font-semibold mb-1 text-customDark font-semibold text-Dark
    "
              >
                Store Schedule
              </p>

              <p className="mb-2 text-sm text-secondHeading ">
                If store scheduling is enabled, it will override item
                scheduling.
              </p>
            </div>
            <div className="flex items-center gap-4">
              <label className="text-sm font-medium text-Dark">
                Days <span className="text-red-600">*</span>
              </label>
              <div className="flex items-center gap-6 border-l border-gray-200 pl-4">
                <div className="flex items-center gap-4">
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox" />
                    All Days
                  </label>
                  {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                    (day) => {
                      const selectedDays = AddStore.shop_working_days
                        ? AddStore.shop_working_days.split(",")
                        : [];

                      return (
                        <label key={day} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            checked={selectedDays.includes(day)} // ✅ Correctly checks the selected day
                            onChange={() => handleWorkingDaysChange(day)}
                          />
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </label>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <label className="text-sm font-medium text-Dark">
              Timings <span className="text-danger mt-2">*</span>
            </label>
            <div className="row g-3 mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Opening Time<span className="text-danger">*</span>
                </label>
                <input
                  type="time"
                  className="date-label-input tasKCategory1"
                  value={AddStore.shop_opening_time}
                  onChange={(e) =>
                    setAddStore({
                      ...AddStore,
                      shop_opening_time: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  Closing Time<span className="text-danger">*</span>
                </label>
                <input
                  type="time"
                  className="date-label-input tasKCategory1"
                  value={AddStore.shop_closing_time}
                  onChange={(e) =>
                    setAddStore({
                      ...AddStore,
                      shop_closing_time: e.target.value,
                    })
                  }
                />

                
              </div>
              <div className="col-md-3">
              <label className="form-label">
                Image<span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  className="date-label-input tasKCategory1"
                  onChange={handleFileChange}
                />

                <img
                  // src={AddStore.shop_images.startsWith("http") ? AddStore.shop_images : `http://192.168.1.22:3017${AddStore.shop_images}`}
                  // src={
                  //   !AddStore.shop_images
                  //     ? ""
                  //     : AddStore.shop_images.length
                  //     ? AddStore.shop_images
                  //     : AddStore.shop_images &&
                  //       URL.createObjectURL(AddStore.shop_images)
                  // }
                   src={AddStore.shop_images}
                  // onError={(ev) => {
                  //   handleErrorImage(ev);
                  // }}
                  alt="Store Preview"
                  className="preview-image"
                  style={{ width: "100px", height: "100px", objectFit: "cover", marginLeft: "10px" }}
                  onError={(e) => (e.target.style.display = "none")} // Hide image if not found
                />
              </div>
            </div>
            </div>
          
          </div>

          <div className="p-3 bg-white rounded-lg">
            <label className="text-sm font-medium text-Dark">
              Store contact Details<span className="text-danger mt-2">*</span>
            </label>
            <div className="row g-3 mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Contact Info <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  placeholder="Enter contact no.."
                  value={AddStore.shop_contact}
                  onChange={(e) =>
                    setAddStore({ ...AddStore, shop_contact: e.target.value })
                  }
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  Latitude<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  placeholder="Enter Latitude .."
                  value={AddStore.shop_latitude}
                  disabled={true}
                  onChange={(e) =>
                    setAddStore({ ...AddStore, shop_latitude: e.target.value })
                  }
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">
                  Longitude <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  placeholder="Enter Longitude.."
                  value={AddStore.shop_longitude}
                  disabled={true}
                  onChange={(e) =>
                    setAddStore({ ...AddStore, shop_longitude: e.target.value })
                  }
                />
              </div>
            </div>

            <label className="text-sm font-medium text-Dark">
            Address<span className="text-danger mt-2">*</span>
            </label>
            <div className="row g-3 mb-4">
             
              <MapComponent AddStore={AddStore} setAddStore={setAddStore} />
            </div>
          </div>
          <div className="p-6 bg-white rounded-lg my-3">
            {/* Description */}
    
            {/* Inventory */}

        
          </div>
    

          {unsavedOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-[500px]">
                {/* Close Button */}
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <img src={"/images/save.svg"} alt="Button Icon" />
                    <h2 style={{ fontSize: "18px", fontWeight: "600" }}>
                      Unsaved changes
                    </h2>
                  </div>
                  {/* <button onClick={() => setDeleteOpen(false)} className=""> */}
                  <img
                    onClick={() => setUnsavedOpen(false)}
                    src={"/images/x-close.svg"}
                    alt="Button Icon"
                    width={20}
                    height={20}
                  />
                  {/* </button> */}
                </div>

                {/* Message */}
                <p className="text-gray-600 mt-2" style={{ fontSize: "14px" }}>
                  Do you want to save or discard changes?
                </p>

                {/* Checkbox */}
                <div className="d-flex justify-content-between">
                  <div className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="dontShow"
                      checked={dontShowAgain}
                      onChange={() => setDontShowAgain(!dontShowAgain)}
                      className="me-2"
                    />
                    <label
                      htmlFor="dontShow"
                      className=""
                      style={{ fontSize: "14px" }}
                    >
                      Don’t show again
                    </label>
                  </div>

                  {/* Buttons */}
                  <div className="flex justify-end gap-3 mt-4">
                    <button
                      onClick={() => setUnsavedOpen(false)}
                      className="btn-cancel px-4 py-2 rounded-md text-gray-700 hover:bg-gray-100"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        alert("Store Deleted");
                        setUnsavedOpen(false);
                      }}
                      className="btn btn-primary-tax px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Store Created Modal */}
          {/* {createdOpen && (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Overlay background
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 9999, // 🔥 Ensure it's above everything
    }}
  >
    <div
      style={{
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
        width: "400px",
        maxWidth: "90%",
        zIndex: 10000, // 🔥 Modal itself should be higher
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src="/images/Done.svg" alt="Done Icon" />
        <img
          src="/images/x-close.svg"
          alt="Close Icon"
          style={{ cursor: "pointer" }}
          onClick={() => setCreatedOpen(false)}
        />
      </div>
      <p style={{ fontSize: "18px", fontWeight: 600, textAlign: "left" }}>
        Store Created
      </p>
      <p style={{ fontSize: "14px", marginTop: "0px", textAlign: "left" }}>
        This store has been created by an admin. Changes can only be made by
        <br /> the admin and republished as needed.
      </p>
      <button
        style={{
          width: "100%",
          marginTop: "10px",
          backgroundColor: "#007bff", // Primary button color
          color: "white",
          padding: "10px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => setCreatedOpen(false)}
      >
        Done
      </button>
    </div>
  </div>
)} */}

          {/* Delete Store Modal */}
          {deleteOpen && (
            <div>
              <button
                onClick={() => setDeleteOpen(true)}
                className="bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700"
              >
                Delete Store
              </button>

              {deleteOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
                    {/* Close Button */}
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <img src="/images/Deletered.svg" alt="Delete Icon" />
                        <h2 style={{ fontSize: "18px", fontWeight: "600" }}>
                          Delete Store
                        </h2>
                      </div>
                      {/* <button onClick={() => setDeleteOpen(false)} className=""> */}
                      <img
                        onClick={() => setDeleteOpen(false)}
                        src={"/images/x-close.svg"}
                        alt="Button Icon"
                        width={20}
                        height={20}
                      />
                      {/* </button> */}
                    </div>

                    {/* Message */}
                    <p
                      className="text-gray-600 mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Are you sure you want to delete this store? This action
                      cannot be undone.
                    </p>

                    {/* Checkbox */}
                    <div className="d-flex justify-content-between">
                      <div className="flex items-center mt-3">
                        <input
                          type="checkbox"
                          id="dontShow"
                          checked={dontShowAgain}
                          onChange={() => setDontShowAgain(!dontShowAgain)}
                          className="me-2"
                        />
                        <label htmlFor="dontShow" className="text-gray-600">
                          Don’t show again
                        </label>
                      </div>

                      {/* Buttons */}
                      <div className="flex justify-end gap-3 mt-4">
                        <button
                          onClick={() => setDeleteOpen(false)}
                          className="btn-cancel px-4 py-2 rounded-md text-gray-700 hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            alert("Store Deleted");
                            setDeleteOpen(false);
                          }}
                          className="btn btn-primary px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="flex justify-content-end gap-4 mt-4 m">
            {id ? (
              <>
                <button
                  // onClick={() => setDeleteOpen(true)}
                  onClick={() => handleDeletetStore(id)}
                  className=" col-md-2 bg-danger text-white px-9 py-2 rounded hover:bg-blue-600 bg-PrimaryButton border-none widthhalf"
                >
                  Delete Store
                </button>
              </>
            ) : (
              <></>
            )}

            <button
              onClick={() => {
                if (id) {
                  handleUpdateStore(id); // Update store if StoreID exists
                } else {
                  handleAddStore(); // Add new store if StoreID is empty
                }
              }}
              className="col-md-2 bg-blue-500 text-white px-9 py-2 rounded hover:bg-blue-600 bg-PrimaryButton border-none widthhalf"
            >
              {id ? " Update Save Changes" : "Add New Store"}
            </button>
            <button
              onClick={() => setCreatedOpen(true)}
              className=" col-md-1 bg-gray-300 text-white px-6 py-2 rounded hover:bg-gray-400 bg-PrimaryButton border-none  widthhalf cancel-bg"
            >
              Cancel
            </button>
          </div>
          <div className="p-2"></div>
        </form>
      </div>
    </div>
    </div>
    </div>
  );
};

export default AddNewStore;
