// import Header from "@/components/Header/Header";
// import Image from "next/image";
import React, { useContext, useEffect, useState } from "react";

import { Accordion, Card, Form, AccordionToggle, AccordionCollapse } from 'react-bootstrap';
import Select from "react-select";
// import { FaTimes } from 'react-icons/fa';
import { AppContext } from "../../../../context/AppContext";
import { postMultipartWithAuthCallINT, putMultipartWithAuthCall, simpleGetCall, simpleGetCallWithErrorResponse } from "../../../../api/ApiServices";
import ApiConfig from "../../../../api/ApiConfig";
import { set, values } from "lodash";
import { ToastMsg } from "../../../TostMsg";
import { useNavigate, useParams } from "react-router-dom";
import { handlwValidationRequired, makeSelectOptions } from "../../../../common";

const stockList = [
  { value: 1, label: "in_stock" },
  { value: 2, label: "do not track" },
  { value: 0, label: " out of stock" },

]

const AddItems = () => {
  const { id } = useParams()
  const navigetion = useNavigate()
  const [fileName, setFileName] = useState("");
  const [isVariationChecked, setIsVariationChecked] = useState(false);
  const [isConsiderAddOn, setisConsiderAddOn] = useState(false)
  const [isAllowAddon, setisAllowAddon] = useState(true)
  const [isAddMultypleVariants, setIsaddMultypleVariant] = useState(false)
  const [isAddMultipleVariants, setIsAddMultipleVariants] = useState(false);
  const [variantList, setVariantList] = useState([])
  const [isValided, setIsValided] = useState(false)

  const [isAddon, setIsAddon] = useState(false)
  const { sidebar, selectedStoreID, setActiveTab } = useContext(AppContext);
  const [categoryList, SetCategoryList] = useState([])
  const [unitList, setUnistList] = useState([])
  const [addonList, setAddonList] = useState([])
  const [hsnList, setHsnList] = useState([])
  const [addItem, setAddItem] = useState({
    item_name: '',
    item_price: '',
    item_description: '',
    item_rank: '0',
    item_allow_variation: '0',
    item_category_id: '',
    item_order_type: '',
    short_code: '',
    gst_type: '',
    item_favourite: '0',
    ignore_taxes: '0',
    ignore_discount: '0',
    item_attribute_id: "",
    nutrition: "",
    sap_code: "",
    hsn_code: "",
    item_schedule: [
      {
        schedule_day: "",
        schedule_time_slots: [
          {
            start_time: "",
            end_time: ""
          }
        ]
      }
    ],
    is_new: '0',
    is_mrp_item: '0',
    is_combo: '0',
    ignore_packaging_charges: '0',
    shop_id: "",
    size: '',
    unit_of_measurement: '',
    quantity: "",
    variations: [
      {
        item_variation_id: '',
        variation_name: '',
        variation_price: '',
        variation_rank: '',
        variation_active: '',
        variation_group_name: '',
        quantity: '',
        variation_allow_addons: "0",
        sku_code: '',
        unit_of_measurement: "",
        size: '',
        addons: [],

      },
    ],
    product_image: '',
    item_allow_addon: '0',
    in_stock: "",
    addons: [],
    master_tax_id: ''
  });




  // Add a new variation
  const handleAddVariation = () => {
    setAddItem((prevState) => ({
      ...prevState,
      variations: [
        ...prevState.variations,
        {
          item_variation_id: "",
          variation_name: "",
          variation_price: "",
          variation_rank: "",
          variation_active: "",
          variation_group_name: "",
          quantity: "",
          variation_allow_addons: "0",
          sku_code: "",
          addons: [],
        },
      ],
    }));
    setIsAddMultipleVariants(true);
  };

  const handleRemoveVariation = (index) => {
    setAddItem((prevState) => ({
      ...prevState,
      variations: prevState.variations.filter((_, i) => i !== index),
    }));
  };


  // Handle input changes
  // const handleVariationChange = (index, field, value) => {
  //   const updatedVariations = addItem.variations.map((variation, i) =>
  //     i === index ? { ...variation, [field]: value } : variation
  //   );

  //   setAddItem((prevState) => ({
  //     ...prevState,
  //     variations: updatedVariations,
  //   }));
  // };
  // const handleVariationChange = (index, field, value) => {
  //   const updatedVariations = addItem.variations.map((variation, i) =>
  //     i === index
  //       ? {
  //         ...variation,
  //         ...(field === "item_variation_id"
  //           ? {
  //             item_variation_id: value.id, // Set selected ID
  //             variation_name: value.variation_name,
  //             variation_group_name: value.variation_group_name,
  //             variation_rank: value.variation_rank,
  //             variation_active: value.variation_status ? 1 : 0, // Convert boolean to 1/0
  //           }
  //           : { [field]: value }) // Update other fields normally
  //       }
  //       : variation
  //   );

  //   setAddItem((prevState) => ({
  //     ...prevState,
  //     variations: updatedVariations,
  //   }));
  // };

  const handleVariationChange = (index, field, value) => {
    console.log("Variations>>>>>", field, value)
    setAddItem((prevState) => {
      const updatedVariations = prevState.variations.map((variation, i) =>
        i === index
          ? {
            ...variation,
            ...(field === "item_variation_id"
              ? {
                item_variation_id: value.id,
                variation_name: value.variation_name,
                variation_group_name: value.variation_group_name,
                variation_rank: value.variation_rank,
                variation_active: value.variation_status ? 1 : 0,
              }
              : { [field]: value } // Update other fields normally
            ),
          }
          : variation
      );

      // If updating price, size, quantity, or unit_of_measure in variations, set main item fields to null
      const resetFields = ["item_price", "size", "quantity", "unit_of_measurement"];
      const shouldReset = resetFields.includes(field);

      return {
        ...prevState,
        variations: updatedVariations,
        ...(shouldReset ? { [field.replace("variation_", "")]: null } : {}), // Reset main field
      };
    });
  };

  console.log("Add Item>>>>", addItem)
  // Function to handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setAddItem({
        ...addItem,
        product_image: selectedFile
      }); // Set the file name in state
    }
  };

  const handleTimeChange = (e, timeSlotIndex, timeType) => {
    const updatedTime = e.target.value;

    setAddItem({
      ...addItem,
      item_schedule: addItem.item_schedule.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            schedule_time_slots: item.schedule_time_slots.map((timeSlot, slotIndex) => {
              if (slotIndex === timeSlotIndex) {
                if (timeType === 'start_time') {
                  return { ...timeSlot, start_time: updatedTime };
                } else if (timeType === 'end_time') {
                  return { ...timeSlot, end_time: updatedTime };
                }
              }
              return timeSlot;
            }),
          };
        }
        return item;
      }),
    });
  };


  // const toggleAddonList = (index) => {
  //   setAddItem((prev) => {
  //     const updatedVariations = prev.variations.map((variation, i) =>
  //       i === index
  //         ? {
  //           ...variation,
  //           variation_allow_addons: !variation.variation_allow_addons ? "1" : "2", // Toggle the value
  //           addons: !variation.variation_allow_addons ? variation.addons : [], // Clear addons when disabling
  //         }
  //         : variation
  //     );
  //     console.log("updatedVariations>>>>>", updatedVariations);
  //     return { ...prev, variations: updatedVariations };
  //   });
  // };

  const toggleAddonList = (index) => {
    setAddItem((prev) => {
      const updatedVariations = prev.variations.map((variation, i) =>
        i === index
          ? {
            ...variation,
            variation_allow_addons: variation.variation_allow_addons ? "1" : "0", // Toggle between "1" and "0"
            addons: variation.variation_allow_addons === "1" ? [] : variation.addons, // Clear addons only when disabling
          }
          : variation
      );
      console.log("Updated Variations >>>>>", updatedVariations);
      return { ...prev, variations: updatedVariations };
    });
  };

  const handleCheckAddon = (addon) => {
    setAddItem((prev) => {
      const isAlreadySelected = prev.addons.some(
        (a) => a.addon_group_id === addon.id
      );

      return {
        ...prev,
        addons: isAlreadySelected
          ? prev.addons.filter((a) => a.addon_group_id !== addon.id) // Remove if already selected
          : [
            ...prev.addons,
            {
              addon_group_id: addon.id,
              addon_item_selection_min: addon.addon_item_min_selection,
              addon_item_selection_max: addon.addon_item_max_selection,
            },
          ], // Add if not selected
      };
    });
  };

  const handleAddonSelection = (variationIndex, group) => {
    setAddItem((prev) => {
      const updatedVariations = prev.variations.map((variation, i) => {
        if (i === variationIndex) {
          const isAlreadySelected = variation.addons.some(
            (addon) => addon.addon_group_id === group.id
          );

          return {
            ...variation,
            addons: isAlreadySelected
              ? variation.addons.filter(
                (addon) => addon.addon_group_id !== group.id
              )
              : [
                ...variation.addons,
                {
                  id: group?.id,
                  addon_group_id: group.id,
                  addon_item_selection_min: group.addon_item_min_selection, // Use actual min selection
                  addon_item_selection_max: group.addon_item_max_selection, // Use actual max selection
                },
              ],
          };
        }
        return variation;
      });

      return { ...prev, variations: updatedVariations };
    });
  };

  const handleCheckboxChange = (e) => {
    // setIsVariationChecked(e.target.checked);
    const allVariation = e.target.checked ? "1" : "0"
    setAddItem({
      ...addItem,
      item_allow_variation: allVariation
    })
  };



  const slectCategoryId = (select) => {
    const selectId = select.value
    console.log("category Id>>>>>", select)
    setAddItem({
      ...addItem,
      item_category_id: selectId
    })
  }

  const selectUnitId = (select) => {
    const unitId = select.value
    console.log("unitId Id>>>>>", unitId)

    setAddItem({
      ...addItem,
      unit_of_measurement: unitId
    })

  }

  const fetchCategoryList = async () => {
    // setLoading(true)

    try {
      const response = await simpleGetCall(`${ApiConfig?.GET_CATEGORY_LIST_DATA}${selectedStoreID}`);
      if (response.success === true) {
        // setLoading(false)
        const category = response?.data.map((item) => ({
          value: item?.id,
          label: item?.category_name
        }))
        console.log("categoryList>>>>", category);

        SetCategoryList(category)
      } else {
        console.log("CateGory Error>>>>>");

        // setLoading(false)
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };
  const fetchUnitList = async () => {
    // setLoading(true)

    try {
      const response = await simpleGetCall(`${ApiConfig?.UNIT_MEASUREMNET}`);
      if (response.success === true) {
        // setLoading(false)

        const unit = response?.data.map((unit) => ({
          value: unit?.id,
          label: unit?.unit_of_measurement,

        }))

        setUnistList(unit)
      } else {
        console.log("unit Error>>>>>");

        // setLoading(false)
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };



  const fetchAddonList = async () => {
    // setLoading(true)

    try {
      const response = await simpleGetCall(`${ApiConfig?.GET_ADDON + selectedStoreID}`);
      if (response.success === true) {
        // setLoading(false)
        const unit = response?.data
        console.log("addOn>>>>", unit);

        setAddonList(unit)
      } else {
        console.log("unit Error>>>>>");

        // setLoading(false)
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };

  const getVariantList = () => {
    // setLoading(true)
    simpleGetCall(ApiConfig.GET_VARIATION_LIST + selectedStoreID)
      .then((res) => {
        // const variant = res?.data
        setVariantList(res?.data)
        // setLoading(false)
        console.log("GET_VARIATION_LIST>>>>", res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getItemDetails = (itemId) => {
    simpleGetCall(ApiConfig?.GET_ITEM_LIST + itemId)
      .then((res) => {
        if (res?.data) {
          console.log("Item Details>>>>>", res);

          const item_schedule = JSON.parse(res?.data?.item_schedule || "[]");
          setAddItem({
            item_name: res.data.item_name || '',
            item_price: res.data.item_price || '',
            item_description: res.data.item_description || '',
            item_rank: res.data.item_rank?.toString() || '0',
            item_allow_variation: res.data.item_allow_variation?.toString() || '0',
            item_category_id: res.data.item_category_id || '',
            item_order_type: res.data.item_order_type || '',
            short_code: res.data.short_code || '',
            gst_type: res.data.gst_type || '',
            item_favourite: res.data.item_favourite?.toString() || '0',
            ignore_taxes: res.data.ignore_taxes?.toString() || '0',
            ignore_discount: res.data.ignore_discount?.toString() || '0',
            item_attribute_id: res.data.item_attribute_id || '',
            nutrition: res.data.nutrition || '',
            sap_code: res.data.sap_code || '',
            hsn_code: res.data.hsn_code || '',
            is_new: res.data.is_new ? "1" : "0",
            is_mrp_item: res.data.is_mrp_item ? "1" : "0",
            is_combo: res.data.is_combo ? "1" : "0",
            ignore_packaging_charges: res.data.ignore_packaging_charges ? "1" : "0",
            shop_id: res.data.restaurant_id || '',
            size: Number(res.data.size) || '',
            unit_of_measurement: res.data.unit_of_measurement_id || '',
            quantity: res.data.quantity?.toString() || "",
            in_stock: res.data.in_stock?.toString() || "",
            item_allow_addon: res.data.item_allow_addon?.toString() || "0",
            product_image: res.data.item_image_url || '',

            // Parse `item_schedule` if available
            item_schedule: item_schedule.map(timing => ({
              schedule_day: timing?.schedule_day || "[]",
              schedule_time_slots: timing?.schedule_time_slots.map(slot => ({
                start_time: slot?.start_time || "",
                end_time: slot?.end_time || ""
              })) || " []"
            })),
            // Handle variations (ensure it's an array)
            variations: Array.isArray(res.data.variations)
              ? res.data.variations?.map((variation) => ({
                id: variation?.id,
                item_variation_id: variation?.item_variation_id || '',
                variation_name: variation?.variation_name || '',
                variation_price: variation?.variation_price || '',
                variation_rank: variation?.variation_rank || '',
                variation_active: variation?.variation_active ? "1" : "0",
                variation_group_name: variation?.variation_group_name || '',
                quantity: variation?.quantity?.toString() || '',
                variation_allow_addons: variation?.variation_allow_addons,
                sku_code: variation?.sku_code || '',
                size: variation?.size || '',
                unit_of_measurement: variation?.unit_of_measurement_id || '',
                addons: variation?.addons || [],
              }))
              : [],

            // Handle addons (ensure it's an array)
            addons: Array.isArray(res.data.addons) ? res.data.addons : [],
          });

          if (addItem.variations[0]?.variation_allow_addons === "1") {
            setisConsiderAddOn(true)
          }
        }
        setIsAddMultipleVariants(true)
      })
      .catch((err) => {
        console.log("Item Details Error>>>>", err);
      });
  };

  const getTax = () => {

    if (hsnList.length) return

    simpleGetCallWithErrorResponse(ApiConfig?.GET_HSN_GST).then((res) => {


      if (res?.json?.success) {
        setHsnList(makeSelectOptions(res?.json?.data, 'description', 'chapter_heading'))

      }

    })

  }

  useEffect(() => {
    getTax()
    getVariantList()
    fetchCategoryList()
    fetchUnitList()
    fetchAddonList()

    setAddItem({
      ...addItem,
      shop_id: selectedStoreID
    })
    if (id) {
      getItemDetails(id)
    }
  }, [selectedStoreID])



  const createFormData = (addItem) => {
    const formdata = new FormData();
    console.log("formdata>>>>>", formdata)

    // Add simple fields
    Object.entries(addItem).forEach(([key, value]) => {
      if (key !== "variations" && key !== "addons" && key !== "item_schedule" && key !== "product_image") {
        formdata.append(key, value ?? ""); // Ensure empty values are handled
      }
    });

    // Add item_schedule (assuming single schedule structure)
    if (addItem.item_schedule) {
      formdata.append("item_schedule", JSON.stringify(addItem.item_schedule) ? JSON.stringify(addItem.item_schedule) : "");
    }

    // Add variations
    addItem.variations.forEach((variation, vIndex) => {
      Object.entries(variation).forEach(([key, value]) => {
        if (key !== "addons") {
          formdata.append(`variations[${vIndex}][${key}]`, value ?? "");
        }
      });

      // Add addons inside variations
      variation.addons.forEach((addon, aIndex) => {
        Object.entries(addon).forEach(([key, value]) => {
          formdata.append(`variations[${vIndex}][addons][${aIndex}][${key}]`, value ?? "");
        });
      });
    });

    // Add global addons
    addItem.addons.forEach((addon, aIndex) => {
      Object.entries(addon).forEach(([key, value]) => {
        formdata.append(`addons[${aIndex}][${key}]`, value ?? "");
      });
    });

    // Add product image (if exists)
    if (addItem.product_image) {
      formdata.append("product_image", addItem.product_image);
    }

    return formdata;
  };

  // Usage:
  const onAddOrUpdateItems = (e) => {

    let isVald = true

    if (!handlwValidationRequired(addItem, ['item_schedule', 'item_allow_addon', 'addons', 'item_allow_variation', 'variations', 'ignore_packaging_charges', 'unit_of_measurement',
      'size', 'quantity'
    ], setIsValided)) {

      isVald = false
    }

    if (!id && (!addItem?.item_schedule[0]?.schedule_day ||
      !addItem?.item_schedule[0]?.schedule_time_slots[0]?.start_time ||
      !addItem?.item_schedule[0]?.schedule_time_slots[0]?.end_time)
    ) {
      isVald = false

      setIsValided(true)
    }

    if (addItem?.item_allow_addon == 1 && !addItem?.addons?.length) {

      isVald = false
      setIsValided(true)
    }

    if (addItem?.item_allow_variation == 1) {



      addItem?.variations?.forEach((i) => {

        if (!handlwValidationRequired(i, ['variation_allow_addons'], setIsValided)) {

          isVald = false
        }

      })


    }


    if (!isVald) return

    if (id) {
      putMultipartWithAuthCall(ApiConfig.ADD_UPDATE_ITEM + id, createFormData(addItem))
        .then((res) => {
          console.log("Add Item>>>>", res);
          if (res.success === true) {
            ToastMsg("success", res.message)
            navigetion("/BaseMenu")
            setActiveTab("Items")
            setAddItem({})
          } else {
            ToastMsg("errro", res.message)
          }
        })
        .catch((err) => {
          console.log("add Item Error>>>>>", err)
        })
    } else {

      postMultipartWithAuthCallINT(ApiConfig.ADD_UPDATE_ITEM, createFormData(addItem))
        .then((res) => {
          console.log("Add Item>>>>", res);
          if (res.success === true) {
            ToastMsg("success", res.message)
            navigetion("/BaseMenu")
            setAddItem({})
          } else {
            ToastMsg("errro", res.message)

          }
        })
        .catch((err) => {
          console.log("add Item Error>>>>>", err)
        })

    }

  }



  return (

    <div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Stock List</h6>
        </div>
        <div>
          {" "}
          {/* <Header /> */}

          <div className=" bg-white brounded-lg px-3 py-2 mt-2 rounded-3">
            <form>
              <div className=" mt-4 border border-gray-300 rounded-lg p-2">
                {/* First Row */}
                <div className="row g-3 mb-4">
                  <div className="col-md-4">
                    <label className="form-label">
                      Display Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name..."
                      value={addItem?.item_name}
                      onChange={(e) => setAddItem({
                        ...addItem,
                        item_name: e.target.value
                      })}
                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.item_name && "Please Enter Display Name"}</span>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Category <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={categoryList}
                      onChange={slectCategoryId}
                      value={categoryList?.filter((val) => val?.value == addItem?.item_category_id)}

                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.item_category_id && "Please Select Category"}</span>

                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Unit of Measure <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={unitList}
                      onChange={selectUnitId}
                      value={unitList?.filter((val) => val?.value == addItem?.unit_of_measurement)}
                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.unit_of_measurement && "Please Select Unit Of Measure"}</span>

                  </div>
                </div>

                {/* Second Row */}
                <div className="row g-3 mb-4">
                  <div className="col-md-4">
                    <label className="form-label">
                      Price<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      min="0"
                      value={addItem.item_price ?? ""}
                      onChange={(e) => setAddItem({
                        ...addItem,
                        item_price: e.target.value
                      })}
                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.item_price && "Please Enter Price"}</span>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Short Code<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Short Code"
                      value={addItem?.short_code}
                      onChange={(e) => setAddItem({
                        ...addItem,
                        short_code: e.target.value
                      })}
                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.short_code && "Please Enter Short Code"}</span>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Size<span className="text-danger">*</span>

                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      placeholder="Enter size..."
                      value={addItem?.size ?? ""}
                      onChange={(e) => setAddItem({
                        ...addItem,
                        size: Number(e.target.value) || ''
                      })}
                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.size && "Please Enter Size"}</span>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Quantity<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter size..."
                      value={addItem?.quantity ?? ''}
                      onChange={(e) => setAddItem({
                        ...addItem,
                        quantity: e.target.value
                      })}
                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.quantity && "Please Enter Quantity"}</span>
                  </div>
                </div>

                {/* Order Type */}
                <div className="row mb-4 align-items-center">
                  <label className="col-md-2 col-form-label font-family font-size-14  text-Dark">
                    Order Type <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-10 border-start ps-4">
                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        name="tax_order_type"
                        value="1"
                        checked={addItem?.item_order_type?.split(",").includes("1")}
                        onChange={(e) => {
                          const updatedTaxOrderType = e.target.checked
                            ? addItem.item_order_type
                              ? `${addItem.item_order_type},1`
                              : "1"
                            : addItem.item_order_type
                              .split(",")
                              .filter((item) => item !== "1")
                              .join(",");

                          setAddItem({
                            ...addItem,
                            item_order_type: updatedTaxOrderType,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="delivery">
                        Delivery
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        name="tax_order_type"
                        value="2"
                        checked={addItem?.item_order_type?.split(",").includes("2")}
                        onChange={(e) => {
                          const updatedTaxOrderType = e.target.checked
                            ? addItem.item_order_type
                              ? `${addItem.item_order_type},2`
                              : "1"
                            : addItem.item_order_type
                              .split(",")
                              .filter((item) => item !== "2")
                              .join(",");

                          setAddItem({
                            ...addItem,
                            item_order_type: updatedTaxOrderType,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="parcel">
                        Parcel
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        name="tax_order_type"
                        value="3"
                        checked={addItem?.item_order_type?.split(",").includes("3")}
                        onChange={(e) => {
                          const updatedTaxOrderType = e.target.checked
                            ? addItem.item_order_type
                              ? `${addItem.item_order_type},3`
                              : "1"
                            : addItem.item_order_type
                              .split(",")
                              .filter((item) => item !== "3")
                              .join(",");

                          setAddItem({
                            ...addItem,
                            item_order_type: updatedTaxOrderType,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="telephone">
                        Telephone Orders
                      </label>
                    </div>

                  </div>

                  <span className="text-danger pl-3">{isValided && !addItem?.item_order_type.length && "Please Select Atleast One"}</span>
                </div>

                <div className="row mb-4 align-items-center">
                  <label className="col-md-2 col-form-label font-family font-size-14 text-Dark">
                    Attribute Type<span className="text-danger">*</span>
                  </label>
                  <div className="col-md-10 border-start ps-4 d-flex align-items-center gap-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="item_attribute_id"
                        name="item_attribute_id"
                        checked={addItem?.item_attribute_id === "1"}
                        onChange={(e) => setAddItem({
                          ...addItem,
                          item_attribute_id: "1"
                        })}
                      />
                      <label className="form-check-label" htmlFor="delivery">
                        Veg
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="item_attribute_id"
                        name="item_attribute_id"
                        checked={addItem?.item_attribute_id === "2"}
                        onChange={(e) => setAddItem({
                          ...addItem,
                          item_attribute_id: "2"
                        })}
                      />
                      <label className="form-check-label" htmlFor="parcel">
                        Non Veg
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="item_attribute_id"
                        name="item_attribute_id"
                        checked={addItem?.item_attribute_id === "25"}
                        onChange={(e) => setAddItem({
                          ...addItem,
                          item_attribute_id: "25"
                        })}
                      />
                      <label className="form-check-label" htmlFor="parcel">
                        Egg
                      </label>
                    </div>
                  </div>

                  <span className="text-danger pl-3">{isValided && !addItem?.item_attribute_id && "Please Select Attribute Type"}</span>
                </div>
                <div className="row mb-4 align-items-center">
                  <label className="col-md-2 col-form-label font-family font-size-14 text-Dark">
                    GST Type<span className="text-danger">*</span>
                  </label>
                  <div className="col-md-10 border-start ps-4 d-flex align-items-center gap-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="delivery"
                        name="gstType"
                        checked={addItem?.gst_type === "services"}
                        onChange={(e) => setAddItem({
                          ...addItem,
                          gst_type: "services"
                        })}
                      />
                      <label className="form-check-label" htmlFor="delivery">
                        Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="parcel"
                        name="gstType"
                        checked={addItem?.gst_type === "goods"}
                        onChange={(e) => setAddItem({
                          ...addItem,
                          gst_type: "goods"
                        })}
                      />
                      <label className="form-check-label" htmlFor="parcel">
                        Goods
                      </label>
                    </div>
                  </div>
                  <span className="text-danger pl-3">{isValided && !addItem?.gst_type && "Please Select GST Type"}</span>
                </div>
                <div className="mb-4 mt-4">
                  <label className="block mb-2 text-sm font-medium text-Dark">
                    Description
                  </label>
                  <textarea
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="Description"
                    value={addItem?.item_description}
                    onChange={(e) => setAddItem({
                      ...addItem,
                      item_description: e.target.value
                    })}
                  ></textarea>

                  <span className="text-danger ">{isValided && !addItem?.item_description && "Please Enter Description"}</span>
                </div>

                <div className="col-md-4">
                  <label className="form-label cat-lab">Image</label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="customFile"
                      style={{ display: "none" }}
                      onChange={handleFileChange}

                    />

                    <div id="fileName" className="mt-1 ms-2 flex-1">
                      {/* {addItem?.product_image.name || "No file selected"}{" "}
                       */}
                      {addItem?.product_image?.name ?
                        addItem?.product_image?.name :
                        addItem?.product_image?.substring(addItem?.product_image.indexOf("uploads/")) || "No file selected"}
                      {/* Display file name or a default text */}
                    </div>
                    <label
                      style={{ backgroundColor: "#53B7E8", color: "#fff" }}
                      className="input-group-text btn"
                      htmlFor="customFile"
                    >
                      Choose File
                    </label>
                  </div>
                  <span className="text-danger ">{isValided && !addItem?.product_image && "Please Enter Description"}</span> <br />
                  <label className="tax-lab text-head text-muted small">
                    Upload only png, jpeg, or jpg file
                  </label>

                </div>
              </div>
              <div className=" mt-4 border border-gray-300 rounded-lg p-2">
                <div>
                  <p
                    className="text-m font-semibold mb-1 text-customDark font-semibold text-Dark
    "
                  >
                    Item Schedule
                  </p>

                  <p className="text-gray-600 mb-2 text-sm text-secondHeading text-Dark">
                    If category scheduling is enabled, it will override item
                    scheduling. This feature is currently supported only for offline
                    POS billing.
                  </p>
                </div>
                <>
                  <div className="flex items-center gap-4">
                    <label className="text-sm font-medium text-Dark">
                      Days <span className="text-red-600">*</span>
                    </label>
                    <div className="flex items-center gap-6 border-l border-gray-200 pl-4">
                      <div className="flex items-center gap-4">
                        <label className="flex items-center gap-2 text-Dark">
                          <input
                            type="checkbox"
                            id="everyDay"
                            // checked={addItem}
                            onChange={(e) => {
                              // setEveryDayChecked(!everyDayChecked)
                              const isChecked = e.target.checked;

                              // If "Every Day" is selected, set all days; otherwise, clear it
                              const updatedScheduleDay = isChecked ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].join(",") : "";

                              setAddItem({
                                ...addItem,
                                item_schedule: addItem.item_schedule?.map((item, index) =>
                                  index === 0 ? { ...item, schedule_day: updatedScheduleDay } : item
                                )
                              });
                            }}
                          />
                          All Days
                        </label>
                        <div className="d-flex flex-wrap gap-2">
                          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]?.map((day) => (
                            <div className="form-check" key={day}>
                              <input
                                type="checkbox"
                                checked={addItem?.item_schedule[0]?.schedule_day.includes(day)}

                                // onChange={(e) => {
                                //   const isChecked = e.target.checked;
                                //   const updatedScheduleDay = isChecked
                                //     ? [...addItem.item_schedule[0]?.schedule_day, day] // Add day
                                //     : addItem.item_schedule[0]?.schedule_day.filter(d => d !== day); // Remove day if unchecked

                                //   setAddItem({
                                //     ...addItem,
                                //     item_schedule: addItem.item_schedule?.map((item, index) =>
                                //       index === 0 ? { ...item, schedule_day: updatedScheduleDay } : item
                                //     )
                                //   });
                                // }}
                                // id={day.toLowerCase()} 
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  const currentSchedule = addItem?.item_schedule?.[0]?.schedule_day ?? [];

                                  const updatedScheduleDay = isChecked
                                    ? [...currentSchedule, day] // Add day
                                    : currentSchedule.filter((d) => d !== day); // Remove day if unchecked

                                  setAddItem((prevState) => ({
                                    ...prevState,
                                    item_schedule: prevState.item_schedule
                                      ? prevState.item_schedule.map((item, index) =>
                                        index === 0 ? { ...item, schedule_day: updatedScheduleDay } : item
                                      )
                                      : [{ schedule_day: updatedScheduleDay }] // Ensure `item_schedule` exists
                                  }));
                                }}
                                id={day.toLowerCase()}
                              />
                              <label className="form-check-label" htmlFor={day.toLowerCase()}>
                                {day}
                              </label>
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                    <br />
                  </div>
                  <span className="text-danger pl-1">{isValided && !addItem?.item_schedule[0]?.schedule_day && "Please Select Atleast One"}</span>
                  <br />
                </>
                <label className="text-sm font-medium text-Dark">
                  Timings <span className="text-danger mt-2">*</span>
                </label>
                <div className="row g-3 mb-4">
                  <div className="col-md-3">
                    <label className="form-label">
                      To <span className="text-danger">*</span>
                    </label>
                    <input
                      value={addItem?.item_schedule[0]?.schedule_time_slots[0]?.start_time}

                      type="time"
                      className="form-control"
                      placeholder="Enter name..."
                      onChange={(e) => handleTimeChange(e, 0, 'start_time')}

                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.item_schedule[0]?.schedule_time_slots[0]?.start_time && "Please Enter Start Time"}</span>
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">
                      From<span className="text-danger">*</span>
                    </label>
                    <input
                      value={addItem?.item_schedule[0]?.schedule_time_slots[0]?.end_time}

                      type="time"
                      className="form-control"
                      placeholder="Enter name..."
                      onChange={(e) => handleTimeChange(e, 0, 'end_time')}

                    />
                    <span className="text-danger pl-1">{isValided && !addItem?.item_schedule[0]?.schedule_time_slots[0]?.end_time && "Please Enter End Time"}</span>
                  </div>
                </div>
              </div>
              {/* Tags Information */}
              <div className="mb-4 mt-4 border rounded-lg p-2">
                <label className="block mb-2 text-sm font-medium mt-2 text-Dark">
                  Tags Information
                </label>
                <div className="flex flex-wrap gap-4 col-md-6">
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox"
                      checked={addItem?.is_mrp_item === "1"}
                      onChange={(e) => setAddItem({
                        ...addItem,
                        is_mrp_item: e.target.checked ? "1" : "0"
                      })}

                    />
                    MRP-Item
                  </label>
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox"
                      checked={addItem?.ignore_discount === "1"}

                      onChange={(e) => setAddItem({
                        ...addItem,
                        ignore_discount: e.target.checked ? "1" : "0"
                      })}
                    />
                    Ignore Discount
                  </label>
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox"
                      checked={addItem?.ignore_taxes === "1"}

                      onChange={(e) => setAddItem({
                        ...addItem,
                        ignore_taxes: e.target.checked ? "1" : "0"
                      })}
                    />
                    Ignore Taxes
                  </label>
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox"
                      checked={addItem?.item_favourite === "1"}

                      onChange={(e) => setAddItem({
                        ...addItem,
                        item_favourite: e.target.checked ? "1" : "0"
                      })}
                    />
                    Set as Favorite
                  </label>
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox"
                      checked={addItem?.is_new === "1"}

                      onChange={(e) => setAddItem({
                        ...addItem,
                        is_new: e.target.checked ? "1" : "0"
                      })}
                    />
                    New
                  </label>
                  <label className="flex items-center gap-2 text-Dark">
                    <input type="checkbox"
                      checked={addItem?.ignore_packaging_charges === "1"}

                      onChange={(e) => setAddItem({
                        ...addItem,
                        ignore_packaging_charges: e.target.checked ? "1" : "0"
                      })}
                    />
                    Ignore packaging Charge( Online )
                  </label>

                </div>
                <span className="text-danger pl-1">{isValided && !addItem?.item_schedule[0]?.schedule_time_slots[0]?.end_time && "Please Select Atleast One"}</span>

              </div>
              <div className="mb-4 mt-4">
                <label className="block mb-2 text-sm font-medium mt-2 text-Dark">
                  Variation/Addon
                </label>

                <div
                  className="flex flex-wrap gap-4"
                  style={{
                    background: "#3B82F61A",
                    borderRadius: "6px",
                    padding: "5px",
                  }}
                >
                  <Form.Check
                    disabled={addItem?.item_allow_variation === "1"}
                    type="checkbox"
                    label="Addon Group"
                    checked={addItem?.item_allow_addon === "1"}
                    onChange={(e) => setAddItem({
                      ...addItem,
                      item_allow_addon: e.target.checked ? "1" : "0"
                    })}
                  />
                </div>
                {
                  addItem?.item_allow_addon === "1" && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">

                        <Accordion.Body>
                          <>
                            <div className="p-4">
                              <p
                                className="text-m font-semibold mb-4 text-customDark font-semibold text-Dark
    "
                              >
                                Addon Group  <span className="text-danger">*</span>
                              </p>
                              <div className="row">
                                {
                                  addonList?.map((item, index) => (
                                    <div className="col-6" key={index}>
                                      <div className="flex flex-wrap gap-2">
                                        <input
                                          type="checkbox"
                                          id={`addon-${index}`}
                                          checked={addItem.addons.some((a) => a.addon_group_id === item.id)}
                                          onChange={() => handleCheckAddon(item)}
                                        />
                                        <label className="form-label" htmlFor={`addon-${index}`}>
                                          {item?.addon_group_name}
                                        </label>
                                      </div>
                                    </div>
                                  ))
                                }

                              </div>

                              <span className="text-danger pl-1">{isValided && !addItem?.addons?.length && "Please Select At Least One"}</span>
                            </div>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                  )
                }
                <div
                  className="flex flex-wrap gap-4"
                  style={{
                    background: "#3B82F61A",
                    borderRadius: "6px",
                    padding: "5px",
                    marginTop: "10px",
                  }}
                >

                  <Form.Check
                    disabled={addItem?.item_allow_addon === "1"}
                    checked={addItem?.item_allow_variation === "1"}
                    type="checkbox"
                    label="Variation"
                    onChange={handleCheckboxChange}
                  />
                </div>


                {addItem?.item_allow_variation === "1" && (
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">

                      <Accordion.Body>
                        <div className="d-flex ">
                          <div className="" style={{ marginRight: "350px" }}>
                            <button className="btn btn-primary mb-3" type="button" onClick={handleAddVariation}>
                              Add Variation
                            </button>
                          </div>

                          <div className="">

                            <label className="flex items-center gap-2 text-Dark"

                            >
                              <input
                                type="checkbox"
                                className="mt-1"
                                checked={isConsiderAddOn}
                                onChange={(e) => setisConsiderAddOn(e.target.checked)}
                              />

                              Consider Addon on Item Variations
                            </label>
                          </div>
                        </div>
                        <div style={{ overflowX: "hidden" }}>
                          {isAddMultipleVariants &&
                            addItem.variations.map((variation, index) => (
                              <div key={index} className="mb-4 mt-2 border rounded-lg p-2" style={{ position: "relative" }}>
                                {/* Remove Button */}
                                <div
                                  className="close-icon"
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "10px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    color: "red",
                                  }}
                                  onClick={() => handleRemoveVariation(index)}
                                >
                                  ✖
                                </div>

                                <div className="row mt-5">
                                  <div className="col-4">
                                    <label className="form-label">
                                      Variation <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                      options={variantList?.map((item) => ({
                                        value: item?.id,
                                        label: item?.variation_name,
                                        ...item
                                      }))}
                                      onChange={(selectedOption) => handleVariationChange(index, "item_variation_id", selectedOption)}
                                      value={variantList
                                        ?.map((item) => ({ value: item?.id, label: item?.variation_name, ...item })) // Ensure the same format
                                        ?.find((val) => val.value == addItem?.variations[index]?.item_variation_id) // Correct filtering
                                      }
                                    />
                                    <span className="text-danger pl-1">{isValided && !variation?.variation_name && "Please Select Variation"}</span>
                                  </div>
                                  <div className="col-4">
                                    <label className="form-label">
                                      Price <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name..."
                                      onChange={(e) => handleVariationChange(index, "variation_price", e.target.value)}
                                      value={addItem?.variations[index].variation_price}

                                    />
                                    <span className="text-danger pl-1">{isValided && !variation?.variation_price && "Please Enter Variation Price"}</span>
                                  </div>
                                  <div className="col-4">
                                    <label className="form-label">
                                      Size <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name..."
                                      onChange={(e) => handleVariationChange(index, "size", e.target.value)}
                                      value={addItem?.variations[index].size}

                                    />
                                    <span className="text-danger pl-1">{isValided && !variation?.size && "Please Enter Variation Size"}</span>
                                  </div>
                                  <div className="col-4 mt-3">
                                    <label className="form-label">
                                      Quantity* <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter name..."
                                      onChange={(e) => handleVariationChange(index, "quantity", e.target.value)}
                                      value={addItem?.variations[index].quantity}

                                    />
                                    <span className="text-danger pl-1">{isValided && !variation?.quantity && "Please Enter variation Quantity"}</span>
                                  </div>
                                  <div className="col-4 mt-3">
                                    <label className="form-label">
                                      Unit of Measure <span className="text-danger">*</span>
                                    </label>
                                    <Select

                                      options={unitList}
                                      onChange={(selectedOption) => handleVariationChange(index, "unit_of_measurement", selectedOption.value)}
                                      value={variantList?.filter((val) => val?.value == addItem?.variations[index].unit_of_measurement)}

                                    />
                                    <span className="text-danger pl-1">{isValided && !variation?.unit_of_measurement && "Please Select Unit Measurement"}</span>
                                  </div>
                                  <div className="col-4 mt-3">
                                    <label className="form-label">
                                      SKU/Code <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name..."
                                      onChange={(e) => handleVariationChange(index, "sku_code", e.target.value)}
                                      value={addItem?.variations[index].sku_code}
                                    />
                                    <span className="text-danger pl-1">{isValided && !variation?.sku_code && "Please Enter SKU/Code"}</span>
                                  </div>


                                  <div className="col-4 mt-3">
                                    {
                                      isConsiderAddOn && (
                                        <>
                                          <div
                                            className="flex flex-wrap gap-2 p-2"

                                          >
                                            <input
                                              type="checkbox"
                                              checked={variation.variation_allow_addons}
                                              onClick={() => toggleAddonList(index)}
                                            />
                                            <label className="form-label">
                                              Allow Addon
                                            </label>
                                          </div>
                                        </>
                                      )
                                    }
                                  </div>

                                  {variation.variation_allow_addons === "1" && (
                                    <div className="p-4">
                                      <p className="text-m font-semibold mb-4 text-customDark">
                                        Select Addon Groups:
                                      </p>
                                      {addonList.map((group) => (
                                        <div key={group.id} className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={variation.addons.some(
                                              (addon) => addon.addon_group_id === group.id
                                            )}
                                            onChange={() => handleAddonSelection(index, group)} // Pass full object
                                          />
                                          <label className="form-check-label">{group.addon_group_name}</label>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>

              {/* Inventory */}
              <label className="block mb-2 text-sm font-medium mt-2 text-Dark">
                Inventory
              </label>
              <div className="row mb-4">
                <div className="col-md-4">
                  <label className="block mb-2 text-sm font-medium">HSN Code</label>
                  <Select
                    options={hsnList}
                    onChange={(e) => {

                      console.log(e);
                      setAddItem({
                        ...addItem,
                        hsn_code: e.value,
                        master_tax_id: e?.item?.id,
                      })
                    }
                    }
                    value={hsnList?.filter((val) => val?.value == addItem?.hsn_code)}

                  />
                  {/* <input
                    type="text"
                    value={addItem?.hsn_code}
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="HSN Code..."
                    onChange={(e) => setAddItem({
                      ...addItem,
                      hsn_code: e.target.value
                    })}
                  /> */}
                  <span className="text-danger">{isValided && !addItem?.hsn_code && "Please Enetr HSN Code "}</span>
                </div>
                <div className="col-md-4">
                  <label className="block mb-2 text-sm font-medium">SAP Code</label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded"
                    value={addItem?.sap_code}

                    placeholder="SAP Code..."
                    onChange={(e) => setAddItem({
                      ...addItem,
                      sap_code: e.target.value
                    })}
                  />
                  <span className="text-danger">{isValided && !addItem?.sap_code && "Please Enetr SAp Code "}</span>
                </div>
 <div className="col-md-4">
                <label className="block mb-2 text-sm font-medium text-Dark">
                  Stock Status
                </label>
                <select className="w-full p-2 border border-gray-300 rounded text-Dark"
                  value={addItem?.in_stock}

                  onChange={(e) => setAddItem({
                    ...addItem,
                    in_stock: e.target.value
                  })}
                >
                  <option value={''}>select Stock </option>
                  {
                    stockList.map((item) => (
                      <option value={item?.value}>{item?.label}</option>
                    ))
                  }

                </select>

                <span className="text-danger">{isValided && !addItem?.in_stock && "Please Select Stock Status"}</span>
              </div>

              </div>
             
              <div className="mb-4 mt-4">
                <label className="block mb-2 text-sm font-medium text-Dark ">
                  Nutrition
                </label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Description"
                  value={addItem?.nutrition}

                  onChange={(e) => setAddItem({
                    ...addItem,
                    nutrition: e.target.value
                  })}
                ></textarea>
                <span className="text-danger">{isValided && !addItem?.nutrition && "Please Enter Nutrition"}</span>
              </div>

              {/* Submit Button */}
              <div className="flex justify-content-end gap-4 mt-4 m">
                <button style={{ backgroundColor: "#53B7E8", color: "#fff" }} className=" col-md-3 text-white px-9 py-2 rounded bg- border-none widthhalf"
                  onClick={onAddOrUpdateItems}
                  type="button"
                >
                  {id ? "Update Item" : "Add Item"}
                </button>
                <button style={{ border: "1px solid #53B7E8", color: "#53B7E8" }} className=" col-md-2  px-6 py-2 rounded  border-none bg-white border  ">
                  Cancel
                </button>
              </div>
              <div className="p-2"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItems;
