"use client";
import React, { useEffect, useRef, useState } from "react";
import "../../pages/Inventory Management System/Stock/variation.css"

const Dropdown = ({ data, placeholder,onChange,selectedItem, setSelectedItem }) => {

  const dropdownRef = useRef(null);
  // const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  
  console.log("selectedItem",selectedItem);
  const [isOpen, setIsOpen] = useState(false); // Manage open state per dropdown

  // const filteredData = data.filter((item) =>
  //   item.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const filteredData = data.filter(
  //   (item) => item?.restaurant_name?.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredData = (data || []).filter((item) =>
    item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // const handleSelect = (item) => {
  //   setSelectedItem(item);
  //   setIsOpen(false); // Close dropdown after selection
  // };
  const handleSelect = (item) => {
    setSelectedItem(item.value);
    setIsOpen(false);
    if (onChange) {
      onChange(item.value); // Notify parent component
    }

  };

  return (
    <div className="relative z-3" ref={dropdownRef}>
      {/* Dropdown Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-button flex items-center justify-between bg-white border border-gray-300 py-2 rounded shadow-md hover:bg-gray-100 focus:outline-none"
        style={{ width: "100%", fontSize: "14px" }}
      >
        {selectedItem ? selectedItem : placeholder}
        <img
          src={"/images/Drop.svg"}
          alt="Logo"
          width={21}
          height={21}
          className=""
        />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className="absolute z-10 mt-2 w-64 bg-white border border-gray-300 rounded shadow-lg"
          style={{ position: "absolute" }}
        >
          {/* Search Box */}
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>

          {/* Dropdown Items */}
          <ul className="max-h-60 overflow-auto">
          {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <li
                  key={item.id}
                  onClick={() => handleSelect(item)}
                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {item.name}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-sm text-gray-500">
                No results found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
