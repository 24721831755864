import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import eye_icon from "../../assets/images/eye_icon.svg";
import editicon from "../../assets/images/bx_edit copy.svg";
import searchicon from "../../assets/images/search_icon.svg";
import { Form, Modal } from "react-bootstrap";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse, putMultipartWithAuthCall, putMultipartWithAuthCallWithErrorResponse, putWithAuthCall, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import Select from "react-select";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";

export default function SubcriptionList() {

  const { sidebar } = useContext(AppContext);
  const [addSubcription, setAddSubcription] = useState({
    name: "",
    ServiceId: "",
    BranchId: "",
    ConsultantId: "",
    ClientId: "",
    FinancialYearId: "",
    // Periods:[],
    Amount1: "",
    Amount2: "",
    Amount3: "",
    Amount4: "",
    AdvanceAmount: "",
    subscriptionType: '',
    counts: ''
  })
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const subscriptionId = useRef()
  const [consutants, setConsultants] = useState([])
  const [clients, setClients] = useState([])
  const [services, setServices] = useState([])
  const [serviceGroups, setServiceGroups] = useState([])
  const [groupId, setGroupId] = useState('')

  const [quotatio, setQuotation] = useState([])
  const [finanacialYear, setFinancialYear] = useState([])
  const [branches, setBranches] = useState([])
  const [Periods, setPeriods] = useState([])
  const [subCriptionList, setSubcriptionList] = useState([])
  const [loading, setLoding] = useState(false)
  const [isValided, setIsValided] = useState(false)
  const [detalsById, setDetailsById] = useState("")
  const [filterSerchName, setFilterSerchName] = useState([])

  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("1");



  const handleClose = () => {
    setShow(false)
    setAddSubcription('')
    setPeriods("")
    setQuotation("")
    setIsValided(false)

  };
  const handleCloses = () => {
    setShow1(false)
    setAddSubcription('')
    setPeriods("")
    setQuotation("")
  };
  const handleShow = () => {
    setShow(true)
  };
  const handleShows = (id) => {
    setShow1(true)
    console.log("active data")
    subscriptionId.current = id
  };



  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  console.log("add Subscription>>>", addSubcription)

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };
  const handleSelectChange = (e) => {
    setStatusFilter(e.target.value);
  };


  const getServiceGroupList = () => {

    // if (serviceGroups?.length > 0) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICE_LIST).then((res) => {

      if (res?.json?.success) {

        setServiceGroups(res?.json?.data)

      }

    })

  }


  const getServices = (id) => {


    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICES_BY_GROUP_ID + id).then((res) => {


      setServices(res?.json?.data)


    })
  }

  const getPeriods = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGEL_SERVICE + id).then((res) => {

      if (res?.json?.success) {
        let options = res?.json?.data?.addPeriods?.map((name) => ({
          label: name.name,
          value: name.id,
        }));
        setQuotation(options)
      }

    })

  }

  const handleChangeGroup = (selected) => {
    const takeValue = selected.map((item) => item.value)
    console.log("select group Value>>>>", takeValue)
    setPeriods(takeValue);
  };

  const getmasterData = () => {


    if (finanacialYear.length) return


    simpleGetCallWithErrorResponse(ApiConfig.MASTER_DATA).then((res) => {

      if (res?.json?.success) {
        let filt = res?.json?.data?.filter((i) => {
          return i.listName === 'FinancialYears'
        })

        setFinancialYear(filt[0]?.listValues)
      }

    })


  }



  const getSubCriptionSetails = (id) => {
    setLoding(true)
    setShow(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_SUBSCRIPTIONS_DETAILS_BYID + `${id}`).then((res) => {

      if (res?.json?.success) {
        setLoding(false)

        const data = res?.json?.data
        setAddSubcription({
          ...addSubcription,
          id: data.id,
          name: data?.name,
          ServiceId: data?.serviceId,
          BranchId: data?.branchId,
          ClientId: data?.clientId,
          FinancialYearId: data?.financialYearId,
          Amount1: data?.amount1,
          Amount2: data?.amount2,
          Amount3: data?.amount3,
          Amount4: data?.amount4,
          AdvanceAmount: data?.advanceAmount,


        })

        if (data.id) {
          let options = data.periods?.map((name) => ({
            label: name.name,
            value: name.id,
          }));

          let options1 = data.periods?.map((name) => name?.id);

          setQuotation(options)
          setDetailsById(options1)
        }

        setGroupId(res?.json.data?.serviceGroupId)
      }

    })

  }

  const getSubcriptionList = () => {
    setLoding(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_SUBSCRIPTIONS_LIST).then((res) => {
      setLoding(false)

      if (res?.json?.success) {
        setSubcriptionList(res?.json?.data)
      }

    })


  }


  const onSubmitData = () => {
    let isValid = true

    const body = {
      "Id": addSubcription?.id ? addSubcription?.id : 0,
      "ServiceId": Number(addSubcription.ServiceId),
      // "BranchId": Number(addSubcription?.BranchId),
      //  "ConsultantId": 0,
      // "ClientId": Number(addSubcription?.ClientId),
      "FinancialYearId": Number(addSubcription?.FinancialYearId),
      "Periods": Periods,
      "Amount1": addSubcription?.Amount1,
      // "Amount2": addSubcription?.Amount2,
      // "Amount3": addSubcription?.Amount3,
      // "Amount4": addSubcription?.Amount4,
      // "AdvanceAmount": addSubcription?.AdvanceAmount,
      "Name": addSubcription?.name,
      subscriptionType: addSubcription?.subscriptionType,
      counts: addSubcription?.counts
    }

    if (!addSubcription?.name

      || !addSubcription?.ServiceId
      || !addSubcription?.FinancialYearId
      || !addSubcription?.Amount1
      || !Periods ||
      !addSubcription?.subscriptionType ||
      (addSubcription?.subscriptionType == 2 && !addSubcription?.counts)
    ) {
      isValid = false
      setIsValided(true)
    }

    if (isValid) {


      if (addSubcription?.id) {
        setLoding(true)

        putWithAuthCall(ApiConfig?.UPDATE_SUBSCRIPTIONS, JSON.stringify(body))
          .then((res) => {
            if (res?.success === true) {
              ToastMsg("success", res?.message);
              setLoding(false);
              setShow(false);
              getSubcriptionList()
            } else {
              ToastMsg("error", res?.message);
            }
            console.log("update Data>>>", res)
            setLoding(false)
          })
          .catch((err) => {
            console.log("update Data Error >>>", err)
          })
      } else {
        setLoding(true)
        PostCallWithErrorResponse(ApiConfig.ADD_SUBCSRIPTION, JSON.stringify(body))
          .then((res) => {
            if (res.json?.success === true) {
              ToastMsg("success", res?.json?.message);
              setLoding(false);
              setShow(false)
              getSubcriptionList()
            } else {
              ToastMsg("error", res?.json?.message);
            }
            console.log("update Data>>>", res)
            setLoding(false)
          })
          .catch((err) => {
            console.log("update Data Error >>>", err)
          })
      }


    }


    // .then((res)=>{
    //   if(res?.json?.success){
    // })

  }

  const onActiveSubscription = () => {
    setLoding(true);
    const activeValid = subscriptionId.current?.active === true ? false : true
    putWithAuthCall(ApiConfig?.ACTIVE_SUBSCRIPTIONS + `${subscriptionId.current?.id}/${activeValid}`)
      .then((res) => {
        if (res?.success === true) {
          ToastMsg("success", res?.message);
          setLoding(false);
          setShow1(false)
          getSubcriptionList()
        } else {
          ToastMsg("error", res?.message);
        }
        console.log("update Data>>>", res)
        setLoding(false)
      })
      .catch((err) => {
        console.log("update Data Error >>>", err)
      })
  }

  useEffect(() => {

    getmasterData();
    getSubcriptionList();
    getServiceGroupList()
  }, [])



  useEffect(() => {
    const filteredData = subCriptionList?.filter((item) => {
      const searchLower = searchValue.toLowerCase();
      const matchesSearch = (item?.name && item?.name.toLowerCase().includes(searchLower)) ||
        (item?.serviceName && item?.serviceName.toLowerCase().includes(searchLower)) ||
        (item?.serviceGroupName && item?.serviceGroupName.toLowerCase().includes(searchLower));

      // Filter based on the status as well (active or de-active)
      const matchesStatus = statusFilter
        ? statusFilter === "1" ? item.active === true : item.active === false
        : true;

      return matchesSearch && matchesStatus;
    });
    setFilterSerchName(filteredData);
  }, [subCriptionList, searchValue, statusFilter]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role">
          <h6 className="mt-2 role-text">Subscription Management List</h6>
          <button
            className="create-btn-sub-header"
            style={{ marginRight: "0" }}
            onClick={handleShow}
          >
            + Create
          </button>
        </div>
        <div className="d-flex align-items-center">
          <div className="search-input-wrapper my-auto mt-4">
            <input type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrentPage(0);
              }}
            />
            <img src={searchicon} alt="" />
          </div>
          <div className="entries-wrapper">
            <p>Select Status</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              onChange={handleSelectChange}
            >
              <option value="" selected>Select</option>
              <option value="1">Active</option>
              <option value="2">De-Active</option>
            </select>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <tr>
              <th>Sr. No.</th>
              <th>Subscription</th>
              <th>Service Group</th>
              <th>Service Name</th>
              <th>Active/Deactive</th>
              <th>Action</th>
            </tr>{
              loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filterSerchName?.length > 0 ? (
                  filterSerchName.slice(startIndex, endIndex).map((item, index) => (
                    <tr className="table-row-custom">
                      <td>{index + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.serviceGroupName}</td>
                      <td>{item?.serviceName}</td>

                      <td
                        className={
                          item.active === true
                            ? "status-green"
                            : "status-red"
                        }
                        onClick={() => handleShows(item)}
                      >
                        {item.active === true ? 'Active' : 'In-Active'}
                        {/* {item.active === true ?
                          (<button className="me-1 p-1 border-none rounded-3 bg-success text-white fw-bold" onClick={() => handleShows(item)}>Active</button>) :
                          <button className="p-1 border-none rounded-3 bg-danger text-white fw-bold" onClick={() => handleShows(item)}>De-Active</button>
                        } */}

                      </td>
                      <td>
                        <img src={editicon} className="me-1" onClick={() => getSubCriptionSetails(item.id)}
                        ></img>
                      </td>
                    </tr>
                  ))

                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={9}>
                      <NoData />
                    </td>
                  </tr>
                )
              )
            }

          </table>

        </div>
        <Pagination
          data={filterSerchName}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={filterSerchName}
        />


        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>{addSubcription?.id ? "Update Subscription" : "Create Subscription"}</Modal.Title>
          </Modal.Header>
          {
            loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <Modal.Body>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Subscription Name
                      </label>
                      <input
                        type="text"
                        className="form-control tasKCategory1"
                        value={addSubcription?.name}
                        onChange={(e) => {
                          setAddSubcription({
                            ...addSubcription,
                            name: e.target.value
                          })
                        }
                        }
                      />
                      <p className="text-danger">{isValided && !addSubcription?.name && 'Please Enter Subscription Name'}</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Service Group
                      </label>
                      <select
                        class="form-select tasKCategory1"
                        aria-label="Default select example"
                        value={groupId}
                        onChange={(e) => {

                          getServices(e.target.value)

                          setGroupId(e.target.value)



                        }}
                      >
                        <option selected value={''} disabled>Select Service Group</option>
                        {
                          serviceGroups?.map((group, index) => {
                            return <option value={group?.id}>{group?.name}</option>
                          })
                        }
                      </select>

                      <p className="text-danger">{isValided && !addSubcription?.ServiceId && 'Please Select Service'}</p>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Service Name
                      </label>
                      <select
                        class="form-select tasKCategory1"
                        aria-label="Default select example"
                        value={addSubcription.ServiceId}
                        onChange={(e) => {
                          setAddSubcription({
                            ...addSubcription,
                            ServiceId: e.target.value
                          })
                          getPeriods(e.target.value)
                        }
                        }
                      >
                        <option selected value={""} disabled>
                          Select Service
                        </option>
                        {
                          services.map((service, index) => {
                            return <option value={service.id}>{service.name}</option>
                          })
                        }
                      </select>

                      <p className="text-danger">{isValided && !addSubcription?.ServiceId && 'Please Select Service'}</p>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Financial Year
                      </label>
                      <select
                        class="form-select tasKCategory1"
                        aria-label="Default select example"
                        value={addSubcription.FinancialYearId}

                        onChange={(e) => {
                          setAddSubcription({
                            ...addSubcription,
                            FinancialYearId: e.target.value
                          })
                        }
                        }
                      >
                        <option selected value={''} disabled>Select Financial Year</option>
                        {
                          finanacialYear?.map((year) => {
                            return <option value={year.id}>{year.value}</option>
                          })
                        }
                      </select>

                      <p className="text-danger">{isValided && !addSubcription?.FinancialYearId && 'Please Select Finacial Year'}</p>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Period's
                      </label>
                      <Select
                        class="form-select tasKCategory1 select-drop-icon"
                        options={quotatio ? quotatio : detalsById}
                        required
                        onChange={handleChangeGroup}
                        isMulti
                      />
                      <p className="text-danger">{isValided && !Periods && 'Please Select Periods'}</p>

                    </div>

                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Chart of Fees ₹
                      </label>
                      <input type="number" className="form-control tasKCategory1"
                        value={addSubcription.Amount1}

                        onChange={(e) => {
                          setAddSubcription({
                            ...addSubcription,
                            Amount1: e.target.value
                          })
                        }}
                      />

                      <p className="text-danger">{isValided && !addSubcription?.Amount1 && 'Please Enter Chart Fess'}</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="text-grey">
                        Select Run Type
                      </label>
                      <select
                        class="form-select tasKCategory1"
                        aria-label="Default select example"
                        value={addSubcription.subscriptionType}

                        onChange={(e) => {
                          setAddSubcription({
                            ...addSubcription,
                            subscriptionType: Number(e.target.value),
                            counts: e.target.value == 1 ? 1 : ''
                          })
                        }
                        }
                      >
                        <option selected value={''} disabled>Select Run Type</option>
                        <option value={'1'} >Single Run</option>
                        <option value={'2'} >Multi Run</option>

                      </select>

                      <p className="text-danger">{isValided && !addSubcription?.subscriptionType && 'Please Select Subscription Type'}</p>
                    </div>

                    {
                      addSubcription?.subscriptionType == 2 &&

                      <div className="col-lg-6 mb-3">
                        <label htmlFor="" className="text-grey">
                          Run Count
                        </label>
                        <input type="number" className="form-control tasKCategory1"
                          value={addSubcription.counts}

                          onChange={(e) => {
                            setAddSubcription({
                              ...addSubcription,
                              counts: Number(e.target.value)
                            })
                          }}
                        />

                        <p className="text-danger">{isValided && !addSubcription?.counts && 'Please Enter Chart Fess'}</p>
                      </div>
                    }

                  </div>

                </Modal.Body>
                <Modal.Footer>
                  <button onClick={handleClose} className="modal-cancel-btn">
                    Close
                  </button>
                  <button onClick={onSubmitData} className="AddQuestion_submit_btn">
                    Submit
                  </button>
                </Modal.Footer>
              </>
            )
          }

        </Modal>

        <Modal show={show1} onHide={handleCloses} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{subscriptionId.current?.active === true ? "De-Active Subscription" : "Active Subscription"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="delete-body ps-4">
            <p className="text-center m-3"> Are You Sure, You Want To <b>{subscriptionId.current?.active === true ? "De-Active" : "Active"} </b>This Subscription ? </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleCloses} className="modal-cancel-btn">
              Close
            </button>
            <button
              onClick={onActiveSubscription}
              className="AddQuestion_submit_btn">
              {subscriptionId.current?.active === true ? "De-Active" : "Active"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>

    </>
  );
}
