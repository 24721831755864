import React, { useContext, useEffect, useState } from "react";

import "../Stock/tax.css";
import "../Stock/variation.css";

import { Modal, Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { simpleGetCall, simplePatchCall } from "../../../api/ApiServices";
import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import Loader from "../../Loader";
import NoData from "../../../sharedComponent/NoData";

const Stock = () => {
  const { selectedStoreID, sidebar } = useContext(AppContext);
  const [DataList, setDataList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All"); // Initializing state
  const [shopName, setShopName] = useState("");
  const [ShopSKUcode, setShopSKUcode] = useState("");
  const [ShopRestockData, setShopRestockData] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [Loading, setLoading] = useState(false);

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + change));
  };

  const handleRestockClick = (data) => {
    setShopRestockData(data);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {}, [quantity, ShopRestockData]);

  const storeStatusChange = async () => {
    try {
      const requestBody = {
        data: [
          {
            id: ShopRestockData?.id, // Ensure ID is present
            variation_id: ShopRestockData?.variation_id || null, // Default to null if missing
            quantity: quantity, // Use the latest quantity state
          },
        ],
      };

      const res = await simplePatchCall(
        `${ApiConfig?.RESTOCK_UPDATE}`, // Ensure API endpoint is correct
        requestBody
      );
      if (res.success) {
        notifySuccess(res.message);
        fetchData();
        handleClosePopup();
      } else {
        notifyError(res.message);
      }
      console.log("Response:", res);
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [shopName, ShopSKUcode, selectedStatus, selectedStoreID]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const res = await simpleGetCall(
        `${
          ApiConfig.GET_STOCK_LIST
        }search=${shopName}&sku_code=${ShopSKUcode}&status=${
          selectedStatus?.value || ""
        }&shop_id=${selectedStoreID || ""}`
      );

      if (res?.data) {
        setDataList(res.data || []);
        setLoading(false);
      } else {
        setDataList([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const stockOptions = [
    { value: "all", label: "All" },
    { value: "in_stock", label: "In Stock" },
    { value: "low_stock", label: "Low Stock" },
    { value: "out_of_stock", label: "Out of Stock" },
  ];

  const formattedStores = stockOptions?.length
    ? stockOptions.map((store) => ({
        value: store.value, // Ensure store has an 'id' field
        label: store.label, // Ensure store has a 'label' field
      }))
    : [];
  const handleChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    console.log("Selected Store:", selectedOption);
  };

  const handleRestock = async () => {
    setLoading(true); // Show loader
    try {
      await storeStatusChange(1); // Call your function
    } finally {
      setLoading(false); // Hide loader after completion
    }
  };

  return (
    <>
      {/* <header /> */}

      {/* <Breadcrumps /> */}

      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Stock List</h6>
          </div>
          <main className="p-3">
            <label htmlFor="title" className="entries-wrapper">
              Search Item
            </label>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start">
              <div className="d-flex flex-wrap flex-column flex-sm-row gap-2 w-sm-auto">
                <div className="w-20 w-sm-1/4  text-start">
                  <input
                    className="form-control tasKCategory1 mb-2"
                    type="text"
                    placeholder="Search"
                    name="text"
                    value={shopName}
                    onChange={(e) => setShopName(e.target.value)}
                  />
                </div>

                <div className="w-[20%] w-sm-1/4 t text-start">
                  <div>
                    <div className="w-[20%] w-sm-1/4 text-start">
                      <Select
                        options={formattedStores}
                        value={setSelectedStatus.value}
                        onChange={handleChange}
                        onMenuOpen={() => console.log("Dropdown opened")}
                        placeholder="Choose a store..."
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: "250px",
                            marginBottom: "10px",
                            backgroundColor: "#fff",
                            borderColor: state.isFocused ? "#ccc" : "#ddd",
                            "&:hover": {
                              borderColor: "#bbb",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#ffffff"
                              : state.isFocused
                              ? "#f0f0f0"
                              : "#fff",
                            color: "#000",
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-[20%]w-sm-1/4 t text-start">
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    placeholder="SKU/Code"
                    id="price"
                    name="price"
                    value={ShopSKUcode}
                    onChange={(e) => setShopSKUcode(e.target.value)}
                  />
                </div>

                <div className="w-[20%] w-sm-1/4  text-start">
                  {/* <button className="btn btn-primary" type="submit">
                    <label className="label-small">Search</label>
                  </button> */}
                </div>
              </div>

              <div className="d-flex flex-wrap flex-column flex-sm-row gap-2 w-sm-auto">
                <div className="w-[20%] w-sm-1/4  text-start">
                  <button style={{backgroundColor:"#53B7E8", color:"#fff"}} className="btn btn-" type="button">
                    <label
                      className="label-small"
                      onClick={() => {
                        setShopName("");
                        setShopSKUcode(""); // Clear dropdown selection
                        setSelectedStatus("All");
                      }}
                    >
                      Show All
                    </label>
                  </button>
                </div>
                <div className="w-[20%] w-sm-1/4  text-start">
                  {/* <button className="btn btn-showall" type="button">
                <label className="label-small">Export Stock Report</label>
              </button> */}
                </div>
              </div>
            </div>

            <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
              {Loading ? (
                <Loader />
              ) : (
                <table className="table">
                  <thead>
                    <tr className="bg-grey font-family font-weight-400 font-size-14">
                      <th>#</th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Item Name
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Category
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        SKU/Code
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Quantity
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Size
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Unit of Measure
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Status
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Last Updated
                      </th>
                      <th scope="col" className="sp-table-head tax-type-label">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {DataList && DataList.length > 0 ? (
                      DataList.map((row, index) => (
                        <tr key={row.code}>
                          <td className="sp-table-data tax-type-label">
                            {index + 1}
                          </td>
                          <td className="sp-table-data tax-type-label">
                            {row.item_name}
                          </td>
                          <td className="sp-table-data tax-type-label">
                            {row.category_name}
                          </td>
                          <td className="sp-table-data tax-type-label">
                            {row.short_code}
                          </td>
                          <td className="sp-table-data tax-type-label">
                            <div className="d-flex justify-content-center align-items-center">
                              {row.quantity}
                            </div>
                          </td>
                          <td className="sp-table-data tax-type-label">
                            <div className="d-flex justify-content-center align-items-center">
                              {row.size}
                            </div>
                          </td>
                          <td className="sp-table-data tax-type-label">
                            <div className="d-flex justify-content-center align-items-center">
                              {row.unit_of_measurement}
                            </div>
                          </td>
                          <td>
                            <div
                              className="sp-buttton-b tax-type-label"
                              style={{
                                backgroundColor:
                                  row.status === "in_stock"
                                    ? "#10B9811A"
                                    : row.status === "out_of_stock"
                                    ? "#FF000033"
                                    : row.status === "low_stock"
                                    ? "#FFCC0033"
                                    : "",
                                color:
                                  row.status === "out_of_stock"
                                    ? "#FF0000"
                                    : row.status === "in_stock"
                                    ? "#119C2B"
                                    : row.status === "low_stock"
                                    ? "#FFCC00"
                                    : "",
                                padding: "10px",
                                borderRadius: "8px",
                                textAlign: "center",
                                width: "80%",
                                fontWeight: "500",
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {row.status?.replace("_", " ")}
                            </div>
                          </td>
                          <td className="sp-table-data tax-type-label">
                            {row.updated_at}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                src="images/ActionEdit.svg"
                                alt="Edit"
                                onClick={() => {
                                  handleRestockClick(row);
                                  setQuantity(row.quantity);
                                }}
                                className="cursor-pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <NoData />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
            {/* Popup Section */}
            <Modal show={showPopup} onHide={handleClosePopup} centered>
              <Modal.Body className="p-4">
                <div className="container">
                  <div className="row g-3 bg-white p-3">
                    {/* Quantity Section */}
                    <div className="col-md-4">
                      <Form.Label>
                        Quantity<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div className="d-flex align-items-center gap-3">
                        <Button
                          variant=""
                          onClick={() => handleQuantityChange(-1)}
                        >
                          <img
                            src="/images/Minus.svg"
                            alt="Minus"
                            width={20}
                            height={20}
                          />
                        </Button>
                        <span className="fw-bold">{quantity}</span>
                        <Button
                          variant=""
                          onClick={() => handleQuantityChange(1)}
                        >
                          <img
                            src="/images/Plus.svg"
                            alt="Plus"
                            width={20}
                            height={20}
                          />
                        </Button>
                      </div>
                    </div>

                    {/* Size Section */}
                    <div className="col-md-4">
                      <Form.Label>Size</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="2"
                        disabled={true}
                        value={ShopRestockData.size}
                      />
                    </div>

                    {/* Unit of Measure Section */}
                    <div className="col-md-4">
                      <Form.Label>
                        Unit Of Measure<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kg"
                        disabled={true}
                        value={ShopRestockData.unit_of_measurement}
                      />
                    </div>
                  </div>

                  {/* Modal Footer */}
                  <div className="d-flex justify-content-end gap-3 pt-3">
                    <Button
                      variant="primary"
                      onClick={handleRestock}
                      disabled={Loading}
                    >
                      {Loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Restock"
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleClosePopup}
                      disabled={Loading}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </main>
        </div>
      </div>
    </>
    //tabs
  );
};

export default Stock;
