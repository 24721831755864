import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import Modal from "react-bootstrap/Modal";
import { Accordion } from "react-bootstrap";
import close from "../../assets/images/close.svg";
import { formatTimes, formatDate } from "../../common";
import { BASE_URL } from "../../api/ApiConfig";

const Completedworkflows = () => {
  const { sidebar } = useContext(AppContext);


  const [subCriptionList, setSubcriptionList] = useState([])
  const [loading, setLoding] = useState(false)

  const [filterSerchName, setFilterSerchName] = useState([])
  const [sortOrder, setSortOrder] = useState("asc");
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  const [showCollectedData, setShowCollectedData] = useState(false)

  const [colletedData, setCollectedData] = useState([])



  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getCollectedData = (id) => {

    setShowCollectedData(true)

    const body = {
      IsAdministrative: false,
      WorkFlowAllocationId: id,
    }

    PostCallWithErrorResponse(ApiConfig.GET_pREVIES_TASKS_LIST, JSON.stringify(body)).then((res) => {

      if (res?.json?.success) {
        setCollectedData(res?.json?.data)
      }

    })

  }


  const getSubcriptionList = () => {
    const getUserId = localStorage.getItem("UserId")
    setLoding(true)
    simpleGetCallWithErrorResponse(ApiConfig.COMPLETED_WORKFLOWA_LIST + getUserId).then((res) => {
      setLoding(false)

      if (res?.json?.success) {
        setSubcriptionList(res?.json?.data)
      }

    })


  }




  const onResumData = (item) => {
    const workFlowId = item.id
    setLoding(true)
    simpleGetCallWithErrorResponse(ApiConfig.RESUME_WORKFLOW + workFlowId).then((res) => {
      setLoding(false)

      if (res?.json?.success === true) {
        ToastMsg("success", res?.json?.message)
        getSubcriptionList()
      } else {
        ToastMsg("error", res?.json?.message)
      }

    })
  }


  useEffect(() => {
    getSubcriptionList();
  }, [])



  useEffect(() => {
    const filteredData = subCriptionList?.filter((item) => {
      const searchLower = searchValue.toLowerCase();
      const matchesSearch = (item?.name && item?.name.toLowerCase().includes(searchLower)) ||
        (item?.subcriptionnName && item?.subcriptionnName.toLowerCase().includes(searchLower));
      return matchesSearch
    });
    setFilterSerchName(filteredData);
  }, [subCriptionList, searchValue,]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role">
          <h6 className="mt-2 role-text">Completed Workflows</h6>

        </div>
        <div className="d-flex align-items-center">
          <div className="search-input-wrapper my-auto mt-4">
            <input type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrentPage(0);
              }}
            />
            <img src={searchicon} alt="" />
          </div>


        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <tr>
              <th>Sr. No.</th>

              <th>WorkFlow</th>
              <th
                style={{ cursor: "pointer" }}
              >Status </th>
              <th>Subscription</th>
              <th>Action</th>
            </tr>{
              loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filterSerchName?.length > 0 ? (
                  filterSerchName.slice(startIndex, endIndex).map((item, index) => (
                    <tr className="table-row-custom">
                      <td>{index + 1}</td>
                      <td>{item?.name}</td>
                      <td> {item.status === "Pending" ? (
                        <span className="colorP">{item.status}</span>
                      ) : item.status === "Allocated" ? (
                        <span className="colorAl">{item.status}</span>
                      ) : item.status === "Completed" ? (
                        <span className="colorC">{item.status}</span>
                      ) : item.status === "Deallocated" ? (
                        <span className="colorDe">{item.status}</span>
                      ) : item.status === "Abort" ? (
                        <span className="colorAb">{item.status}</span>
                      ) : item.status === "InProgress" ? (
                        <span className="colorIn">{item.status}</span>
                      ) : item.status === "Rejected" ? (
                        <span className="colorR">{item.status}</span>
                      ) : item.status === "Reallocated" ? (
                        <span className="colorRe">{item.status}</span>
                      ) : item.status === "Hold" ? (
                        <span className="colorH">{item.status}</span>
                      ) : item.status === "Approved" ? (
                        <span className="colorA"></span>
                      ) : (
                        ""
                      )}</td>
                      <td>{item?.subcriptionnName}</td>

                      <td>
                        <button className="me-1 p-1 border-none rounded-3 AddQuestion_submit_btn"
                          onClick={() => onResumData(item)}
                        >Resume</button>
                        <button className="me-1 p-1 border-none rounded-3 AddQuestion_submit_btn"
                          onClick={() => getCollectedData(item?.id)}
                        >View Details</button>

                      </td>
                    </tr>
                  ))

                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={9}>
                      <NoData />
                    </td>
                  </tr>
                )
              )
            }
          </table>
        </div>
        <Pagination
          data={subCriptionList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={filterSerchName}
        />
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showCollectedData}
        className="create-workflow-main modal-lg"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Collected Data
            </label>
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={() => setShowCollectedData(false)}
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div
            style={{
              maxHeight: "580px",
              overflowX: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <Accordion defaultActiveKey={0}>
              {colletedData.length > 0
                ? colletedData?.map((task, index) => {
                  return (
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      className="mt-1"
                    >
                      <Accordion.Header>
                        <div
                          className="menus-items w-100 d-flex justify-content-between"
                          style={{
                            backgroundColor: "whiteSmoke",
                            padding: "15px 15px 15px 15px",
                          }}
                        >
                          <h5 className=""> {task?.taskName} </h5>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          style={{
                            maxHeight: "580px",
                            overflowX: "scroll",
                            scrollbarWidth: "none",
                          }}
                        >
                          <div className="row m-4">
                            <dv className="d-flex justify-content-between">
                              <div className="d-flex ">
                                <label className="text-muted">Name : </label>
                                <label className="fw-bold ml-2">
                                  {task?.taskName}
                                </label>
                              </div>
                              <div className="d-flex ">
                                <label className="text-muted ">
                                  Status :{" "}
                                </label>
                                <label className="fw-bold ml-2">
                                  {task?.status}
                                </label>
                              </div>
                            </dv>
                            <dv className="d-flex justify-content-between">
                              <div className="d-flex mt-2">
                                <label className="text-muted">
                                  Data Provided By :{" "}
                                </label>
                                <label className="fw-bold ml-2">
                                  {task?.assignedUser}
                                </label>
                              </div>
                              <div className="d-flex mt-2">
                                <label className="text-muted">
                                  Sequence Number :{" "}
                                </label>
                                <label className="fw-bold ml-2">
                                  {task?.sequenceNumber}
                                </label>
                              </div>


                            </dv>
                            <dv className="d-flex justify-content-between">
                              <div className="d-flex mt-2">
                                <label className="text-muted ">Time : </label>
                                <label className="fw-bold ml-2">
                                  {task?.date}
                                </label>
                              </div>
                              <div className="d-flex mt-2">
                                <label className="text-muted ">Date : </label>
                                <label className="fw-bold ml-2">
                                  {task?.date}
                                </label>
                              </div>
                            </dv>
                            <div className="hr-line-modal mt-2">
                              <hr style={{ margin: "0" }} />
                            </div>
                            <div className="table-wrapper">
                              <div className="ml-7">
                                <table>
                                  <tr>
                                    <th>Sr. no</th>
                                    <th>label</th>
                                    <th>value</th>
                                  </tr>
                                  {task?.taskDynamicvalues?.map(
                                    (item, index) => {
                                      return (
                                        <tr className="table-row-custom">
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item?.taskDataTypeName === "Attachment" ? (
                                            <a href={BASE_URL + "/assets/uploads/" + item?.value} target="_blank"
                                              className="text-decoration-none text-primary"
                                              download
                                            >{item?.value} </a>
                                          ) : (
                                            item?.value
                                          )

                                          }</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
                : (
                  <NoData />
                )}
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Completedworkflows