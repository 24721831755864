import React, { useEffect, useState } from "react";
import Select from "react-select";

const SelectDrop = ({defaultValue, options, placeholder = "Select an option", onChange,id }) => {
    const [selectedOption, setSelectedOption] = useState(null);
console.log("selected id",id);

    useEffect(() => {
      // Set the default option if provided
      if (defaultValue !== undefined) {
        const defaultSelected = options.find(
          (option) => option.value === defaultValue
        );
        setSelectedOption(defaultSelected);
      }
    }, [defaultValue, options]);

    const handleChange = (selected) => {
      setSelectedOption(selected);
  
      if (onChange) {
        onChange({ newStatus: selected?.value, id }); // Pass object with newStatus and id
      }
    };


  useEffect(() => {

  }, [selectedOption]);




  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#ddd",
      boxShadow: "none",
      "&:hover": { borderColor: "#aaa" },
    }),
  };

  return (
    <Select
      styles={customStyles}
      options={options}
      value={selectedOption}
      placeholder={placeholder}
      onChange={handleChange}
      id={id}
    />
  );
};

export default SelectDrop;
