"use client";

import { useContext, useState } from "react";

import "../../Inventory Management System/Styles/tax.css";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { simplePostCallIVT } from "../../../api/ApiServices";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import ApiConfig from "../../../api/ApiConfig";

const Addtax = () => {
  const { selectedStoreID ,sidebar,setActiveTab} = useContext(AppContext)
  const navigetion =useNavigate()



  const [AddTaxes, setAddTeaxes] = useState({
    tax_name: "",
    tax_type: "",
    tax: "",
    tax_order_type: "",
    tax_active: false,
    tax_coreor_total: false,
    tax_tax_type: "",
    tax_rank: "0",
    consider_in_core_amount: false,
    tax_description: "",
   
  });
  

  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
  
    // Validate tax_name
    if (!AddTaxes.tax_name) {
      tempErrors.tax_name = "Tax Name is required";
    }
  
    // Validate tax
    if (!AddTaxes.tax) {
      tempErrors.tax = "Tax amount is required";
    } else if (isNaN(AddTaxes.tax) || AddTaxes.tax <= 0) {
      tempErrors.tax = "Tax amount must be a valid positive number";
    }
  
    
  
    // Validate tax_tax_type
    if (!AddTaxes.tax_tax_type) {
      tempErrors.tax_tax_type = "Tax Type  is required";
    }
  
  
    setErrors(tempErrors);
  
    return Object.keys(tempErrors).length === 0;
  };
  


  const onAddTax = () => {
    if (!validate()) {
      return;  // Stop the submission if validation fails
    }

    const body = {
      tax_name: AddTaxes.tax_name,
      tax_type: Number(AddTaxes?.tax_type),
      tax: AddTaxes?.tax,
      tax_order_type: AddTaxes?.tax_order_type,
      tax_active: AddTaxes?.tax_active,
      tax_coreor_total: AddTaxes?.tax_coreor_total === true ? "2" : "1",
      tax_tax_type: AddTaxes?.tax_tax_type,
      tax_rank: AddTaxes?.tax_rank,
      consider_in_core_amount: AddTaxes?.consider_in_core_amount === true ? "1" : "0",
      tax_description: AddTaxes?.tax_description,
      shop_id: selectedStoreID
    }
    simplePostCallIVT(ApiConfig.ADD_TAXES, JSON.stringify(body))
      .then((res) => {
        console.log("submit varation response>>>", res)
        if (res.success === true) {
          notifySuccess(res.message)
          navigetion("/BaseMenu")
          setActiveTab("Taxes")
          setAddTeaxes("");
          // setErrors({});
        } else {
          notifyError(res.message)
        }
      })
      .catch((err) => {
        console.log("submit varation error>>>", err)
      })

  }

  return (
    <>
      {/* <Header /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
        <div className="sub-header-role ">
            <h6 className="mt-2">Stock List</h6>
          </div>
      <div className="py-2">
        {/* <div className="container"> */}
        <div className="bg-white border border-1 p-3 border-tax">
          <div className="flex flex-column  gap-4 ">
            <div className="row align-items-center">
              {/* Tax Type Label */}
              <div className="col-md-2">
                <label className="tax-type-label mb-0">Tax Type</label>

              </div>

              {/* Radio Buttons */}
              <div className="col-md-2">
                <label className="radio-option tax-type-label mb-0 d-flex align-items-center gap-1">
                  <input
                    type="radio"
                    name="tax_tax_type "
                    value="1"
                    checked={AddTaxes.tax_tax_type === "1"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_tax_type: "1"
                    })}
                  />
                  Forward
                </label>
              </div>

              <div className="col-md-2">
                <label className="radio-option tax-type-label mb-0 d-flex align-items-center gap-1">
                  <input
                    type="radio"
                    name="tax_tax_type "
                    value="2"
                    checked={AddTaxes.tax_tax_type === "2"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_tax_type: "2"
                    })}
                  />
                  Backward
                </label>
              </div>
              {errors.tax_tax_type && <span style={{ color: "red",margin:"5pxs" }}>{errors.tax_tax_type}</span>}

              {/* <div className="col-md-2">
                <label className="radio-option tax-type-label mb-0 d-flex align-items-center gap-1">
                  <input
                    type="radio"
                    name="tax_tax_type "
                    value="3"
                    checked={AddTaxes.tax_tax_type === "3"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_tax_type: "3"
                    })}
                  />
                  Circulation on Tax
                </label>
              </div> */}
            </div>

            <div className="row">
              {/* Title */}
              <div className="col-md-2">
                <label htmlFor="title" className="tax-type-label">
                  Title<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control tax-inp tax-type-label"
                  type="text"
                  id="tax_name"
                  name="tax_name"
                  style={{width:"300px"}}
                  placeholder="Enter Tax Name"
                  value={AddTaxes.tax_name}
                  onChange={(e) => setAddTeaxes({
                    ...AddTaxes,
                    tax_name: e.target.value

                  })}
                />
                  {errors.tax_name && <span style={{ color: "red" }}>{errors.tax_name}</span>}
              </div>

              {/* Display Name */}
              {/* <div className="col-md-2">
                <label htmlFor="displayName" className="tax-type-label">
                  Display Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control tax-inp tax-type-label"
                  type="text"
                  id="displayName"
                  name="displayName"
                  placeholder="Enter Display Name"
                />
              </div> */}

              {/* Category */}
              {/* <div className="col-md-3">
                <label htmlFor="category" className="tax-type-label">
                  Category<span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown data={actions} placeholder="Select Category" />
              </div> */}

              {/* Price */}
              {/* <div className="col-md-2">
                <label htmlFor="price" className="tax-type-label">
                  Price<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control inp-size tax-type-label"
                  placeholder="Price"
                  min="0"
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
        
              </div> */}

              {/* Description */}
              <div className="col-md-3">
                {/* <label htmlFor="description" className="tax-type-label">
                  Description
                </label>
                <textarea
                  className="form-control tax-type-label"
                  id="description"
                  name="description"
                  placeholder="Enter Description"
                  rows={2}
                /> */}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-2">
                <label className="tax-type-label">Area</label>
              </div>

              <div className="col-md-2">
                <label className="radio-option tax-type-label">
                  <input type="checkbox" name="area" value="home_delivery" />
                  Home Delivery
                </label>
              </div>

              <div className="col-md-7 d-flex gap-3 flex-column flex-md-row">
                <label className="radio-option tax-type-label">
                  <input type="checkbox" name="area" value="parcel" />
                  Parcel
                </label>

                <label className="radio-option tax-type-label">
                  <input type="checkbox" name="area" value="home_website" />
                  Home Website
                </label>

                <label className="radio-option tax-type-label">
                  <input type="checkbox" name="area" value="grocery_store01" />
                  Grocery Store01
                </label>
              </div>
            </div> */}
            <div className="row items-center">
              {/* Title */}
              <div className="col-md-2">
                <label className="tax-type-label">Tax Valued</label>
              </div>

              {/* Display Name */}
              <div className="col-md-2">
                <label className="radio-option tax-type-label">
                  <input
                    type="radio"
                    name="tax_type" // Unique name for this group
                    value="1"
                    checked={AddTaxes?.tax_type === "1"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_type: "1"
                    })}
                  />
                  Percentage
                </label>
              </div>

              {/* Category */}
              <div className="col-md-1">
                {
                  AddTaxes?.tax_tax_type === "2" ? ("") : (
                    <>
                      <label className="radio-option tax-type-label">
                        <input

                          type="radio"
                          name="tax_type" // Unique name for this group 
                          value="2"
                          checked={AddTaxes?.tax_type === "2"}
                          onChange={(e) => setAddTeaxes({
                            ...AddTaxes,
                            tax_type: "2"
                          })}
                        />
                        Fixed
                      </label>
                    </>
                  )
                }

              </div>


              {/* Price */}
             
            </div>
            <div className="col-md-2 flex flex-col">
                <label className="tax-type-label mb-1">Amount<span style={{ color: "red" }}>*</span></label>
                <input
                  className="form-control tax-inp tax-type-label"
                  type="text"
                  id="title"
                  style={{width:"300px"}}
                  name="tax"
                  placeholder="Enter Title"
                  onChange={(e) => setAddTeaxes({
                    ...AddTaxes,
                    tax: e.target.value
                  })}
                />
              </div>

              {/* Description */}
              <div className="col-md-5 d-flex gap-3 flex-column flex-md-row">
                <label className="radio-option tax-type-label">
                  Order Type :
                  {/* <input type="checkbox" name="area" value="home_delivery" /> */}
                </label>

               <div className="d-flex">

               <label className="radio-option tax-type-label ms-5">
                  <input type="checkbox"
                    name="tax_order_type "
                    value="1"
                    checked={AddTaxes?.tax_order_type === "1"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_order_type: "1"
                    })}
                  />
                  Delivery

                </label>

                <label className="radio-option tax-type-label mx-5">
                  <input type="checkbox" name="tax_order_type "
                    value="1"
                    checked={AddTaxes?.tax_order_type === "2"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_order_type: "2"
                    })} />
                  Pickup

                </label>

                <label className="radio-option tax-type-label">
                  <input type="checkbox"
                    name="tax_order_type "
                    value="1"
                    checked={AddTaxes?.tax_order_type === "3"}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_order_type: "3"
                    })} />
                  Dine In

                </label>
               </div>
              </div>
            
            <div className="row">
              <div className="col-md-4 ">

              </div>
              <div className="col-md-4 ">
              {errors.tax && <span style={{ color: "red",margin:"5p1xs" }}>{errors.tax}</span>}

              </div>
              <div className="col-md-4 ">

              </div>
              </div>


            <div className="row">
              <div className="col-md-12 ">
                <label htmlFor="description" className="tax-type-label flex">
                  Description:
                </label>
                <textarea
                  id="description"
                  name="description"
                  placeholder="Enter the description here..."
                  rows={4} // Use numbers for numeric attributes in TSX
                  className="tax-type-label border border-gray p-3 form-control"
                  // style={{ width: "50%" }}
                  onChange={(e) => setAddTeaxes({
                    ...AddTaxes,
                    tax_description: e.target.value
                  })}
                ></textarea>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="tax-type-label">
                  <input
                    type="checkbox"
                    id="coreAmountCalculation"
                    name="coreAmountCalculation"
                    className="me-2"
                    checked={AddTaxes?.tax_coreor_total}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_coreor_total: e.target.checked
                    })}
                  />
                  Consider this in core amount calculation
                </label>
                <p className="tax-type-label1">
                  Note: Check this box only if you are taking taxes on certain
                  applied taxes.
                  <br />
                  Eg: If you want to take 18% service charge on items purchased
                  and want to calculate GST on item + service charge, then mark
                  this box.
                </p>
              </div>

            </div>
            <div className="row">
              {/* Status Checkbox */}
              <div className="col-md-2 d-flex align-items-center">
                <label
                  htmlFor="status"
                  className="tax-type-label d-flex align-items-center"
                >
                  <input
                    type="checkbox"
                    id="status"
                    name="status"
                    className="me-2"
                    checked={AddTaxes?.tax_status}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      tax_status: e.target.checked
                    })}


                  />
                  Status
                </label>

              </div>

              {/* Mandatory Tax Checkbox */}
              <div className="col-md-10 d-flex align-items-center">
                {
                  AddTaxes?.tax_tax_type === "2"?(""):(
                  <>
                  <label
                  htmlFor="mandatoryTax"
                  className="tax-type-label d-flex align-items-center"
                >
                  <input
                    type="checkbox"
                    id="mandatoryTax"
                    name="consider_in_core_amount"
                    className="me-2"
                    checked={AddTaxes?.consider_in_core_amount}
                    onChange={(e) => setAddTeaxes({
                      ...AddTaxes,
                      consider_in_core_amount: e.target.checked
                    })}

                  />
                  Consider this tax as mandatory. So this can&#39;t be removed
                  even if the user has rights.
                </label>
                  </>
                  )
                }
                
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end gap-3 pt-2 pb-2">
            <button className="w-30 w-sm-auto btn " style={{border:"1px solid #53B7E8", color:"#53B7E8"}} type="button">
              <span className="add-">Cancel</span>
            </button>
            <button className="w-30 w-sm-auto btn" style={{backgroundColor:"#53B7E8", color:"#fff"}} type="button" onClick={onAddTax}>
              <span className="add-">Add Changes</span>
            </button>
          </div>
        </div>

        {/* </div> */}
      </div>
      </div>
      </div>
    </>
  );
};

export default Addtax;
