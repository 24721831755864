import { useContext, useState } from "react";
import "../../Inventory Management System/Styles/tax.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import deleteIcon from "../../../assets/images/delete.svg";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";

const AddTable = () => {
  const { selectedStoreID, sidebar,setActiveTab } = useContext(AppContext);
  const navigate = useNavigate();


  const [isValided, setIsValided] = useState(false)
  const initialState = {
    table_no: "",
    shop_id: '',
    num_persons: '',
    extra_info: "",
    is_available_for_reservation: false,
  };

  const [state, setState] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      is_available_for_reservation: e.target.checked,
    }));
  };

  const handleSubmit = () => {



    if (!state.table_no ||
      !state.num_persons ||
      !state.extra_info
    ) {


      setIsValided(true)

      return
    }

    state.shop_id = Number(selectedStoreID)

    PostCallWithErrorResponse(ApiConfig.ADD_NEW_TABLE, JSON.stringify(state)).then((res) => {

      if (res?.json?.success) {
        ToastMsg('success', res?.json?.message)
        navigate('/BaseMenu')
        setActiveTab("Table/Area")
      } else {
        ToastMsg('error', res?.json?.message)
      }

    })

  };

  return (
    <div className={sidebar ? "taskMain" : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Add Table</h6>
        </div>
        <div className="p-2">
          <div className="w-full bg-white p-4 border border-gray-300">
            <div className="row g-3">
              {/* Table No */}
              <div className="col-md-6">
                <label htmlFor="table_no" className="form-label tax-type-label">
                  Table No<span style={{ color: "red" }}>*</span>
                </label>
                <div className="position-relative z-3 w-full">
                  <input
                    type="text"
                    id="table_no"
                    className="form-control tax-type-label"
                    name="table_no"
                    value={state.table_no}
                    onChange={handleInputChange}
                    placeholder="Table No"
                  />
                  <span className="text-danger">{isValided && !state.table_no && "Please Enter Table Name"}</span>
                </div>
              </div>

              {/* No. of persons */}
              <div className="col-md-6">
                <label htmlFor="num_persons" className="form-label tax-type-label">
                  No. of persons<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="num_persons"
                  className="form-control tax-type-label"
                  name="num_persons"
                  value={state.num_persons}
                  onChange={handleInputChange}
                  placeholder="0"
                />
                <span className="text-danger">{isValided && !state.num_persons && "Please Enter No Of Persons"}</span>
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="table_info"
                  className="form-label tax-type-label"
                  style={{
                    color: "gray",
                  }}
                >
                  Note: You can enter multiple table numbers or ranges using comma(,) and colon(:).
                  <br />
                  Examples:
                  <br />
                  1) Table Range - A10:A20.
                  <br />
                  2) Single Table and Table Range - AA10:AA20,BB1.
                  <br />
                  3) Multiple Table Range - A10:A100,B1:B20.
                  <br />
                  4) Multiple Tables - 1,2,3
                </label>
              </div>

              {/* Extra Information */}
              <div className="col-md-6">
                <label
                  htmlFor="extra_info"
                  className="form-label tax-type-label"
                >
                  Extra Information<span style={{ color: "red" }}>*</span>
                </label>
                <div className="position-relative z-3 w-full">
                  <textarea
                    style={{ width: "100%", height: "250px", resize: "none" }}
                    className="form-control tax-type-label"
                    name="extra_info"
                    value={state.extra_info}
                    onChange={handleInputChange}
                  />
                </div>
                <span className="text-danger">{isValided && !state.extra_info && "Please Enter Extra Information"}</span>
              </div>

              {/* Available for Reservation */}
              <div className="col-md-6">
                <label
                  htmlFor="is_available_for_reservation"
                  className="form-label tax-type-label"
                >
                  <input
                    type="checkbox"
                    name="is_available_for_reservation"
                    checked={state.is_available_for_reservation}
                    onChange={handleCheckboxChange}
                  />
                  Available for reservation
                </label>
              </div>
            </div>

            {/* Buttons */}
            <div className="d-flex justify-content-end gap-3 pt-2">
              <button
                className="btn"
                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                type="button"
                onClick={handleSubmit}
              >
                <label className="add-">Add Table</label>
              </button>
              <button
                className="btn btn-"
                type="button"
                style={{border:"1px solid #53B7E8", color:"#53B7E8"}}
                onClick={() => navigate("/BaseMenu")}
              >
                <label className="add-">Cancel</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTable;
