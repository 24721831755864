"use client";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import eye from "../../../assets/images/eye_icon.svg";
import ticket from "../../../assets/images/ticket-discount.svg";
import "../../Inventory Management System/Styles/spreadsheet.css";

import Form from 'react-bootstrap/Form';



import Select from "react-select";
import { PetchCallWithErrorResponse, PostCallWithErrorResponse, patchMultipartWithAuthCall, putRequest, simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Loader from "../../Loader";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import { AppContext } from "../../../context/AppContext";
import NoData from "../../../sharedComponent/NoData";
import { Modal } from "react-bootstrap";
import { ToastMsg } from "../../TostMsg";



export default function TableArea() {
    const { parentCategory, setParentCategory, selectedStoreID } = useContext(AppContext)
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([])
    const [categoryList, SetCategoryList] = useState([])
    const [parentCategoryList, setParentCategoryList] = useState([])
    const [searchCategory, setSearchCategory] = useState("");
    const [loading, setLoading] = useState(false)
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [activeInCtive, setActiveInctive] = useState('')
    const [rankData, setRankData] = useState({ data: [] })
    const [parentTab, setParentTab] = useState(false)
    const [id, setId] = useState(0)
    const [tableDetails, setTableDetails] = useState({})
    const [discount, setDiscount] = useState(
        {
            "selected": [],
            "discount": '',
            table_ids: [],
            name: ''

        })

    const [activeAction, setActiveAction] = useState({
        data: [

        ]
    })



    const ActionList = [

        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
    ];

    const [search, setSearch] = useState({
        search: '',
        handle: 1
    })

    const getTableData = () => {

        simpleGetCall(ApiConfig.TABLES_DATA + `?shop_id=${selectedStoreID}&search=${search.search}&page=1&limit=150`).then((res) => {

            if (res?.success) {
                setTableData(res?.tables)
            } else {
                setTableData([])
            }

        })

    }

    useEffect(() => {

        getTableData()

    }, [selectedStoreID, search.handle])

    const handletableAtion = (action) => {

        if (!discount?.selected?.length) {
            ToastMsg('error', 'Please Select Table')
            return

        }

        let ids = discount?.selected?.map((id) => id?.id)

        PostCallWithErrorResponse(ApiConfig.TABLE_ACTION, JSON.stringify({
            table_ids: ids,
            action: action
        })).then((res) => {

            if (res?.json?.success) {
                setDiscount(prev => ({
                    ...prev,
                    selected: []
                }))

                ToastMsg('success', res?.json?.message)

                getTableData()
            } else {
                ToastMsg('error', res?.json?.message)
            }

        })

    }

    const handleUpdate = () => {

        if (!tableDetails?.num_persons) {
            ToastMsg('error', "Please Enter No Of Persons")

            return
        }


        putRequest(ApiConfig.TABLES_DATA + '/' + id, JSON.stringify(tableDetails)).then((res) => {

            if (res?.success) {
                ToastMsg('success', res?.message)

                handleCloseEdit()

                getTableData()
            } else {
                ToastMsg('error', res?.message)
            }

        })

    }


    const getTableById = (id) => {



        setId(id)

        simpleGetCall(parentTab ? ApiConfig?.ADD_AREA + `/${id}` : ApiConfig.TABLES_DATA + `/${id}`).then((res) => {


            if (res?.success) {
                setTableDetails(res?.table || res?.area)
            }

        })

    }

    const updatePercentage = () => {

        if (!discount?.discount || discount?.discount > 100) {
            ToastMsg('error', 'Please Enter Valid Discount')
            return
        }

        let ids = []

        if (discount?.table_ids?.length > 0) {
            ids = discount?.table_ids
        } else {

            discount?.selected?.forEach((id) => ids.push(id?.id))
        }

        if (parentTab) {
            putRequest(ApiConfig.ADD_AREA_DISCOUNT, JSON.stringify({
                area_id: ids[0],
                discount: Number(discount.discount)
            })).then((res) => {

                if (res?.success) {
                    ToastMsg('success', res?.message)

                    setDiscount({
                        "selected": [],
                        "discount": '',
                        table_ids: [],
                        name: ''

                    })

                    fetchCategoryList()

                    handleClosePercentage()
                } else {
                    ToastMsg('error', res?.json?.message)
                }

            })
        } else {

            PostCallWithErrorResponse(ApiConfig?.ADD_DISCOUNT, JSON.stringify({
                table_ids: ids,
                discount: Number(discount.discount)
            })).then((res) => {

                if (res?.json?.success) {
                    ToastMsg('success', res?.json?.message)

                    setDiscount({
                        "selected": [],
                        "discount": '',
                        table_ids: [],
                        name: ''

                    })

                    getTableData()

                    handleClosePercentage()
                } else {
                    ToastMsg('error', res?.json?.message)
                }

            })
        }

    }


    const updateStatus = (id, status) => {

        PetchCallWithErrorResponse(ApiConfig?.TABLES_DATA + `/${id}/status`, JSON.stringify({
            status: status
        })).then((res) => {
            if (res?.json?.success) {
                ToastMsg('success', res?.json?.message)

                getTableData()
            } else {
                ToastMsg('error', res?.json?.message)
            }

        })

    }

    const handleAction = (select) => {
        console.log("handleAction>>>", select.value)
        setActiveInctive(select.value)
        handleActiveData(select.value)
    }

    const fetchCategoryList = async (search = '', e) => {
        setLoading(true)
        const url = parentTab === false ? ApiConfig?.GET_PARENT_CATEGORY_LIST : ApiConfig?.GET_CATEGORY_LIST_DATA
        try {
            const response = await simpleGetCall(ApiConfig?.ADD_AREA + `?shop_id=${selectedStoreID}&search=${search}&page=1&limit=150`);
            if (response.success === true) {
                setLoading(false)
                const category = response?.areas
                console.log("categoryList>>>>", category);
                if (parentTab === false) {
                    setParentCategoryList(category)
                } else {
                    SetCategoryList(category)
                }
            } else {
                setLoading(false)
            }
        } catch (err) {
            console.error("Dropdown API Error:", err);
        }
    };

    useEffect(() => {
        fetchCategoryList(searchCategory)
    }, [parentTab, selectedStoreID])


    const handleSearchChange = (e) => {
        e.preventDefault();
        const query = e.target.value;
        setSearchCategory(query);
    };

    const handleCheckboxChange = (id) => {
        setRankData((prevState) => {

            const data = prevState.data || [];

            const exists = data.find((item) => item.id === id);

            if (exists) {

                return {
                    ...prevState,
                    data: data.filter((item) => item.id !== id),
                };
            } else {

                return {
                    ...prevState,
                    data: [...data, { id, active: null }],
                };
            }
        });
    };


    const handleActiveData = (selectedOption) => {
        const newActiveValue = selectedOption
        console.log("select Value", newActiveValue)

        setRankData((prevState) => {
            const updatedData = prevState?.data?.map((item) =>
                item.active === null ? { ...item, active: newActiveValue } : item
            );
            return { ...prevState, data: updatedData };
        });

        setActiveInctive('')

    };


    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index); // Store the dragged index
    };


    const handleDragOver = (e) => {
        e.preventDefault();
    };


    const handleDrop = (e, newIndex) => {
        const oldIndex = e.dataTransfer.getData("index");
        console.log("oldIndex>>>", oldIndex)

        if (oldIndex !== newIndex) {
            const updatedCategories = updateRanks(categoryList, parseInt(oldIndex), newIndex);
            SetCategoryList(updatedCategories);

            const apiData = updatedCategories.map((category) => ({
                id: category.id,
                active: null,
                rank: category.category_rank,
            }));


            setRankData({ data: apiData });
            setActiveInctive('')
            setActiveAction("")
        }
    };

    const handleDropPrent = (e, newIndex) => {
        const oldIndex = e.dataTransfer.getData("index");
        console.log("oldIndex>>>", oldIndex)

        if (oldIndex !== newIndex) {
            const updatedCategories = updateRanks(parentCategoryList, parseInt(oldIndex), newIndex);
            setParentCategoryList(updatedCategories);

            const apiData = updatedCategories.map((category) => ({
                id: category.id,
                active: null,
                rank: category.category_rank,
            }));


            setRankData({ data: apiData });
            setActiveInctive('')
            setActiveAction("")
        }
    };

    const updateRanks = (categories, oldIndex, newIndex) => {
        const movedItem = categories[oldIndex];
        let updatedCategories = [...categories];

        updatedCategories.splice(oldIndex, 1);

        updatedCategories.splice(newIndex, 0, movedItem);


        updatedCategories.forEach((item, index) => {
            item.category_rank = index + 1;
        });

        return updatedCategories;
    };



    // update Rank Apis
    const onUpdateRank = (active) => {

        let body;


        if (parentTab === false) {
            body = {
                data: rankData.data?.map(item => ({
                    id: item.id,
                    status: item.active === "1" ? true : null || item.active === "0" ? false : null,
                    rank: item.rank ? item.rank : null,
                }))
            }
        } else {
            body = {
                data: rankData.data?.map(item => ({
                    id: item.id,
                    active:
                        item.active === "1" ? item.active : null || item.active === "0" ? item.active : null,


                    rank: item.rank ? item.rank : null,
                }))
            };
        }


        const url = parentTab === false ? ApiConfig?.PARENT_UPDATE_RANK : ApiConfig.UPDATE_RANK;

        patchMultipartWithAuthCall(url, JSON.stringify(body))
            .then((res) => {
                console.log("res>>>", res.json?.message)
                if (res.json?.success === true) {
                    notifySuccess(res.json?.message)
                    fetchCategoryList()
                    setRankData("")
                } else {
                    notifyError(res.json?.message)
                }
            })
            .catch((err) => {
                console.log("err>>>", err)
            })
    }

    console.log("Update rank body", rankData)

    useEffect(() => {
        if (rankData?.data?.length > 0) {
            const firstItem = rankData.data[0];

            if (firstItem.active === "0" || firstItem.active === "1") {
                onUpdateRank();
            }
        }
    }, [rankData]);

    const [showData, setShowData] = useState(null);
    const handleShowData = () => {
        setShowData(true);
    };
    const handleCloseData = () => {
        setShowData(false);
    };

    const [percentage, setPercentage] = useState(null);
    const handleShowPercentage = () => {
        setPercentage(true);
    };
    const handleClosePercentage = () => {
        setPercentage(false);
        setDiscount(prev => ({
            ...prev,
            discount: '',
            name: '',
            table_ids: []
        }))
    };

    const [edit, setEdit] = useState(false);

    const handleCloseEdit = () => {
        setEdit(false);
    };

    return (
        <div className="category-main">
            {parentTab ?
                <div className="d-flex flex-wrap justify-content-center justify-content-md-end gap-3 cat-but1">
                    <div className="addbut1 catbutton2">
                        <button
                            type="button"
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            style={{backgroundColor:"#53B7E8", color:"#fff", gap: "8px", width: "180px" }}
                            onClick={() => {
                                navigate("/AddArea")
                            }
                            }
                        >
                            <label className="bt-1">
                                Add Area
                            </label>

                        </button>
                    </div>
                    <div className="addbut1 catbutton2">
                        <button
                            type="button"
                            className="btn border d-flex align-items-center justify-content-center"
                            style={{backgroundColor:"#53B7E8", color:"#fff", gap: "8px", width: "180px" }}
                        >
                            <label className="bt-1">
                                Update Rank
                            </label>

                        </button>
                    </div>
                </div> :
                <div className="d-flex flex-wrap justify-content-center justify-content-md-end gap-3 cat-but1">

                    <div className="addbut1 catbutton2">
                        <button
                            type="button"
                            className="btn d-flex align-items-center justify-content-center"
                            style={{backgroundColor:"#53B7E8", color:"#fff" ,gap: "8px", width: "180px" }}
                            onClick={() =>
                                navigate("/AddTable")


                            }

                        >
                            <label className="bt-1">
                                Add New Table
                            </label>

                        </button>
                    </div>
                    <div className="addbut1 catbutton2">
                        <button
                            type="button"
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            style={{backgroundColor:"#53B7E8", color:"#fff",gap: "8px", width: "180px"}}
                            onClick={() => {
                                if (!discount?.selected?.length) {
                                    ToastMsg('error', 'Please Select Table')

                                    return
                                }
                                handleShowPercentage()
                            }}

                        >
                            <label className="bt-1">
                                Add Discount
                            </label>

                        </button>
                    </div>

                    <div className="z-3">
                        <Select
                            options={ActionList}
                            onChange={(e) => handletableAtion(e.value)}
                            placeholder="Select Action"
                        />

                    </div>

                </div>}
            <div className="tabs-container">
                <ul className="nav nav-tabs border-bottom-primary flex-column flex-md-row" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active custom-tab border"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            onClick={() => setParentTab(false)}
                        >
                            <label className="bt-1">Table</label>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link custom-tab border"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            onClick={() => setParentTab(true)}
                        >
                            <label className="bt-1">Areas</label>
                        </button>
                    </li>

                </ul>
            </div>


            <div className="tab-content" id="myTabContent">
                <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                >
                    <div>
                        <div className="responsive-container">
                            <div>
                                <label className="tax-lab sp-text">Table No</label>
                            </div>
                            <nav className="navbar">
                                <div className="w-100">
                                    <form
                                        className="d-flex align-items-center justify-content-between"
                                        role="search"
                                    >
                                        <input
                                            className="form-control me-2 flex-grow-1"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            name="search"
                                            value={search.search}
                                            onChange={(e) => {
                                                setSearch(prev => ({
                                                    ...prev,
                                                    search: e.target.value
                                                }))
                                            }}
                                        />
                                        <div className="d-flex justify-content-end">
                                            <button
                                                className="btn me-2 bt-1"
                                                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                                                type="button"
                                                onClick={getTableData}
                                            >
                                                Search
                                            </button >
                                            <button
                                                className="btn me-2 sp-button bt-1"
                                                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                                                type="button"
                                                onClick={() => {
                                                    setSearch({
                                                        search: '',
                                                        handle: search.handle + 1
                                                    })
                                                }}
                                            >
                                                Show all
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </nav>

                        </div>
                        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col" className="sp-table-head">
                                            Table No
                                        </th>
                                        <th scope="col" className="sp-table-head">
                                            No. of Persons
                                        </th>
                                        <th scope="col" className="sp-table-head">
                                            Extra Information
                                        </th>
                                        <th scope="col" className="sp-table-head">
                                            Area Name
                                        </th>
                                        <th scope="col" className="sp-table-head">
                                            Status
                                        </th>
                                        <th scope="col" className="sp-table-head">
                                            Discount(%)
                                        </th>
                                        <th scope="col" className="sp-table-head">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                <Loader />
                                            </td>
                                        </tr>
                                    ) : tableData.length > 0 ? (
                                        tableData.map((row, index) => (
                                            <tr key={index} className="sp-table-head">

                                                <td className="sp-table-data">
                                                    <input
                                                        type="checkbox"

                                                        className="mr-2"
                                                        checked={discount.selected.findIndex((sele) => sele?.id == row?.id) != -1}
                                                        onChange={(e) => {

                                                            if (e.target.checked) {
                                                                setDiscount(prev => ({
                                                                    ...prev,
                                                                    selected: [...discount.selected, {
                                                                        id: row?.id,
                                                                        name: row?.table_no
                                                                    }],

                                                                }))
                                                            } else {

                                                                setDiscount(prev => ({
                                                                    ...prev,
                                                                    selected: discount?.selected?.filter((i) => i?.id != row?.id),

                                                                }))

                                                            }

                                                        }}
                                                    />
                                                    {row.tableNo}
                                                </td>
                                                <td className="sp-table-data">{row?.table_no} </td>
                                                <td className="sp-table-data">{row.num_persons}</td>
                                                <td className="sp-table-data">{row.extra_info}</td>
                                                <td className="sp-table-data">{row.area_name}</td>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <Form.Check
                                                            type="switch"
                                                            checked={row?.status}
                                                            onChange={(e) => updateStatus(row?.id, e.target.checked)}
                                                        />
                                                    </div>
                                                </td>

                                                <td className="sp-table-data">{row.discount}%</td>

                                                <td>
                                                    <img src={eye} alt="view" width={20}
                                                        height={20}
                                                        onClick={() => {
                                                            getTableById(row?.id)
                                                            handleShowData()
                                                        }}
                                                    />
                                                    <img
                                                        src="images/dots.svg"
                                                        className="mx-2"
                                                        alt="Button Icon"
                                                        width={20}
                                                        height={20}
                                                        onClick={() => {
                                                            getTableById(row?.id)
                                                            setEdit(true);
                                                        }}
                                                    />
                                                    <img src={ticket} alt="ticket" width={20}
                                                        height={20} onClick={() => {
                                                            setDiscount(prev => ({
                                                                ...prev,
                                                                table_ids: [row?.id],
                                                                names: [row?.table_no]
                                                            }))
                                                            handleShowPercentage()
                                                        }} />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="ErrorDataNotFound">
                                            <td colSpan={9}>
                                                <NoData />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>

                </div>
                <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                >
                    <div>
                        <div className="responsive-container">
                            <div>
                                <label className="tax-lab sp-text">
                                    Category name </label>
                            </div>
                            <nav className="navbar">
                                <div className="w-100">
                                    <form
                                        className="d-flex align-items-center justify-content-between"
                                        role="search"
                                    >
                                        <input
                                            className="form-control me-2 flex-grow-1"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            name="search"
                                            value={searchCategory}
                                            onChange={handleSearchChange}
                                        />
                                        <div className="d-flex justify-content-end">
                                            <button
                                                className="btn me-2 bt-1"
                                                type="button"
                                                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                                                onClick={() => fetchCategoryList(searchCategory)}
                                            >
                                                Search
                                            </button >
                                            <button
                                                className="btn me-2 sp-button bt-1"
                                                type="button"
                                                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                                                onClick={() => {
                                                    fetchCategoryList("")
                                                    setSearchCategory("")
                                                }}
                                            >
                                                Show all
                                            </button>
                                            {/* <button
                                                className="btn btn-primary cat-button1 bt-1"
                                                type="button"
                                                onClick={onUpdateRank}
                                            >
                                                Update rank
                                            </button> */}
                                        </div>
                                    </form>
                                </div>
                            </nav>
                            <div>
                                <label className="tax-lab text-head">
                                    Note: Drag rows to rearrange order or ranking.
                                </label>
                            </div>
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col" className="sp-table-head">
                                        Display name
                                    </th>
                                    <th scope="col" className="sp-table-head">
                                        Status
                                    </th>
                                    <th scope="col" className="sp-table-head">
                                        Discount
                                    </th>
                                    <th scope="col" className="sp-table-head">
                                        Created
                                    </th>

                                    <th scope="col" className="sp-table-head">
                                        Actions
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    loading ? (<>
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                <Loader />
                                            </td>
                                        </tr>
                                    </>) : (
                                        categoryList?.length > 0 ? (
                                            categoryList?.map((row, index) => (
                                                <tr
                                                    key={index} className="sp-table-head"

                                                >
                                                    <td>

                                                        <input type="checkbox"
                                                            onClick={() => handleCheckboxChange(
                                                                row.id
                                                            )}
                                                        />
                                                    </td>
                                                    <td className="sp-table-data">{row.name}</td>

                                                    <td>
                                                        <div
                                                            className="sp-buttton"
                                                            style={{
                                                                backgroundColor: row.active === 1 ? "#eaf8f3" : "#ffe9e9",
                                                                color: row.active === 1 ? "#119C2B" : "#fd5e5e",
                                                                padding: "5px 10px",
                                                                borderRadius: "4px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {row.active === 1 ? "Active" : "Inactive"}
                                                        </div>
                                                    </td>
                                                    <td className="sp-table-data">{row?.discount}</td>
                                                    <td className="sp-table-data"
                                                    >
                                                        {row.created_at}
                                                    </td>

                                                    <td>

                                                        <td>
                                                            <img src={eye} alt="view" width={20}
                                                                height={20}
                                                                onClick={() => {
                                                                    handleShowData()
                                                                    getTableById(row?.id)
                                                                }}
                                                            />
                                                            <img
                                                                src="images/dots.svg"
                                                                alt="Button Icon"
                                                                width={20}
                                                                height={20}
                                                                className="mx-2"
                                                                onClick={() => {
                                                                    navigate(`/AddArea?id=${row.id}`)
                                                                    setParentCategory(false)
                                                                }}
                                                            />
                                                            <img src={ticket} alt="ticket" width={20}
                                                                height={20} onClick={() => {
                                                                    setDiscount(prev => ({
                                                                        ...prev,
                                                                        table_ids: [row?.id],
                                                                        names: [row?.name]
                                                                    }))
                                                                    handleShowPercentage()
                                                                }} />
                                                        </td>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className="ErrorDataNotFound">
                                                <td colSpan={9}>
                                                    <NoData />
                                                </td>
                                            </tr>
                                        )
                                    )

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                >
                    ..bcbcbcbc.
                </div>
                <div
                    className="tab-pane fade"
                    id="configuration"
                    role="tabpanel"
                    aria-labelledby="configuration-tab"
                >
                    hfthfhfhhf
                </div>
                <div
                    className="tab-pane fade"
                    id="action"
                    role="tabpanel"
                    aria-labelledby="action-tab"
                >
                    kkkkjkkjkjkjkjk
                </div>
            </div>
            <Modal size="lg" centered show={showData} onHide={handleCloseData}>
                <div className="border-bottom d-flex justify-content-between p-3">
                    <h6 className="text-dark">{parentTab ? tableDetails?.name : tableDetails?.table_no}</h6>
                    <button className="btn btn-close" onClick={handleCloseData}></button>
                </div>
                <Modal.Body>
                    <div className="border-bottom d-flex justify-content-between p-2">
                        <h5>Information</h5>
                        <h5>Date</h5>
                    </div>
                    <div className="border-bottom d-flex justify-content-between p-2">
                        <h5>{tableDetails?.extra_info || tableDetails?.name}</h5>
                        <h5>{tableDetails?.created_at?.split('T')[0]}</h5>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered show={percentage} onHide={handleClosePercentage}>
                <div className="border-bottom d-flex justify-content-between p-3">
                    <h6 className="text-dark">{discount?.table_ids?.length ? discount?.names?.join(',') : discount?.selected?.map((i) => i?.name + " ")}</h6>
                    <button className="btn btn-close" onClick={handleClosePercentage}></button>
                </div>
                <Modal.Body>
                    <div className="border-bottom d-flex flex-column p-2">
                        <label className="mb-2">Percentage</label>
                        <input type="number" className="form-control"
                            value={discount?.discount}
                            onChange={(e) => {
                                setDiscount(prev => ({
                                    ...prev,
                                    discount: Number(e.target.value) || ''
                                }))
                            }}
                        />
                    </div>
                    <div className="d-flex justify-content-end my-3">
                        <button className="btn text-white" style={{ height: "38px", backgroundColor: "#858D9D", width: "80px" }}
                            onClick={handleClosePercentage}
                        >Cancel</button>
                        <button className="btn btn-primary" style={{ height: "38px", color: "white", width: "80px" }}
                            onClick={updatePercentage}
                        >Save</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="lg" centered show={edit} onHide={handleCloseEdit}>
                <div className="border-bottom d-flex justify-content-between p-3">
                    <h6 className="text-dark">{tableDetails?.table_no}</h6>
                    <button className="btn btn-close" onClick={handleCloseEdit}></button>
                </div>
                <Modal.Body>
                    <div className="border-bottom d-flex justify-content-between align-items-center p-2">
                        <label className="">Number of Persons</label>
                        <input type="number" className="form-control" style={{ width: "350px" }}
                            value={tableDetails?.num_persons}
                            onChange={(e) => {
                                setTableDetails(prev => ({
                                    ...prev,
                                    num_persons: Number(e.target.value) || ''
                                }))
                            }}
                        />
                    </div>
                    <div className="mt-2">
                        <input type="checkbox" className="me-1"
                            checked={tableDetails?.is_available_for_reservation}
                            onChange={(e) => {
                                setTableDetails(prev => ({
                                    ...prev,
                                    is_available_for_reservation: e.target.checked
                                }))
                            }}
                        />
                        <label>Available for reservation</label>
                    </div>
                    <div className="d-flex justify-content-end my-3">
                        <button className="btn text-white" style={{ height: "38px", backgroundColor: "#858D9D", width: "80px" }}
                            onClick={handleCloseEdit}
                        >Cancel</button>
                        <button className="btn btn-primary" style={{ height: "38px", color: "white", width: "80px" }}
                            onClick={handleUpdate}
                        >Save</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
}
