

export const tableAreaOptions = [
    {
        label: 'Default Configuration of order types',
        value: '0'
    },
    {
        label: 'Calculate Automatically',
        value: '1'
    },
    {
        label: 'Calcualte Automatically',
        value: '2'
    },

]