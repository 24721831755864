import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import Check from "../../assets/images/check.svg";
import searchicon from "../../assets/images/ic_search.svg";
import optionIcon from "../../assets/images/bx_edit.svg";
import downArrow from "../../assets/images/Downarrow.svg";
import upArrow from "../../assets/images/upArrow.svg";
import DelectAcBlc from "../../assets/images/DelectAcBlc.svg";
import { Button, Carousel, Form } from "react-bootstrap";
import EditacBlc from "../../assets/images/EditacBlc.svg";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import disabled_delete from "../../assets/images/disabled_delete.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Accordion, Dropdown } from "react-bootstrap";
import addBtn from "../../assets/images/addBtn.svg";
import "../../assets/css/Timesheet.scss";
import Modal from "react-bootstrap/Modal";
import redCross from "../../assets/images/redCross.svg";
import watch from "../../assets/images/watch.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
// import  MDBTimepicker  from 'mdb-react-ui-kit';
import {
  convertMinutesInTime,
  convertTimeInMinutes,
  displayTime,
  getDateddmmyyyy,
  getWeekDay,
  punchedTime,
  setWorkHrs,
  totalEnteredTime,
} from "../../common";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import swal from "sweetalert";
import { Calendar } from "primereact/calendar";
import { notifyError } from "../../toaster/notify";
import NoData from "../../sharedComponent/NoData";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import { ClipLoader } from "react-spinners";
import BookIcon from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const Timesheet = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [showSheetModal, setShowSheetModal] = useState(false);
  const [date, setDate] = useState('')


  const [excelLoading, setExcelLoading] = useState(false);
  const [editTime, setEditTime] = useState(false);
  const [dropdownacc, setDropdownacc] = useState(false);
  const [task, setTask] = useState({});
  const [timesheetListLoader, setTimesheetListLoader] = useState(false);
  const [timesheetList, setTimesheetList] = useState([]);
  const [month, setMonth] = useState(new Date());
  const [year, setyear] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [projectId, setProjectId] = useState();
  const [projectName, setProjectName] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [projectNameDate, setProjectNameDate] = useState([]);
  console.log("projectName", projectName);
  const [loader, setloader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backDropLoading, setBackDropLoading] = useState(false);
  const [addTimeshetdata, setAddTimeshetdata] = useState({
    EmpId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    TotalHours: 0,
    TotalEnteredHours: 0,
    WorkedDate: "",
    AddTimeSheet: [
      {
        ProjectId: "",
        TaskId: "",
        WorkedHours: "",
        Activity: "",
        EntryDate: new Date().toISOString().toString(),
      },
    ],
  });

  const displayMonthAndYear = `${month.getFullYear()}-${month.getMonth() + 1 < 10 ? 0 : ""
    }${month.getMonth() + 1}`;

  // TOTAL ENTERED TIME CALCULATIONS
  let totalWorkedHrsArr = addTimeshetdata.AddTimeSheet.map((item) => {
    return item.WorkedHours ? item.WorkedHours : "";
  });

  let totalWorkedMinutes = 0;
  totalWorkedHrsArr.map((hrs) => {
    let time = hrs ? hrs.split(":") : "00:00";
    let minutes = Number(time[0]) * 60 + Number(time[1]);
    totalWorkedMinutes += minutes;
  });

  let totalHrs = addTimeshetdata.TotalEnteredHours
    ? addTimeshetdata.TotalEnteredHours.toString().split(".")
    : "00:00";
  let totalEnteredminutes =
    Number(totalHrs[0]) * 60 + (totalHrs[1] ? Number(totalHrs[1]) : 0);
  let hours =
    (totalWorkedMinutes + (showSheetModal ? totalEnteredminutes : 0)) / 60;
  let rhours = Math.floor(hours);
  let minute = (hours - rhours) * 60;
  let rminutes = Math.round(minute);
  let totalWorkingHrs = `${rhours < 10 ? 0 : ""}${rhours}:${rminutes < 10 ? 0 : ""
    }${rminutes}`;

  // Total working Hours for Validation for ADD TIMESHEET
  let totalEnteredTimeInDecimalValue = Number(
    totalWorkingHrs.split(":").join(".")
  );

  const editTimesheet = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (totalEnteredTimeInDecimalValue > addTimeshetdata.TotalHours) {
      ToastMsg(
        "error",
        "Total Entered Hrs should be less than or equal to Total Punched Hrs"
      );
    } else {
      setLoading(true);
      PostCallWithErrorResponse(
        ApiConfig.UPDATE_TIMESHEET,
        JSON.stringify(addTimeshetdata)
      )
        .then((res) => {
          if (res.json.Success === true) {
            setLoading(false);
            ToastMsg("success", res.json.Message);
            getTimeSheetListApi(projectId);
            setEditTime(false);
          } else {
            setLoading(false);
            ToastMsg("error", "something went wrong");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    setValidated(true);
  };

  const [workHrsErrMsg, setWorkHrsErrMsg] = useState("");

  const addTimeSheet = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setWorkHrsErrMsg("Please Enter Working Hours");
    } else if (totalWorkedHrsArr.includes("")) {
      setWorkHrsErrMsg("Please Enter Working Hours");
    } else if (totalEnteredTimeInDecimalValue > addTimeshetdata.TotalHours) {
      ToastMsg(
        "error",
        "Total Entered Hrs should be less than or equal to Total Punched Hrs "
      );
    } else {
      setLoading(true);
      PostCallWithErrorResponse(
        ApiConfig.ADD_TIMESHEET,
        JSON.stringify(addTimeshetdata)
      )
        .then((res) => {
          if (res.json.Success === true) {
            setLoading(false);
            ToastMsg("success", res.json.Message);
            getTimeSheetListApi(projectId);
            setShowSheetModal(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    setValidated(true);
  };

  //DOWNLOAD_EXCEL_FILE
  const downloadExcelFile = (e, ProjectId) => {
    setExcelLoading(true);
    console.log("Indside Download Excel File");
    e.preventDefault();
    PostCallWithErrorResponse(
      ApiConfig.DOWNLOAD_TIMESHEET_EXCEL,
      JSON.stringify({
        ProjectId: ProjectId,
        Month: month.getMonth() + 1,
        year: new Date().getFullYear(),
      })
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            ToastMsg("success", "Excel sheet download successfully");
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            ToastMsg("error", "Something went wrong");
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTimeSheetListApi(projectId);
  }, [month, projectId]);

  useEffect(() => {
    getProjectApi();
    getProjectListApi()
  }, []);

  // Delete Modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const getEmployeeTask = (projectId, editDate = '') => {
    PostCallWithErrorResponse(
      ApiConfig.GET_MP_TASK,
      JSON.stringify({
        ProjectId: projectId,
        date: editDate ? editDate : date
      })
    )
      .then((res) => {
        if (res.json.Data.length > 0) {
          console.log(res);
          let taskItem = { ...task };
          taskItem[projectId] = res.json?.Data;
          setTask({ ...taskItem });
        } else {
          let taskItem = { ...task };
          taskItem[projectId] = [];
          setTask({ ...taskItem });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeTaskDate = (date) => {
    PostCallWithErrorResponse(
      ApiConfig.GET_MP_TASK,
      JSON.stringify({
        Date: date,
      })
    )
      .then((res) => {
        if (res.json.Data.length > 0) {
          let taskItem = { ...task };
          taskItem[projectId] = res.json?.Data;
          setTask({ ...taskItem });
        } else {
          let taskItem = { ...task };
          taskItem[projectId] = [];
          setTask({ ...taskItem });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  // get edit data by Timesheet Id
  const getTimeSheetEditData = async (date, TimeSheetDetId, TimeSheetId) => {
    setBackDropLoading(true);
    simpleGetCallWithToken(
      `${ApiConfig.TIME_SHEET_UPDATE_VIEW}/${TimeSheetDetId}`
    )
      .then((res) => {
        setBackDropLoading(false);
        if (res.Data.timesheetDetails.length > 0) {
          setBackDropLoading(false);
          getEmployeeTask(res.Data.timesheetDetails[0].ProjectId, res?.Data?.Date);
          getProjectApi(res?.Data?.Date)
          let resArr = res.Data.timesheetDetails.map((item, i) => {
            return {
              ProjectId: item.ProjectId ? item.ProjectId : "",
              TaskId: item.TaskId ? item.TaskId : "",
              WorkedHours: item.WorkedHours ? item.WorkedHours : "",
              Activity: item.Activity ? item.Activity : "",
              EntryDate: new Date().toISOString().toString(),
            };
          });

          console.log(resArr);

          setAddTimeshetdata({
            ...addTimeshetdata,
            TotalHours: res.Data.TotalHours ? res.Data.TotalHours : "",
            TotalEnteredHours: res.Data.TotalEnteredHours
              ? res.Data.TotalEnteredHours
              : "",
            DayEnteredHours: res.Data.DayEnteredHours
              ? res.Data.DayEnteredHours
              : "",
            AddTimeSheet: resArr,
            WorkedDate: new Date(date).toISOString().toString(),
            modifiedAt: new Date(),
            TimeSheetId: TimeSheetId,
          });
        } else {
          setAddTimeshetdata({
            EmpId: Number(userData.EmpId),
            OrgId: Number(userData.OrgId),
            TotalHours: 0,
            TotalEnteredHours: 0,
            WorkedDate: "",
            AddTimeSheet: [
              {
                ProjectId: "",
                TaskId: "",
                WorkedHours: "",
                Activity: "",
                EntryDate: new Date().toISOString().toString(),
              },
            ],
          });
          setBackDropLoading(false);
        }
      })
      .catch((err) => {
        setBackDropLoading(false);
        console.log(err);
      });
  };

  // data by date
  const getTimeSheetdetaByDate = (date, TimeSheetId) => {
    setBackDropLoading(true);
    let body = {
      date: date,
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(
      ApiConfig.TIME_SHEET_DETAILS,
      JSON.stringify(body)
    )
      .then((res) => {
        setBackDropLoading(false);
        setAddTimeshetdata({
          EmpId: Number(userData.EmpId),
          OrgId: Number(userData.OrgId),
          TotalHours: 0,
          WorkedDate: "",
          AddTimeSheet: [
            {
              ProjectId: "",
              TaskId: "",
              WorkedHours: "",
              Activity: "",
              EntryDate: new Date().toISOString().toString(),
            },
          ],
        });

        if (res.json.Data.timesheetDetails.length > 0) {
          setBackDropLoading(false);
          let resArr = res.json.Data.timesheetDetails.map((item, i) => {
            return {
              ProjectId: item.ProjectId ? item.ProjectId : "",
              TaskId: item.TaskId ? item.TaskId : "",
              WorkedHours: item.WorkedHours ? item.WorkedHours : "",
              Activity: item.Activity ? item.Activity : "",
              EntryDate: new Date().toISOString().toString(),
            };
          });
          setAddTimeshetdata({
            ...addTimeshetdata,
            TotalHours: res.json.Data.TotalHours
              ? res.json.Data.TotalHours
              : "",
            // AddTimeSheet: resArr,
            TotalEnteredHours: res.json.Data.TotalEnteredHours,
            WorkedDate: new Date(date).toISOString().toString(),
          });
        } else {
          setAddTimeshetdata({
            EmpId: Number(userData.EmpId),
            OrgId: Number(userData.OrgId),
            TotalHours: 0,
            WorkedDate: "",
            AddTimeSheet: [
              {
                ProjectId: "",
                TaskId: "",
                WorkedHours: "",
                Activity: "",
                EntryDate: new Date().toISOString().toString(),
              },
            ],
          });
          setBackDropLoading(false);
        }
      })
      .catch((err) => {
        setBackDropLoading(false);
        console.log(err);
      });
  };

  const getTimeSheetListApi = (ProjectId) => {
    setTimesheetListLoader(true);
    PostCallWithErrorResponse(
      ApiConfig.TIMESHEET_LIST,
      JSON.stringify({
        // EmpId: Number(userData.EmpId),
        // OrgId: Number(userData.OrgId),
        ProjectId: ProjectId,
        Month: month.getMonth() + 1,
        year: new Date().getFullYear(),
      })
    )
      .then((res) => {
        if (res.json.Success) {
          setTimesheetList(res.json.Data);
        } else {
          console.log("getTimeSheetListApi error response,", res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimesheetListLoader(false);
      });
  };

  const getProjectListApi = () => {
    setloader(true);
    PostCallWithErrorResponse(
      ApiConfig.PROJECTS_NAMES,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
        Month: new Date().getMonth() + 1,
        Year: new Date().getFullYear(),
      })
    )
      .then((res) => {
        setloader(false);
        if (res.json.Success) {
          setProjectList(res.json.Data);
          console.log("projectList>>>>>>", res);
        } else {
          console.log("error response,", res);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log("error response,", err);
      });
  };

  // Project New Api

  const getProjectApi = (TimeSheetDetId) => {
    setloader(true);

    simpleGetCallWithToken(
      `${ApiConfig.PROJECTS_NAMES_FOR_DATE}${TimeSheetDetId}`
    )

      .then((res) => {
        setloader(false);
        console.log("error responssssssse,", res);

        if (res.Success) {
          setProjectName(res?.Data);
        } else {
          console.log("error response,", res);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log("error response,", err);
      });
  };


  const timeSheetDeleteApi = (timeSheetId, ProjectId) => {
    let payLoad = {
      TimeSheetId: timeSheetId,
    };
    PostCallWithErrorResponse(
      ApiConfig.DELETE_TIME_SHEET,
      JSON.stringify(payLoad)
    )
      .then((res) => {
        ToastMsg("success", res.json.Message);
        getTimeSheetListApi(ProjectId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteHandler = (index) => {
    const projectList = [...addTimeshetdata.AddTimeSheet];
    projectList.splice(index, 1);
    setAddTimeshetdata({ ...addTimeshetdata, AddTimeSheet: projectList });
  };

  return (
    <>
      {backDropLoading ? <BackDropLoader /> : null}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content timeSheet">
          <div className="sub-header-role ">
            <h6 className="mt-2">Timesheet Entry</h6>
            {/* <Calendar id="calendar-timeonly"  onChange={() => {}}  timeOnly /> */}
            {permissions && permissions["Create Timesheet Entry"] && (
              <button
                type="button"
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  setShowSheetModal(true);
                  setAddTimeshetdata({
                    EmpId: Number(userData.EmpId),
                    OrgId: Number(userData.OrgId),
                    TotalHours: 0,
                    TotalEnteredHours: 0,
                    WorkedDate: "",
                    AddTimeSheet: [
                      {
                        ProjectId: "",
                        TaskId: "",
                        WorkedHours: "",
                        Activity: "",
                        EntryDate: new Date().toISOString().toString(),
                      },
                    ],
                  });
                  setValidated(false);
                  setIsDisabled(false);
                  setWorkHrsErrMsg("");
                  setTask({});
                }}
              >
                {/* <img
                src={addBtn}
                alt=""
                height="10"
                width="10"
                className="mx-2"
              /> */}
                + Add Timesheet{" "}
              </button>
            )}
          </div>
          {/* <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="month"
                  placeholder="Search"
                  className="ms-2"
                  onChange={(e) => {
                    let selectedMonth = new Date(e.target.value);
                    setMonth(selectedMonth);

                    let selectYear = new Date(e.target.value);
                    setyear(selectYear);
                  }}
                  value={displayMonthAndYear ? displayMonthAndYear : ""}
                />
              </div>
            </div>
          </div> */}
          {loader ? (
            <Loader />
          ) : projectList && projectList.length > 0 ? (
            <Accordion>
              <Accordion.Item eventKey="" className="topAccordiance">
                <Accordion.Header>
                  <div className="table-wrapper" id="tbl-rowBld">
                    <table>
                      <tr>
                        <th className="serialnumber">Sr.No</th>
                        <th className="projectname">Project Name</th>
                        <th className="startdate">Start Date</th>
                        <th className="enddate">End Date</th>
                        <th className="remark">Remark</th>
                        {/* <th className="downld-af" style={{paddingRight: 1}}>Download</th> */}
                      </tr>
                    </table>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
              {projectList.map((projet, index) => {
                return (
                  <Accordion.Item eventKey={"project" + index}>
                    <Accordion.Header>
                      <div className="table-wrapper table-row-custom-padding">
                        <table>
                          <tr className="table-row-custom bodypadding">
                            <td
                              onClick={(e) => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetListApi(projet.ProjectId);
                                setProjectId(projet.ProjectId);
                              }}
                            >
                              {" "}
                              {index + 1}{" "}
                            </td>
                            <td
                              onClick={(e) => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetListApi(projet.ProjectId);
                                setProjectId(projet.ProjectId);
                              }}
                            >
                              {projet.ProjectNames}{" "}
                            </td>
                            <td
                              onClick={(e) => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetListApi(projet.ProjectId);
                                setProjectId(projet.ProjectId);
                              }}
                            >
                              {" "}
                              {projet.StartDate}
                            </td>
                            <td
                              onClick={(e) => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetListApi(projet.ProjectId);
                                setProjectId(projet.ProjectId);
                              }}
                              style={{ paddingRight: 80 }}
                            >
                              {" "}
                              {projet.EndDate}
                            </td>
                            <td
                              onClick={(e) => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetListApi(projet.ProjectId);
                                setProjectId(projet.ProjectId);
                                // Zeshaan Changes for status count {projet.Approved && projet.Count}
                              }}
                              style={{ paddingRight: 170 }}
                            >
                              {projet.Approved}
                              {projet?.Remark}
                            </td>

                            <td
                              onClick={(e) => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetListApi(projet.ProjectId);
                                setProjectId(projet.ProjectId);
                              }}
                            >
                              {dropdownacc == true ? (
                                <img src={upArrow} alt="" />
                              ) : (
                                <img src={downArrow} alt="" />
                              )}
                            </td>
                            <td></td>
                          </tr>
                        </table>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {/* timesheetListLoader */}
                      <div className="AccordianBody">
                        {timesheetListLoader ? (
                          <div
                            style={{
                              marginLeft: "50%",
                              // marginTop: "20px",
                              // marginBottom: "20px",
                            }}
                            class="spinner-border text-info"
                            role="status"
                          ></div>
                        ) : timesheetList && timesheetList.length > 0 ? (
                          <table>
                            <tr className="table-row-custom bodypadding">
                              <td>Day & Date</td>
                              <td>Task</td>
                              <td>Activity</td>
                              <td>Total Working Hrs</td>
                              <td>Status</td>
                              <td>Action</td>
                              <td
                                onClick={(e) => {
                                  setDropdownacc(!dropdownacc);
                                  //getTimeSheetListApi(projet.ProjectId);
                                  setProjectId(projet.ProjectId);
                                  downloadExcelFile(e, projet.ProjectId);
                                }}
                              >
                                {excelLoading ? (
                                  <div
                                    class="spinner-border"
                                    role="status"
                                    style={{
                                      width: "1rem",
                                      height: "1rem",
                                      //marginTop: "5px",
                                      //marginLeft: "5px",
                                      color: "#5ac8fa",
                                    }}
                                  ></div>
                                ) : (
                                  <img
                                    className="downld-exl "
                                    src={BookIcon}
                                    alt=""
                                    style={{
                                      paddingRight: 1,
                                      backgroundColor: "#EEF8FD",
                                    }}
                                  //onClick={(e) => downloadExcelFile(e)}
                                  />
                                )}
                              </td>
                            </tr>
                            {timesheetList.map((item, index) => {
                              return (
                                <tr
                                  className="tabel-body-acordince"
                                  key={"piojn" + index}
                                >
                                  <td>{getDateddmmyyyy(item.Date)}</td>
                                  <td className="fixed-width">
                                    {item.TaskName}
                                    {/* <ul>
                              <li>{item.TaskName}</li>
                            </ul> */}
                                  </td>
                                  <td className="fixed-width">
                                    {item.Activity}
                                    {/* <ul>
                              <li>{item.Activity}</li>
                            </ul> */}
                                  </td>
                                  <td>
                                    {`${displayTime(item.TotalHour).hours < 10
                                      ? 0
                                      : ""
                                      }${displayTime(item.TotalHour).hours}:${displayTime(item.TotalHour).minutes < 10
                                        ? 0
                                        : ""
                                      }${displayTime(item.TotalHour).minutes}`}
                                  </td>
                                  <td
                                    className={
                                      item.Status === "P"
                                        ? "pending-tabel-data"
                                        : item.Status === "A"
                                          ? "Approve-tabel-data"
                                          : item.Status === "R"
                                            ? "Rejected-tabel-data"
                                            : ""
                                    }
                                  >
                                    {item.Status === "P" ? (
                                      <span>Pending</span>
                                    ) : item.Status === "A" ? (
                                      <span>Approved</span>
                                    ) : item.Status === "R" ? (
                                      <span>Rejected</span>
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    {permissions &&
                                      permissions["Edit Timesheet Entry"] && (
                                        <Link>
                                          {" "}
                                          {item.Status === "A" ||
                                            item.Status === "R" ? (
                                            <img
                                              src={disabled_edit}
                                              alt=""
                                              className="me-3"
                                            />
                                          ) : (
                                            <img
                                              onClick={(e) => {
                                                setValidated(false);
                                                setAddTimeshetdata({
                                                  EmpId: Number(userData.EmpId),
                                                  OrgId: Number(userData.OrgId),
                                                  TotalHours: 0,
                                                  TotalEnteredHours: 0,
                                                  WorkedDate: "",
                                                  AddTimeSheet: [
                                                    {
                                                      ProjectId: "",
                                                      TaskId: "",
                                                      WorkedHours: "",
                                                      Activity: "",
                                                      EntryDate: new Date()
                                                        .toISOString()
                                                        .toString(),
                                                    },
                                                  ],
                                                });
                                                getTimeSheetEditData(
                                                  item.Date,
                                                  item.TimeSheetDetId,
                                                  item.TimeSheetId
                                                );
                                                setProjectId(projet.ProjectId);
                                                setEditTime(true);
                                              }}
                                              src={EditacBlc}
                                              alt=""
                                              className="me-3"
                                            />
                                          )}
                                        </Link>
                                      )}
                                    {permissions &&
                                      permissions["Delete Timesheet Entry"] && (
                                        <Link>
                                          {item.Status === "A" ||
                                            item.Status === "R" ? (
                                            <img src={DelectAc} alt="" />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                swal({
                                                  text: "Are you sure you want to delete?",
                                                  icon: "warning",
                                                  buttons: true,
                                                  dangerMode: true,
                                                }).then((willDelete) => {
                                                  if (willDelete) {
                                                    timeSheetDeleteApi(
                                                      item.TimeSheetId,
                                                      projet.ProjectId
                                                    );
                                                  }
                                                });
                                              }}
                                              src={DelectAcBlc}
                                              alt=""
                                            />
                                          )}
                                        </Link>
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              color: "red",
                              padding: "10px",
                            }}
                          >
                            Data Not Found
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <>
              <NoData />
            </>
          )}
        </div>
      </div>

      {/* Add Timesheet Modal */}
      <Modal
        show={showSheetModal}
        onHide={() => setShowSheetModal(false)}
        size="lg"
        centered
      >
        <Form noValidate validated={validated} onSubmit={addTimeSheet}>
          <Modal.Header>
            <Modal.Title>Add TimeSheet</Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShowSheetModal(false)} />
            </div>
          </Modal.Header>
          <div className="lineSeperate2"></div>
          <Modal.Body>
            <div className="mainTimeModal mb-3">
              <div className="dateTimeSection ">
                <input
                  type="date"
                  className="form-control tasKCategory1"
                  onChange={(e) => {
                    setAddTimeshetdata({
                      EmpId: Number(userData.EmpId),
                      OrgId: Number(userData.OrgId),
                      TotalHours: 0,
                      WorkedDate: "",
                      AddTimeSheet: [
                        {
                          ProjectId: "",
                          TaskId: "",
                          WorkedHours: "",
                          Activity: "",
                          EntryDate: new Date().toISOString().toString(),
                        },
                      ],
                    });
                    setDate(e.target.value)
                    getTimeSheetdetaByDate(e.target.value);
                    getProjectApi(e.target.value)
                    setAddTimeshetdata({
                      ...addTimeshetdata,
                      WorkedDate: new Date(e.target.value)
                        .toISOString()
                        .toString(),
                    });
                    getEmployeeTaskDate(new Date(e.target.value))
                  }}
                  required
                />
                <div className="invalid-feedback">Please select date</div>
                {/* <label className="text-grey">Date : 20-11-2022, Monday</label> */}
              </div>
              <div className="punchedHrs p-0">
                <div className="">
                  <div className="d-flex mb-2 punch-hrs">
                    <label className="text-grey">Total Punched Hrs :</label>{" "}
                    <label className="text-grey">
                      &nbsp;
                      {punchedTime(addTimeshetdata?.TotalHours)
                        ? punchedTime(addTimeshetdata?.TotalHours).slice(0, 2)
                        : "00"}{" "}
                      Hrs{" "}
                      {punchedTime(addTimeshetdata?.TotalHours)
                        ? punchedTime(addTimeshetdata?.TotalHours).slice(3, 5)
                        : "00"}{" "}
                      Min
                    </label>
                  </div>
                  <div className="d-flex wrk-hrs">
                    <label className="text-grey">Total Entered Hrs :</label>
                    &nbsp;
                    {totalWorkingHrs === "00:00" ? (
                      <label className="text-grey">00 Hrs 00 Min</label>
                    ) : (
                      <label className="text-grey">
                        {totalWorkingHrs ? totalWorkingHrs.slice(0, 2) : "00"}{" "}
                        Hrs &nbsp;
                        {totalWorkingHrs
                          ? totalWorkingHrs.slice(3, 5)
                          : "00"}{" "}
                        Min
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="forms">
              {addTimeshetdata.AddTimeSheet.map((item, index) => {
                return (
                  <div className="mainFormSection box-shadow p-3 mb-3">
                    <div className="d-flex justify-content-between headingMain">
                      <h5>Task {index + 1}</h5>
                      {addTimeshetdata.AddTimeSheet.length > 1 ? (
                        <img
                          src={redCross}
                          alt=""
                          onClick={() => deleteHandler(index)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="lineSeperate2"></div>
                    <div className="row" id="tite-alig">
                      <div className="col-md-4">
                        <label className="text-grey mb-2">Project</label>
                        <select
                          className="form-select tasKCategory1 mb-2 select-drop-icon"
                          aria-label="Default select example"
                          required
                          // disabled={addTimeshetdata?.TotalHours ? false : true}
                          value={item.ProjectId}
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      ProjectId: Number(e.target.value),
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                            getEmployeeTask(Number(e.target.value));
                          }}
                        >
                          <option value="">Select Project</option>
                          {projectName &&
                            projectName.map((project) => {
                              return (
                                <option value={project.ProjectId}>
                                  {project.ProjectName}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          Please select project
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="text-grey mb-2">Task</label>
                        <select
                          className="form-select tasKCategory1 mb-2 select-drop-icon"
                          aria-label="Default select example"
                          disabled={
                            Object.keys(task).length > 0
                              ? // && task[item.ProjectId]?.length
                              false
                              : true
                          }
                          value={item.TaskId}
                          placeholder="Select task..."
                          required
                          onChange={(e) => {

                            let selectedTask = task[item.ProjectId]?.filter((i) => i.TaskId == e.target.value)

                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {

                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      TaskId: Number(e.target.value),
                                      Activity: selectedTask ? selectedTask[0]?.TaskDescription : '',
                                      TaskDescription: selectedTask ? selectedTask[0]?.TaskDescription : ''
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                        >
                          {
                            // Object.keys(task).length > 0 &&
                            task[item.ProjectId]?.length > 0 ? (
                              <>
                                <option value="">Select Task</option>
                                {task[item.ProjectId] &&
                                  task[item.ProjectId].map((tsk) => {
                                    return (
                                      <option value={tsk.TaskId}>
                                        {tsk.TaskName}
                                      </option>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <option value="">Select Task</option>
                                <option value="">No task</option>
                              </>
                            )
                          }
                        </select>
                        <div className="invalid-feedback">
                          Please select task
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <label className="text-grey mb-2">Working Hours</label>
                        <Calendar
                          id="calendar-timeonly"
                          // disabled={isDisabled ? true : false}
                          value={
                            item?.WorkedHours
                              ? convertMinutesInTime(item?.WorkedHours)
                              : ""
                            // convertMinutesInTime("09:00")
                          }
                          onChange={(e) => {
                            console.log("total work hours,", e?.value);
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      WorkedHours:
                                        e?.value && setWorkHrs(e?.value),
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                            setWorkHrsErrMsg("");
                          }}
                          timeOnly
                        />
                        {totalWorkedHrsArr.indexOf("") === index &&
                          workHrsErrMsg && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: ".875em",
                                color: "#dc3545",
                              }}
                            >
                              Please select work hours
                            </div>
                          )}
                        {/* <input
                          type="time"
                          className="form-control tasKCategory1 mb-2"
                          // disabled={item?.WorkedHours ? true : false}
                          value={convertMinutesInTime(item.WorkedHours)}
                          required
                          onChange={(e) => {
                           
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      WorkedHours: convertTimeInMinutes(
                                        e.target.value
                                      ),
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                        /> */}
                        {/* <img src={watch} className="watchTimesheet" alt="" /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12" id="tite-alig">
                        <label className="text-grey mb-2">Activity</label>
                        <textarea
                          className="form-control "
                          style={{ border: "1px solid #53b7e8" }}
                          rows="3"
                          value={item?.TaskDescription}
                          // disabled={isDisabled ? true : false}
                          required
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      Activity: e.target.value,
                                      TaskDescription: e.target.value
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                        ></textarea>
                        <div className="invalid-feedback">Enter Activity</div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div class="p-datepicker p-component p-datepicker-timeonly p-ripple-disabled p-connected-overlay-enter-done"
                style={{
                  left: "572px",
                  top: "218px"
                }}
              >
                <div class="p-timepicker"><div class="p-hour-picker"><button type="button" class="p-link"><span class="pi pi-chevron-up"></span></button><span>19</span><button type="button" class="p-link"><span class="pi pi-chevron-down"></span></button></div><div class="p-separator"><span>:</span></div><div class="p-minute-picker"><button type="button" class="p-link"><span class="pi pi-chevron-up"></span></button><span>53</span><button type="button" class="p-link"><span class="pi pi-chevron-down"></span></button></div></div></div>
              */}
              <div className="addMoreBtn mb-3" id="tite-alig">
                <button
                  type="button"
                  className="addNewBtn "
                  onClick={() => {
                    setAddTimeshetdata({
                      ...addTimeshetdata,
                      AddTimeSheet: [
                        ...addTimeshetdata.AddTimeSheet,
                        {
                          ProjectId: "",
                          TaskId: "",
                          WorkedHours: "",
                          Activity: "",
                          EntryDate: new Date().toISOString().toString(),
                        },
                      ],
                    });
                    setValidated(false);
                  }}
                >
                  {" "}
                  + Add New
                </button>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => {
                setDate('')
                setShowSheetModal(false);
                setAddTimeshetdata({
                  EmpId: Number(userData.EmpId),
                  OrgId: Number(userData.OrgId),
                  TotalHours: 0,
                  WorkedDate: new Date().toJSON().slice(0, 10),
                  AddTimeSheet: [
                    {
                      ProjectId: "",
                      TaskId: "",
                      WorkedHours: "",
                      Activity: "",
                      EntryDate: new Date().toISOString().toString(),
                    },
                  ],
                  modifiedAt: new Date().toISOString().toString(),
                  TimeSheetId: 0,
                });
              }}
            >
              Cancel
            </button>
            <button
              disabled={
                loading ? true : addTimeshetdata?.TotalHours ? false : true
              }
              className="btn-width saveBtn"
            >
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#fff" size={18} />
                </div>
              ) : (
                // <div
                //   class="spinner-border text-light"
                //   role="status"
                //   style={{
                //     width: "1.2rem",
                //     height: "1.2rem",
                //   }}
                // ></div>
                "Submit"
              )}
            </button>
          </div>
        </Form>
      </Modal>

      {/* Edit Time Modal */}
      <Modal
        show={editTime}
        onHide={() => setEditTime(false)}
        size="lg"
        centered
      >
        <Form noValidate validated={validated} onSubmit={editTimesheet}>
          <Modal.Header>
            <Modal.Title>Edit TimeSheet</Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setEditTime(false)} />
            </div>
          </Modal.Header>
          <div className="lineSeperate2"></div>
          <Modal.Body>
            <div className="mainTimeModal mb-3">
              <div className="dateTimeSection">
                {/* <label className="text-grey">Date : 20-11-2022, Monday</label> */}
                {addTimeshetdata.WorkedDate && (
                  <label className="text-grey">
                    Date :{" "}
                    {addTimeshetdata.WorkedDate
                      ? getDateddmmyyyy(addTimeshetdata.WorkedDate)
                      : ""}
                    , {getWeekDay(addTimeshetdata.WorkedDate)}
                  </label>
                )}
              </div>
              <div className="punchedHrs p-0">
                <div className="">
                  <div className="d-flex mb-2 punch-hrs">
                    <label className="text-grey">Total Punched Hrs :</label>
                    <label className="text-grey">
                      &nbsp;
                      {addTimeshetdata?.TotalHours !== 0
                        ? punchedTime(addTimeshetdata?.TotalHours).slice(0, 2)
                        : "00"}{" "}
                      Hrs{" "}
                      {addTimeshetdata?.TotalHours !== 0
                        ? punchedTime(addTimeshetdata?.TotalHours).slice(3, 5)
                        : "00"}{" "}
                      Min
                    </label>
                  </div>
                  <div className=" d-flex wrk-hrs">
                    <label className="text-grey">Total Entered Hrs :</label>
                    <label className="text-grey">
                      {totalWorkingHrs ? totalWorkingHrs.slice(0, 2) : "00"} Hrs
                      &nbsp;
                      {totalWorkingHrs ? totalWorkingHrs.slice(3, 5) : "00"} Min
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="forms">
              {addTimeshetdata.AddTimeSheet.map((item, index) => {
                let filteredTaskId =
                  Object.keys(task).length > 0 &&
                  task[item.ProjectId]?.filter(
                    (task) => item?.TaskId === task?.TaskId
                  )[0]?.TaskId;
                return (
                  <div className="mainFormSection box-shadow p-3 mb-3">
                    <div className="d-flex justify-content-between headingMain">
                      <h5>Project {index + 1}</h5>
                      {addTimeshetdata.AddTimeSheet.length > 1 ? (
                        <img
                          src={redCross}
                          alt=""
                          onClick={() => deleteHandler(index)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="lineSeperate2"></div>
                    <div className="row" id="tite-alig">
                      <div className="col-md-4 mb-4">
                        <label className="text-grey mb-2">Project</label>
                        <select
                          className="form-select tasKCategory1"
                          aria-label="Default select example"
                          value={item.ProjectId}
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      ProjectId: Number(e.target.value),
                                      TaskId: "",
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                            getEmployeeTask(Number(e.target.value));
                          }}
                          required
                        >
                          <option value="">Select Project</option>
                          {projectName &&
                            projectName.map((pro) => {
                              return (
                                <option value={pro.ProjectId}>
                                  {pro.ProjectName}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          Please select project
                        </div>
                      </div>
                      <div className="col-md-4 mb-4">
                        <label className="text-grey mb-2">Task</label>
                        <select
                          className="form-select tasKCategory1"
                          aria-label="Default select example"
                          // value={item.TaskId}
                          value={filteredTaskId ? filteredTaskId : 0}
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      TaskId: Number(e.target.value),
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                          required
                        >
                          <option value="">Select Task</option>
                          {task[item.ProjectId] &&
                            task[item.ProjectId].map((tsk) => {
                              return (
                                <option value={tsk.TaskId}>
                                  {tsk.TaskName}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          Please select task
                        </div>
                      </div>
                      <div className="col-md-4 mb-4 d-flex flex-column">
                        <label className="text-grey mb-2">Working Hours</label>
                        <Calendar
                          id="calendar-timeonly"
                          value={
                            item.WorkedHours
                              ? convertMinutesInTime(item.WorkedHours)
                              : ""
                          }
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      WorkedHours:
                                        e?.value && setWorkHrs(e.value),
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                          timeOnly
                        />
                        {/* <input
                          type="time"
                          className="form-control tasKCategory1"
                          value={convertMinutesInTime(item.WorkedHours)}
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      WorkedHours: convertTimeInMinutes(
                                        e.target.value
                                      ),
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                        // required
                        /> */}
                        {/*<div className="invalid-feedback">Please select task</div>*/}
                        {/* <img src={watch} className="watchTimesheet" alt="" /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12" id="tite-alig">
                        <label className="text-grey mb-2">Activity</label>
                        <textarea
                          className="form-control tasKCategory1"
                          rows="4"
                          value={item?.Activity}
                          required
                          onChange={(e) => {
                            setAddTimeshetdata({
                              ...addTimeshetdata,
                              AddTimeSheet: addTimeshetdata.AddTimeSheet.map(
                                (innerItem, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                      ...innerItem,
                                      Activity: e.target.value,
                                    }
                                    : innerItem;
                                }
                              ),
                            });
                          }}
                        ></textarea>
                        <div className="invalid-feedback">
                          Please select task
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="addMoreBtn mb-3">
                <button
                  className="addNewBtn"
                  onClick={() => {
                    setAddTimeshetdata({
                      ...addTimeshetdata,
                      AddTimeSheet: [
                        ...addTimeshetdata.AddTimeSheet,
                        {
                          ProjectId: "",
                          TaskId: "",
                          WorkedHours: 0,
                          Activity: "",
                          EntryDate: new Date().toISOString().toString(),
                        },
                      ],
                    });
                  }}
                >
                  {" "}
                  + Add New
                </button>
              </div> */}
            </div>
          </Modal.Body>
          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => {
                setDate('')
                setEditTime(false);
                setAddTimeshetdata({
                  EmpId: Number(userData.EmpId),
                  OrgId: Number(userData.OrgId),
                  TotalHours: 0,
                  WorkedDate: "",
                  AddTimeSheet: [
                    {
                      ProjectId: "",
                      TaskId: "",
                      WorkedHours: "",
                      Activity: "",
                      EntryDate: new Date().toISOString().toString(),
                    },
                  ],
                });
              }}
            >
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              disabled={loading ? true : false}
            >
              {" "}
              {loading ? (
                <div
                  class="spinner-border text-light"
                  role="status"
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    position: "static",
                    marginLeft: "40%",
                  }}
                ></div>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Form>
      </Modal>

      {/* Delete Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Timesheet Entry
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete ?</p>
        </Modal.Body>
        {/* <div class="lineSeperate"></div>
        <Modal.Footer className="delete-footer">
          <div className="col-md-10 d-flex justify-content-end align-items-end">
            <div className="mainBtnsSub2">
              <Button
                variant="secondary"
                className="btn-width cancel-btn mx-4"
                onClick={handleClose3}
              >
                Cancel
              </Button>
              <Button variant="primary" className="btn-width sbmt-btn">
                Delete
              </Button>
            </div>
          </div>
        </Modal.Footer> */}

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button className="btn-width saveBtn">Delete</button>
        </div>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default Timesheet;
