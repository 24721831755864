import "../../Inventory Management System/Styles/tax.css";
import "../../Inventory Management System/Styles/variation.css";
import "../../Inventory Management System/Styles/spreadsheet.css";

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import ApiConfig from "../../../api/ApiConfig";
import { simpleGetCall, simplePatchCall } from "../../../api/ApiServices";
import { AppContext } from "../../../context/AppContext";
import zamzam from "../../../assets/images/zamzam.svg";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import Loader from "../../Loader";
import NoData from "../../../sharedComponent/NoData";

// index.js or App.js
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import Image from "next/image";

const MenuOnOff = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const [statusItem, setStatusItem] = useState("");
  const [DataList, setDataList] = useState([]);
  const [activeTab, setActiveTab] = useState(DataList);

  const [SelectedDropId, setSelectedDropId] = useState("All");
  const [Productname, setProductname] = useState("");
  const [categoryList, SetCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const isBaseMenuRoute = pathnames.includes("BaseMenu"); // Check if current route is under BaseMenu
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { sidebar, selectedStoreID } = useContext(AppContext);
  const [storeOnOff, setStoreOnOff] = useState(false);
  const [AddOnOff, setAddOnOff] = useState(false);

  let permissions = JSON.parse(localStorage.getItem("Permissions"))
    ? JSON.parse(localStorage.getItem("Permissions"))
    : {};
  const resetFilters = () => {
    SetCategoryList("");
    setSelectedItem("All");
    setSelectedDropId("All");
    fetchCategoryList();
    // Reset other filter states as needed
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const options = [
    { label: "All", value: "All" },
    {
      label: (
        <div className="d-flex align-items-center gap-2">
          <span
            className="rounded-circle bg-success"
            style={{ width: "10px", height: "10px" }}
          ></span>
          <span>On</span>
        </div>
      ),
      value: "On",
    },
    {
      label: (
        <div className="d-flex align-items-center gap-2">
          <span
            className="rounded-circle bg-danger"
            style={{ width: "10px", height: "10px" }}
          ></span>
          <span>Off</span>
        </div>
      ),
      value: "Off",
    },
  ];

  const handleSelect = (item) => {
    console.log(item);
    const backendValues = {
      On: 1,
      Off: 0,
      All: "",
    };

    setSelectedItem(item.value);
    setStatusItem(backendValues[item.value]);
    setIsOpen(false);
  };

  const handleChange = (selectedOption) => {
    setSelectedDropId(selectedOption);
  };
  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    setLoading(true);
    const url = ApiConfig?.GET_CATEGORY_LIST_DATA;
    try {
      const response = await simpleGetCall(`${url}202`);
      if (response.success === true) {
        setLoading(false);
        const category = response?.data;
        const storeList = category.map((store) => ({
          value: store.id,
          label: store.category_name,
        }));

        console.log("categoryList>>>>", category);
        SetCategoryList(storeList);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };

  useEffect(() => {
    if (selectedStoreID) {
      fetchData();
    }
  }, [selectedStoreID, SelectedDropId, Productname, statusItem]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const res = await simpleGetCall(
        `${ApiConfig.LIST_MENU_ITEM}category_name=${
          SelectedDropId.label || ""
        }&product_name=${Productname}&status=${statusItem}&shop_id=${
          selectedStoreID || ""
        }`
      );

      if (res?.data) {
        setDataList(res.data || []);
        setLoading(false);
      } else {
        setDataList([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const formattedStores = categoryList?.length
    ? categoryList.map((store) => ({
        value: store.value, // Ensure store has an 'id' field
        label: store.label, // Ensure store has a 'label' field
      }))
    : [];

  // const activeItems =
  //   DataList.find((tab) => tab.category_id === activeTab)?.item_data || [];

  const activeItems =
    DataList.find((tab) => tab.category_id === activeTab)?.item_data || [];
  // Drag True

  const handleTabClick = (category_id) => {
    setActiveTab(category_id);

    // Find the selected category
    const selectedCategory = DataList.find(
      (tab) => tab.category_id === category_id
    );

    // Check if selectedCategory and selectedCategory.items are defined and are arrays
    if (selectedCategory && Array.isArray(selectedCategory.items)) {
      const newItems = selectedCategory.items.filter(
        (item) => !activeItems.some((activeItem) => activeItem.id === item.id)
      );
      setDataList((prevItems) => [...prevItems, ...newItems]);
    } else {
      console.error(
        "Selected category or items are undefined or not an array."
      );
    }
  };

  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const toggleSwitch = (id, active, status) => {
    // Toggle the switch state

    // Send the updated status to the server
    updateSingleStatus(id, active, status);
  };

  const updateSingleStatus = async (id, isActive, status) => {
    try {
      const requestBody = {
        type: status,
        ids: [id], // Assuming the API expects an array of IDs
        active: isActive, // Send the new active status
      };

      const res = await simplePatchCall(
        `${ApiConfig?.UPDATE_MENU_ITEM}`,
        requestBody
      );

      if (res.success) {
        notifySuccess(res.message);
        fetchData(); // Refresh data after successful update
        AddonList();
      } else {
        console.error("API response was not successful:", res.message);
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (itemId) => {
    setSelectedItemIds(
      (prevSelected) =>
        prevSelected.includes(itemId)
          ? prevSelected.filter((id) => id !== itemId) // Deselect if already selected
          : [...prevSelected, itemId] // Select if not selected
    );
  };

  const updateSelectedItemsStatus = async (newStatus, status) => {
    try {
      // Prepare the data for the API request

      const requestBody = {
        type: status,
        ids: selectedItemIds,
        active: newStatus,
      };
      // Send the PATCH request to your API
      const res = await simplePatchCall(
        `${ApiConfig?.UPDATE_MENU_ITEM}`,
        requestBody
      );

      if (res.success) {
        notifySuccess(res.message);
        AddonList();
        fetchData(); // Refresh data after successful update
      } else {
        console.error("API response was not successful:", res.message);
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  const [AddonItemList, SetAddonItemList] = useState([]);
  // const [storeList, SetStoreItem] = useState({});
  console.log(AddonItemList);

  useEffect(() => {
    if (selectedStoreID) {
      AddonList();
      // StoreList()
    }
  }, [selectedStoreID]);
  // Adddon ON OFF Secation//
  const AddonList = async () => {
    setLoading(true);
    const storeId = selectedStoreID;

    try {
      const response = await simpleGetCall(
        ApiConfig.ADDON_ON_OFF_LIST + storeId
      );
      if (response.success === true) {
        setLoading(false);
        const list = response?.data;

        SetAddonItemList(list);
      } else {
        SetAddonItemList([]);
        setLoading(false);
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };

  // ---------------------------------------------------

  const [storeList, setStoreList] = useState({
    store_status: "",
    turn_on_time: "",
    store_status_reason: "",
    restaurant_name: "",
    toggle: false,
  });
  console.log("storeList", storeList);

  useEffect(() => {}, [storeList]);

  const storeOfStatus = async () => {
    try {
      // Prepare the data for the API request

      const requestBody = {
        store_status: Number(storeList.store_status),
        turn_on_time: storeList.turn_on_time,
        reason: storeList.store_status_reason,
      };
      // Send the PATCH request to your API
      const res = await simplePatchCall(
        `${ApiConfig?.STORE_STATUS + selectedStoreID}`,
        requestBody
      );

      if (res.success) {
        notifySuccess(res.message);
        fetchStoreData();
        setStoreOnOff(false);
      } else {
        console.error("API response was not successful:", res.message);
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  const storeonStatus = async () => {
    try {
      // Prepare the data for the API request

      const requestBody = {
        store_status: 1,
        turn_on_time: "",
        reason: "",
      };
      // Send the PATCH request to your API
      const res = await simplePatchCall(
        `${ApiConfig?.STORE_STATUS + selectedStoreID}`,
        requestBody
      );

      if (res.success) {
        notifySuccess(res.message);
        fetchStoreData();
        setStoreOnOff(false);
      } else {
        console.error("API response was not successful:", res.message);
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };
  // Function to handle input changes
  const handleChangetoggle = (e) => {
    const { name, value, type, checked } = e.target;

    setStoreList((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (checked === true) {
      storeonStatus();
    }
  };

  // Function to handle form submission

  // Fetch data when component mounts
  useEffect(() => {
    fetchStoreData();
  }, []);

  // Function to fetch store data
  const fetchStoreData = async () => {
    try {
      const response = await simpleGetCall(
        ApiConfig.STORE_ON_OFF + selectedStoreID + "/status"
      );
      if (response.success) {
        const data = response.status;
        setStoreList({
          store_status: data.store_status,
          turn_on_time: data.turn_on_time || "",
          store_status_reason: data.store_status_reason || "",
          restaurant_name: data.restaurant_name,
          // toggle: data.store_status === '1',
        });
      } else {
        // Handle error or set default values
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Stock List</h6>
        </div>
        <div className=" p-3 mt-1 position-relative" style={{ height: "100%" }}>
          {/* Header Section */}

          {/* <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <div
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "10px",
                flexDirection: "row",
                display: isSmallScreen ? null : "flex",
              }}
            >
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-center gap-3 justify-content-lg-end justify-content-center mb-8 mb-lg-0 w-auto ms-0">
         



            </div>
          </div> */}

          {/* Search Form */}
          {loading ? (
              <Loader />
            ) : (
         <>
          {AddOnOff === false ? (
            <div className="d-flex justify-content-between align-items-end">
              <div className="row g-2 align-items-end" role="search">
                <div className="col-md-auto col-sm-6 col-12 z-3">
                  <label htmlFor="category" className="add-text1">
                    Category
                  </label>
                  <Select
                    options={formattedStores}
                    value={SelectedDropId}
                    className=""
                    onChange={handleChange}
                    onMenuOpen={() => console.log("Dropdown opened")}
                    placeholder="Choose a store..."
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        width: "250px",
                        height: "42px",
                        backgroundColor: "#fff",
                        borderColor: state.isFocused ? "#ccc" : "#ddd",
                        "&:hover": {
                          borderColor: "#bbb",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#ffffff"
                          : state.isFocused
                          ? "#f0f0f0"
                          : "#fff",
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-md-auto col-sm-6 col-12">
                  <label htmlFor="category" className="add-text1">
                    Status
                  </label>
                  <div className="position-relative z-3">
                    {/* Dropdown Button */}
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      className="dropdown-button flex items-center justify-between bg-white border border-gray-300 px-4 py-2 rounded shadow-md hover:bg-gray-100 focus:outline-none"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        height: "42px",
                      }}
                    >
                      {selectedItem}
                      <span className="" style={{ scale: "1.2" }}>
                        <svg
                          style={{ width: "16px" }}
                          xmlns="http://www.w3.org/2000/svg"
                          className={`transform transition-transform ${
                            isOpen ? "rotate-180" : "rotate-0"
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    </button>

                    {/* Dropdown Menu */}
                    {isOpen && (
                      <div
                        className="absolute z-10 mt-2 w-100 bg-white border border-gray-300 rounded shadow-lg"
                        style={{ position: "absolute" }}
                      >
                        {/* Dropdown Items */}
                        <ul className="max-h-60 overflow-auto">
                          {options.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(item)}
                              className="px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer d-flex align-items-start gap-2"
                            >
                              {item.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-auto col-sm-6 col-12">
                  <button className="btn btn-primary w-100 px-3 py-2 btn-responsive">
                    <label className="add-text">Show</label>
                  </button>
                </div> */}

                <div className="col-md-auto col-sm-6 col-12">
                  <button
                    className="btn w-100 px-3 py-2 "
                    onClick={resetFilters}
                    style={{backgroundColor:"#53B7E8", color:"#fff"}}
                  >
                    <label className="add-">Clear All</label>
                  </button>
                </div>

                {/* <div className="col-md-auto col-sm-6 col-12">
                  <button
                    className="btn  d-flex justify-content-center align-items-center gap-2 w-100 px-3 py-2 border border-gray-300 btn-responsive "
                    onClick={() => window.location.reload()}
                  >
                    <label className="add-text1">Refresh</label>
                    <img
                      src="/images/refresh.svg"
                      alt="Button Icon"
                      width={16}
                      height={16}
                    />
                  </button>
                </div> */}
              </div>

              <div className="d-flex flex-column flex-lg-row  justify-content-between">
            {/* Breadcrumbs */}
            <div
              style={{
                alignItems: "center",
                // justifyContent: "space-between",
                gap: "10px",
                padding: "10px",
                flexDirection: "row",
                display: isSmallScreen ? null : "flex",
              }}
            >
              {/* Back Button */}
            </div>

            {/* Buttons Section */}
            <div className="d-flex flex-column flex-lg-row align-items-center gap-3 justify-content-lg-end justify-content-center mb-8 mb-lg-0 w-auto ms-0">
              {/* Action On/Off Button */}

              {AddOnOff === false && 
  <>
    {permissions && permissions["View Addon on/off"] && (
      <div className="addbut1 catbutton2 col-auto mb-3">
        <button
          style={{ backgroundColor: "#53B7E8", color: "#fff" }}
          className="create-btn-sub-header"
          onClick={() => setAddOnOff(true)}
        >
          <label className="add-te">Addon on/off</label>
        </button>
      </div>
    )}
  </>
}


              {/* Dropdown Button */}
              
              <div className="addbut1 catbutton2 col-auto mb-3">
                <button
                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                  // className="bg-white text-gray-700 d-flex justify-content-center align-items-center w-100 w-lg-auto gap-2 px-4 py-2 rounded border border-gray-300"
                  className="create-btn-sub-header"
                >
                  <label
                    className="add-text-"
                    onClick={() => setStoreOnOff(true)}
                  >
                    Store on/off
                  </label>
                </button>
              </div>
            </div>
          </div>
            </div>
          ) : (
            <>
            <div className="d-flex justify-content-end addbut1 catbutton2 col-auto mb-3">
            <button
              style={{ backgroundColor: "#53B7E8", color: "#fff" }}
              className="create-btn-sub-header"
              onClick={() => setAddOnOff(false)}
            >
              <label className="add-">Item</label>
            </button>
          </div>
          </>
          )}

          {/* Tabs and Content */}
          {DataList.length > 0 ? (
                  <>

          <div className="flex h-full gap-4 ">
            {/* Tabs Sidebar */}

            {AddOnOff === false ? (
              <>
                <div className="bg-white pt-3 rounded-2" style={{ marginTop: "18px" }}>
                  <ul
                    style={{
                      padding: "10px",
                      margin: "5px",
                      backgroundColor: "#ffffff",
                      height: "550px", // Fixed height
                      overflowY: "scroll", // Allows scrolling
                      scrollbarWidth: "none", // Hides scrollbar in Firefox
                      msOverflowStyle: "none", // Hides scrollbar in IE/Edge
                    }}
                    className="hide-scrollbar"
                  >
                  

{DataList && DataList.length > 0 ? (
  DataList.map((tab, index) => (
                      <li
                        key={tab.category_id}
                        onClick={() => handleTabClick(tab.category_id)}
                        className={`flex py-1 ps-1 font-size-14 border-bottom ${
                          activeTab === tab.category_id
                            ? "border-left"
                            : "bg-white text-gray"
                        }`}
                        style={{
                          width: "200px",
                          marginBottom: "20px",
                          borderBottom: "1px solid #d3d3d3",
                          cursor: "pointer",
                          padding: "10px",
                          backgroundColor: "#e9ecef",
                        }}
                      >
                        <span
                          className={`width165 ${
                            activeTab === tab.category_id ? "blue-text" : ""
                          }`}
                        >
                          {tab.category_name}
                        </span>
                      </li>
                   ))
                  ) : (
                    <></>
                  )}
                  </ul>
                </div>

                {/* Table Content */}
                {/* Table Content */}

                <main className="pt-3 w-100">
                  <div className="d-flex flex-column gap-3">
                    {/* Active Tab Items */}
                    <div className="w-ful py-1 rounded-2" >
                      <div className="d-flex justify-content-between align-items-start bg-white border-bottom border-light rounded-3 px-2 pt-1">
                        <h3 className="heading-menu">
                          {DataList.find((tab) => tab.category_id === activeTab)
                            ?.category_name || "No Category"}
                        </h3>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="d-flex align-items-center gap-2"
                            onClick={() =>
                              updateSelectedItemsStatus(false, "item")
                            }
                          >
                            <span
                              className="rounded-circle bg-danger"
                              style={{
                                width: "10px",
                                height: "10px",
                                cursor: "pointer",
                              }}
                            ></span>
                            <span>Off</span>
                          </div>
                          <div
                            className="d-flex align-items-center gap-2"
                            onClick={() =>
                              updateSelectedItemsStatus(true, "item")
                            }
                          >
                            <span
                              className="rounded-circle bg-success"
                              style={{
                                width: "10px",
                                height: "10px",
                                cursor: "pointer",
                              }}
                            ></span>
                            <span className="heading-menu">On</span>
                          </div>
                        </div>
                      </div>
<div className="table-wrapper-main mb-4">
   <table className="table table-responsive">
                        <thead>
                          <tr className="bg-grey font-family font-weight-400 font-size-14 ">
                            <th className="text-left tax-type-label">
                              <input type="checkbox" />
                            </th>
                            <th className="text-center tax-type-label">
                              Status
                            </th>
                            <th className="text-left tax-type-label">Name</th>
                            <th className="text-start tax-type-label">
                              Mark As
                            </th>
                            <th className="text-start tax-type-label"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeItems.length > 0 ? (
                            activeItems.map((item) => (
                              <tr key={item.id} className="tax-type-label">
                                <td className="p-2 text-left">
                                  <input
                                    type="checkbox"
                                    checked={selectedItemIds.includes(item.id)}
                                    onChange={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  />
                                </td>
                                <td className="text-center">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span
                                      className={`rounded-circle ${
                                        item.attribute_id
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                      style={{ width: "12px", height: "12px" }}
                                    ></span>
                                  </div>
                                </td>
                                <td className="p-2">{item.item_name}</td>
                                <td className="p-2">
                                  <div
                                    onClick={() =>
                                      toggleSwitch(
                                        item.id,
                                        !item.item_active,
                                        "item"
                                      )
                                    }
                                    style={{
                                      width: "36px",
                                      height: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      borderRadius: "16px",
                                      padding: "4px",
                                      cursor: "pointer",
                                      backgroundColor: item.item_active
                                        ? "#198754"
                                        : "#DC3545",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow:
                                          "0 2px 4px rgba(0, 0, 0, 0.2)",
                                        transform: item.item_active
                                          ? "translateX(16px)"
                                          : "translateX(0)",
                                        transition: "transform 0.3s ease",
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="p-2"></td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                No items available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
</div>
                     
                    </div>
                  </div>
                </main>
              </>
            ) : (
              <>
                <main className="pt-3 w-100">
                  <div className="d-flex flex-column gap-3">
                    {/* Active Tab Items */}
                    {AddonItemList.length > 0 ? (
                      AddonItemList.map((group) => (
                        <div
                          key={group.id}
                          className="w-full bg-white p-4 border border-gray-300"
                        >
                          <div className="d-flex justify-content-between align-items-start bg-white border-bottom border-light">
                            <h3 className="heading-menu">
                              {group.addon_group_name}
                            </h3>
                            <div className="d-flex align-items-center gap-3">
                              <div
                                className="d-flex align-items-center gap-2"
                                onClick={() =>
                                  updateSelectedItemsStatus(false, "addon")
                                }
                              >
                                <span
                                  className="rounded-circle bg-danger"
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    cursor: "pointer",
                                  }}
                                ></span>
                                <span>Off</span>
                              </div>
                              <div
                                className="d-flex align-items-center gap-2"
                                onClick={() =>
                                  updateSelectedItemsStatus(true, "addon")
                                }
                              >
                                <span
                                  className="rounded-circle bg-success"
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    cursor: "pointer",
                                  }}
                                ></span>
                                <span className="heading-menu">On</span>
                              </div>
                            </div>
                          </div>

                          <table className="table table-responsive">
                            <thead>
                              <tr className="bg-grey font-family font-weight-400 font-size-14">
                                <th className="text-left tax-type-label"></th>
                                <th className="text-center tax-type-label">
                                  Status
                                </th>
                                <th className="text-left tax-type-label">
                                  Name
                                </th>
                                <th className="text-start tax-type-label">
                                  Mark As
                                </th>
                                <th className="text-start tax-type-label"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {group.addon_items.length > 0 ? (
                                group.addon_items.map((item) => (
                                  <tr key={item.id} className="tax-type-label">
                                    <td className="p-2 text-left">
                                      <input
                                        type="checkbox"
                                        checked={selectedItemIds.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(item.id)
                                        }
                                      />
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <span
                                          className={`rounded-circle ${
                                            item.addon_group_attributes
                                              ? "bg-success"
                                              : "bg-danger"
                                          }`}
                                          style={{
                                            width: "12px",
                                            height: "12px",
                                          }}
                                        ></span>
                                      </div>
                                    </td>
                                    <td
                                      className="p-2"
                                      style={{
                                        width: "850px", // Set your desired width
                                        height: "50px", // Set your desired height
                                        overflow: "hidden", // Optional: hides overflow content
                                        whiteSpace: "nowrap", // Optional: prevents text from wrapping
                                        textOverflow: "ellipsis", // Optional: adds ellipsis (...) for overflowing text
                                      }}
                                    >
                                      {item.addon_group_item_name}
                                    </td>

                                    <td className="p-2">
                                      <div
                                        onClick={() =>
                                          toggleSwitch(
                                            item.id,
                                            !item.addon_group_item_active,
                                            "addon"
                                          )
                                        }
                                        style={{
                                          width: "36px",
                                          height: "20px",
                                          display: "flex",
                                          alignItems: "center",
                                          borderRadius: "16px",
                                          padding: "4px",
                                          cursor: "pointer",
                                          backgroundColor:
                                            item.addon_group_item_active
                                              ? "rgba(var(--bs-success-rgb),var(--bs-bg-opacity))!important"
                                              : "rgba(var(--bs-danger-rgb),var(--bs-bg-opacity))!important",
                                          transition:
                                            "background-color 0.3s ease",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            boxShadow:
                                              "0 2px 4px rgba(0, 0, 0, 0.2)",
                                            transform:
                                              item.addon_group_item_active
                                                ? "translateX(16px)"
                                                : "translateX(0)",
                                            transition: "transform 0.3s ease",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td className="p-2"></td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No items available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">
                        No addon groups available
                      </div>
                    )}
                  </div>
                </main>
              </>
            )}

            {/* Show Add on  */}
          </div>
          </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <NoData />
                    </div>
                  </>
                )}

          {storeOnOff && (
            <div
              className="position-absolute top-0 right-0 shadow rounded-3 p-5"
              style={{
                width: "500px",
                height: "700px",
                backgroundColor: "white",
                border: "1px solid #fff",
              }}
            >
              <div className="d-flex justify-content-between">
                <h6 className="fw-bold">Store On/Off</h6>
                <button
                  className="btn btn-close"
                  onClick={() => setStoreOnOff(false)}
                ></button>
              </div>
              <div className="mt-2 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center mt-3">
                  <img
                    src={zamzam}
                    style={{
                      width: "36px",
                      height: "36px",
                      marginRight: "12px",
                    }}
                    alt="Restaurant Logo"
                  />
                  <p style={{ fontSize: "14px" }}>
                    {storeList.restaurant_name}
                  </p>
                </div>
                <div className="form-check form-switch d-flex align-items-center">
                  <input
                    name="store_status"
                    checked={storeList.store_status == 1}
                    onChange={handleChangetoggle}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    style={{ transform: "scale(1.2)" }} // Use "transform" for scaling
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    {storeList.store_status == 1 ? "On" : "Off"}
                  </label>
                </div>
              </div>

              {storeList.store_status == 1 ? (
                <></>
              ) : (
                <>
                  <div className="mt-3">
                    <p style={{ color: "#53B7E8" }}>
                      Reason:{" "}
                      <span style={{ color: "#111827" }}>
                        {storeList.store_status_reason || "No reason provided"}
                      </span>
                    </p>
                    <p>
                      Next open at {storeList.turn_on_time || "Not scheduled"}
                    </p>

                    <div className="mt-3">
                      <label>
                        Enter reason for marking your restaurant offline*
                      </label>
                      <textarea
                        name="store_status_reason"
                        placeholder="Description..."
                        value={storeList.store_status_reason}
                        onChange={handleChangetoggle}
                        className="form-control"
                        rows={8}
                      ></textarea>
                    </div>
                    <div className="mt-3">
                      <p></p>
                      <div className="row justify-content-start">
                        <div
                          className="col-4 d-flex flex-column"
                          style={{
                            width: "250px",
                          }}
                        >
                          <label>Auto Turn-On Time</label>
                          <input
                            name="turn_on_time"
                            type="datetime-local"
                            value={storeList.turn_on_time}
                            onChange={handleChangetoggle}
                            className="form-control"
                          />
                        </div>

                        <button
                          className="btn text-nowrap px-2 ms-3 mt-4"
                          style={{
                            backgroundColor:"#53B7E8", color:"#fff",
                            // backgroundColor: "#3B82F6",
                            // color: "#FFFFFF",
                            width: "130px",
                            height: "50px",
                            fontSize: "14px",
                          }}
                          onClick={storeOfStatus}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
                  </>
            )}

        </div>

      </div>
    </div>
  );
};

export default MenuOnOff;
