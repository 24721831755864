import { useEffect, useRef, useState } from "react";
// import Pagination from "../../Components/Pagination";

import { useContext } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faPenToSquare,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import Loader from "../Loader";
import { multipartPostCallWithErrorResponse, simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import NoDataFound from "./NodataFound";
import CommonButton from "./button";
import { AppContext } from "../../context/AppContext";

export default function TestCases() {
  const [conversationalFlowList, setConversationalFlowList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  const {
    isDrawerOpen,
    selectedDomainId,
    domainName,
    setBackTo,
    sidebar,
    setEditKeyWordDtls,
  } = useContext(AppContext);



  const getList = () => {
    simpleGetCallWithToken(
      ApiConfig?.GET_MODULE_LIST + "?domain_id=" + selectedDomainId
    )
      .then((res) => {
        if (res?.success) {
          setConversationalFlowList(res?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    getList();
  }, [selectedDomainId]);





  const navigate = useNavigate();










  const uploadExcell = (e) => {
    setIsLoading(true);

    let file = e.target.files[0];

    console.log(file);

    let formData = new FormData();
    formData.append("domain_id", selectedDomainId);
    formData.append("file", e.target.files[0]);

    multipartPostCallWithErrorResponse(ApiConfig.IMPORT_ANSERS_LIST, formData)
      .then((res) => {
        console.log(res)
        if (res?.json.success) {
          ToastMsg("success", res?.json.message);

       
          clearFileInput();
          setIsLoading(false);
        } else {
          ToastMsg("error",  res?.json.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        ToastMsg("error", "Something Went Wrong");

        setIsLoading(false);
      });
  };

  const clearFileInput = () => {
    ref.current.value = null;
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div>
        <div>
          <div className="role-content">
            <div className="sub-header-role d-flex justify-content-between">
              <h6 className="mt-2">Test Cases</h6>
              <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div className="input_wraper">
              
              </div>
              <div>
              <input
                    type="file"
                    name=""
                    id=""
                    style={{ display: "none" }}
                    ref={ref}
                    accept=".xls,.xlsx"
                    onChange={uploadExcell}
                  />

                  <button
                    className="AddQuestion_submit_btn mx-3"
                    onClick={() => {
                      ref.current.click();
                    }}
                  >
                    Import Test Cases{" "}
                  </button>
               
              </div>
            </div>
            <div
              className="table-wrapper table-wrapper-2"
              style={{ height: "600px" }}
            >
              {isLoading ? (
                <Loader />
              ) : conversationalFlowList.length > 0 ? (
                <table style={{ marginBottom: "20px" }}>
                  <thead className="">
                    <tr className="firstTabelRow">
                      <th>Sr. no</th>
                      <th>Module Name</th>
                      <th>Related Module</th>
                      <th>Module Action</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {conversationalFlowList.map((item, index) => (
                    <tr key={index} className="table-row-custom">
                      <td>{index + 1} </td>
                      <td>{item?.module_name} </td>
                      <td>{item?.module_relation}</td>

                      <td>{item?.module_action}</td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 300 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Edit Question
                            </Tooltip>
                          )}
                        >
                          <FontAwesomeIcon
                            onClick={() => {
                              //  setBackTo('/traineeQues')
                              navigate("/addconverstioflow");
                              setEditKeyWordDtls(item);
                            }}
                            icon={faPenToSquare}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                        </OverlayTrigger>{" "}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 300 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Delete Question
                            </Tooltip>
                          )}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            // onClick={() => deleteKeyword(item?.module_id)}
                            style={{ width: "15px", height: "15px" }}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <NoDataFound />
              )}
            </div>
            {/* <Pagination totalPage={totalPage}
                            currentPage={currentPage}
                            pageChangeHandler={(page) => setCurrentPage(page + 1)}
                        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
