import React, { useContext, useState } from "react";

// import Breadcrumps from "../../components/Breadcrumps";
import Toptab from "../Toptab/Toptab";
import Breadcrumps from "../Toptab/Breadcrumps";
import { AppContext } from "../../../context/AppContext";

const BaseMenu = () => {

  const { sidebar,activeTab, setActiveTab } = useContext(AppContext);

  return (
    <div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Stock List</h6>
        </div>
        <div className="h-screen bg-white mt-3" style={{ overflow: "auto", borderRadius:"10px" }}>
          {/* Breadcrumb with activeTab passed as a prop */}
          {/* <Breadcrumps activeTab={activeTab} /> */}

          {/* Toptab with activeTab and setActiveTab passed as props */}
          <Toptab activeTab={activeTab} setActiveTab={setActiveTab} />

          {/* Content */}
          <div className="container mt-4">{/* Add content here */}</div>
        </div>
      </div>
    </div>
  );
};

export default BaseMenu;