import { useContext, useEffect, useState } from "react";
import "../../Inventory Management System/Styles/tax.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Select from "react-select";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { PostCallWithErrorResponse, getWithAuthCall, putRequest, putWithAuthCall, simpleGetCall, simplePostCall, simplePostCallIVT } from "../../../api/ApiServices";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import deleteIcon from "../../../assets/images/delete.svg"
import { ToastMsg } from "../../TostMsg";


const AddAddon = () => {
  const { selectedStoreID, sidebar,setActiveTab } = useContext(AppContext)

  const navigate = useNavigate()
  const [isValided, setIsValided] = useState(false)

  const [params] = useSearchParams()


  const initialsState = {
    "addon_group_name": "",
    "addon_group_rank": 2,
    "addon_group_active": 1,
    "shop_id": '',
    "addon_item_selection": 1,
    "addon_item_max_selection": '',
    "addon_item_min_selection": '',
    "addon_group_items": [
      {
        "addon_name": "",
        "addon_price": "",
        "addon_rank": 1,
        "addon_active": 1,
        "addon_attributes": "",
        "sap_code": ""
      }
    ]
  }

  const [state, setState] = useState(initialsState)


  const handleChange = (key, val, index, subkey = '') => {

    if (subkey) {

      const newAddonItems = [...state.addon_group_items]

      newAddonItems[index] = {
        ...newAddonItems[index],
        [subkey]: val
      };

      setState(prev => ({
        ...prev,
        addon_group_items: newAddonItems
      }))

      console.log(newAddonItems);

    } else {

      console.log(key, val);

      setState(prev => ({
        ...prev,
        [key]: val
      }))

    }

  }


  const navigetion = useNavigate()

  useEffect(() => {

    let id = params.get('id')

    if (id) {
      getDataForUpdate(id)
    }

  }, [])

  const getDataForUpdate = (id) => {

    simpleGetCall(ApiConfig?.GET_ADDON_FOR_UPDATE + id).then((res) => {
      if (res?.success) {

        let addon = res?.data
        let data = res?.data?.addon_group_items?.map((i) => ({
          "id": i?.id,
          "addon_name": i?.addon_group_item_name,
          "addon_price": i?.addon_group_item_price,
          "addon_rank": i?.addon_group_item_rank,
          "addon_active": i?.addon_group_item_active,
          "addon_attributes": i?.addon_group_attributes,
          "sap_code": i?.sap_code,
          "is_deleted": i?.is_deleted
        }))

        addon.addon_group_items = data;

        setState(res?.data)


      }
    })
  }


  const handleAddAdon = () => {


    let isValid = true

    if (
      !state?.addon_group_name ||
      !state?.addon_item_max_selection ||
      !state?.addon_item_min_selection
    ) {

      isValid = false;

      setIsValided(true)

    }


    state?.addon_group_items?.forEach((item) => {

      if (
        !item?.addon_name ||
        !item?.addon_price ||
        !item?.addon_attributes ||
        !item?.sap_code
      ) {
        isValid = false

        setIsValided(true)
      }

    })


    if (isValid) {

      state.shop_id = selectedStoreID

      let id = params.get('id')

      if (id) {
        putRequest(ApiConfig?.ADD_ADDON + '/' + id, JSON.stringify(state)).then((res) => {


          if (res?.success) {

            ToastMsg('success', res?.message)

            navigate('/BaseMenu')
          setActiveTab("Addons")



          } else {

            ToastMsg('error', res?.json?.message)

          }

        })
      } else {



        PostCallWithErrorResponse(ApiConfig.ADD_ADDON, JSON.stringify(state)).then((res) => {

          if (res?.json?.success) {

            ToastMsg('success', res?.json?.message)

            navigate('/BaseMenu')


          } else {

            ToastMsg('error', res?.json?.message)

          }

        })
      }
    }



  }


  const handleDelete = (index, isDelete) => {
    let del = state.addon_group_items;

    if (isDelete) {

      del = state.addon_group_items;

      del[index].is_deleted = true

    } else {
      del = state?.addon_group_items?.filter((_, ind) => {

        return ind != index

      })
    }

    setState(prev => ({
      ...prev,
      addon_group_items: del
    }))

  }


  const addNewAddon = () => {

    setState(prev => ({
      ...prev,
      addon_group_items: [...state.addon_group_items, {
        "addon_name": "",
        "addon_price": "",
        "addon_rank": 1,
        "addon_active": 1,
        "addon_attributes": "",
        "sap_code": ""
      }]
    }))

  }

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Stock List</h6>
          </div>
          <div className="p-2">



            <div className="">
              <div className="flex flex-column">
                {/* <form> */}
                <div className="w-full bg-white p-4  border border-gray-300">
                  <div className="row g-3">
                    {/* Department */}
                    <div className="col-md-4" >
                      <label htmlFor="department" className="form-label tax-type-label">
                        Department Name<span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="position-relative z-3 w-full">
                        <input
                          type="text"
                          id="variantName"
                          className="form-control tax-type-label"
                          value={state?.addon_group_name}
                          onChange={(e) => handleChange('addon_group_name', e.target.value)}
                        />
                        {isValided && !state?.addon_group_name && <span style={{ color: "red" }}>Please Enter Name </span>}

                      </div>



                    </div>

                    {/* Variant Name */}
                    <div className="col-md-4">
                      <label
                        htmlFor="variantName"
                        className="form-label tax-type-label"
                      >
                        Addon Min<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="number"
                        id="variantName"
                        className="form-control tax-type-label"
                        placeholder="0"

                        value={state?.addon_item_min_selection}
                        onChange={(e) => handleChange('addon_item_min_selection', Number(e.target.value) || '')}
                      />
                      {isValided && !state?.addon_item_min_selection && <span style={{ color: "red" }}>Please Enter Minimum Addon </span>}
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="variantName"
                        className="form-label tax-type-label"
                      >
                        Addon Max<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="number"
                        id="variantName"
                        className="form-control tax-type-label"
                        name="variantName"
                        placeholder="0"

                        value={state?.addon_item_max_selection}
                        onChange={(e) => handleChange('addon_item_max_selection', Number(e.target.value) || '')}
                      />
                      {isValided && !state?.addon_item_max_selection && <span style={{ color: "red" }}>Please Enter Max Addon </span>}
                    </div>

                    {/* Unit of Measure */}
                    {/* <div className="col-md-4">
                      <label
                        htmlFor="unitOfMeasure"
                        className="form-label tax-type-label tax-type-label"
                      >
                        Addon Item Selection<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={unitList}
                        onChange={handleselectData}
                        placeholder="Select Unit of Measure"
                      />
                      {errors.unit_of_measurement && (
                        <span style={{ color: "red" }}>{errors.unit_of_measurement}</span>
                      )}
                    </div> 
                    <div className="col-md-4">
                      <label
                        htmlFor="department"
                        className="form-label tax-type-label"
                      >
                        Max Selection Per Addon Allowed*<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        id="variantName"
                        className="form-control tax-type-label"
                        name="variantName"
                        placeholder="1"
                        onChange={(e) => setAddVariant({
                          ...addVariant,
                          size: e.target.value
                        })}
                      />
                      {errors.size && <span style={{ color: "red" }}>{errors.size}</span>}
                    </div>
                    */}


                  </div>
                  <div className="row mt-2 g-3">
                    {/* Department */}
                    {/* <div className="col-md-2">
                      <label className="flex items-center gap-2 text-Dark">
                        <input type="checkbox"
                        //   checked={addItem?.ignore_taxes === "1"}

                        //   onChange={(e) => setAddItem({
                        //     ...addItem,
                        //     ignore_taxes: e.target.checked ? "1" : "0"
                        //   })}
                        />
                        Show in Online
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label className="flex items-center gap-2 text-Dark">
                        <input type="checkbox"
                        //   checked={addItem?.ignore_taxes === "1"}

                        //   onChange={(e) => setAddItem({
                        //     ...addItem,
                        //     ignore_taxes: e.target.checked ? "1" : "0"
                        //   })}
                        />
                        Allow Open Quantity
                      </label>
                    </div> */}

                    {/* <div className="col-md-4">
                  <label
                    htmlFor="variantName"
                    className="form-label tax-type-label"
                  >
                    Minimum Quantity<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="variantName"
                    className="form-control tax-type-label"
                    name="variantName"
                    placeholder="20"
                    onChange={(e) => setAddVariant({
                      ...addVariant,
                      quantity: e.target.value
                    })}
                  />
                       {errors.quantity && (
              <span style={{ color: "red" }}>{errors.quantity}</span>
            )}
                </div> */}
                  </div>
                  <table className="w-full mt-5 ">
                    <thead>
                      <tr className="bg-grey font-family font-weight-400 font-size-14  ">
                        <th className="p-2 font-weight-400 font-size-14">Department Name</th>
                        <th className=" p-2 font-weight-400 font-size-14">Price</th>
                        <th className=" p-2 font-weight-400 font-size-14">SAP code</th>
                        <th className=" p-2 font-weight-400 font-size-14">Attributes    </th>
                        <th className=" p-2 font-weight-400 font-size-14">Attributes</th>
                        <th className=" p-2 font-weight-400 font-size-14">Actions</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        state?.addon_group_items?.filter((i) => !i?.is_deleted)?.map((add, index) => {
                          return <tr className="hover:bg-light border my-2">
                            <td className="p-2 align-middle">
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="text"
                                  id="variantName"
                                  className="form-control tax-type-label"

                                  placeholder="Name"
                                  value={add?.addon_name}
                                  onChange={(e) => handleChange('', e.target.value, index, 'addon_name')}
                                />
                                <br />

                              </div>
                              <span className="text-danger">
                                {
                                  isValided && !add?.addon_name && 'Please Enter name'
                                }
                              </span>

                            </td>
                            <td className="p-2 align-middle">
                              {/* {item.category_name} */}
                              <input
                                type="number"
                                id="variantName"
                                className="form-control tax-type-label"

                                placeholder="0"

                                value={add?.addon_price}
                                onChange={(e) => handleChange('', e.target.value, index, 'addon_price')}
                              />
                              <span className="text-danger">
                                {
                                  isValided && !add?.addon_price && 'Please Enter Price'
                                }
                              </span>
                            </td>
                            <td className="p-2 align-middle">
                              {/* <div className="border p-2 rounded w-100">{item.short_code}</div>
                             */}
                              <input
                                type="text"
                                id="variantName"
                                className="form-control tax-type-label"
                                placeholder="Sap Code"

                                value={add?.sap_code}
                                onChange={(e) => handleChange('', e.target.value, index, 'sap_code')}
                              />
                              <span className="text-danger">
                                {
                                  isValided && !add?.sap_code && 'Please Enter Sap Code'
                                }
                              </span>
                            </td>
                            <td className="p-2 align-middle">
                              <div className="col-md-10 border-start ps-4 d-flex align-items-center gap-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"


                                    checked={add?.addon_attributes == '1'}
                                    onChange={(e) => handleChange('', '1', index, 'addon_attributes')}
                                  />
                                  <label className="form-check-label" htmlFor="delivery">
                                    Veg
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"


                                    checked={add?.addon_attributes == '2'}
                                    onChange={(e) => handleChange('', '2', index, 'addon_attributes')}
                                  />
                                  <label className="form-check-label" htmlFor="parcel">
                                    Non Veg
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"


                                    checked={add?.addon_attributes == '24'}
                                    onChange={(e) => handleChange('', '24', index, 'addon_attributes')}

                                  />
                                  <label className="form-check-label" htmlFor="parcel">
                                    Egg
                                  </label>
                                </div>
                              </div>
                              <span className="text-danger">
                                {
                                  isValided && !add?.sap_code && 'Please Select'
                                }
                              </span>
                            </td>
                            <td className="p-2 align-middle">
                              <div
                                className="sp-buttton"
                                style={{
                                  backgroundColor: "#eaf8f3",
                                  color: "#119C2B",
                                  padding: "5px 10px", // Optional: Add padding for better appearance
                                  borderRadius: "4px", // Optional: Add rounded corners
                                  textAlign: "center", // Optional: Center align text
                                }}
                              >
                                Active
                              </div>
                            </td>



                            {/* Actions */}
                            <td className="p-2 align-middle">
                              <div className="d-flex align-items-center gap-2">

                                <img
                                  src={deleteIcon}
                                  alt="Edit"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => handleDelete(index, add?.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        })
                      }






                    </tbody>
                  </table>
                  <div style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "5px"
                  }}>
                    <button className="btn w-full sm:w-auto "
                      onClick={addNewAddon}
                      style={{backgroundColor:"#53B7E8", color:"#fff"}}
                    >
                      Add new
                    </button>
                  </div>

                </div>
                <div className="row">
                  <div className="d-flex justify-content-end gap-3 pt-2">

                    <button className="btn" style={{backgroundColor:"#53B7E8", color:"#fff"}} type="button"
                      onClick={handleAddAdon}
                    >
                      <label className="add-">Save</label>
                    </button>
                    <button className="btn " style={{border:"1px solid #53B7E8", color:"#53B7E8"}} type="button"
                      onClick={() => navigetion("/BaseMenu")}
                    >
                      <label className=" add-" > Cancel</label>
                    </button>

                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAddon;
