import { Select } from "antd";
import React, { useState, useContext, useEffect } from "react";
import importExport from "../../../assets/images/importExp.svg";
import down from "../../../assets/images/down.svg";
import up from "../../../assets/images/up.svg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Loader from "../../Loader";
import NoData from "../../../sharedComponent/NoData";



export default function Addons() {
    const { selectedStoreID, sidebar } = useContext(AppContext)

    const naviget = useNavigate()
    const [showData, setShowData] = useState(null);

    const [addons, setAddons] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [serach, setSearch] = useState({
        type: '',
        addon_group_name: '',
        search: 1
    })

    useEffect(() => {
        getAddAddons()
    }, [selectedStoreID, serach.search])


    const getAddAddons = () => {

        setIsLoading(true)

        simpleGetCall(ApiConfig.GET_ADDONS_BY_ID + selectedStoreID + `&addon_group_name=${serach.addon_group_name}&type=${serach.type}`).then((res) => {
            console.log(res);
            if (res?.success) {
                setAddons(res?.data)
            } else {
                setAddons([])
            }
            setIsLoading(false)
        })

    }

    return (
        <>
            <div className="row align-items-end p-3">
                <div className="col-3 d-flex flex-column">
                    <label className="tax-lab sp-text mb-1">Department Name</label>
                    <input type="text" className="form-control" placeholder="Enter Department Name"
                        value={serach?.addon_group_name}
                        onChange={(e) => setSearch(prev => ({
                            ...prev,
                            addon_group_name: e.target.value
                        }))} />

                </div>

                <div className="col-3 d-flex flex-column">
                    <label className="tax-lab sp-text mb-1">Search By Department</label>
                    <Select style={{ height: "38px" }}
                        value={serach?.type}
                        onChange={(e) => setSearch(prev => ({
                            ...prev,
                            type: e.valueOf()
                        }))}
                    >
                        <option value={'active'}>Active</option>
                        <option value={'inactive'}>In Active</option>
                    </Select>

                </div>
                <div className="col-3 d-flex align-items-end">
                    <button className="btn" style={{backgroundColor:"#53B7E8", color:"#fff", height:"38px"}}  onClick={getAddAddons}>Search</button>
                    <button className="btn" style={{ height: "38px", backgroundColor: "#53B7E8", color: "white" }}
                        onClick={() => {
                            setSearch({
                                type: '',
                                addon_group_name: '',
                                search: serach.search + 1
                            })


                        }}
                    >Show All</button>
                    {/* <button className="btn" style={{ height: "38px", backgroundColor: "#fff", color: "#000", border: "1px solid #3B82F6" }}>Assign Addon</button> */}
                </div>
                <div className="col-3 d-flex justify-content-end">
                <button className="btn" style={{backgroundColor:"#53B7E8", color:"#fff", height:"38px"}}
                    onClick={() => naviget("/AddAddon")}
                >Add New Addon Group</button>
                {/* <Select placeholder="Actions" style={{ height: "38px" }} className="mx-2 fw-bold">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </Select> */}
                {/* <div className="border rounded-2" >
                    <button className="btn mx-2">Import/Export</button>
                    <img src={importExport} alt="importExport" className="mx-2" />
                </div> */}

            </div>
                <p className="text-muted my-3">Note: Drag rows to rearrange order or ranking.</p>

                <table className="table">
                    {
                        isloading ? <div className="d-flex justify-content-center"> <tr><Loader /></tr></div> :
                            addons?.length == 0 ? <NoData /> :

                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Department Name</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                        <th>Modified</th>
                                        <th>Actions</th>
                                    </tr>
                                    {addons?.map((addon, index) => {

                                        return <>

                                            <tr >
                                                <td>
                                                    <input type="checkbox" className="ms-1 form-check-input" />
                                                </td>
                                                <td onClick={() => setShowData(addon?.id)}>
                                                    <img className="me-3" style={{ scale: "1.3" }} src={showData == addon?.id ? up : down} alt="importExport" />
                                                    <span >{addon?.addon_group_name}</span>
                                                </td>
                                                <td>
                                                    <div
                                                        className={`sp-buttton-b tax-type-label 
                      p-2 p-md-23 p-lg-2`} // Apply responsive padding using Bootstrap classes
                                                        style={{
                                                            backgroundColor: "#eaf8f3",
                                                            color: "#119C2B",
                                                            padding: "5px 10px", // Optional: Add padding for better appearance
                                                            borderRadius: "4px", // Optional: Add rounded corners
                                                            textAlign: "center", // Optional: Center align text
                                                        }}
                                                    >
                                                        {addon.addon_group_active === 1 ? "Active" : "InActive"}
                                                    </div>
                                                </td>
                                                <td>{addon?.created_at?.split('T')[0]}</td>
                                                <td>{addon?.updated_at?.split('T')[0]}</td>
                                                <td >
                                                    <img src="images/dots.svg"
                                                        onClick={() => naviget('/AddAddon?id=' + addon?.id)}
                                                    />
                                                    {/* <img src="images/eye.svg" /> */}
                                                </td>
                                            </tr>
                                            {
                                                showData == addon?.id &&

                                                addon?.addon_items?.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td></td>
                                                        <td>{item?.addon_group_item_name}</td>
                                                        <td>{item?.addon_group_item_price}</td>
                                                        <td>{item?.addon_group_item_rank}</td>
                                                        <td>{item?.addon_group_attributes}</td>
                                                        <td>{item?.sap_code}</td>
                                                    </tr>
                                                })


                                            }
                                        </>
                                    })}


                                </thead>
                    }
                    {showData && <tbody>

                    </tbody>}
                </table>
            </div>
        </>
    )
}