
import React, { useContext, useEffect, useState } from "react";
import "../../Inventory Management System/Styles/tax.css";
import "../../Inventory Management System/Styles/variation.css";

import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { getWithAuthCall, patchMultipartWithAuthCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import Loader from "../../Loader";
import { AppContext } from "../../../context/AppContext";
import NoData from "../../../sharedComponent/NoData";




const Variations = () => {
  const { selectedStoreID } = useContext(AppContext)
  console.log(selectedStoreID);

  const option = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Assigned", label: "Assigned" },
    { value: "Unassigned", label: "Unassigned" },




  ]
  const [variantList, setVariantList] = useState([])
  const [slectOpetion, setSelectOpetion] = useState('All');
  const [search, setSearch] = useState("")
  const [rankData, setRankData] = useState({ data: [] })
  const [activeInCtive, setActiveInctive] = useState('')
  const [loading, setLoading] = useState(false)
  const [activeAction, setActiveAction] = useState({
    data: [

    ]
  })


  console.log("update rank>>>>>", rankData)
  const navigate = useNavigate();
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);

  const toggleDropdown = (id) => {
    setVisibleDropdownId((prevId) => (prevId === id ? null : id));
  };

  const closeDropdown = () => {
    setVisibleDropdownId(null);
  };


  // const Department = tableData.map(tab => tab.department);
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value)

  }

  const handleCategoryChange = (selectedCategory) => {
    console.log("Selected Category:", selectedCategory);
    setSelectOpetion(selectedCategory?.value)

    // Your logic to update active tab or state
  };
  // const toggleDropdown = () => {
  //   setIsOpen((prev) => !prev);
  // };
  // Your table code here...

  const getVariantList = (search = '') => {
    setLoading(true)
    getWithAuthCall(ApiConfig.GET_VARIATION_LIST + selectedStoreID + `&variation_name=${search}&search_by=${slectOpetion}`)
      .then((res) => {
        setVariantList(res.data)
        setLoading(false)
        console.log("GET_VARIATION_LIST>>>>", res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getVariantList(search)
  }, [slectOpetion, selectedStoreID])


  const ActionList = [

    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const handleCheckboxChange = (id) => {
    setRankData((prevState) => {

      const data = prevState.data || [];

      const exists = data.find((item) => item.id === id);

      if (exists) {

        return {
          ...prevState,
          data: data.filter((item) => item.id !== id),
        };
      } else {

        return {
          ...prevState,
          data: [...data, { id, active: null }],
        };
      }
    });
  };

  const handleActiveData = (selectedOption) => {
    const newActiveValue = selectedOption
    console.log("select Value", newActiveValue)

    setRankData((prevState) => {
      const updatedData = prevState?.data?.map((item) =>
        item.active === null ? { ...item, active: newActiveValue } : item
      );
      return { ...prevState, data: updatedData };
    });

    setActiveInctive('')

  };


  const handleAction = (select) => {
    console.log("handleAction>>>", select.value)
    setActiveInctive(select.value)
    handleActiveData(select.value)
  }


  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index); // Store the dragged index
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const updateRanks = (categories, oldIndex, newIndex) => {
    const movedItem = categories[oldIndex];
    let updatedCategories = [...categories];

    updatedCategories.splice(oldIndex, 1);

    updatedCategories.splice(newIndex, 0, movedItem);


    updatedCategories.forEach((item, index) => {
      item.variation_rank = index + 1;
    });

    return updatedCategories;
  };

  const handleDrop = (e, newIndex) => {
    const oldIndex = e.dataTransfer.getData("index");
    console.log("oldIndex>>>", oldIndex)

    if (oldIndex !== newIndex) {
      const updatedCategories = updateRanks(variantList, parseInt(oldIndex), newIndex);
      setVariantList(updatedCategories);

      const apiData = updatedCategories.map((category) => ({
        id: category.id,
        active: null,
        rank: category.variation_rank,
      }));


      setRankData({ data: apiData });
      setActiveInctive('')
      setActiveAction("")
    }
  };




  const onUpdateRank = () => {
    setLoading(true)
    const body = {
      variations: rankData.data?.map(item => ({
        id: item.id,
        status: item.active,
        rank: item.rank ? item.rank : null,
      }))
    }
    console.log("body>>>", body)


    patchMultipartWithAuthCall(ApiConfig.UPDATE_VARIATION_RANK, JSON.stringify(body))
      .then((res) => {
        console.log("res>>>", res.json?.message)
        if (res.json?.success === true) {
          notifySuccess(res.json?.message)
          getVariantList()
          setRankData("")
          setLoading(false)
        } else {
          notifyError(res.json?.message)
        }
      })
      .catch((err) => {
        console.log("err>>>", err)
      })
  }

  useEffect(() => {
    if (rankData?.data?.length > 0) {
      const firstItem = rankData.data[0];

      if (firstItem.active === false || firstItem.active === true) {
        onUpdateRank();
      }
    }
  }, [rankData]);


  return (
    <>
      <head>
        {/* Popper.js */}
        <script
          src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
          integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r"
          crossOrigin="anonymous"
          defer
        ></script>

        {/* Bootstrap JS */}
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js"
          integrity="sha384-0pUGZvbkm6XF6gxjEnlmuGrJXVbNuzT9qBBavbLwCsOGabYfZo0T0to5eqruptLy"
          crossOrigin="anonymous"
          defer
        ></script>
      </head>

      <main className="h-screen p-2">
        <div className="d-flex flex-column flex-sm-row justify-content-sm-end align-items-center gap-2">

          {/* Action On/Off Button */}
        

          {/* Dropdown */}
        

          {/* Back Button */}
      
        </div>
        <label htmlFor="title" className="form-label1">
          Variant Name
        </label>
        <div className="d-flex flex-column flex-sm-row align-items-start gap-2" style={{marginBottom:"-30px"}}>

          <div className="d-flex flex-column pb-6">

            <div className="w-100 w-sm-auto text-start">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
                name="search"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>

          <div className="w-20 w-sm-auto text-start">
            <button className="btn w-30" type="button" style={{backgroundColor:"#53B7E8", color:"#fff"}}
              onClick={() => getVariantList(search)}
            >
              <label className=" add-text"> Search</label>
            </button>
          </div>
          <div className="w-20 w-sm-auto text-start">
            <Select
              options={option} // Extracted category names
              placeholder="Select category"
              onChange={handleCategoryChange} // Fix: Defined function
            />
          </div>







          <div className="w-20 w-sm-auto text-start d-flex flex-wrap gap-2">
            <button className="btn" type="button"
            style={{backgroundColor:"#53B7E8", color:"#fff"}}
              onClick={() => {
                getVariantList("")
                setSearch("")
                handleCategoryChange("")
              }}

            >
              <label className=" add-text"> Show All</label>
            </button>
            <button className="btn"style={{border:"1px solid #53B7E8", color: "#53B7E8"}} type="button"
              onClick={onUpdateRank}
            >
              <label className=" add-"> Update Variation</label>
            </button>
          </div>

          <div className="w-20 w-sm-auto text-center">

<button className="btn me-1 " style={{backgroundColor:"#53B7E8", color:"#fff", marginLeft:"200px"}}type="submit" onClick={() => navigate("/Addvariant")}>
  <label className="add-text">Add variant</label>
</button>

</div>

{/* Dropdown */}
<div className="w-20 w-sm-auto text-center">
{/* <div className="dropdown"> */}
{/* <button
    className="bg-white text-gray-700 px-4 py-2 rounded border border-gray-300 w-full"
    type="button"
    id="dropdownMenuButton1"

  >
    <label className="add-text1">Bulk  Update</label>
  </button> */}
{/* </div> */}
</div>

{/* Back Button */}
<div className="w-20 w-sm-auto text-center z-3">
<Select
  options={ActionList}
  onChange={handleAction}
  placeholder="Select Action"
/>



{/* <select
          id="category"
          className="form-select"
          name="category"
        >
          <option value="">Actions</option>
          <option value="electronics">Electronics</option>
          <option value="fashion">Fashion</option>
          <option value="grocery">Grocery</option>
          <option value="books">Books</option>
        </select> */}
</div>



        </div>
        <div className="text-start">
          <label className="tax-lab">
            Note: Drag rows to rearrange order or ranking.
          </label>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">


          <table className="table">
            <thead>
              <tr className="bg-grey font-family font-weight-400 font-size-14  ">
                <th></th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Name
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Department
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Unit of Measure
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Size
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Status
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Rank
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Last Updated
                </th>
                <th scope="col" className="sp-table-head tax-type-label">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (<>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              </>) : (
                variantList?.length > 0 ? (

                  variantList?.map((row, index) => (
                    <tr
                      key={index} className="sp-table-head"
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOver(e)}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      <td>
                        <input type="checkbox"
                          onClick={() => handleCheckboxChange(
                            row.id
                          )}
                        />
                      </td>
                      <td className="sp-table-data  tax-type-label">{row.variation_name}</td>
                      <td className="sp-table-data  tax-type-label">
                        {row.variation_group_name}
                      </td>
                      <td className="sp-table-data tax-type-label">
                        {row.name}
                      </td>
                      <td className="sp-table-data tax-type-label">{row.size}</td>

                      <td>
                        <div
                          className={`sp-buttton-b tax-type-label 
                      p-2 p-md-23 p-lg-2`} // Apply responsive padding using Bootstrap classes
                          style={{
                            backgroundColor: row.variation_status === true ? "#eaf8f3" : "#ffe9e9",
                            color: row.variation_status === true ? "#119C2B" : "#fd5e5e",
                            padding: "5px 10px", // Optional: Add padding for better appearance
                            borderRadius: "4px", // Optional: Add rounded corners
                            textAlign: "center", // Optional: Center align text
                          }}
                        >
                          {row.variation_status === true ? "Active" : "InActive"}
                        </div>
                      </td>
                      <td className="sp-table-data tax-type-label">
                        {row.variation_rank}
                      </td>
                      <td className="sp-table-data tax-type-label">
                        {row.updated_at}
                      </td>
                      <td style={{ position: "relative" }}>


                        {/* <button
                type="button"
                className="btn"
                onClick={() => toggleDropdown(row.id)}
                style={{
                  fontSize: "14px",
                  transform: "rotate(90deg)",
                  display: "inline-block",
                  border:"none"
                }}
              >
                &#x22EE;
              </button> */}

                        <img
                          src="images/dots.svg"
                          alt="Button Icon"
                          width={20}
                          height={20}
                          className=""
                          onClick={() => {
                            navigate(`/Updatevariant/`, { state: { row } })
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={9}>
                      <NoData />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </main>
    </>
    //tabs
  );

}
export default Variations;
