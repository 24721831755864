import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import FileSaver from "file-saver";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AddEmployeeAttendanceReport = () => {
  const [branchname, setBranchName] = useState([]);
  const [addBrData, setAddBrData] = useState({
    Month: 0,
    Year: 0,
    LocationId: 0,
  });
  const [excelLoading, setExcelLoading] = useState(false);

  const { permissions } = useContext(AppContext);

  const fetchLocationName = () => {
    simpleGetCallWithToken(ApiConfig.GET_BRANCH_NAME_LIST)
      .then((res) => {
        if (res.Success === true) {
          setBranchName(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLocationName();
    fetchYearList();
  }, []);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);

    e.preventDefault();
    console.log(">>>>>>>>>>>>", addBrData);
    PostCallWithErrorResponse(
      ApiConfig.ALL_EMPLOYEE_DOWNLOAD_EXCEL,
      JSON.stringify(addBrData)
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeData = (e) => {
    const { name, value } = e.target;
    setAddBrData({
      ...addBrData,
      [name]: Number(value),
    });
  };

  const [YearList, setYearList] = useState([]);

  const fetchYearList = () => {
    simpleGetCallWithToken(ApiConfig.TEAMSHEET_YEAR)
      .then((res) => {
        if (res.Success === true) {
          setYearList(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div id="cx-main">
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Branch Attendance Report</h6>
          </div>

          <div className="innerRoleSec">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label className="enter-role-text">Select Branch Name</label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="LocationId"
                    value={addBrData.LocationId}
                    className="form-select tasKCategory1 select-drop-icon"
                  >
                    <option value={""}>Select Branch Name</option>
                    {branchname.map((name, index) => {
                      console.log("LocationName", name.LocationName);
                      return (
                        <option value={name.LocationId} key={index}>
                          {name.LocationName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="enter-role-text">Select Year</label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="Year"
                    value={addBrData.Year}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Year</option>

                    {YearList.map((name, index) => {
                      return (
                        <option value={name.Id} key={index}>
                          {name.year}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="enter-role-text">Select Month</label>
                  <select
                    onChange={(e) => {
                      changeData(e);
                    }}
                    name="Month"
                    value={addBrData.Month}
                    className="form-select tasKCategory1 select-drop-icon"
                  >
                    <option value={""}>Select Month</option>
                    {month.map((month, index) => {
                      return (
                        <option key={index} value={index + 1}>
                          {month}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-8 text-end">
                <button
                  type="button"
                  className="modal-create-btn"
                  id="downld-atten-btn"
                  onClick={(e) => {
                    downloadExcelFile(e);
                  }}
                >
                  {" "}
                  {excelLoading ? (
                    //<ExcelLoader className="loder-af"/>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        color: "#FFFFFF",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  ) : (
                    <span>Download excel sheet</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployeeAttendanceReport;
