"use client";

import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../sharedComponent/DropDown/DropDown";
import { patchMultipartWithAuthCall, putMultipartWithAuthCall, putWithAuthCall, simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/AppContext";
import Loader from "../../Loader";
import NoData from "../../../sharedComponent/NoData";
import Form from 'react-bootstrap/Form';
import { ToastMsg } from "../../TostMsg";
import Select from "react-select";

const initialTabs = [
  {
    id: "fruits-vegetables",
    name: "Fruits and Vegetables",
    items: [
      {
        id: "1",
        name: "Watermelon",
        category: "Fruits",
        code: "100",
        price: "100",
        description: "A refreshing summer fruit",
        availability: true,
      },
      {
        id: "2",
        name: "Mango",
        category: "Fruits",
        code: "101",
        price: "120",
        description: "Sweet tropical fruit",
        availability: false,
      },
    ],
  },
  {
    id: "desserts",
    name: "Desserts",
    items: [
      {
        id: "3",
        name: "Chocolate Cake",
        category: "Bakery",
        code: "102",
        price: "200",
        description: "Rich and moist chocolate cake",
        availability: true,
      },
      {
        id: "4",
        name: "Ice Cream",
        category: "Frozen",
        code: "103",
        price: "80",
        description: "Vanilla ice cream",
        availability: true,
      },
    ],
  },
  {
    id: "dairy-eggs",
    name: "Dairy and Eggs",
    items: [
      {
        id: "5",
        name: "Milk",
        category: "Dairy",
        code: "200",
        price: "50",
        description: "Fresh milk",
        availability: true,
      },
      {
        id: "6",
        name: "Eggs",
        category: "Eggs",
        code: "201",
        price: "60",
        description: "Farm fresh eggs",
        availability: true,
      },
    ],
  },
  {
    id: "chicken-meal",
    name: "Chicken Meal",
    items: [
      {
        id: "7",
        name: "Grilled Chicken",
        category: "Poultry",
        code: "202",
        price: "300",
        description: "Delicious grilled chicken",
        availability: true,
      },
      {
        id: "8",
        name: "Chicken Curry",
        category: "Cuisine",
        code: "203",
        price: "250",
        description: "Spicy chicken curry",
        availability: false,
      },
    ],
  },
  {
    id: "herbs-greens",
    name: "Herbs and Greens",
    items: [
      {
        id: "9",
        name: "Spinach",
        category: "Leafy Greens",
        code: "204",
        price: "40",
        description: "Fresh green spinach",
        availability: true,
      },
      {
        id: "10",
        name: "Coriander",
        category: "Herbs",
        code: "205",
        price: "20",
        description: "Aromatic coriander leaves",
        availability: true,
      },
    ],
  },
  {
    id: "hot-coffees",
    name: "Hot Coffees",
    items: [
      {
        id: "11",
        name: "Espresso",
        category: "Beverage",
        code: "300",
        price: "150",
        description: "Rich and bold coffee",
        availability: true,
      },
      {
        id: "12",
        name: "Latte",
        category: "Beverage",
        code: "301",
        price: "180",
        description: "Smooth and creamy coffee",
        availability: true,
      },
    ],
  },
  {
    id: "side-orders",
    name: "Side Orders",
    items: [
      {
        id: "13",
        name: "Garlic Bread",
        category: "Appetizers",
        code: "302",
        price: "70",
        description: "Crispy garlic bread",
        availability: false,
      },
      {
        id: "14",
        name: "French Fries",
        category: "Snacks",
        code: "303",
        price: "90",
        description: "Crispy golden fries",
        availability: true,
      },
    ],
  },
  {
    id: "seafood-meal",
    name: "Seafood Meal",
    items: [
      {
        id: "15",
        name: "Grilled Salmon",
        category: "Seafood",
        code: "304",
        price: "400",
        description: "Freshly grilled salmon",
        availability: true,
      },
      {
        id: "16",
        name: "Shrimp Curry",
        category: "Cuisine",
        code: "305",
        price: "350",
        description: "Spicy shrimp curry",
        availability: false,
      },
    ],
  },
];

const actions = [
  { value: "available", label: "Available" },
  { value: "unavailable", label: "Unavailable" },
  { value: "ignore_tax", label: "Ignore Tax" },
  { value: "ignore_discount", label: "Ignore Discount" },
  { value: "mark_favourite", label: "Mark as Favourite" },
  { value: "unmark_favourite", label: "Unmark as Favourite" },
  { value: "mark_veg", label: "Mark as Vegetarian" },
  { value: "mark_non_veg", label: "Mark as Non-Vegetarian" }
];

console.log(actions);

const Items = () => {
  const { selectedStoreID } = useContext(AppContext)

  const navigate = useNavigate();
  const [tabs, setTabs] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [draggedTabId, setDraggedTabId] = useState(null);
  // const [switchStates, setSwitchStates] = useState(false); 
  const [itemList, setItemList] = useState([])
  const [categoryList, SetCategoryList] = useState([])
  const [searchName, setSearchName] = useState("")
  const [loading, setLoading] = useState(false);
  const [rank, setRank] = useState("")
  const [actionsValue, setActionValue] = useState([])
  const [activeAction, setActiveAction] = useState('')



  const [switchStates, setSwitchStates] = useState({});

  console.log("actionsValue>>>>", actionsValue)


  const handleToggle = (itemId) => {
    setSwitchStates((prevStates) => {
      const updatedState = !prevStates[itemId]; // Toggle only the clicked item

      // Create the required body format
      const requestBody = {
        id: itemId,
        is_available: updatedState,
      };

      console.log("Sending API Request:", requestBody); // Log API body before sending

      // Call the API function to update backend
      onChangeToggle(requestBody);

      return {
        ...prevStates,
        [itemId]: updatedState, // Update only the clicked item
      };
    });
  };


  useEffect(() => {
    if (itemList?.length) {
      const initialStates = itemList.reduce((acc, item) => {
        acc[item.item_id] = item.is_available === true;
        return acc;
      }, {});
      setSwitchStates(initialStates);
    }
  }, [itemList]);
  const handleDragStart = (id) => {
    setDraggedTabId(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Allow drop
  };

  const handleDrop = (targetId) => {
    if (!draggedTabId) return;

    const draggedIndex = tabs.findIndex((tab) => tab.id === draggedTabId);
    const targetIndex = tabs.findIndex((tab) => tab.id === targetId);

    if (draggedIndex === -1 || targetIndex === -1) return;

    const updatedTabs = [...tabs];
    const [draggedTab] = updatedTabs.splice(draggedIndex, 1);
    updatedTabs.splice(targetIndex, 0, draggedTab);

    setTabs(updatedTabs);
    setDraggedTabId(null);
  };

  const handleACtion = (select) => {
    console.log("select>>>>>", select)
    setActiveAction(select.value)
    const data = actionsValue?.data?.map((id) => {
      return id
    })
    const body = {
      action: select.value,
      ids: data
    }
    putWithAuthCall(ApiConfig.UPDATE_ACTION, JSON.stringify(body))
      .then((res) => {
        if (res?.success === true) {
          ToastMsg("success", res?.message)
          getItemList()
          setActionValue([])
        } else {
          ToastMsg("error", res?.message)
        }
        console.log(res)
      })
      .catch((err) => [
        console.log(err)
      ])

  }
  console.log("selectedStoreID>>>", selectedStoreID)





  const handleCheckboxChange = (id) => {
    setActionValue((prevState) => {

      const data = prevState.data || [];

      const exists = data.find((item) => item.id === id);

      if (exists) {

        return {
          ...prevState,
          data: data.filter((item) => item.id !== id),
        };
      } else {

        return {
          ...prevState,
          data: [...data, id],
        };
      }
    });
  };


  const getItemList = (search = "") => {
    setLoading(true)
    simpleGetCall(ApiConfig?.GET_ITEM_LIST + `?category_id=${activeTab}&shop_id=${selectedStoreID}&product_name=${search}&sort=${rank ? rank : ''}`)
      .then((res) => {
        if (res.success === true) {
          setLoading(false)
          setItemList(res?.data)
        } else {
          setLoading(false)

        }
        console.log("Items ResPonse>>>>", res)
      })
      .catch((err) => {
        console.log("Items ResPonse Err>>>>", err)

      })
  }

  const fetchCategoryList = async () => {
    // setLoading(true)

    try {
      const response = await simpleGetCall(`${ApiConfig?.GET_CATEGORY_LIST_DATA}${selectedStoreID}&search=${""}`);
      if (response.success === true) {
        // setLoading(false)
        const category = response?.data
        console.log("categoryList>>>>", category);
        SetCategoryList(category)
      } else {
        console.log("CateGory Error>>>>>");

        // setLoading(false)
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };

  const onChangeToggle = (body) => {
    patchMultipartWithAuthCall(ApiConfig.TOGGLE_ON_OF, JSON.stringify(body))
      .then((response) => {
        console.log("API Response:", response);
        if (response.json.success === true) {
          ToastMsg("success", response?.json.message)
          getItemList()
        } else {
          ToastMsg("error", response?.json.message)
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };



  // const onUpdateRank = () => {
  //   setLoading(true)
  //   const body = {

  //   }
  //   console.log("body>>>", body)


  //   patchMultipartWithAuthCall(ApiConfig.UPDATE_VARIATION_RANK, JSON.stringify(body))
  //     .then((res) => {
  //       console.log("res>>>", res.json?.message)
  //       if (res.json?.success === true) {
  //         notifySuccess(res.json?.message)
  //         getVariantList()
  //         setRankData("")
  //         setLoading(false)
  //       } else {
  //         notifyError(res.json?.message)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err>>>", err)
  //     })
  // }


  useEffect(() => {
    fetchCategoryList()
  }, [selectedStoreID])

  useEffect(() => {

    getItemList(searchName)


  }, [searchName, activeTab, selectedStoreID, rank])

  return (
    <div className="flex h-screen mt-2 p-3">
      {/* Draggable Sidebar */}
      <ul
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        {categoryList.map((tab) => (
          <li
            key={tab.id}
            draggable
            onDragStart={() => handleDragStart(tab.id)}
            onDragOver={handleDragOver}
            onDrop={() => handleDrop(tab.id)}
            onClick={() => setActiveTab(tab.id)}
            className={`flex  py-2 ps-1 font-size-14 border-bottom ${activeTab === tab.id ? "border-left" : "bg-white text-gray "
              }`}
          >
            {/* Tab Name */}
            <span
              className={`width165 ${activeTab === tab.id ? "blue-text" : ""}`}
            >
              {tab.category_name}
            </span>

            <img
              src={"images/Drag.svg"}
              alt="Expand Icon"
              width={16} // Adjust as needed
              height={16} // Adjust as needed
              className="flex-shrink-0"
            />
          </li>
        ))}
      </ul>

      {/* Main Content */}
      <div className="w-full bg-white  border ms-2 py-2">
        <div className="d-flex flex-wrap align-items-center p-2">
          {/* Fullscreen Icon */}
          <div className="d-flex align-items-center me-2">
            <img
              src={"images/Expand.svg"}
              alt="Expand Icon"
              width={24}
              height={24}
              className="flex-shrink-0"
            />
          </div>

          {/* Input Box */}
          <div className="me-2 ">
            <input
              type="text"
              placeholder="Search.."
              className="dropdown-button flex items-center justify-between bg-white border border-gray-300  py-2 rounded shadow-md hover:bg-gray-100 focus:outline-none px-2"
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>

          {/* Dropdowns */}
          <div className="d-flex flex-wrap">
            {/* First Dropdown */}
            <Select
              options={actions}
              onChange={handleACtion}
              placeholder="Select Action"
            />

            <div className="me-2" />

            {/* Second Dropdown */}
            {/* <Dropdown
              data={["Option 1", "Option 2"]}
              placeholder="Quick Actions"
              className="me-2"
              isOpen={openDropdown === "dropdown2"}
              setOpenDropdown={() =>
                setOpenDropdown(
                  openDropdown === "dropdown2" ? null : "dropdown2"
                )
              }
            /> */}

            <div className="me-2" />
            <div className="w-20 w-sm-auto text-center">

              <button

                className="btn ms-4 d-flex align-items-center" style={{backgroundColor:"#53B7E8", color:"#fff"}}
                type="button" onClick={() => setRank("rank")}>
                Rank wise
              </button>

            </div>

          </div>
          {/* Availability */}
          <div className="d-flex align-items-center ms-4">
            <span
              className="bg-success rounded-circle d-inline-block"
              style={{ width: "10px", height: "10px" }}
            ></span>
            <span className="ms-2">Availability</span>
          </div>

          {/* Add Item Button */}
          <button
            className="btn ms-4 d-flex align-items-center" style={{backgroundColor:"#53B7E8", color:"#fff"}}
            onClick={() => navigate("/AddItems")}
          >
            Add Item <span className="fw-bold">&nbsp;+</span>
            {/* <img
              src={"images/plus.svg"}
              alt="Add Icon"
              width={18}
              height={18}
              className="ms-2"
            /> */}
          </button>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">


          <table className="w-full mt-2 ">
            <thead>
              <tr className="bg-grey font-family font-weight-400 font-size-14  ">
                <th className="p-2 font-weight-400 font-size-14">Display Name</th>
                <th className=" p-2 font-weight-400 font-size-14">Category</th>
                <th className=" p-2 font-weight-400 font-size-14">Code</th>
                <th className=" p-2 font-weight-400 font-size-14">Price</th>
                <th className=" p-2 font-weight-400 font-size-14">Description</th>
                <th className=" p-2 font-weight-400 font-size-14">
                  Availability
                </th>
                <th className=" p-2 font-weight-400 font-size-14">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ? (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  </>
                ) : (
                  itemList.length > 0 && itemList ? (
                    itemList.map((item, index) => (
                      <tr key={item.item_id} className="hover:bg-light border my-2">
                        <td className="p-2 align-middle">
                          <div className="d-flex align-items-center gap-2">
                            {/* {item.is_available == true ? (
                              <div
                                className="bg-success rounded-circle d-inline-block"
                                style={{ width: "10px", height: "10px" }}
                              ></div>
                            ) : (
                              <div
                                className="bg-danger rounded-circle d-inline-block"
                                style={{ width: "10px", height: "10px" }}
                              ></div>
                            )} */}

                            <input type="checkbox"
                              onClick={() => handleCheckboxChange(
                                item.item_id
                              )}
                            />
                            <div className="border p-2 rounded w-100">{item.item_name}</div>
                          </div>
                        </td>
                        <td className="p-2 align-middle">
                          <div className="border p-2 rounded w-100">
                            {item.category_name}
                          </div>
                        </td>
                        <td className="p-2 align-middle">
                          <div className="border p-2 rounded w-100">{item.short_code}</div>
                        </td>
                        <td className="p-2 align-middle">
                          <div className="border p-2 rounded w-100">{item.item_price}</div>
                        </td>
                        <td className="p-2 align-middle">
                          <div className="border p-2 rounded w-100">
                            {item.item_description}
                          </div>
                        </td>

                        {/* Toggle for Availability */}
                        <td className="p-2 align-middle">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Form.Check
                              type="switch"
                              id={`switch-${item.item_id}`}
                              checked={switchStates[item.item_id]} // Show toggle ON if is_available is true
                              onChange={() => handleToggle(item.item_id)} // Toggle on click
                            />
                          </div>
                        </td>

                        {/* Actions */}
                        <td className="p-2 align-middle">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="images/ActionExport.svg"
                              alt="Export"
                              width={24}
                              height={24}
                            />
                            <img
                              src="images/ActionTwo.svg"
                              alt="Action"
                              width={24}
                              height={24}
                            />
                            <img
                              src="images/ActionInfo.svg"
                              alt="Info"
                              width={24}
                              height={24}
                            />
                            <img
                              src="images/ActionEdit.svg"
                              alt="Edit"
                              width={24}
                              height={24}
                              className="cursor-pointer"
                              onClick={() => navigate(`/EditeItem/${item.item_id}`)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr className="ErrorDataNotFound">
                        <td colSpan={9}>
                          <NoData />
                        </td>
                      </tr>
                    </>
                  )


                )


              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Items;
