import React, { useContext, useEffect, useState } from "react";
import DropdownManu from "../../../sharedComponent/DropDown/DropDown.js";
import { Link, useNavigate } from "react-router-dom";

import SelectDrop from "../../../sharedComponent/DropDown/SelectDrop.js";
import Dropdown from "react-bootstrap/Dropdown";
import ApiConfig from "../../../api/ApiConfig.js";
import { simpleGetCall, simplePatchCall } from "../../../api/ApiServices.js";
import { AppContext } from "../../../context/AppContext.js";
import Loader from "../../Loader.js";
import NoData from "../../../sharedComponent/NoData.js";
import { notifyError, notifySuccess } from './../../../toaster/notify';

const ManageStore = () => {
  const shopStatusList = [
    { value: "1", label: "Open" },
    { value: "0", label: "Closed" },
  ];
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [selectedOption, setSelectedOption] = useState("Availability Mode");
  const [data, setData] = useState([]);
  console.log("data v====", data);
  const [shopName, setShopName] = useState("");
  const [shopStatus, setShopStatus] = useState("");
  const [shopType, setShopType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const { sidebar,selectedStoreID } = useContext(AppContext);
  const [Loading, setLoading] = useState(false);
  let permissions = JSON.parse(localStorage.getItem("Permissions"))
    ? JSON.parse(localStorage.getItem("Permissions"))
    : {};
  const [shopDropDownList] = useState([
    { value: "SMKT", name: "Supermarket (SMKT)" },
    { value: "WGS", name: "Wholesale Grocery Store (WGS)" },
    { value: "FFS", name: "Frozen Foods Store (FFS)" },
    { value: "REST", name: "Restaurant (REST)" },
    { value: "QSR", name: "Quick Service Restaurant (QSR)" },
    { value: "CAFE", name: "Cafes (CAFE)" },
    { value: "GCS", name: "grocery Store (GCS)" },
  ]);
  const [shopStatusValue, setShopStatusValue] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchData();

    fetchStatusList();
  }, [selectedStoreID]);

  useEffect(() => {}, [shopStatusValue]);

  useEffect(() => {
    fetchData();
  }, [shopName, shopStatus, shopType]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await simpleGetCall(
        `${ApiConfig.GET_STORE}?shop_name=${shopName}&shop_status=${shopStatus}&shop_type=${shopType}`
      );

      if (res?.shops) {
        setData(res.shops || []);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const fetchStatusList = async () => {
    try {
      const res = await simpleGetCall(ApiConfig?.GET_STORE_Status);
      const statusList = res.status;
      console.log("statusList", statusList);
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  const handleStatusChange = ({ newStatus, id }) => {
    setShopStatusValue(newStatus);
    console.log("Updated Store Status:", newStatus, "For Shop ID:", id);

    storeStatusChange(newStatus, id); // 🔥 Pass newStatus first, then id
  };

  const storeStatusChange = async (newStatus, id) => {
    try {
      const requestBody = {
        store_status: newStatus,
        turn_on_time: "", // Update if needed
      };

      const res = await simplePatchCall(
        `${ApiConfig?.GET_STORE_Status_Change}${id}`, // Ensure API endpoint is correct
        requestBody
      );

  if(res.success){
    notifySuccess(res.message)
    fetchData()
  }else {
    notifyError(res.message)
  }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  const handleShopTypeChange = (newStatus) => {
    setShopType(newStatus);
  };
  const handleSelect = (eventKey) => {
    const optionText =
      eventKey === "1"
        ? "Open"
        : eventKey === "0"
        ? "Closed"
        : "Availability Mode";
    setSelectedOption(optionText);
    setShopStatus(eventKey);
  };
  const handleErrorImage = (ev) => {
    ev.target.src = "images/Storemanagement.svg";
  };
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content ">
        <div className="sub-header-role ">
          <h6 className="mt-2">Stock List</h6>
        </div>

        {/* Filters and Add Store */}
        <div className="d-flex justify-between items-center mb-3 mt-2 ">
          <div className="flex space-x-4">
            <div className="flex space-x-4">
              <div className="flex-grow">
                <input
                  type="text"
                  placeholder="Search store..."
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200 font-family font-size-14 second-heading"
                  onChange={(e) => setShopName(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <div className="ml-2">
                  <DropdownManu
                    data={shopDropDownList}
                    onChange={handleShopTypeChange}
                    placeholder="Store"
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    className="text-center" // Ensure this class is applied to center the text
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="available_btn ms-3  text-gray-700  p-2 rounded font-size-14"
                style={{ width: "200px", height: "38px" }}
              >
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    // className="w-full text-start bg-transparent border-none shadow-none btn-store"
                    className="available_btn w-full text-start btn-default text-black border-none shadow-none bg-transparent"
                    style={{
                      padding: 0,
                      boxShadow: "none",
                      backgroundColor: "red",
                    }}
                  >
                    {selectedOption}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "100%" }}>
                    <Dropdown.Item eventKey="1">Open</Dropdown.Item>
                    <Dropdown.Item eventKey="0">Closed</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <button
              className="ml-4 px-4 py-2  transition focus:outline-none  text-white px-4 py-2 rounded  border-none font-family font-size-14 create-btn-sub-header"
              // style={{ backgroundColor: "transparent" }}
              style={{backgroundColor:"#53B7E8", color:"#fff"}}
              onClick={() => {
                setShopName("");
                setShopType(""); // Clear dropdown selection
                setSelectedOption("Availability Mode");
                setShopStatus("");
                setSelectedItem(null);
              }}
            >
              Clear All Filters
            </button>
          </div>
          {permissions &&
                            permissions["Add New Store"] && (
          <button
            className="create-btn-sub-header   font-family font-size-14"
            onClick={() => navigate("/AddNewStore")}
            style={{backgroundColor:"#53B7E8", color:"#fff"}}
          >
            Add New Store +
          </button>
)}

        </div>

        {/* Stores Grid */}
        {Loading ? (
          <Loader />
        ) : (
          <div className="row ">
            {data && data.length > 0 ? (
              data.map((item, index) => (
             
                <div
                  key={index}
                  className=" p-4 shadow-md col-6 border w-80% Cards_class "
                >
                  <h3 className="font-family font-size-14 dark-text font-weight-600">
                    {item.restaurant_name}
                  </h3>

                  <div className="flex gap-4 mt-2 d-flex align-items-center">
                    <div className="">
                      <img
                      className="box-shadow"
                        src={
                          item.shop_images?.[0]
                         
                        } // Default image if null/undefined
                        alt="MetroMart Supermarket"
                       style={{maxWidth:"300px", maxHeight:"250px", borderRadius:"10px" }}
                        onError={(ev) => handleErrorImage(ev)}
                      />
                    </div>

                    {/* Store Details */}

                    <div className="flex-grow">
                      <div className="container">
                        <div className="row d-flex justify-content-between align-items-center">
                          {/* Dropdown Column */}
                          <div className="col-md-auto col-12 mb-2 mb-md-0">
                            <SelectDrop
                              options={shopStatusList}
                              id={item.id}
                              placeholder="Select Store Status"
                              defaultValue={item.store_status}
                              onChange={handleStatusChange}
                            />
                          </div>

                          {/* Edit Button Column */}
                          <div className="d-flex ">
                            <button
                              className=" border bg-white rounded blue-text foont-family font-size-14 px-2 py-2"
                              onClick={() =>
                                navigate(`/AddNewStore/${item.id}`)
                              }
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <div className="bg-light-color px-3 py-1 rounded text-sm">
                          {item.shop_type}
                        </div>
                        <div className="bg-light-color px-3 py-1 rounded text-sm ms-2">
                          {item.restaurant_menu_sharing_code}
                        </div>
                      </div>
                      <p className="second-heading font-family font-size-14 font-weight-500">
                        {item.restaurant_address}
                      </p>
                      <p className="second-heading font-family font-size-14 font-weight-500">
                        {item.opening_time} - {item.closing_time}
                      </p>
                      <p className="second-heading font-family font-size-14 font-weight-500">
                        Mobile Number: {item.restaurant_contact}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                <NoData />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageStore;
