import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import mapOmg from "../../../../src/assets/shopping.png"
const MapComponent = ({ AddStore, setAddStore }) => {
  console.log("AddStore", AddStore);

  const defaultPosition = [10.8505, 76.2711];; // Default Location (London)

  const [markerPosition, setMarkerPosition] = useState([
    AddStore.shop_latitude || defaultPosition[0],
    AddStore.shop_longitude || defaultPosition[1],
  ]);

  const [searchAddress, setSearchAddress] = useState(AddStore.shop_address || "");
  const [suggestions, setSuggestions] = useState([]);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  // Custom Shop Icon
  const shopIcon = new L.Icon({
    iconUrl: mapOmg,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  // Update marker position when AddStore data changes
  useEffect(() => {
    if (AddStore.shop_latitude && AddStore.shop_longitude) {
      setMarkerPosition([AddStore.shop_latitude, AddStore.shop_longitude]);
    }
  }, [AddStore.shop_latitude, AddStore.shop_longitude]);

  // Move Map when search changes
  const MoveMap = ({ position }) => {
    const map = useMap();
    useEffect(() => {
      if (position) {
        map.flyTo(position, 13, { animate: true, duration: 1.5 }); // Smooth transition
      }
    }, [position, map]);

    return null;
  };

  // Fetch address suggestions
  const fetchSuggestions = async (query) => {
    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
      );
      const data = await response.json();
      setSuggestions(
        data.map((item) => ({
          name: item.display_name,
          lat: parseFloat(item.lat),
          lon: parseFloat(item.lon),
        }))
      );
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    if (AddStore.shop_address) {
      setSearchAddress(AddStore.shop_address);
    }
  }, [AddStore.shop_address]);

  // Function to extract location details
  const extractLocationDetails = (addressData) => {
    const { display_name, address = {} } = addressData;
  
    return {
      shop_address: display_name || "Unknown Address",
      shop_landmark: address.neighbourhood || address.suburb || "",
      shop_city: address.city || address.town || address.village || "",
      shop_state: address.state || "",
      shop_country: address.country || "",
    };
  };

  // Handle selecting an address
  const handleSelectAddress = async (item) => {
    const { lat, lon, name } = item;
    setMarkerPosition([lat, lon]);
    setSearchAddress(name);
    setSuggestions([]);
    setIsAddressSelected(true);
  
    try {
      const res = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await res.json();
      const locationDetails = extractLocationDetails(data);
  
      setAddStore((prev) => ({
        ...prev,
        shop_latitude: lat.toFixed(6),
        shop_longitude: lon.toFixed(6),
        shop_address: locationDetails.shop_address,
        shop_landmark: locationDetails.shop_landmark,
        shop_city: locationDetails.shop_city,
        shop_state: locationDetails.shop_state,
        shop_country: locationDetails.shop_country,
      }));
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  return (
    <div className="">
      {/* Address Search Input */}
      <input
        type="text"
        className="date-label-input tasKCategory1 mb-4"
        placeholder="Search for an address..."
        value={searchAddress}
        onChange={(e) => {
          const newValue = e.target.value;
          setSearchAddress(newValue);
          setIsAddressSelected(false);
          setAddStore((prev) => ({ ...prev, shop_address: newValue })); // Fix: update AddStore

          if (newValue.length >= 3) {
            fetchSuggestions(newValue);
          } else {
            setSuggestions([]);
          }
        }}
        onFocus={() => {
          if (!isAddressSelected && searchAddress.length >= 3) {
            fetchSuggestions(searchAddress);
          }
        }}
        onBlur={() => setTimeout(() => setSuggestions([]), 200)}
      />

      {/* Suggestions List */}
      {searchAddress !== "" && !isAddressSelected && suggestions.length > 0 && (
        <ul className="list-group">
          {suggestions.map((item, index) => (
            <li
              key={index}
              className="list-group-item list-group-item-action"
              onClick={() => handleSelectAddress(item)}
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}

      {/* Map Component */}
      <MapContainer center={markerPosition} zoom={13} style={{ height: "500px", width: "100%" }}>
        <MoveMap position={markerPosition} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          position={markerPosition}
          draggable={true}
          icon={shopIcon}
          eventHandlers={{
            dragend: async (e) => {
              const { lat, lng } = e.target.getLatLng();
              setMarkerPosition([lat, lng]);
          
              try {
                const res = await fetch(
                  `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
                );
                const data = await res.json();
                const locationDetails = extractLocationDetails(data);
          
                setAddStore((prev) => ({
                  ...prev,
                  shop_latitude: lat.toFixed(6),
                  shop_longitude: lng.toFixed(6),
                  shop_address: locationDetails.shop_address,
                  shop_landmark: locationDetails.shop_landmark,
                  shop_city: locationDetails.shop_city,
                  shop_state: locationDetails.shop_state,
                  shop_country: locationDetails.shop_country,
                }));
          
                setSearchAddress(locationDetails.shop_address);
              } catch (error) {
                console.error("Error fetching address:", error);
              }
            },
          }}
        >
          <Popup>Drag the marker or type an address.</Popup>
        </Marker>
      </MapContainer>

      {/* Show Latitude and Longitude */}
      <div className="mt-3">
        <strong>Latitude:</strong> {markerPosition[0]} <br />
        <strong>Longitude:</strong> {markerPosition[1]}
      </div>
    </div>
  );
};

export default MapComponent;
