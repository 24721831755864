"use client";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";


import "../../Inventory Management System/Styles/spreadsheet.css";




import Select from "react-select";
import { patchMultipartWithAuthCall, simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Loader from "../../Loader";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import { AppContext } from "../../../context/AppContext";
import NoData from "../../../sharedComponent/NoData";

// index.js or App.js
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import { Navigate, useNavigate } from "react-router-dom";

export default function Category() {
  const { parentCategory, setParentCategory, selectedStoreID } = useContext(AppContext)
  const navigate = useNavigate();
  const [categoryList, SetCategoryList] = useState([])
  const [parentCategoryList, setParentCategoryList] = useState([])
  const [searchCategory, setSearchCategory] = useState("");
  const [loading, setLoading] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [activeInCtive, setActiveInctive] = useState('')
  const [rankData, setRankData] = useState({ data: [] })
  const [parentTab, setParentTab] = useState(false)

  const [activeAction, setActiveAction] = useState({
    data: [

    ]
  })


  const ActionList = [

    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const handleAction = (select) => {
    console.log("handleAction>>>", select.value)
    setActiveInctive(select.value)
    handleActiveData(select.value)
  }

  const fetchCategoryList = async (search = '', e) => {
    setLoading(true)
    const url = parentTab === false ? ApiConfig?.GET_PARENT_CATEGORY_LIST : ApiConfig?.GET_CATEGORY_LIST_DATA
    try {
      const response = await simpleGetCall(`${url}${selectedStoreID}&search=${search}`);
      if (response.success === true) {
        setLoading(false)
        const category = response?.data
        console.log("categoryList>>>>", category);
        if (parentTab === false) {
          setParentCategoryList(category)
        } else {
          SetCategoryList(category)
        }
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };

  useEffect(() => {
    fetchCategoryList(searchCategory)
  }, [parentTab, selectedStoreID])


  const handleSearchChange = (e) => {
    e.preventDefault();
    const query = e.target.value;
    setSearchCategory(query);
  };

  const handleCheckboxChange = (id) => {
    setRankData((prevState) => {

      const data = prevState.data || [];

      const exists = data.find((item) => item.id === id);

      if (exists) {

        return {
          ...prevState,
          data: data.filter((item) => item.id !== id),
        };
      } else {

        return {
          ...prevState,
          data: [...data, { id, active: null }],
        };
      }
    });
  };


  const handleActiveData = (selectedOption) => {
    const newActiveValue = selectedOption
    console.log("select Value", newActiveValue)

    setRankData((prevState) => {
      const updatedData = prevState?.data?.map((item) =>
        item.active === null ? { ...item, active: newActiveValue } : item
      );
      return { ...prevState, data: updatedData };
    });

    setActiveInctive('')

  };


  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index); // Store the dragged index
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };


  const handleDrop = (e, newIndex) => {
    const oldIndex = e.dataTransfer.getData("index");
    console.log("oldIndex>>>", oldIndex)

    if (oldIndex !== newIndex) {
      const updatedCategories = updateRanks(categoryList, parseInt(oldIndex), newIndex);
      SetCategoryList(updatedCategories);

      const apiData = updatedCategories.map((category) => ({
        id: category.id,
        active: null,
        rank: category.category_rank,
      }));


      setRankData({ data: apiData });
      setActiveInctive('')
      setActiveAction("")
    }
  };

  const handleDropPrent = (e, newIndex) => {
    const oldIndex = e.dataTransfer.getData("index");
    console.log("oldIndex>>>", oldIndex)

    if (oldIndex !== newIndex) {
      const updatedCategories = updateRanks(parentCategoryList, parseInt(oldIndex), newIndex);
      setParentCategoryList(updatedCategories);

      const apiData = updatedCategories.map((category) => ({
        id: category.id,
        active: null,
        rank: category.category_rank,
      }));


      setRankData({ data: apiData });
      setActiveInctive('')
      setActiveAction("")
    }
  };

  const updateRanks = (categories, oldIndex, newIndex) => {
    const movedItem = categories[oldIndex];
    let updatedCategories = [...categories];

    updatedCategories.splice(oldIndex, 1);

    updatedCategories.splice(newIndex, 0, movedItem);


    updatedCategories.forEach((item, index) => {
      item.category_rank = index + 1;
    });

    return updatedCategories;
  };



  // update Rank Apis
  const onUpdateRank = (active) => {

    let body;


    if (parentTab === false) {
      body = {
        data: rankData.data?.map(item => ({
          id: item.id,
          status: item.active === "1" ? true : null || item.active === "0" ? false : null,
          rank: item.rank ? item.rank : null,
        }))
      }
    } else {
      body = {
        data: rankData.data?.map(item => ({
          id: item.id,
          active:
            item.active === "1" ? item.active : null || item.active === "0" ? item.active : null,


          rank: item.rank ? item.rank : null,
        }))
      };
    }


    const url = parentTab === false ? ApiConfig?.PARENT_UPDATE_RANK : ApiConfig.UPDATE_RANK;

    patchMultipartWithAuthCall(url, JSON.stringify(body))
      .then((res) => {
        console.log("res>>>", res.json?.message)
        if (res.json?.success === true) {
          notifySuccess(res.json?.message)
          fetchCategoryList()
          setRankData("")
        } else {
          notifyError(res.json?.message)
        }
      })
      .catch((err) => {
        console.log("err>>>", err)
      })
  }

  console.log("Update rank body", rankData)

  useEffect(() => {
    if (rankData?.data?.length > 0) {
      const firstItem = rankData.data[0];

      if (firstItem.active === "0" || firstItem.active === "1") {
        onUpdateRank();
      }
    }
  }, [rankData]);
  return (
    <div className="category-main">
      <div className="d-flex flex-wrap justify-content-center justify-content-md-end gap-3 cat-but1">
        {/* Add Category Button */}
        <div className="addbut1 catbutton2">
          <button
            type="button"
            className="btn btn-primary d-flex align-items-center justify-content-center"
            style={{gap: "8px", width: "180px",backgroundColor:"#53B7E8", color:"#fff"}}
            onClick={() => {
              navigate("/Categorymanagement")
              parentTab === false ? setParentCategory(true) : setParentCategory(false)

            }
            }
          >
            <label className="bt-1">
              {parentTab === false ? "Add Parent " : "Add Category"}
            </label>
            <img
              src="/images/plus1.svg"
              alt="Arrow"
              width={20}
              height={20}
              className="ms-2"
            />
          </button>
        </div>

        {/* Action Dropdown Button */}
        <div className="z-3">
          <Select
            options={ActionList}
            onChange={handleAction}
            placeholder="Select Action"
          />

        </div>

      </div>
      <div className="tabs-container">
        <ul className="nav nav-tabs border-bottom-primary flex-column flex-md-row" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active custom-tab border"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              onClick={() => setParentTab(false)}
            >
              <label className="bt-1" >Parent Category</label>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-tab border"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={() => setParentTab(true)}
            >
              <label className="bt-1">Category</label>
            </button>
          </li>

        </ul>
      </div>


      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div>
            <div className="responsive-container">
              <div>
                <label className="tax-lab sp-text">Parent Category name</label>
              </div>
              <nav className="navbar">
                <div className="w-100">
                  <form
                    className="d-flex align-items-center justify-content-between" 
                    role="search"
                  >
                    <input
                      className="form-control me-2 flex-grow-1"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      name="search"
                      value={searchCategory}
                      onChange={handleSearchChange}
                    />
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn me-2 bt-1"
                        type="button"
                        style={{backgroundColor:"#53B7E8", color:"#fff"}}
                        onClick={() => fetchCategoryList(searchCategory)}
                      >
                        Search
                      </button >
                      <button
                        className="btn me-2 sp-button bt-1"
                        style={{backgroundColor:"#53B7E8", color:"#fff"}}
                        type="button"
                        onClick={() => {
                          fetchCategoryList("")
                          setSearchCategory("")
                        }}
                      >
                        Show all
                      </button>
                      <button
                        className="btn cat-button1 bt-1"
                        style={{backgroundColor:"#53B7E8", color:"#fff"}}
                        type="button"
                        onClick={onUpdateRank}
                      >
                        Update rank
                      </button>
                    </div>
                  </form>
                </div>
              </nav>
              <div>
                <label className="tax-lab text-head">
                  Note: Drag rows to rearrange order or ranking.
                </label>
              </div>
            </div>
            <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th scope="col" className="sp-table-head">
                      Display name
                    </th>
                    {/* <th scope="col" className="sp-table-head">
                  Category
                  </th> */}
                    <th scope="col" className="sp-table-head">
                      Rank
                    </th>
                    <th scope="col" className="sp-table-head">
                      Status
                    </th>
                    <th scope="col" className="sp-table-head">
                      Created
                    </th>

                    <th scope="col" className="sp-table-head">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {
                    loading ? (<>
                      <tr>
                        <td colSpan="9" className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    </>) : (
                      parentCategoryList?.length > 0 && parentCategoryList ? (
                        parentCategoryList?.map((row, index) => (
                          <tr
                            key={index} className="sp-table-head"
                            draggable="true"
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDropPrent(e, index)}
                          >
                            <td>

                              <input type="checkbox"
                                onClick={() => handleCheckboxChange(
                                  row.id
                                )}
                              />
                            </td>
                            <td className="sp-table-data">{row.name}</td>
                            <td className="sp-table-data">{row.rank}</td>

                            <td>
                              <div
                                className="sp-buttton"
                                style={{
                                  backgroundColor: row.status === true ? "#eaf8f3" : "#ffe9e9",
                                  color: row.status === true ? "#119C2B" : "#fd5e5e",
                                  padding: "5px 10px", // Optional: Add padding for better appearance
                                  borderRadius: "4px", // Optional: Add rounded corners
                                  textAlign: "center", // Optional: Center align text
                                }}
                              >
                                {row.status === true ? "Active" : "Inactive"}
                              </div>
                            </td>
                            <td className="sp-table-data"
                            >
                              {row.created_at}
                            </td>

                            <td>

                              <img
                                src="images/dots.svg"
                                alt="Button Icon"
                                width={20}
                                height={20}
                                className=""
                                onClick={() => {
                                  navigate(`/Editcategory/${row.id}`)
                                  setParentCategory(true)

                                }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="ErrorDataNotFound">
                          <td colSpan={9}>
                            <NoData />
                          </td>
                        </tr>
                      )
                    )

                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div
          className="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div>
            <div className="responsive-container">
              <div>
                <label className="tax-lab sp-text">
                  Category name </label>
              </div>
              <nav className="navbar">
                <div className="w-100">
                  <form
                    className="d-flex align-items-center justify-content-between"
                    role="search"
                  >
                    <input
                      className="form-control me-2 flex-grow-1"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      name="search"
                      value={searchCategory}
                      onChange={handleSearchChange}
                    />
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn me-2 bt-1"
                        type="button"
                        onClick={() => fetchCategoryList(searchCategory)}
                        style={{backgroundColor:"#53B7E8", color:"#fff"}}
                      >
                        Search
                      </button >
                      <button
                        className="btn me-2 sp-button bt-1"
                        type="button"
                        style={{backgroundColor:"#53B7E8", color:"#fff"}}
                        onClick={() => {
                          fetchCategoryList("")
                          setSearchCategory("")
                        }}
                      >
                        Show all
                      </button>
                      <button
                        className="btn  cat-button1 bt-1"
                        type="button"
                        style={{backgroundColor:"#53B7E8", color:"#fff"}}
                        onClick={onUpdateRank}
                      >
                        Update rank
                      </button>
                    </div>
                  </form>
                </div>
              </nav>
              <div>
                <label className="tax-lab text-head">
                  Note: Drag rows to rearrange order or ranking.
                </label>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col" className="sp-table-head">
                    Display name
                  </th>
                  <th scope="col" className="sp-table-head">
                    Rank
                  </th>
                  <th scope="col" className="sp-table-head">
                    Status
                  </th>
                  <th scope="col" className="sp-table-head">
                    Created
                  </th>

                  <th scope="col" className="sp-table-head">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  loading ? (<>
                    <tr>
                      <td colSpan="9" className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  </>) : (
                    categoryList?.length > 0 ? (
                      categoryList?.map((row, index) => (
                        <tr
                          key={index} className="sp-table-head"
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragOver={(e) => handleDragOver(e)}
                          onDrop={(e) => handleDrop(e, index)}
                        >
                          <td>

                            <input type="checkbox"
                              onClick={() => handleCheckboxChange(
                                row.id
                              )}
                            />
                          </td>
                          <td className="sp-table-data">{row.category_name}</td>
                          <td className="sp-table-data">{row.category_rank}</td>

                          <td>
                            <div
                              className="sp-buttton"
                              style={{
                                backgroundColor: row.active === 1 ? "#eaf8f3" : "#ffe9e9",
                                color: row.active === 1 ? "#119C2B" : "#fd5e5e",
                                padding: "5px 10px", // Optional: Add padding for better appearance
                                borderRadius: "4px", // Optional: Add rounded corners
                                textAlign: "center", // Optional: Center align text
                              }}
                            >
                              {row.active === 1 ? "Active" : "Inactive"}
                            </div>
                          </td>
                          <td className="sp-table-data"
                          >
                            {row.created_at}
                          </td>

                          <td>

                            <img
                              src="images/dots.svg"
                              alt="Button Icon"
                              width={20}
                              height={20}
                              className=""
                              onClick={() => {
                                navigate(`/Editcategory/${row.id}`)
                                setParentCategory(false)
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="ErrorDataNotFound">
                        <td colSpan={9}>
                          <NoData />
                        </td>
                      </tr>
                    )
                  )

                }
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          ..bcbcbcbc.
        </div>
        <div
          className="tab-pane fade"
          id="configuration"
          role="tabpanel"
          aria-labelledby="configuration-tab"
        >
          hfthfhfhhf
        </div>
        <div
          className="tab-pane fade"
          id="action"
          role="tabpanel"
          aria-labelledby="action-tab"
        >
          kkkkjkkjkjkjkjk
        </div>
      </div>
    </div>
  );
}
