import { useNavigate } from "react-router-dom";

import "../../Inventory Management System/Styles/tax.css";
import "../../Inventory Management System/Styles/variation.css";

import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { patchMultipartWithAuthCall, simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../../toaster/notify";
import Loader from "../../Loader";
import NoData from "../../../sharedComponent/NoData";



const Taxes = () => {
  const navigate = useNavigate();
  const { selectedStoreID } = useContext(AppContext)

  const [taxList, setTaxList] = useState([])
  const [loading, setLoading] = useState(false)
  const [rankData, setRankData] = useState({ data: [] })

  const tableData = [
    {
      id: 1,
      name: "CGST",
      department: "Forward Tax",
      status: "Active",
      created: "Percentage",
      modified: "Dec 30 ,2024",
    },
    {
      id: 2,
      name: "SGST",
      department: "Forward Tax",
      status: "Active",
      created: "Percentage",
      modified: "Dec 29 ,2024",
    },
  ];
  const actions = [
    "Select your store",
    "Grocery Store 01",
    "Lorem Ipsum 01",
    "Lorem Ipsum 02",
  ];


  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index); // Store the dragged index
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const updateRanks = (categories, oldIndex, newIndex) => {
    const movedItem = categories[oldIndex];
    let updatedCategories = [...categories];

    updatedCategories.splice(oldIndex, 1);

    updatedCategories.splice(newIndex, 0, movedItem);


    updatedCategories.forEach((item, index) => {
      item.tax_rank = index + 1;
    });

    return updatedCategories;
  };

  const handleDrop = (e, newIndex) => {
    const oldIndex = e.dataTransfer.getData("index");
    console.log("oldIndex>>>", oldIndex)

    if (oldIndex !== newIndex) {
      const updatedCategories = updateRanks(taxList, parseInt(oldIndex), newIndex);
      setTaxList(updatedCategories);

      const apiData = updatedCategories.map((category) => ({
        id: category.id,
        rank: category.tax_rank,
      }));


      setRankData({ data: apiData });
      // setActiveInctive('')
      // setActiveAction("")
    }
  };


  const fetchTaxList = async () => {
    setLoading(true)
    const storeId = selectedStoreID

    try {
      const response = await simpleGetCall(ApiConfig.GET_TAXES_LIST + storeId);
      if (response.success === true) {
        setLoading(false)
        const taxListresponse = response?.data
        setTaxList(taxListresponse)
        console.log("TaxList>>>>", taxListresponse);

      } else {
        setLoading(false)
      }
    } catch (err) {
      console.error("Dropdown API Error:", err);
    }
  };

  const onUpdateRank = () => {
    setLoading(true)
    const body = {
      ranks: rankData.data?.map(item => ({
        id: item.id,
        tax_rank: item.rank ? item.rank : null,
      }))
    }
    console.log("body>>>", body)


    patchMultipartWithAuthCall(ApiConfig.TEX_UPDATE_RANK, JSON.stringify(body))
      .then((res) => {
        console.log("res>>>", res.json?.message)
        if (res.json?.success === true) {
          notifySuccess(res.json?.message)
          fetchTaxList()
          setRankData("")
          setLoading(false)
        } else {
          notifyError(res.json?.message)
        }
      })
      .catch((err) => {
        console.log("err>>>", err)
      })
  }


  useEffect(() => {
    fetchTaxList()
  }, [selectedStoreID])

  return (
    <main>
      <div style={{ padding: 5 }}>
        <div className="d-flex mb-4 flex-sm-row justify-content-sm-start justify-content-lg-end align-items-start gap-3">

          <div>

            <button className="btn w-full sm:w-auto " style={{backgroundColor:"#53B7E8", color:"#fff"}} onClick={() => navigate("/Addtax")} >


              <label className="add-text">Add Tax</label>
            </button>
          </div>

          <button className="btn w-full sm:w-auto"
          style={{backgroundColor:"#53B7E8", color:"#fff", width:"110px",height:"34px"}}
            type="button"
            onClick={onUpdateRank}
          >
            Update rank
          </button>
        </div>

        <div className="text-start">
          <label className="tax-lab">
            Note: Drag rows to rearrange order or ranking.
          </label>
        </div>

        {/* Table */}
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table className="table">
            <thead>
              <tr className="bg-grey font-family font-weight-400 font-size-14  ">
                <th>
                  sr.no
                </th>
                <th scope="col" className="tax-type-label">Title</th>
                <th scope="col" className="tax-type-label">Department</th>
                <th scope="col" className="tax-type-label">Status</th>
                <th scope="col" className="tax-type-label">Type</th>
                <th scope="col" className="tax-type-label">Rank</th>
                <th scope="col" className="tax-type-label">Created</th>
                <th scope="col" className="tax-type-label">Actions</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (<>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              </>) : (
                taxList.length > 0 ? (
                  taxList?.map((row, index) => (
                    <tr key={row.id} className="tax-name hover:bg-gray-100"
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOver(e)}
                      onDrop={(e) => handleDrop(e, index)}

                    >
                      <td>
                        {index + 1}
                      </td>

                      <td>{row.tax_name}</td>
                      <td>{row.tax_tax_type === "1" ? "Forward Tax" : "Backward Tax"}</td>
                      <td>
                        <div
                          className={`sp-buttton-b tax-type-label 
                      p-2 p-md-23 p-lg-2`} // Apply responsive padding using Bootstrap classes
                          style={{
                            backgroundColor: row.tax_active === 1 ? "#eaf8f3" : "#ffe9e9",
                            color: row.tax_active === 1 ? "#119C2B" : "#fd5e5e",
                            padding: "5px 10px", // Optional: Add padding for better appearance
                            borderRadius: "4px", // Optional: Add rounded corners
                            textAlign: "center", // Optional: Center align text
                          }}
                        >
                          {row.tax_active === 1 ? "Active" : "InActive"}
                        </div>
                      </td>
                      <td>{row.tax_type === "1" ? "Percentage" : "Fixed"}</td>
                      <td>{row.tax_rank}</td>

                      <td>{row?.created_at}</td>
                      <td>
                        <img src="images/dots.svg" alt="Button Icon" width={20} height={20} onClick={() => navigate(`/Edittax/${row.id}`)} />

                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={9}>
                      <NoData />
                    </td>
                  </tr>
                )
              )}
            </tbody>



            {/* <tbody>
                            {
                              loading ? (<>
                                <div style={{
                                  marginRight: "100Px",
                                  alignItems: 'center',
                                  height: '50vh'
                                }}
                                >
                                  <Loader />
                                </div>
                              </>) : (
                                tableData?.length > 0 ? (
                                  tableData?.map((row, index) => (
                                    <tr
                                      // key={index} className="sp-table-head"
                                      // draggable="true"
                                      // onDragStart={(e) => handleDragStart(e, index)}
                                      // onDragOver={(e) => handleDragOver(e)}
                                      // onDrop={(e) => handleDrop(e, index)}
                                    >
                                      <td>
            
                                        <input type="checkbox"
                                          // onClick={() => handleCheckboxChange(
                                          //   row.id
                                          // )}
                                        />
                                      </td>
                                      <td className="sp-table-data">{row.name}</td>
                                      <td className="sp-table-data">{row.rank}</td>
            
                                      <td>
                                        <div
                                          className="sp-buttton"
                                          style={{
                                            backgroundColor: row.status === true ? "#eaf8f3" : "#ffe9e9",
                                            color: row.status === true ? "#119C2B" : "#fd5e5e",
                                            padding: "5px 10px", // Optional: Add padding for better appearance
                                            borderRadius: "4px", // Optional: Add rounded corners
                                            textAlign: "center", // Optional: Center align text
                                          }}
                                        >
                                          {row.status === true ? "Active" : "Inactive"}
                                        </div>
                                      </td>
                                      <td className="sp-table-data"
                                      >
                                        {row.created_at}
                                      </td>
            
                                      <td>
            
                                        <img
                                          src="images/dots.svg"
                                          alt="Button Icon"
                                          width={20}
                                          height={20}
                                          className=""
                                          // onClick={() => {
                                          //   navigate(`/Editcategory/${row.id}`)
                                          //   setParentCategory(true)
            
                                          // }}
                                        />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <div>
                                    <h2>No Data</h2>
                                  </div>
                                )
                              )
            
                            }
                          </tbody> */}
          </table>
        </div>
      </div>
    </main>
  );
};

export default Taxes;
