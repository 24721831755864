import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { Accordion } from "react-bootstrap";
import DynamicField from "../../sharedComponent/dynamicField";
import CommonButton from "../ChatAdmin/button";
import { emailValidation, formatDate, formatTime, formatTimes } from "../../common";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";
import NoData from "../../sharedComponent/NoData";
import AddEmployee from "../Employee/AddEmployee";
import ClientList from "../ClientManagement/ClientList";
import EntitiesList from "../ClientManagement/EntitiesList";
import BranchList from "../ClientManagement/BranchList";
import BankList from "../ClientManagement/BankList";


const screens = ["AddEmployee", 'AddClient']

function CompleteTaskDynamic() {
  const {
    sidebar,
    setSidebar,
    Dark,
    setDark,
    userData,
    permissions,
    setLoadWorkFlowTaskForm,
    getPendingWorkflowTaskCount
  } = useContext(AppContext);

  const { id, taskAllocationId } = useParams();

  const navigate = useNavigate();

  const [valudationMessage, setValidationMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isReject, setIsReject] = useState(false);
  const [isUpdateStatusOpne, setiSUpadateStatusOpen] = useState(false);
  const [showPrievesData, seShowPrievesDataOpen] = useState(false);
  const [prievesDataList, setPrievesDataList] = useState([]);
  const [createid, setCreateId] = useState(0)

  const [inprogressId, setInProgressId] = useState(0);

  const initialPayload = {
    taskId: "",
    taskAllocationId: "",
    data: [
      {
        key: "resion",
        value: "",
      },
      {
        key: "action",
        value: "",
      },
    ],
    action: "",
    resion: "",
    Reason: "",
    isAdministrative: false,
  };

  const [payload, setPayload] = useState(initialPayload);


  const [isValided, setIsvalided] = useState(false);

  const [taskDeatils, setTaskDetails] = useState({});
  const [dynamicFields, setDynamicsFields] = useState([]);

  const [clientStep, setClientStep] = useState(1)

  const getDynamicFields = () => {
    PostCallWithErrorResponse(
      ApiConfig.GET_DYNAMIC_FIELDS_FOR_TASK,
      JSON.stringify({
        Id: Number(id),
        IsAdministrative: false,
      })
    ).then((res) => {
      if (res?.json?.success) {
        setDynamicsFields(res?.json?.data);
        let defaultId = res?.json?.data[0]?.taskActions?.filter(
          (i) => i?.statusName === "In Progress"
        );

        setInProgressId(defaultId[0]?.id);

        handleAddAction(defaultId[0]?.id);

        setPayload((prev) => ({
          ...prev,
          action: defaultId[0]?.id + "",
        }));
      }
    });
  };

  const getPrivesTaskData = () => {
    seShowPrievesDataOpen(true);
    const body = {
      IsAdministrative: false,
      WorkFlowAllocationId: taskDeatils?.workFlowAllocationId,
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_pREVIES_TASKS_LIST,
      JSON.stringify(body)
    ).then((res) => {
      console.log("Previes task Data>>> ", res.json);

      if (res?.json?.success === true) {
        setPrievesDataList(res?.json?.data);
      }
    });
  };

  console.log("prieves Task List>>>", prievesDataList);

  const getTaskDetails = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_TASK_DETAILS + id).then(
      (res) => {
        if (res?.json?.success) {
          setTaskDetails(res?.json?.data);
        } else {
          ToastMsg("error", res?.json?.message);
        }
      }
    );
  };

  useEffect(() => {
    getDynamicFields();

    getTaskDetails();
  }, []);

  const handleAddTask = (key, val) => {


    let indexOf = payload?.data?.findIndex((i, index) => {
      return i.key == key;
    });

    if (indexOf != -1) {
      let updateData = [...payload?.data];

      updateData[indexOf].value = val + "";

      setPayload((prev) => ({
        ...prev,
        data: updateData,
      }));
    } else {
      let updateData = [
        ...payload?.data,
        {
          key: key,
          value: val,
        },
      ];

      setPayload((prev) => ({
        ...prev,
        data: updateData,
      }));
    }
  };

  const handleAddAction = (id) => {
    let indexOf = payload?.data?.findIndex((i, index) => {
      return i.key == "action";
    });
    let updateData = [...payload?.data];

    updateData[indexOf].value = id + "";

    let isValid = true;

    setPayload((prev) => ({
      ...prev,
      action: "" + id,
      data: updateData,
    }));
  };

  const handleSubmit = () => {
    if (!payload?.action) {
      ToastMsg("error", "please Select Action");
      return;
    }

    let isValid = true;

    let allValidationMesg = {
      ...valudationMessage,
    };

    dynamicFields[0]?.taskDataToUIDtos?.forEach((i) => {
      if (i.validations[0]?.name != "None") {
        let findStoreValue = payload?.data?.filter((val) => val.key == i?.id);
        let validation = i?.validations[0]?.name;
        let val = findStoreValue[0]?.value;
        let ifAny = i?.validations[0]?.validationifany;
        let isRejectField = i?.validations[0]?.value === "Reject";

        let regex = new RegExp(ifAny);

        if (findStoreValue?.length === 0) {
          if (!isRejectField) {
            allValidationMesg[i?.id + ""] = i?.description;
            isValid = false;
            setIsvalided(true);

          }
        } else {
          if (
            isRejectField &&
            isReject &&
            validation == "Required" &&
            val.length == 0
          ) {
            isValid = false;

            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (
            !isRejectField &&
            validation == "Required" &&
            val.length == 0
          ) {

            isValid = false;

            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (validation == "Email" && !emailValidation(val)) {
            isValid = false;
            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (validation == "MaxLength" && val.length > Number(ifAny)) {
            isValid = false;
            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (validation == "MinLength" && val.length < Number(ifAny)) {
            isValid = false;
            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (
            validation == "Max" &&
            (isNaN(val) || Number(val) > Number(ifAny))
          ) {
            isValid = false;
            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (
            validation == "Min" &&
            (isNaN(val) || Number(val) < Number(ifAny))
          ) {
            isValid = false;
            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else if (validation == "Pattern" && !regex.test(val)) {
            isValid = false;
            allValidationMesg[i?.id + ""] = i?.description;
            setIsvalided(true);
          } else {
            allValidationMesg[i?.id + ""] = "";
          }
        }
      }
    });

    if (payload?.action != inprogressId && !payload?.Reason) {
      ToastMsg("error", "Please Enter Reason");

      return;
    }

    setValidationMessage(allValidationMesg);
    if (isValid) {
      setIsLoading(true);

      payload.taskId = Number(taskAllocationId);

      payload.taskAllocationId = Number(id);

      PostCallWithErrorResponse(
        ApiConfig?.SUBMIT_DYNAMIC_TASK,
        JSON.stringify(payload)
      ).then((res) => {
        if (res?.json?.success) {
          ToastMsg("success", res?.json?.message);
          navigate("/CompleteTask");
          setLoadWorkFlowTaskForm(true);
          getPendingWorkflowTaskCount()
        } else {
          ToastMsg("error", res?.json?.message);
        }

        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Workflow Task Form</h6>
            <div className="d-flex  justify-content-center align-items-center">
              <h5>Status : {taskDeatils?.status}</h5>
              <button
                className="ml-4 modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                style={{ cursor: "pointer" }}
                onClick={() => setiSUpadateStatusOpen(true)}
              >
                Change status
              </button>
            </div>
          </div>

          <div className="bg-white p-3 rounded-3 mt-4">
            <div className="mt-4 row mainFormSection box-shadow mr-4 p-4">
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Workflow Name : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.workFlowName}</h6>
              </div>
              <div className="col-lg-4 d-flex">
                <h6 className="text-muted ">Client Name : </h6>
                <h6 className="fw-bold ml-2">
                  {taskDeatils?.client?.firstName +
                    " " +
                    taskDeatils?.client?.lastName}
                </h6>
              </div>
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Sequence No : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.sequenceNumber}</h6>
              </div>
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Branch Name : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.branchName}</h6>
              </div>

              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Description : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.description}</h6>
              </div>
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Date : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.date}</h6>
              </div>
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Subscription : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.subscription}</h6>
              </div>
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Display Type : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.displayType}</h6>
              </div>

              <div className="col-lg-4 d-flex mb-2"></div>

              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Quotation : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.quotation}</h6>
              </div>
              <div className="col-lg-4 d-flex mb-2">
                <h6 className="text-muted">Service : </h6>
                <h6 className="fw-bold ml-2">{taskDeatils?.service}</h6>
              </div>
            </div>

            <button
              className="ml-2 modal-create-btn branchList-modal-cancel-btn-arbaz-responsive mt-2"
              style={{ cursor: "pointer" }}
              onClick={getPrivesTaskData}
            >
              WorkFlow Collected data
            </button>
            <Accordion defaultActiveKey={0}>
              {dynamicFields?.map((task, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index} className="mt-2">
                    <Accordion.Header>
                      <div
                        className="menus-items w-100 d-flex justify-content-between"
                        style={{
                          backgroundColor: "whiteSmoke",
                          padding: "15px 15px 15px 15px",
                        }}
                      >
                        <h5 className="">
                          {" "}
                          {task?.taskDataToUIDtos[0]?.taskName}{" "}
                        </h5>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row m-4">
                        {task?.taskDataToUIDtos?.map((field) => {
                          return (
                            <div
                              className={`${field?.displayTypeId == 1
                                ? "col-lg-12"
                                : "col-lg-6"
                                }`}
                            >
                              {
                                screens.includes(field?.dataType) ? <>
                                  {
                                    field?.dataType === 'AddEmployee' && <AddEmployee
                                      fromDynamic={true}
                                      handleAddTask={handleAddTask}
                                      keyName={field?.id + ""} />

                                  }
                                  {
                                    field?.dataType === 'AddClient' && <>
                                      {
                                        clientStep == 1 &&
                                        <>
                                          <h6>Create Client</h6>
                                          <ClientList
                                            fromDynamic={true}
                                            handleAddTask={handleAddTask}
                                            keyName={field?.id + ""}
                                            setClientStep={setClientStep}
                                            createid={createid}
                                            setCreateId={setCreateId}

                                          />
                                        </>
                                      }
                                      {
                                        clientStep == 2 && <>

                                          <h6>Create Entity</h6>
                                          <EntitiesList
                                            fromDynamic={true}
                                            setClientStep={setClientStep}
                                            clientId={createid}
                                            setCreateId={setCreateId}
                                          />
                                        </>
                                      }

                                      {
                                        clientStep == 3 && <>

                                          <BranchList
                                            fromDynamic={true}
                                            setClientStep={setClientStep}
                                            _entityId={createid}
                                            setCreateId={setCreateId}
                                          />
                                        </>
                                      }

                                      {
                                        clientStep == 4 && <>

                                          <h6>Create Bank</h6>

                                          <BankList
                                            fromDynamic={true}
                                            _branchId={createid}
                                          />
                                        </>
                                      }
                                    </>
                                  }



                                </> :

                                  <DynamicField
                                    slection={field?.selections}
                                    label={field?.name}
                                    placeholder={field?.description}
                                    required={field?.validations[0]?.name}
                                    type={field?.dataType?.toLowerCase()}
                                    keyName={field?.id + ""}
                                    handleAddTask={handleAddTask}
                                    value={
                                      payload?.data?.filter(
                                        (i) => i.key == field?.id
                                      )[0]?.value
                                    }
                                    taskId={field?.taskId}
                                    isValided={isValided}
                                    validations={valudationMessage}
                                    isReject={isReject}
                                    setIsReject={setIsReject}
                                  />
                              }
                            </div>
                          );
                        })}
                      </div>
                      <div className="d-flex justify-content-end">
                        <CommonButton
                          title={"Submit"}
                          handlerFunction={handleSubmit}
                          isLoading={isLoading}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPrievesData}
        className="create-workflow-main modal-lg"
        size="md"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Previous Task Data
            </label>
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={() => seShowPrievesDataOpen(false)}
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div
            style={{
              maxHeight: "580px",
              overflowX: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <Accordion defaultActiveKey={0}>
              {prievesDataList.length > 0
                ? prievesDataList?.map((task, index) => {
                  return (
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      className="mt-2"
                    >
                      <Accordion.Header>
                        <div
                          className="menus-items w-100 d-flex justify-content-between"
                          style={{
                            backgroundColor: "whiteSmoke",
                            padding: "15px 15px 15px 15px",
                          }}
                        >
                          <h5 className=""> {task?.taskName} </h5>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          style={{
                            maxHeight: "580px",
                            overflowX: "scroll",
                            scrollbarWidth: "none",
                          }}
                        >
                          <div className="row m-4">
                            <dv className="d-flex justify-content-between">
                              <div className="d-flex ">
                                <label className="text-muted">Name : </label>
                                <label className="fw-bold ml-2">
                                  {task?.taskName}
                                </label>
                              </div>
                              <div className="d-flex ">
                                <label className="text-muted ">
                                  Status :{" "}
                                </label>
                                <label className="fw-bold ml-2">
                                  {task?.status}
                                </label>
                              </div>
                            </dv>
                            <dv className="d-flex justify-content-between">
                              <div className="d-flex mt-2">
                                <label className="text-muted">
                                  Data Provided By :{" "}
                                </label>
                                <label className="fw-bold ml-2">
                                  {task?.assignedUser}
                                </label>
                              </div>
                              <div className="d-flex mt-2">
                                <label className="text-muted">
                                  Sequence Number :{" "}
                                </label>
                                <label className="fw-bold ml-2">
                                  {task?.sequenceNumber}
                                </label>
                              </div>


                            </dv>
                            <dv className="d-flex justify-content-between">
                              <div className="d-flex mt-2">
                                <label className="text-muted ">Time : </label>
                                <label className="fw-bold ml-2">
                                  {formatTimes(task?.date)}
                                </label>
                              </div>
                              <div className="d-flex mt-2">
                                <label className="text-muted ">Date : </label>
                                <label className="fw-bold ml-2">
                                  {formatDate(task?.date)}
                                </label>
                              </div>
                            </dv>
                            <div className="hr-line-modal mt-2">
                              <hr style={{ margin: "0" }} />
                            </div>
                            <div className="table-wrapper">
                              <div className="ml-7">
                                <table>
                                  <tr>
                                    <th>Sr. no</th>
                                    <th>label</th>
                                    <th>value</th>
                                  </tr>
                                  {task?.taskDynamicvalues?.map(
                                    (item, index) => {
                                      return (
                                        <tr className="table-row-custom">
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item?.taskDataTypeName === "Attachment" ? (
                                            <a href={BASE_URL + "/assets/uploads/" + item?.value} target="_blank"
                                              className="text-decoration-none text-primary"
                                              download
                                            >{item?.value} </a>
                                          ) : (
                                            item?.value
                                          )

                                          }</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
                : (
                  <NoData />
                )}
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isUpdateStatusOpne}
        className="create-workflow-main modal-lg"
        size="md"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Update Workflow Status
            </label>
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={() => setiSUpadateStatusOpen(false)}
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="row">
            <div className="m col-lg-6">
              {dynamicFields[0]?.taskActions?.map((action, index) => {
                return (
                  <div key={index}>
                    <input
                      type="radio"
                      id={`action-${action?.id}`}
                      name="taskAction"
                      value={action?.id + ""}
                      checked={action?.id == payload?.action}
                      //  defaultChecked={action?.id}
                      onChange={() => {
                        handleAddAction(action?.id + "");
                      }}
                    />
                    <label
                      htmlFor={`action-${action?.id}`}
                      className="ml-4 mt-1"
                    >
                      {action?.statusName}
                    </label>
                  </div>
                );
              })}
            </div>
            {payload?.action != inprogressId && (
              <div className="col-lg-6">
                <label htmlFor="Reason">Reason :</label>
                <textarea
                  name=""
                  rows="8"
                  placeholder="Enrer Reason"
                  class="form-control tasKCategory1"
                  style={{ height: "100px" }}
                  value={payload?.Reason}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      Reason: e.target.value,
                    }));
                  }}
                ></textarea>
              </div>
            )}
          </div>
        </Modal.Body>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button
              className="modal-cancel-btn"
              onClick={() => setiSUpadateStatusOpen(false)}
            >
              Close
            </button>
            <button
              onClick={() => {
                if (payload.action != inprogressId && !payload?.Reason) {
                  ToastMsg("error", "Please Enter Reason");
                } else {
                  setiSUpadateStatusOpen(false);
                }
              }}
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompleteTaskDynamic;
