import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import searchicon from "../../assets/images/search_icon.svg";
import { Modal } from "react-bootstrap";
import close from "../../assets/images/close.svg";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { getDateddmmyyyy } from "../../common";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import Pagination from "../../../src/sharedComponent/Pagination";
import CommonButton from "../ChatAdmin/button";




function WorkFlowMapping() {

    const initialState = {
        "id": 0,
        "subscriptionIds": [
            ''
        ],
        entityId: 0,
        "Name": "",
        "isRetrospective": false,
        "isSequential": false,
        "beginDate": "",
        "endDate": "",
        "workflowId": '',
        "Status": ""
    }

    const [state, setState] = useState(initialState)

    const { sidebar } = useContext(AppContext)

    const [search, setSearch] = useState('')
    const [isOpenWorkflowMapping, setIsOpenWorkflowMapping] = useState(false)
    const [workflowList, setWorkflowList] = useState([])
    const [isWorkflowListLoad, setIsWorkflowListLoad] = useState(false)
    const [subscription, setSubscriptions] = useState([]);
    const [workflows, setWorkflows] = useState([])
    const [selectStatus, setSelectStatus] = useState("")
    const [isSubscriptionWorkFlow, setIsSubscriptionWorkflow] = useState(true)

    const [perPageData, setPerpgaeData] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [isValided, setIsValided] = useState(false);
    const [isWorkflowCreating, setIsWorkflowCreating] = useState(false)


    const startIndex = currentPage * perPageData;
    const endIndex = startIndex + perPageData;



    const handleModalOpen = () => {
        setIsOpenWorkflowMapping(true)

        getWorkFlowList()
    }

    const handleClose = () => {

        setIsOpenWorkflowMapping(false)
        setIsValided(false)
        setState(initialState)
        setIsWorkflowCreating(false)
        setIsSubscriptionWorkflow(true)
    }

    useEffect(() => {
        getWorkflows()
    }, [])

    const getWorkflows = () => {
        setIsWorkflowListLoad(true);

        simpleGetCallWithErrorResponse(ApiConfig.GET_WORKFLOWS).then((res) => {
            if (res?.json?.success) {
                setWorkflowList(res?.json?.data);
            }

            setIsWorkflowListLoad(false);
        });


    }

    const getSubsciptions = (id) => {


        simpleGetCallWithErrorResponse(ApiConfig.GET_PENDING_SUBSCRIPTIONS + '?ServiceGroupType=' + id).then(
            (res) => {
                if (res?.json?.success) {
                    setSubscriptions(res?.json?.data);
                }
            }
        );
    };

    const getWorkFlowList = () => {


        if (workflows?.length > 0) return


        simpleGetCallWithErrorResponse(ApiConfig.GET_ALL_WORKFLOW + true).then((res) => {
            if (res?.json?.success) {
                setWorkflows(res?.json?.data);
            }


        });
    };

    const handleChange = (key, val) => {

        setState(prev => ({
            ...prev,
            [key]: val
        }))

    }

    const handleSubmit = () => {
        let isValid = true;

        if (
            !state?.beginDate ||
            !state?.endDate ||
            (state?.subscriptionIds.length == 0 || state?.subscriptionIds[0] === '') ||

            !state?.workflowId
        ) {
            isValid = false;

            setIsValided(true);
        }

        if (isValid) {

            setIsWorkflowCreating(true)
            let endPoint = state?.id == 0 ? ApiConfig?.CREATE_WORKFLOW_MAPPING : ApiConfig.UPDATE_WORKFLOW_MAPPING

            PostCallWithErrorResponse(endPoint, JSON.stringify(state)).then((res) => {

                if (res?.json?.success) {
                    ToastMsg('success', res?.json?.message)
                    handleClose()
                    getWorkflows()


                } else {
                    ToastMsg('error', res?.json?.message)
                }

                setIsWorkflowCreating(false)




            })

        }
    }

    const getWorkflowById = (id) => {

        simpleGetCallWithErrorResponse(ApiConfig.GET_WORKFLOW_BY_ID_MAPPING + id).then((res) => {
            if (res?.json?.success) {
                setState(res?.json?.data)
                setIsSubscriptionWorkflow(res?.json?.data?.entityId == 0)

                getSubsciptions(res?.json?.data?.entityId == 0 ? 1 : 2)
            } else {
                ToastMsg('error', res?.json?.message)
            }
        })

    }

    return (
        <div>

            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div>
                    <div className="role-content">
                        <div className="sub-header-role">
                            <h6 className="mt-2 role-text"> Workflow Event Mapping </h6>
                            <button
                                className="create-btn-sub-header"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    getSubsciptions(1)
                                    handleModalOpen()

                                }}
                            >
                                Create
                            </button>
                        </div>
                        <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
                            <div className="role-table-header reponsivesrc">
                                <div className="search-input-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                            setCurrentPage(0)
                                        }}
                                    />
                                    <img src={searchicon} alt="" />
                                </div>
                                <div className="entries-wrapper">
                                    <p>Select Status</p>
                                    <select
                                        class="form-select select-drop-icon remove-border"
                                        aria-label="Default select example"
                                        value={selectStatus}
                                        onChange={(e) => {
                                            setSelectStatus(e.target.value)
                                            setCurrentPage(0)

                                        }
                                        }
                                    >
                                        <option selected value={""}>
                                            Select Status
                                        </option>
                                        <option value="Completed">Completed</option>
                                        <option value="InProgress">InProgress</option>
                                        <option value="InActive">InActive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {isWorkflowListLoad ? (
                            <Loader />
                        ) : workflowList?.length == 0 ? (
                            <NoData />
                        ) : (
                            <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                                <table>
                                    <tr>
                                        <th>Sr. no</th>
                                        <th>Name</th>
                                        <th>Name Id</th>
                                        <th>Status</th>
                                        <th>Action</th>

                                    </tr>

                                    {workflowList
                                        ?.filter((workflow) =>
                                            search?.length
                                                ? workflow?.name
                                                    ?.toLowerCase()
                                                    .includes(search.toLowerCase())
                                                : workflow
                                        ).filter((workflow) => selectStatus?.length ? workflow?.status === selectStatus : workflow
                                        ).slice(startIndex, endIndex)
                                        .map((workFlow, index) => {
                                            return (
                                                <tr className="table-row-custom">
                                                    <td>{startIndex + index + 1}</td>
                                                    <td className="send-quote-status">
                                                        {workFlow?.name}
                                                    </td>
                                                    <td>{workFlow?.uniqueId}</td>
                                                    <td>{workFlow.status == 'InActive' ? (
                                                        <span className="colorP">{workFlow.status}</span>
                                                    ) : workFlow.status === "Allocated" ? (
                                                        <span className="colorAl">{workFlow.status}</span>
                                                    ) : workFlow.status === "Completed" ? (
                                                        <span className="colorC">{workFlow.status}</span>
                                                    ) : workFlow.status === "Deallocated" ? (
                                                        <span className="colorDe">{workFlow.status}</span>
                                                    ) : workFlow.status === "Abort" ? (
                                                        <span className="colorAb">{workFlow.status}</span>
                                                    ) : workFlow.status === "InProgress" ? (
                                                        <span className="colorIn">{workFlow.status}</span>
                                                    ) : workFlow.status === "Rejected" ? (
                                                        <span className="colorR">{workFlow.status}</span>
                                                    ) : workFlow.status === "Reallocated" ? (
                                                        <span className="colorRe">{workFlow.status}</span>
                                                    ) : workFlow.status === "Suspended" ? (
                                                        <span className="colorH">{workFlow.status}</span>
                                                    ) : workFlow.status === "Approved" ? (
                                                        <span className="colorA"></span>
                                                    ) : (
                                                        ""
                                                    )}</td>
                                                    <tr>
                                                        <img
                                                            src={workFlow?.workflowId != 0 ? disabled_edit : editicon}
                                                            alt=""
                                                            onClick={() => {
                                                                if (workFlow?.workflowId != 0) return
                                                                handleModalOpen();
                                                                getWorkflowById(workFlow?.id);
                                                            }}
                                                            className="me-3"
                                                        />

                                                    </tr>
                                                </tr>
                                            );
                                        })}
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <Pagination
                                data={workflowList}
                                pageChangeHandler={(selected) =>
                                    setCurrentPage(selected.selected)
                                }
                                usersPerPage={perPageData}
                                currentPage={currentPage}
                                searchValue={search}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpenWorkflowMapping}
                className="create-workflow-main modal-lg"
                size="xl"

            >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            {state?.id == 0 ? "Create" : "Update"}
                            WorkFlow Mapping
                        </label>
                    </Modal.Title>
                    <img src={close} alt=""
                        onClick={handleClose}
                    />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Body>
                    <div
                        style={{
                            maxHeight: "580px",
                            overflowX: "scroll",
                            scrollbarWidth: "none",
                        }}
                    >
                        <div className="row create-workflow-topbody pb-4">
                            <div className="col-lg-12  d-flex align-items-center gap-2 mainBtnsSub">
                                <button className={isSubscriptionWorkFlow ? 'btn-width saveBtn ' : 'active all-anc-btn-sub-header'}
                                    onClick={() => {
                                        setIsSubscriptionWorkflow(true)
                                        handleChange("subscriptionIds", [''])
                                        handleChange("entityId", 0)
                                        getSubsciptions(1)
                                    }}
                                >Subscription</button>
                                <button className={!isSubscriptionWorkFlow ? 'btn-width saveBtn ' : 'active all-anc-btn-sub-header'}
                                    onClick={() => {
                                        setIsSubscriptionWorkflow(false)
                                        handleChange("subscriptionIds", [''])
                                        handleChange("entityId", 1)
                                        getSubsciptions(2)
                                    }}
                                >New Entity</button>

                            </div>
                            <div className="col-lg-4 mt-4">

                                <label htmlFor="" className="text-grey">
                                    Workflow
                                </label>
                                <select
                                    class="form-select tasKCategory1"
                                    aria-label="Default select example"
                                    value={
                                        state?.workflowId
                                    }
                                    onChange={(e) =>
                                        handleChange("workflowId", Number(e.target.value))
                                    }
                                >
                                    <option value={""} disabled>
                                        Select Workflow
                                    </option>
                                    {workflows?.map((workflow) => {
                                        return <option value={workflow?.id}>{workflow?.name}</option>;
                                    })}
                                </select>
                                <p className="text-danger">
                                    {isValided && !state?.workflowId &&
                                        "Please Select Subscription "}
                                </p>
                            </div>

                            <div className="col-lg-4  mt-6 d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    checked={state?.isRetrospective}
                                    onChange={(e) =>
                                        handleChange("isRetrospective", e.target.checked)
                                    }
                                />
                                <label htmlFor="" className="text-grey me-5 ml-1">
                                    Is retrospective ?
                                </label>
                            </div>
                            <div className="col-lg-4  mt-6 d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    checked={state?.isSequential}
                                    onChange={(e) =>
                                        handleChange("isSequential", e.target.checked)
                                    }
                                />
                                <label htmlFor="" className="text-grey me-5 ml-1">
                                    Is Sequential ?
                                </label>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <label htmlFor="" className="text-grey">
                                    Select Start Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control tasKCategory1"

                                    value={state?.beginDate?.split("T")[0]}
                                    onChange={(e) => handleChange("beginDate", e.target.value)}
                                />
                                <p className="text-danger">
                                    {isValided &&
                                        !state?.beginDate &&
                                        "Please Select Start Date "}
                                </p>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <label htmlFor="" className="text-grey">
                                    Select End Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control tasKCategory1"
                                    placeholder="Workflow Name"
                                    value={state?.endDate?.split("T")[0]}
                                    onChange={(e) => handleChange("endDate", e.target.value)}
                                />
                                <p className="text-danger">
                                    {isValided && !state?.endDate && "Please Select End Date "}
                                </p>
                            </div>
                            <div className="col-lg-4 mt-4">


                                <>
                                    <label htmlFor="" className="text-grey">
                                        Subscription
                                    </label>
                                    <select
                                        class="form-select tasKCategory1"
                                        aria-label="Default select example"
                                        value={
                                            state?.subscriptionIds ? state?.subscriptionIds[0] : ""
                                        }
                                        onChange={(e) =>
                                            handleChange("subscriptionIds", [Number(e.target.value)])
                                        }
                                    >
                                        <option selected value={""} disabled>
                                            Select Subscription
                                        </option>
                                        {subscription?.map((sub) => {
                                            return <option value={sub?.id}>{sub?.name}</option>;
                                        })}
                                    </select>
                                    <p className="text-danger">
                                        {isValided &&
                                            (!state?.subscriptionIds ||
                                                state?.subscriptionIds?.length === 0 ||
                                                state.subscriptionIds[0] === "") &&
                                            "Please Select Subscription "}
                                    </p>

                                </>

                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                    <div>
                        <button className="modal-cancel-btn"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <CommonButton
                            isLoading={isWorkflowCreating}
                            title={state?.id == 0 ? "Submit" : "Update"}
                            handlerFunction={handleSubmit}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        </div >
    )

}

export default WorkFlowMapping