import React, { useContext, useEffect, useState } from "react";

import "../../Inventory Management System/Styles/variation.css";

import Items from "../Items/Items.js";
import Category from "../Category/Category.js";
import Variations from "../Variations/Variations.js";
import Taxes from "../Tax/Taxes.js";
import { AppContext } from "../../../context/AppContext.js";
import Addons from "./Addons.js";
import TableArea from "../TextArea/Table.js";
import Discount from './Discount';

export default function Toptab({ activeTab, setActiveTab }) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const {setStoreMenusTabs,storeMenusTabs} =useContext(AppContext)

  const hadleMenuItems=(id)=>{
    setActiveTab(id)
    setStoreMenusTabs(id)
  }

  console.log("Menu Item Tabs>>>>",storeMenusTabs)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const tabs = [
    { id: "Items", label: "Items", content: <Items /> },
    { id: "Categories", label: "Categories", content: <Category /> },
    { id: "Variants", label: "Variants", content: <Variations /> },
    { id: "Taxes", label: "Taxes", content: <Taxes /> },
    { id: "Addons", label: "Addons", content: <Addons /> },
    { id: "Table/Area", label: "Table/Area", content: <TableArea /> },
    { id: "Discount", label: "Discount", content: <Discount/> },

  ];

  return (
    <div>
      {/* Tab Navigation */}
      <div
        className="flex border-b border-gray-300 col-md-12 bg-grey border-top border-bottom "
        style={{ flexDirection: isSmallScreen ? "column" : null }}
      >
        
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => hadleMenuItems(tab.id)}
            className={`py-2 px-4 text-sm font-medium font-size-14 font-family flex-1 ${tab.disabled ? "text-gray-400 cursor-not-allowed" : ""}`}
            style={activeTab === tab.id ? {backgroundColor:"#53B7E8", color:"#fff",border:"none"} : {border:"none",backgroundColor:"#fff"}}
            // className={`py-2 px-4 text-sm font-medium font-size-14 font-family flex-1 
            //   ${
            //     activeTab === tab.id
            //       ? "border-blue bg-white rounded-lg text-blue-600 font-weight-600"
            //       : "border-none font-weight-500"
            //   }
            //   ${tab.disabled ? "text-gray-400 cursor-not-allowed" : ""}
            // `}
            disabled={tab.disabled}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="">
        {tabs.map(
          (tab) =>
            activeTab === tab.id && (
              <div key={tab.id} className="text-gray-700">
                {tab.content}
              </div>
            )
        )}
      </div>
    </div>
  );
}
