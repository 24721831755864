import React, { useContext, useEffect, useState } from "react";

import "../Styles/spreadsheet.css";

// import actionImage from "../../../component/svg/eye.svg";
import actionImage from "../../../assets/images/Active1.svg";

import Modal from "react-bootstrap/Modal";
import BarGraph from "./Baragraph";
import { simpleGetCall, simplePatchCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { notifySuccess } from "../../../toaster/notify";
import { AppContext } from "../../../context/AppContext";
import Loader from "../../Loader";
import NoData from "../../../sharedComponent/NoData";
import eye from "../../../assets/images/view.svg";
export default function OnlineOrderActivity() {
  const orderStatusMap = {
    0: "Order Received",
    1: "Rider Assigned",
    2: "Picked Up",
    3: "Ready to Deliver",
    4: "Payment Done",
    5: "Delivered",
    6: "Returned",
    7: "Return Back to Warehouse",
    8: "Rider Arrived",
    9: "Delivery Person Reached for Delivery",
    10: "Cancelled",
    11: "Pending Refund",
    12: "Failed Refund",
    13: "Processed Refund",
    14: "Order Accepted",
  };
  const [selectedStatus, setSelectedStatus] = useState("all");

  const statusOptions = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "accepted", label: "Accepted" },
    { value: "cancelled", label: "Cancelled" },
    { value: "delivered", label: "Delivered" },
    { value: "ready_to_deliver", label: "Ready to Deliver" },
  ];
  // Updated function name
  useEffect(() => {
    if (typeof window !== "undefined") {
      // No need to include `require()` here because Bootstrap is already imported globally
    }
  }, []);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (id) => {
    fetchStatusIDView(id);
    setShowModal(true);
  };
  const [showChart, setShowChart] = useState(false);
  const [dataItems, setdataItems] = useState([]);
  const [ChatData, setChatData] = useState([]);
  const [dataView, setdataView] = useState({});
  const [selectedOption, setSelectedOption] = useState("last_2_days");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [OrderNumber, setOrderNumber] = useState("");
  const [Loading, setLoading] = useState(false);

  console.log("dataItems", dataItems.user_address);

  const { selectedStoreID, sidebar } = useContext(AppContext);

  useEffect(() => {
    if (selectedStoreID) {
      fetchStatusList();
    }
  }, [
    selectedStoreID,
    selectedOption,
    selectedStatus,
    startDate,
    endDate,
    OrderNumber,
  ]);

  useEffect(() => {
    if (selectedOption === "") {
      setStartDate("");
      setEndDate("");
    }
  }, [selectedOption]);

  const fetchStatusList = async () => {
    setLoading(true);

    try {
      const res = await simpleGetCall(
        // `${ApiConfig?.OrderList + selectedStoreID.value&recordType=%{}&status=all&start_date&end_date }`
        ` ${ApiConfig?.OrderList}?shop_id=${selectedStoreID}&recordType=${selectedOption}&status=${selectedStatus}&start_date=${startDate}&end_date=${endDate}&order_no=${OrderNumber}`
      );

      const itemlist = res.data;
      if (res.success) {
        setdataItems(itemlist);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  //
  const fetchStatusIDView = async (id) => {
    try {
      const res = await simpleGetCall(ApiConfig?.ORDER_VIEW_ID + id);
      console.log(res);

      const itemlist = res.data;
      if (res.success) {
        setdataView(itemlist);
      } else {
        setdataView();
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  const OrderStatusChange = async (newStatus, id) => {
    try {
      const requestBody = {
        status: newStatus,
        id: id, // Update if needed
      };

      const res = await simplePatchCall(
        `${ApiConfig?.CHANGE_STAUS_ORDER}`, // Ensure API endpoint is correct
        requestBody
      );
      if (res.success) {
        notifySuccess(res.message);
        fetchStatusList();
        fetchChartView();
      } else {
      }

      console.log("Response:", res);
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };

  useEffect(() => {
    fetchChartView();
  }, [selectedStoreID]);

  const fetchChartView = async () => {
    try {
      const res = await simpleGetCall(ApiConfig?.CHAT_VIEW + selectedStoreID);
      console.log(res);

      const itemlist = res.data;
      if (res.success) {
        setChatData(itemlist);
      } else {
        setdataView();
      }
    } catch (err) {
      console.error("Status API Error:", err);
    }
  };
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content ">
          <div className="sub-header-role ">
            <h6 className="mt-2">Stock List</h6>
          </div>

          <div>
            <div className="bg-white mt-6 p-4">
              <button
                className="create-btn-sub-header"
                type="button"
                onClick={() => setShowChart(!showChart)}
              >
                <img
                  src={"/images/square.svg"}
                  alt="Button Icon"
                  width={20}
                  height={20}
                />
                {showChart ? " Hide Chart" : " Show Chart"}
              </button>

              {/* Conditionally render the BarGraph */}
              {showChart && <BarGraph dataList={ChatData} />}
            </div>
            <div className="online-sub-div ">
              <div className="">
                <div>
                  <label className="my-2">Record Type</label>
                  <select
                    className="form-select "
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="last_2_days">Last 2 days records</option>
                    <option value="last_5_days">Last 5 days records</option>
                    <option value="">Select custom date range</option>
                  </select>
                </div>

                {/* Show date pickers only if 'custom' is selected */}
              </div>
              {selectedOption === "" && (
                <>
                  <div className="">
                    <label className="my-2">Start Date</label>
                    <input
                      type="date"
                      className="form-select "
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="my-2">End Date</label>
                    <input
                      type="date"
                      className="form-select "
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </>
              )}
              <div>
                <label className="my-2">Status</label>
                <div className="dropdown">
                  <button
                    className="btn btn-light d-flex justify-content-evenly align-items-center form-select"
                    style={{
                      width: "170px",
                      height: "40px",
                    }}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {
                      statusOptions.find(
                        (option) => option.value === selectedStatus
                      )?.label
                    }
                    <span className="" style={{ scale: "1.2" }}></span>
                  </button>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {statusOptions.map((option) => (
                      <li key={option.value}>
                        <button
                          className="dropdown-item"
                          onClick={() => setSelectedStatus(option.value)}
                        >
                          {option.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <label className="my-2">Order no:</label>
                  <input
                    className="form-select me-2 "
                    type="search"
                    placeholder="Order Number "
                    value={OrderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </div>
                <div className="d-flex align-items-center">
                  {/* <div style={{ marginTop: "29px" }}>
                    <button
                      type="button"
                      style={{height:"40px"}}

                      className="btn btn-primary"
                      onClick={(e) => {
                        setFilterApply(true);
                      }}
                    >
                      Apply
                    </button>
                  </div> */}
                  <div style={{ marginTop: "29px", float: "right" }}>
                    <button
                    style={{backgroundColor:"#53B7E8", color:"#fff", height: "40px"}}
                      type="button"
                      className="create-btn-sub-header"
                      onClick={(e) => {
                        setEndDate("");
                        setStartDate("");
                        setOrderNumber("");
                        setSelectedOption("a");
                        setSelectedStatus("all");
                      }}
                    >
                      Show All
                    </button>
                  </div>
                  {/* <div style={{ marginTop: "25px" }}>
              <button type="button" className="btn btn-light bt-1">
                Export Custom Report
              </button>
            </div> */}
                </div>
              </div>
            </div>

            {Loading ? (
              <Loader />
            ) : (
              <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                <table className="table">
                  <thead>
                    <tr className="bg-grey font-family font-weight-400 font-size-14  ">
                      <th scope="col" className="order-head">
                        Order no
                      </th>
                      <th scope="col" className="order-head">
                        Order Name
                      </th>
                      <th scope="col" className="order-head">
                        Order Type
                      </th>
                      <th scope="col" className="order-head">
                        Customer Details
                      </th>
                      <th scope="col" className="order-head">
                        OTP
                      </th>
                      <th scope="col" className="order-head">
                        Date & Time
                      </th>
                      <th scope="col" className="order-head">
                        Total
                      </th>
                      <th scope="col" className="order-head">
                        Status
                      </th>
                      {/* <th scope="col" className="order-head">
                AT
              </th> */}
                      <th scope="col" className="order-head">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataItems && dataItems.length > 0 ? (
                      dataItems.map((row, index) => (
                        <tr key={index}>
                          <td scope="row" className="table-primary order-tdata">
                            {row.id} <br />({row.payment_type})
                          </td>
                          <td className="order-tdata">
                            {row.restaurant_name}
                            <br />[ {row.ondc_bap}]
                          </td>
                          <td className="order-tdata">
                            <td className="order-tdata">
                              {row.order_type === "H"
                                ? "Home Delivery"
                                : row.order_type === "P"
                                ? "Parcel"
                                : row.order_type === "D"
                                ? "Dine In"
                                : row.order_type}
                            </td>

                            {row.deliveryMode}
                          </td>
                          <td className="order-tdata">
                            {row.first_name} <br />[{row.phone_number}]
                          </td>
                          <td className="order-tdata">{row.otp}</td>
                          <td className="order-tdata">
                            Created: {row.dateCreated} {row.timeCreated}
                            {/* Nested Data Example */}
                            {row.order_statuses &&
                              row.order_statuses.map((status, i) => (
                                <div key={i} className="nested-status">
                                  Status:{" "}
                                  {orderStatusMap[status.order_status] ||
                                    "Unknown Status"}{" "}
                                  <br />
                                  Created At: {status.created_at}
                                </div>
                              ))}
                            <br />
                          </td>
                          <td className="table-success order-tdata">
                            {row.total}
                          </td>
                          <td className="order-tdata">
                            {row.upcoming_status.upcoming_status != "" && (
                              <button
                                type="button"
                                style={{backgroundColor:"#53B7E8", color:"#fff"}}
                                className="btn  online-button"
                                onClick={(e) => {
                                  if (
                                    row.upcoming_status &&
                                    row.upcoming_status.upcoming_status !==
                                      undefined
                                  ) {
                                    OrderStatusChange(
                                      row.upcoming_status.upcoming_status,
                                      row.id
                                    );
                                  } else {
                                    console.warn(
                                      "Upcoming status is not available"
                                    );
                                  }
                                }}
                              >
                                <label className="" >
                                  {row.upcoming_status.upcoming_status_name}
                                </label>
                              </button>
                            )}
                          </td>
                          {/* <td className="order-tdata">{row.at}</td> */}
                          <td>
                            <img
                              onClick={() => {
                                handleShowModal(row.id);
                              }}
                              src="images/eye.svg"
                              alt="Button Icon"
                              width={20}
                              height={20}
                              style={{
                                width: "16.67px",
                                height: "16.67px",
                                cursor: "pointer",
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                {dataItems.length > 0 ? (
                  <>
                  
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <NoData />
                    </div>
                  </>
                )}
              </div>
            )}

            <Modal
              style={{ maxHeight: "900px", overflowY: "scroll" }}
              centered
              size="xl"
              show={showModal}
              onHide={handleCloseModal}
            >
              <Modal.Header>
                <Modal.Title>
                  <h5>Online Order Details</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-3 py-1">
                <div className="row border rounded-3 mx-2">
                  <div className="col-4 d-flex flex-column my-2">
                    <p className="mb-0 fw-bold">Order No:</p>
                    <p>{dataView?.order_id || ""}</p>
                  </div>
                  <div className="col-4 d-flex flex-column my-2">
                    <p className="mb-0 fw-bold">Order From:</p>
                    <p>{dataView?.ondc_bap || ""}</p>
                  </div>
                  <div className="col-4 d-flex flex-column my-2">
                    <p className="mb-0 fw-bold">Custome Name:</p>
                    <p>{dataView?.user_address?.user_name || "N/A"}</p>
                  </div>
                  <hr />
                  <div className="col-4 d-flex flex-column">
                    <p className="mb-0 fw-bold">Customer Phone:</p>
                    <p>
                      {dataView?.user_address?.user_address_mobile || "N/A"}
                    </p>
                  </div>
                  <div className="col-4 d-flex flex-column ">
                    <p className="mb-0 fw-bold">Customer Address:</p>
                    <p>
                      <p>{dataView?.user_address?.user_address || "N/A"}</p>
                    </p>
                  </div>
                  <div className="col-4 d-flex flex-column ">
                    <p className="mb-0 fw-bold">Number of Persons:</p>
                    <p>-</p>
                  </div>
                  <hr />
                  <div className="col-4 d-flex flex-column">
                    <p className="mb-0 fw-bold">Order Type:</p>
                    <p>
                      {/* {dataView?.order_type === "H"
                      ? "Home Delivery"
                      : dataView.order_type === "P"
                      ? "Parcel"
                      : dataView.order_type === "D"
                      ? "Dine In"
                      : dataView.order_type || ""} */}
                    </p>
                  </div>
                  <div className="col-4 d-flex flex-column">
                    <p className="mb-0 fw-bold">Payment Type:</p>
                    <p>{dataView?.payment_type || ""}</p>
                  </div>
                  <div className="col-4 d-flex flex-column">
                    <p className="mb-0 fw-bold">Order Status:</p>
                    <p>
                      {orderStatusMap[dataView?.order_status] ||
                        "Unknown Status"}
                    </p>
                  </div>
                  <hr />
                  <div className="col-4 d-flex flex-column">
                    <p className="mb-0 fw-bold">Restaurant</p>
                    <p>
                      {dataView?.restaurant_address?.restaurant_address ||
                        "Unknown Status"}
                    </p>
                  </div>
                  <hr />
                  <div className="col-4 d-flex flex-column">
                    <p className="mb-0 fw-bold">Customer Notes:</p>
                    <p>{dataView?.order_special_note || ""}</p>
                  </div>
                </div>
                <hr />
                <p className="fw-bold ms-3">Online Order Items</p>
                <hr />

                <table className="table">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Special Note</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataView?.order_items?.map((item, index) => {
                      const orderItemPrice =
                        parseFloat(item.order_item_price) || 0;
                      // const variationPrice = parseFloat(item.order_item_variation?.variation_price) || 0;
                      const quantity = parseInt(item.order_item_quantity) || 1;

                      // Calculate Addon Total Price
                      const addonTotalPrice =
                        item.order_item_addon?.reduce((sum, addon) => {
                          const addonPrice = parseFloat(addon.addon_price) || 0;
                          const addonQuantity =
                            parseInt(addon.addon_quantity) || 1;
                          return sum + addonPrice * addonQuantity;
                        }, 0) || 0;

                      // Total Price Calculation
                      const totalUnitPrice = orderItemPrice + addonTotalPrice;
                      const totalPrice = totalUnitPrice * quantity;

                      return (
                        <tr key={index}>
                          {/* Item Name */}
                          <td>
                            {item.order_item_name} (
                            {item.order_item_variation?.variation_name})
                          </td>

                          {/* Special Note / Description */}
                          <td>
                            {/* Addon Mapping */}
                            {item.order_item_addon?.map((addon, addonIndex) => (
                              <tr key={`addon-${index}-${addonIndex}`}>
                                <td style={{ textDecoration: "underline" }}>
                                  {" "}
                                  {addon.addon_group_name}:{addon.addon_name}{" "}
                                </td>
                                <td></td>
                                <td>{addon.addon_quantity}</td>
                              </tr>
                            ))}
                            {item.order_item_description || "--"}
                          </td>

                          {/* Quantity */}
                          <td>{quantity}</td>

                          {/* Unit Price (Including Addons) */}
                          <td>{totalUnitPrice.toFixed(2)}</td>

                          {/* Total Price */}
                          <td>{totalPrice.toFixed(2)}</td>
                        </tr>
                      );
                    })}

                    {/* Addons Mapping */}

                    {/* Tax Mapping */}
                    {dataView?.tax?.map((tax, taxIndex) => (
                      <tr key={`tax-${taxIndex}`}>
                        <td colSpan={3}></td>
                        <td>
                          {tax.order_tax_title} ({tax.order_tax_price}){" "}
                          {tax.order_tax_type === "F" ? "" : "%"}
                        </td>
                        <td>
                          {parseFloat(tax.restaurant_liable_amount).toFixed(2)}
                        </td>
                      </tr>
                    ))}

                    {/* Delivery Charges */}
                    {dataView?.order_discount_price != "" && (
                      <tr>
                        <td colSpan={3}></td>
                        <td>Discount</td>
                        <td>{dataView?.order_discount_price || "0.00"}</td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={3}></td>
                      <td>Delivery Charges</td>
                      <td>{dataView?.delivery_charges || "0.00"}</td>
                    </tr>

                    {/* Grand Total */}
                    <tr style={{ fontWeight: "bold" }}>
                      <td colSpan={3}></td>
                      <td>Grand Total</td>
                      <td>
                        {dataView?.bill_details?.[0]?.total_price || "0.00"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}
